import React, { Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ClockIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { styleHelper } from 'helpers';

import Text, { TextProps } from '../Text';

type SelectProps = {
	list?: ObjectKey[];
	placeholder?: string;
	keyObj?: string;
	sizing?: string;
	spacing?: string;
	clear?: boolean;
	setClear?: React.Dispatch<React.SetStateAction<boolean>>;
	iconType?: 'chevron' | 'arrow';
	border?: string;
	shadow?: string;
	wrapperClassName?: string;
	onChange?: any;
	state?: any;
	setState?: any;
	type?: 'default' | 'time-range';
	disabled?: boolean;
	width?: string;
	isPortfolio?: boolean;
	minWidth?: string;
	listBoxTextSize?: string;
	height?: string;
	isFullWidth?: boolean;
};

const Select: React.FC<SelectProps> = ({
	list,
	placeholder,
	keyObj,
	sizing = 'w-48',
	spacing = 'pl-5 pr-10 py-2',
	clear,
	setClear,
	iconType = 'chevron',
	border = 'border-gray-300 border-solid border ',
	shadow = 'shadow-sm',
	wrapperClassName,
	onChange,
	state = { id: 0 },
	setState,
	type = 'default',
	disabled = false,
	width = 'fit-content',
	isPortfolio = false,
	minWidth = '200px',
	listBoxTextSize = 'text-sm',
	height, 
	isFullWidth = true,
}) => {
	// const [selected, setSelected] = useState<ObjectKey | null>(null);
	const selectClassNames = styleHelper.classNames(
		`${ disabled ? 'bg-gray-300' : 'bg-white'
		} relative rounded-lg cursor-pointer hover:outline-none hover:ring-1 text-sm`,
		sizing,
		spacing,
		border,
		shadow,
		wrapperClassName,
		height
	);
	useEffect(() => {
		if (list && keyObj) {
			const idxSelected = list.findIndex(data => data[keyObj] === placeholder);
			if (idxSelected > -1) {
				setState && setState(list[idxSelected]);
				onChange && onChange(list[idxSelected]);

				setClear && setClear(false);
			}
		}
	}, []);

	useEffect(() => {
		if (list && clear) {
			setState && setState(list[0]);
			onChange && onChange(list[0]);
		}
	}, [clear]);

	const renderIconSelect = (open: boolean) => {
		return open ? (
			<ChevronUpIcon className='h-5 w-5 text-gray-700' aria-hidden='true' />
		) : (
			<ChevronDownIcon className='h-5 w-5 text-gray-700' aria-hidden='true' />
		);
	};

	const onChangeListbox = selected => {
		if (setState) {
			setState(selected);
		}
		if (onChange) {
			onChange(selected);
		}
	};
	const selectedName = isPortfolio ? state?.title : state?.name || state?.type || state?.full_name_doctor;

	return (
		<Listbox value={ state } onChange={ onChangeListbox } disabled={ disabled }>
			{ ({ open }) => {
				const selectedTextProps: TextProps = {
					weight: 'font-normal',
					cursor: 'cursor-pointer',
					align: 'text-left',
					className: 'truncate',
					size: listBoxTextSize
				};
				const selectedText = !selectedName ? placeholder : state[keyObj || 'id'];
				return (
					<>
						<div className='group'>
							<Listbox.Button
								className={ selectClassNames }
								style={ {
									width,
									minWidth: minWidth
								} }
							>
								{ type === 'time-range' && (
									<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
										<ClockIcon className='w-5 h-5 text-gray-400' />
									</div>
								) }
								<span className='flex items-start'>
									<Text { ...selectedTextProps } className={ type === 'time-range' ? 'ml-5' : '' }>
										{ selectedText }
									</Text>
								</span>
								<span className='absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none'>{ renderIconSelect(open) }</span>
							</Listbox.Button>

							<Transition show={ open } as={ Fragment } leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
								<Listbox.Options className={ `absolute z-10 mt-3 ${ isFullWidth ? 'w-10/12' : 'w-max' } bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto hover:outline-none sm:text-sm`}>
									{ list && list.length ? (
										<React.Fragment>
											{ list.map((data: ObjectKey, index: number) => (
												<Listbox.Option
													key={ index }
													className={ ({ active }) =>
														styleHelper.classNames(
															active ? 'text-white rspi-main-bg' : 'text-hydeBlack-900',
															'cursor-pointer select-none relative py-2 pl-3 pr-9'
														)
													}
													value={ data }
												>
													{ ({ selected, active }) => {
														const textProps: TextProps = {
															weight: selected ? 'font-semibold' : 'font-normal',
															className: styleHelper.classNames(active ? ' rspi-main-bg' : '', 'truncate'),
															color: active ? 'text-white rspi-main-bg' : 'text-hydeBlack-900',
															cursor: 'cursor-pointer',
															size: 'text-sm'
														};
														return (
															<>
																{ type === 'time-range' && (
																	<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
																		<ClockIcon className='w-5 h-5 text-gray-400' />
																	</div>
																) }
																<Text { ...textProps } className={ type === 'time-range' ? 'ml-7' : '' }>
																	{ data[keyObj || 'id'] }
																</Text>
																{ selected ? (
																	<span
																		className={ styleHelper.classNames(
																			active ? 'text-white' : 'text-moss-100',
																			'font-primary absolute inset-y-0 right-0 flex items-center pr-4 text-sm'
																		) }
																	>
																		<CheckIcon className='h-5 w-5' aria-hidden='true' />
																	</span>
																) : null }
															</>
														);
													} }
												</Listbox.Option>
											)) }
										</React.Fragment>
									) : (
										<Text color='text-hydeDarkGrey' align='text-center' spacing='p-5'>
											Empty
										</Text>
									) }
								</Listbox.Options>
							</Transition>
						</div>
					</>
				);
			} }
		</Listbox>
	);
};

export default Select;
