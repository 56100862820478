import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_LIST_BANNERS, GET_BANNER_DETAILS } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllBanners = (keyword: string, page: number, is_publish: boolean | '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get banner list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllBanners(keyword, page, is_publish));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_BANNERS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get banner list end'));
  };
};

export const getBannerDetails = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get banner detail begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getBannerDetails(id));
      if (code === 200) {
        await dispatch({
          type: GET_BANNER_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get banner detail end'));
  };
};

export const deleteBanner = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete banner begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteBanner(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Banner');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete banner end'));
  };
};

export const addBanner = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add banner begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addBanner(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Banner');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add banner end'));
  };
};

export const editBanner = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit banner begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editBanner(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Banner');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit banner end'));
  };
};