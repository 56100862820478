import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormArrayType, ParamsType } from 'interfaces/common';
import { hooks } from 'helpers';
import { debounce } from 'helpers/misc';
import { selectors } from 'store/selectors';
import {
	getAllListSpecialties, stopLoading
} from 'store/actions';
import { addGroupSpecialties, deleteGroupSpecialties } from 'store/Specialties/action';

const useListSpecialties = () => {
	const dispatch = hooks.useAppDispatch();
	const isEdit = location.pathname.includes('edit') || location.pathname.includes('view');
	const history = useHistory();
	const thTitles: string[] = [
		'NAME',
		'TOTAL SPECIALTIES',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		name: ''
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const [author, setAuthor] = useState<any[]>([]);
	const onDeleteAuthor = (tagIndex: number) => setAuthor(author.filter((_, i) => i !== tagIndex));
	const onAddAuthor = newTag => {
		setAuthor([newTag]);
	};

	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.groupSpecialties);
	const { data: dropdownSpecialties } = hooks.useAppSelector(selectors.specialities);

	const renameKey = (obj) => {
		obj['name'] = obj['speciality'];
		obj['id'] = obj['specialty_code'];
	};
	dropdownSpecialties.forEach(obj => renameKey(obj));
	const [specialties, setSpecialties] = useState<any[]>([]);
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		});
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const refreshData = () => {
		history.push('/list-specialties');
		dispatch(getAllListSpecialties('', 1, 10));
	};

	const onClickSubmit = async () => {
		const validateForm = () => {
			if (!form.name.trim().length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (specialties.length > 0 && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
		};

		const validation =
			specialties.length > 0 &&
			form.name.trim().length;
		
		if (validation) {
			await dispatch(addGroupSpecialties(form.name, {
				speciality: [...specialties.map(item => item.id)],
			}, () => refreshData()));
		}
		else {
			validateForm();
		}
	};

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllListSpecialties(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, 10));
	};

	const onDeleteGroupSpecialties = (id: string) => {
		console.log;
	};

	const onSearchFooter = debounce(e => {
		dispatch(getAllListSpecialties(e[0].target.value, 1, 10));
	});

	const effectFunctions = () => {
		if (details) {
			setForm({
				...form,
				name: details?.speciality_category
			});
			setSpecialties(details?.specialty?.map((item) => ({
				id: item.specialty_code,
				name: item.speciality
			})));
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Group Specialties Name',
			name: 'name',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Group Specialties Name',
		},
		{
			index: 1,
			label: 'Group Specialties',
			name: 'tags',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: dropdownSpecialties,
			renderType: 'tags',
			placeholder: 'Group Specialties',
			tagType: 'specialties',
		},
	];
	const onDeleteSpecialties = (id: string) => {
		dispatch(deleteGroupSpecialties(id, () => {
			refreshData()
		}));
	};

	const onAddSpecialties = (newTag: Record<string, any>) => {
		const alreadyAddTag = specialties.find(tag => tag.name === newTag.name)?.name;
		if (!alreadyAddTag) setSpecialties([...specialties, newTag]);
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		onChangeForm,
		onClickSubmit,
		data,
		details,
		paginationFunction,
		pagination,
		onDeleteGroupSpecialties,
		onSearchFooter,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		editor,
		onChangeEditor,
		setEditor,
		dropdownSpecialties,
		onDeleteAuthor,
		onAddAuthor,
		author,
		setAuthor,
		specialties,
		onDeleteSpecialties,
		onAddSpecialties,
		isEdit
	};
};

export default useListSpecialties;