import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { useEffect } from 'react';
import { getAllListSpecialties } from 'store/actions';
import useFooter from './useListSpecialties';

const ListSpecialties = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteSpecialties,
    onSearchFooter,
	} = useFooter();

  useEffect(() => {
		dispatch(getAllListSpecialties('', 1, 10));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create List Specialties'
      thTitles={ thTitles }
      editLink='list-specialties/edit'
      viewLink='list-specialties/view'
      items={ data }
			title='List Specialties'
      type='list_specialties'
      createLink='list-specialties/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteSpecialties }
			onChangeSearch={ onSearchFooter }
      withFilter={ true }
			getData={ getAllListSpecialties }
    />
  );
};

export default ListSpecialties;