import { Pagination } from 'interfaces/common';
import {
  BannersActionTypes, GET_BANNER_DETAILS, GET_LIST_BANNERS
} from './actionType';

interface EventAndClassesState {
  data: any;
  details: any;
  pagination: Pagination;
}

const initState: EventAndClassesState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  }
};

const BannersReducer = (state = initState, action: BannersActionTypes) => {
  switch (action.type) {
    case GET_LIST_BANNERS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_BANNER_DETAILS:
      return {
        ...state,
        details: action.details
      };
    default:
      return state;
  }
};

export default BannersReducer;