import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { FormArrayType, ParamsType } from 'interfaces/common';
import {
	addBookingSetting, getClinicsByHospitalCode, getAllDoctorsDropdown, getBookingConfigDetail, updateBookingSetting, getAllHospitals
} from 'store/actions';
import { useParams } from 'react-router-dom';
import { error } from 'console';

export default function useBookingSettings () {
	const history = useHistory();
	const location = useLocation();
	const isDoctorSetting = location.pathname.includes('doctor-setting');
	const dispatch = hooks.useAppDispatch();
	const { data: dataHospitals } = hooks.useAppSelector(selectors.hospitals);
	const { list: doctors } = hooks.useAppSelector(selectors.doctors);
	const { details: detailBooking } = hooks.useAppSelector(selectors.bookingConfig);
	const isEdit = location.pathname.includes('edit');
	const isView = location.pathname.includes('view');
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'HOSPITAL',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});

	const [form, setForm] = useState({
		hospital: '',
		doctor: '',
		speciality: '',
		max_slot: '',
		percent_slot: '',
		interval_time: '',
		slot_availability: '',
		max_slot_availability: '',
		max_allowed_time_minutes: '',
		max_allowed_time_days: '',
		max_number_not_arrived: '',
		blacklist_duration: '',
		calendar_entries: '',
		custom_message_idn: '',
		custom_message_en: '',
	});
	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.doctors);

	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Active',
			value: true
		}, {
			id: 2,
			name: 'Inactive',
			value: false
		}
	];
	const timeHourData: Record<string, any>[] = [
		{
			id: 0,
			name: '00',
			value: '00'
		},
		{
			id: 1,
			name: '01',
			value: '01'
		},
		{
			id: 2,
			name: '02',
			value: '02'
		},
		{
			id: 3,
			name: '03',
			value: '03'
		},
		{
			id: 4,
			name: '04',
			value: '04'
		},
		{
			id: 5,
			name: '05',
			value: '05'
		},
		{
			id: 6,
			name: '06',
			value: '06'
		},
		{
			id: 7,
			name: '07',
			value: '07'
		},
		{
			id: 8,
			name: '08',
			value: '08'
		},
		{
			id: 9,
			name: '09',
			value: '09'
		},
		{
			id: 10,
			name: '10',
			value: '10'
		},
		{
			id: 11,
			name: '11',
			value: '11'
		},
		{
			id: 12,
			name: '12',
			value: '12'
		},
		{
			id: 13,
			name: '13',
			value: '13'
		},
		{
			id: 14,
			name: '14',
			value: '14'
		},
		{
			id: 15,
			name: '15',
			value: '15'
		},
		{
			id: 16,
			name: '16',
			value: '16'
		},
		{
			id: 17,
			name: '17',
			value: '17'
		},
		{
			id: 18,
			name: '18',
			value: '18'
		},
		{
			id: 19,
			name: '19',
			value: '19'
		},
		{
			id: 20,
			name: '20',
			value: '20'
		},
		{
			id: 21,
			name: '21',
			value: '21'
		},
		{
			id: 22,
			name: '22',
			value: '22'
		},
		{
			id: 23,
			name: '23',
			value: '23'
		},
	];
	const timeMinuteData: Record<string, any>[] = [
		{
			id: 0,
			name: '00',
			value: '00'
		},
		{
			id: 1,
			name: '01',
			value: '01'
		},
		{
			id: 2,
			name: '02',
			value: '02'
		},
		{
			id: 3,
			name: '03',
			value: '03'
		},
		{
			id: 4,
			name: '04',
			value: '04'
		},
		{
			id: 5,
			name: '05',
			value: '05'
		},
		{
			id: 6,
			name: '06',
			value: '06'
		},
		{
			id: 7,
			name: '07',
			value: '07'
		},
		{
			id: 8,
			name: '08',
			value: '08'
		},
		{
			id: 9,
			name: '09',
			value: '09'
		},
		{
			id: 10,
			name: '10',
			value: '10'
		},
		{
			id: 11,
			name: '11',
			value: '11'
		},
		{
			id: 12,
			name: '12',
			value: '12'
		},
		{
			id: 13,
			name: '13',
			value: '13'
		},
		{
			id: 14,
			name: '14',
			value: '14'
		},
		{
			id: 15,
			name: '15',
			value: '15'
		},
		{
			id: 16,
			name: '16',
			value: '16'
		},
		{
			id: 17,
			name: '17',
			value: '17'
		},
		{
			id: 18,
			name: '18',
			value: '18'
		},
		{
			id: 19,
			name: '19',
			value: '19'
		},
		{
			id: 20,
			name: '20',
			value: '20'
		},
		{
			id: 21,
			name: '21',
			value: '21'
		},
		{
			id: 22,
			name: '22',
			value: '22'
		},
		{
			id: 23,
			name: '23',
			value: '23'
		},
		{
			id: 24,
			name: '24',
			value: '24'
		},
		{
			id: 25,
			name: '25',
			value: '25'
		},
		{
			id: 26,
			name: '26',
			value: '26'
		},
		{
			id: 27,
			name: '27',
			value: '27'
		},
		{
			id: 28,
			name: '28',
			value: '28'
		},
		{
			id: 29,
			name: '29',
			value: '29'
		},
		{
			id: 30,
			name: '30',
			value: '30'
		},
		{
			id: 31,
			name: '31',
			value: '31'
		},
		{
			id: 32,
			name: '32',
			value: '32'
		},
		{
			id: 33,
			name: '33',
			value: '33'
		},
		{
			id: 34,
			name: '34',
			value: '34'
		},
		{
			id: 35,
			name: '35',
			value: '35'
		},
		{
			id: 36,
			name: '36',
			value: '36'
		},
		{
			id: 37,
			name: '37',
			value: '37'
		},
		{
			id: 38,
			name: '38',
			value: '38'
		},
		{
			id: 39,
			name: '39',
			value: '39'
		},
		{
			id: 40,
			name: '40',
			value: '40'
		},
		{
			id: 41,
			name: '41',
			value: '41'
		},
		{
			id: 42,
			name: '42',
			value: '42'
		},
		{
			id: 43,
			name: '43',
			value: '43'
		},
		{
			id: 44,
			name: '44',
			value: '44'
		},
		{
			id: 45,
			name: '45',
			value: '45'
		},
		{
			id: 46,
			name: '46',
			value: '46'
		},
		{
			id: 47,
			name: '47',
			value: '47'
		},
		{
			id: 48,
			name: '48',
			value: '48'
		},
		{
			id: 49,
			name: '49',
			value: '49'
		},
		{
			id: 50,
			name: '50',
			value: '50'
		},
		{
			id: 51,
			name: '51',
			value: '51'
		},
		{
			id: 52,
			name: '52',
			value: '52'
		},
		{
			id: 53,
			name: '53',
			value: '53'
		},
		{
			id: 54,
			name: '54',
			value: '54'
		},
		{
			id: 55,
			name: '55',
			value: '55'
		},
		{
			id: 56,
			name: '56',
			value: '56'
		},
		{
			id: 57,
			name: '57',
			value: '57'
		},
		{
			id: 58,
			name: '58',
			value: '58'
		},
		{
			id: 59,
			name: '59',
			value: '59'
		},
	];
	const formPinned: Record<string, any>[] = [
		{
			id: 1,
			name: 'Pinned',
			value: true
		}, {
			id: 2,
			name: 'Unpinned',
			value: false
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: '',
		value: ''
	});
	const [selectedHospital, setSelectedHospital] = useState({
		id: 0,
		name: '',
		hospital_code: '',
		language: '',
	});
	const [selectedSpeciality, setSelectedSpeciality] = useState({
		id: 0,
		name: '',
		value: ''
	});
	const [selectedHourStart, setSelectedHourStart] = useState({
		id: 99,
		name: '-',
		value: '-'
	});
	const [selectedMinuteStart, setSelectedMinuteStart] = useState({
		id: 99,
		name: '-',
		value: '-'
	});
	const [selectedHourEnd, setSelectedHourEnd] = useState({
		id: 99,
		name: '-',
		value: '-'
	});
	const [selectedMinuteEnd, setSelectedMinuteEnd] = useState({
		id: 99,
		name: '-',
		value: '-'
	});
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [isTimeChanged, setIsTimeChanged] = useState<boolean>(false);

	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		});
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
			if (e.target.name === 'slot_availability' || e.target.name === 'max_slot_availability') {
				setErrorForm(errorForm.splice(0, 1));
				setErrorForm(errorForm.splice(1, 1));
			}

			if (e.target.name === 'percent_slot' || e.target.name === 'max_slot') {
				setErrorForm(errorForm.splice(3, 1));
				setErrorForm(errorForm.splice(4, 1));
			}
		}
	};
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [doctor, setDoctor] = useState<any>({});

	const formArrayDoctorSetting: FormArrayType[] = [
		{
			index: 0,
			label: 'Hospital',
			name: 'hospital',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: dataHospitals,
			renderType: 'select',
			placeholder: 'Select Hospital First',
			selectType: 'hospital'
		},
		{
			index: 1,
			label: 'Doctor Name',
			name: 'doctor',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: doctors,
			renderType: 'dropdown_search',
			placeholder: 'Select Doctor',
			tagType: 'doctor'
		},
		{
			index: 2,
			label: 'Max Slot',
			name: 'max_slot',
			isMandatory: true,
			disabledCondition: isLoading || Number(form.percent_slot) > -1,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 3,
			label: 'Percent Slot %',
			name: 'percent_slot',
			isMandatory: true,
			disabledCondition: isLoading || Number(form.max_slot) > -1,
			listSelect: formStatus,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 4,
			label: 'Interval Time',
			name: 'interval_time',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input interval time per slot in minutes',
		},
		{
			index: 5,
			label: 'Max Number Not Attended',
			name: 'max_number_not_arrived',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input max number not attended',
		},
		{
			index: 6,
			label: 'Blacklist Duration (in days)',
			name: 'blacklist_duration',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input blacklist duration in days',
		},
		{
			index: 7,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 8,
			label: 'Calendar Entries (in days)',
			name: 'calendar_entries',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input calendar entries',
		},
	];

	const formArrayGlobalSetting: FormArrayType[] = [
		{
			index: 0,
			label: 'Max Slot Availability (value)',
			name: 'max_slot_availability',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 1,
			label: 'Slot Availability (%)',
			name: 'slot_availability',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 2,
			label: 'Max. Allowed Time (before appoinment) in minutes',
			name: 'max_allowed_time_minutes',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 3,
			label: 'Interval Time (in minutes)',
			name: 'interval_time',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Not Set',
		},
		{
			index: 4,
			label: 'Max Number Not Attended',
			name: 'max_number_not_arrived',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input max number not attended',
		},
		{
			index: 5,
			label: 'Blacklist Duration (in days)',
			name: 'blacklist_duration',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input blacklist duration in days',
		},
		{
			index: 6,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 7,
			label: 'Calendar Entries (in days)',
			name: 'calendar_entries',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'Please input calendar entries',
		},
	];

	const onDeleteDoctor = (tagIndex: number) => setDoctor(doctor.filter((_, i) => i !== tagIndex));
	const onAddDoctor = newTag => {
		setDoctor([newTag]);
	};

	useEffect(() => {
		if (!isDoctorSetting) {
			dispatch(getBookingConfigDetail('1'));
		}
	}, []);

	useEffect(() => {
		dispatch(getAllHospitals('', 1, true, 1000, 'idn'));
		setDoctor({});
		if (selectedHospital?.hospital_code && isDoctorSetting) {
			dispatch(getAllDoctorsDropdown(selectedHospital.hospital_code, 9999));
			dispatch(getClinicsByHospitalCode(selectedHospital.hospital_code, selectedHospital.language));

			setSelectedSpeciality({
				id: 0,
				name: '',
				value: ''
			});

			if (isDoctorSetting && (isEdit || isView)) {
				if (detailBooking?.doctor?.doctor_code) {
					setDoctor(
						{
							value: detailBooking?.doctor?.doctor_code,
							label: detailBooking?.doctor?.fullname_doctor
						}
					);
				}
			}
		}
	}, [selectedHospital]);

	const effectFunctions = () => {
		if (detailBooking && (id === detailBooking?.id.toString())) {
			setForm({
				...form,
				max_allowed_time_minutes: `${ detailBooking.max_allowed_time_in_minutes }`,
				interval_time: `${ detailBooking.interval_time }`,
				max_number_not_arrived: `${ detailBooking.max_number_not_arrived }`,
				blacklist_duration: `${ detailBooking.blacklist_duration }`,
				calendar_entries: `${ detailBooking.calendar_entries }`,
				custom_message_idn: `${ detailBooking.custom_message_idn }`,
				custom_message_en: `${ detailBooking.custom_message_en }`,
				slot_availability: detailBooking.slot_availability,
				percent_slot: detailBooking.slot_availability,
				max_slot_availability: detailBooking.max_slot_availability ?? '',
				max_slot: detailBooking.max_slot_availability ?? '',
			});
			setSelectedStatus(detailBooking?.is_active ?
				{
					id: 1,
					name: 'Active',
					value: true
				} : {
					id: 2,
					name: 'Inactive',
					value: true
				});
			if (isDoctorSetting) {
				setForm({
					...form,
					slot_availability: detailBooking.slot_availability,
					percent_slot: detailBooking.slot_availability,
					max_slot_availability: detailBooking.max_slot_availability ?? '',
					max_slot: detailBooking.max_slot_availability ?? '',
					max_allowed_time_minutes: `${ detailBooking.max_allowed_time_in_minutes }`,
					interval_time: `${ detailBooking.interval_time }`,
					max_number_not_arrived: `${ detailBooking.max_number_not_arrived }`,
					blacklist_duration: `${ detailBooking.blacklist_duration }`,
					calendar_entries: `${ detailBooking.calendar_entries }`,
					custom_message_idn: `${ detailBooking.custom_message_idn}`,
					custom_message_en: `${ detailBooking.custom_message_en }`,
				});
				if (detailBooking?.hospital?.hospital_code) {
					setSelectedHospital({
						id: 0,
						name: detailBooking?.hospital?.hospital_name,
						hospital_code: detailBooking?.hospital?.hospital_code,
						language: 'en',
					});
				}
				
				if (detailBooking?.doctor?.doctor_code) {
					setDoctor(
						{
							value: detailBooking?.doctor?.doctor_code,
							label: detailBooking?.doctor?.fullname_doctor
						}
					);
				}
			}
		}
	};

	const refreshData = () => {
		history.push(isDoctorSetting ? '/booking-doctor' : '/global-settings');
	};

	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (isDoctorSetting) {
				const { hospital_code } = selectedHospital;
				const {
					max_slot, percent_slot, interval_time, blacklist_duration, max_number_not_arrived
				} = form;
				if (!hospital_code.length && !errorForm.includes(0)) {
					setErrorForm(prev => [...prev, 0]);
				}

				if (!doctor?.['value'].length && !errorForm.includes(1)) {
					setErrorForm(prev => [...prev, 1]);
				}
				if (!max_slot && !errorForm.includes(2)) {
					if (!percent_slot) {
							setErrorForm(prev => [...prev, 2]);
					}
				}
				if (!percent_slot && !errorForm.includes(3)) {
					if (!max_slot) {
							setErrorForm(prev => [...prev, 3]);
					}
				}
				if (!interval_time.length && !errorForm.includes(4)) {
					setErrorForm(prev => [...prev, 4]);
				}
				if (!max_number_not_arrived.length && !errorForm.includes(5)) {
					setErrorForm(prev => [...prev, 5]);
				}
				if (!blacklist_duration.length && !errorForm.includes(6)) {
					setErrorForm(prev => [...prev, 6]);
				}
				if (!selectedStatus.name.length && !errorForm.length) {
					setErrorForm(prev => [...prev, 7]);
				}
			} else {
				const {
					slot_availability, max_slot_availability, max_allowed_time_minutes, max_allowed_time_days, interval_time, blacklist_duration, max_number_not_arrived
				} = form;

				if (!slot_availability.length && !errorForm.includes(0)) {
					if (!max_slot_availability.length) {
						setErrorForm(prev => [...prev, 1]);
					}
				}
				if (!max_slot_availability.length && !errorForm.includes(1)) {
					if (!slot_availability.length) {
						setErrorForm(prev => [...prev, 0]);
					}
				}
				if (!max_allowed_time_minutes.length && !errorForm.includes(2)) {
					setErrorForm(prev => [...prev, 2]);
				}
				if (!interval_time.length && !errorForm.includes(3)) {
					setErrorForm(prev => [...prev, 3]);
				}
				if (!max_number_not_arrived.length && !errorForm.includes(4)) {
					setErrorForm(prev => [...prev, 4]);
				}
				if (!blacklist_duration.length && !errorForm.includes(5)) {
					setErrorForm(prev => [...prev, 6]);
				}
				if (!selectedStatus.name.length && !errorForm.length) {
					setErrorForm(prev => [...prev, 7]);
				}
			}
		};
		console.log(form);
		const validationPost = isDoctorSetting ?
			selectedHospital.hospital_code.length &&
			doctor?.['value']?.length &&
			(form.max_slot || form.percent_slot) &&
			form.interval_time &&
			form.blacklist_duration &&
			form.max_number_not_arrived
			:
			(form.slot_availability || form.max_slot_availability) &&
			form.max_allowed_time_minutes.length &&
			form.interval_time.length &&
			form.blacklist_duration.length &&
			form.max_number_not_arrived.length;

		if (validationPost) {
			const _body = isDoctorSetting ? {
				hospital_code: selectedHospital.hospital_code,
				doctor_code: doctor?.['value'] ?? '',
				type: 'doctor',
				max_slot_availability: parseInt(form.max_slot),
				slot_availability: parseInt(form.percent_slot),
				interval_time: parseInt(form.interval_time), // minutes
				blacklist_duration: parseInt(form.blacklist_duration),
				max_number_not_arrived: parseInt(form.max_number_not_arrived),
				is_active: selectedStatus.value,
				calendar_entries: parseInt(form.calendar_entries),
				custom_message_en: form.custom_message_en,
				custom_message_idn: form.custom_message_idn,
			} : {
				type: 'global',
				slot_availability: parseInt(form.slot_availability),
				max_slot_availability: parseInt(form.max_slot_availability),
				max_allowed_time_in_minutes: parseInt(form.max_allowed_time_minutes),
				interval_time: parseInt(form.interval_time),
				blacklist_duration: parseInt(form.blacklist_duration),
				max_number_not_arrived: parseInt(form.max_number_not_arrived),
				is_active: selectedStatus.value,
				calendar_entries: parseInt(form.calendar_entries),
				custom_message_en: form.custom_message_en,
				custom_message_idn: form.custom_message_idn,
			};
			if (isEdit) {
				await dispatch(updateBookingSetting(_body, id, () => refreshData()));
			} else {
				if (isDoctorSetting) {
					await dispatch(addBookingSetting(_body, () => refreshData()));
				} else {
					await dispatch(updateBookingSetting(_body, 1, () => refreshData()));
				}
			}
		} else {
			validateForm();
		}
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		pagination,
		formPinned,
		selectedHospital,
		setSelectedHospital,
		formArrayDoctorSetting,
		formArrayGlobalSetting,
		effectFunctions,
		onClickSubmit,
		selectedSpeciality,
		setSelectedSpeciality,
		timeHourData,
		timeMinuteData,
		selectedHourStart,
		setSelectedHourStart,
		selectedMinuteStart,
		setSelectedMinuteStart,
		selectedHourEnd,
		setSelectedHourEnd,
		selectedMinuteEnd,
		setSelectedMinuteEnd,
		errorForm,
		setErrorForm,
		location,
		isDoctorSetting,
		onDeleteDoctor,
		onAddDoctor,
		doctor,
		doctors,
		getBookingConfigDetail,
		isEdit,
		isView,
		detailBooking,
		setIsTimeChanged,
		isTimeChanged,
		setDoctor,
	};
}