import { useEffect } from 'react';

import { hooks } from 'helpers';
import { getFooterBySlug } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import useFooter from '../useFooter';

const FooterForm = () => {
	const dispatch = hooks.useAppDispatch();
	const { formArray, getAllFootersCategory } = useFooter();

	useEffect(() => {
		dispatch(getAllFootersCategory('', 1, ''));
	}, []);

  return (
    <AdminForm
      formArray={ formArray }
      backLink='/footer'
			getDetailAction={ getFooterBySlug }
      isAbleToSubmit={ true }
      title='Footer'
      localHooks={ useFooter }
      type='footer'
    />
  );
};

export default FooterForm;