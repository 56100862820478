import { Pagination } from 'interfaces/common';
import {
	GET_ALL_MEDICAL_SPECIALITIES, MedicalSpecialityResponse, MedicalSpecialitiesActionTypes, GET_ALL_MEDICAL_SPECIALITIES_CATEGORIES, GET_MEDICAL_SPECIALITIES_DETAIL
} from './actionTypes';

interface FooterState {
  data: MedicalSpecialityResponse[];
	category: MedicalSpecialityResponse[];
  pagination: Pagination;
  details: any;
	slug_data: MedicalSpecialityResponse[];
}

const initState: FooterState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  details: {},
	category: [],
	slug_data: [],
};

const MedicalSpecialitiesReducer = (state = initState, action: MedicalSpecialitiesActionTypes) => {
  switch (action.type) {
		case 'GET_ALL_MEDICAL_SPECIALITIES':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
		case 'GET_ALL_MEDICAL_SPECIALITIES_CATEGORIES':
			return {
				...state,
				category: action.data,
				pagination: action.pagination
			};
		case 'GET_MEDICAL_SPECIALITIES_DETAIL':
			return {
				...state,
				details: action.details,
				pagination: action.pagination
			};
		case 'GET_ALL_MEDICAL_SPECIALITIES_SLUG':
			return {
				...state,
				slug_data: action.slug_data,
				pagination: action.pagination
			};
    default:
      return state;
  }
};

export default MedicalSpecialitiesReducer;