import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { useEffect } from 'react';
import { getBookingConfig } from 'store/actions';
import useBookingDoctor from './useBookingDoctor';
import { selectors } from 'store/selectors';

const BookingDoctor = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
		onDeleteBooking,
    onSearchBooking,
		selectedHospital,
		setSelectedHospital,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		formTypeBooking,
		selectedTypeBooking,
		setSelectedTypeBooking,
	} = useBookingDoctor();
	
	const { data: dataHospital } = hooks.useAppSelector(selectors.hospitals);
	
	const isGlobalSetting = location.pathname.includes('booking-global-setting');

	return (
    <AdminView
      cellType='edit_delete'
			createText={ isGlobalSetting ? 'Booking Global Setting' : 'Booking Doctor Setting'}
      thTitles={ thTitles }
      editLink=''
			viewLink='booking_config/view'
      items={ data }
			title={ isGlobalSetting ? 'Booking Global Setting' : 'Booking Doctor Setting' }
			type='booking_doctor'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
			onDeleteModalClick={ onDeleteBooking }
			onChangeSearch={ onSearchBooking }
      withFilter={ true }
			filter={ createFilterStatus(formStatus) }
			filterType={ createFilterStatus(formTypeBooking) }
			getData={ getBookingConfig }
			selectedStatus={ selectedStatus }
			setSelectedStatus={ setSelectedStatus }
			selectedTypes={ selectedTypeBooking }
			setSelectedTypes={ setSelectedTypeBooking }
			selectedHospital={ selectedHospital }
			setSelectedHospital={ setSelectedHospital }
			hospitalData={ [
				...dataHospital, {
					id: '',
					name: 'All',
					value: ''
				}
			] }
			isBookingGlobal={ isGlobalSetting }
    />
  );
};

export default BookingDoctor;