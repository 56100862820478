export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLE_ACCESS = 'GET_ALL_ROLE_ACCESS';
export const GET_ALL_ACCESS = 'GET_ALL_ACCESS';
export const GET_ROLE_DETAIL = 'GET_ROLE_DETAIL';
export const GET_ACCESS_DETAIL = 'GET_ACCESS_DETAIL';

export interface GetAllRoles {
  type: typeof GET_ALL_ROLES;
  data: any;
}

export interface GetAllRoleAccess {
  type: typeof GET_ALL_ROLE_ACCESS;
  role: any;
}

export interface GetAllAccess {
  type: typeof GET_ALL_ACCESS;
  access: any;
}
export interface GetRoleDetail {
  type: typeof GET_ROLE_DETAIL;
  detail: any;
}

export interface GetAccessDetail {
  type: typeof GET_ACCESS_DETAIL;
  accessDetail: any;
}

export type RBACActionTypes = GetAllRoles | GetAllRoleAccess | GetAllAccess | GetRoleDetail | GetAccessDetail;
