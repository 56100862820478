import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
	Combobox,
	Portal,
	Transition
} from '@headlessui/react';

import { Modifier, usePopper } from 'react-popper';
import { Spinner, Text } from 'components';
import { SelectValueType } from 'interfaces/geocodes';

type AsyncSelectProps = {
	loadOptions?: (
		inputValue: string,
		callback: (options?: SelectValueType[]) => void
	) => Promise<SelectValueType[]> | void;
	defaultOptions?: SelectValueType[];
	value: SelectValueType | undefined,
	onChange: (selected: SelectValueType) => void;
	loading?: boolean;
	label?: string;
	spacing?: string;
	padding?: string;
	labelClassName?: string;
	inputClassName?: string;
	textSize?: string;
	labelSize?: string;
	iconType?: 'arrow' | 'chevron';
	placeholder?: string;
	disabled?: boolean;
	clearAfterLeave?: boolean;
	queryVal?: string;
	isMandatory?: boolean;
	error?: number[],
	setError?: React.Dispatch<React.SetStateAction<number[]>>;
};

const AsyncSelect: React.FC<AsyncSelectProps> = ({
	loadOptions: propsLoadOptions,
	defaultOptions: propsDefaultOptions,
	value,
	onChange,
	loading,
	label,
	spacing = 'mt-2.5',
	padding = 'py-[0.813rem] px-5',
	labelClassName,
	inputClassName,
	textSize = 'text-base',
	labelSize = 'text-sm',
	iconType = 'arrow',
	placeholder,
	disabled,
	clearAfterLeave,
	queryVal,
	isMandatory = false
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const popperElRef = useRef<any>(null);

	const [targetElement, setTargetElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [query, setQuery] = useState<string | undefined>('');
	const [isTyping, setIsTyping] = useState<boolean>(false);
	const [loadedOptions, setLoadedOptions] = useState<SelectValueType[]>([]);
	const [defaultOptions, setDefaultOptions] = useState<SelectValueType[]>([]);

	const useDebounce = (value: any, delay: number) => {
		const [debouncedValue, setDebouncedValue] = useState(value);

		useEffect(() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		}, [value, delay]);

		return debouncedValue;
	};

	const options: SelectValueType[] = query
		? loadedOptions
		: (defaultOptions || []);
	const debouncedSearch = useDebounce(query, 800);

	const modifiers: Partial<Modifier<string, object>>[] = useMemo(() => [
		{
			name: 'sameWidth',
			enabled: true,
			fn: ({ state }) => {
				state.styles.popper.width = `${ state.rects.reference.width }px`;
			},
			phase: 'beforeWrite',
			requires: ['computeStyles']
		}, {
			name: 'offset',
			options: { offset: [0, 8] }
		}
	], []);

	const { styles, attributes } = usePopper(targetElement, popperElement, {
		placement: 'bottom-start',
		modifiers
	});

	const loadOptions = useCallback((inputValue: string, callback: (options?: SelectValueType[]) => void) => {
		if (!propsLoadOptions) return callback();
		const loader = propsLoadOptions(inputValue, callback);
		if (loader && typeof loader.then === 'function') {
			loader.then(callback, () => callback());
		}
	}, [propsLoadOptions]);

	useEffect(() => {
		if (queryVal && !isTyping && !clearAfterLeave) {
			setQuery(queryVal);
		}

		return () => {
			setQuery('');
		};
	}, [queryVal, clearAfterLeave]);

	useEffect(() => {
		if (propsDefaultOptions) {
			setDefaultOptions(propsDefaultOptions || []);
		}
	}, [loading]);

	useEffect(() => {
		loadOptions(debouncedSearch, (options?: SelectValueType[]) => {
			setLoadedOptions(options || []);

			setIsTyping(false);
		});

		return () => {
			setLoadedOptions([]);
		};
	}, [debouncedSearch]);

	const onChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;

		setIsTyping(true);

		setQuery(inputValue);
	};

	const renderIconSelect = (open: boolean) => {
		if (iconType === 'chevron')
			return open
				? <ChevronUpIcon className='h-5 w-5' aria-hidden='true' />
				: <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />;
	};

	const renderOptions = () => {
		if (loading) {
			return (
				<div className='my-12.5 center-content'>
					<Spinner height='h-7.5' width='w-7.5' />
				</div>
			);
		}

		if ((!options || !options?.length) && query !== '') {
			return (
				<div className='cursor-default select-none relative py-2 pl-3'>
					<Text size='text-sm'>Nothing found.</Text>
				</div>
			);
		}

		return (
			<>
				{ options.map((option: SelectValueType, index: number) => (
					<Combobox.Option
						key={ index }
						value={ option }
						className='cursor-pointer select-none relative py-2 pl-3 pr-9'
					>
						{ ({ active, selected: selectedDefault }) => {
							const selected = clearAfterLeave
								? `${ option.value }` === `${ value && value.value }`
								: selectedDefault;

							return (
								<>
									<Text
										weight={ selected ? 'font-semibold' : 'font-normal' }
										className='bg-hydeLightGrey'
										color='text-hydeBlack-900'
										cursor='cursor-pointer'
										size='text-sm'
									>{ option.label }</Text>

									{ selected && (
										<span className='absolute inset-y-0 right-0 flex items-center pr-4'>
											<CheckIcon className='h-5 w-5' aria-hidden='true' />
										</span>
									) }
								</>
							);
						} }
					</Combobox.Option>
				)) }
			</>
		);
	};

	return (
		<Combobox
			as='div'
			value={ value }
			onChange={ onChange }
			disabled={ disabled }
		>
			{ ({ open }) => (
				<div className='my-3'>
					<div className='flex'>
						<Combobox.Label className='block font-primary'>{ label }</Combobox.Label>
						{ isMandatory ? <Text text='*' color='text-red-500' size='text-xs' className='ml-1' /> : null }
					</div>
					<div onClick={ () => inputRef.current?.focus() }>
						<div ref={ setTargetElement }>
							{ !disabled
								? (
									<Combobox.Input
										ref={ inputRef }
										className={ `font-primary font-normal text-gray-900 placeholder-gray-500 border rounded-md focus:ring-moss-100 focus:border-moss-100 focus:z-10 sm:text-sm article-title w-full + ${ inputClassName }` }
										onChange={ onChangeQuery }
										displayValue={ (selected: SelectValueType) => (selected.value ? selected.label : selected.value) }
										placeholder={ value && !value.value ? placeholder : undefined }
										autoComplete='off'
										spellCheck={ false }
									/>
								)
								: (
									<div className='font-primary font-normal text-hydeBlack-900'>
										<Text color='text-[#A0A0A0]'>{ placeholder }</Text>
									</div>
								) }

							{ !disabled
								? (
									<Combobox.Button className='combobox-button'>
										<div className='absolute inset-y-0 right-0 pr-4.5 flex items-center'>
											{ renderIconSelect(open) }
										</div>
									</Combobox.Button>
								)
								: (
									<div className='absolute inset-y-0 right-0 pr-4.5 flex items-center'>
										{ renderIconSelect(open) }
									</div>
								)
							}
						</div>

						<Portal>
							<div
								ref={ popperElRef }
								style={ styles.popper }
								{ ...attributes.popper }
							>
								<Transition
									show={ open }
									enter='transition ease-out duration-100'
									enterFrom='transform opacity-0 scale-95'
									enterTo='transform opacity-100 scale-100'
									leave='transition ease-in duration-75'
									leaveFrom='transform opacity-100 scale-100'
									leaveTo='transform opacity-0 scale-95'
									beforeEnter={ () => setPopperElement(popperElRef.current) }
									afterLeave={ () => {
										setPopperElement(null);

										if (clearAfterLeave) setQuery('');
										else setQuery(value && value?.label);
									} }
								>
									<Combobox.Options static className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 overflow-auto custom-scrollbar hover:outline-none'>
										{ !disabled && renderOptions() }
									</Combobox.Options>
								</Transition>
							</div>
						</Portal>

					</div>
				</div>
			) }
		</Combobox>
	);
};

export default AsyncSelect;
