import { useEffect } from 'react';

import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllNews } from 'store/actions';
import { AdminView } from 'components';

import useNewsAndArticles from './useNewsAndActicles';
const NewsAndArticles = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteNews,
    onSearchNews,
    formStatus,
    selectedStatus,
    setSelectedStatus,
    selectedType,
    setSelectedType,
    formType
  } = useNewsAndArticles();

  useEffect(() => {
    dispatch(getAllNews('', 1, '', ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create News/Article'
      thTitles={ thTitles }
      editLink='news-articles/edit'
      viewLink='news-articles/view'
      items={ data }
      title='News and Articles'
      type='articles'
      createLink='news-articles/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteNews }
      onChangeSearch={ onSearchNews }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      filterType={ formType }
      getData={ getAllNews }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
      selectedTypes={ selectedType }
      setSelectedTypes={ setSelectedType }
    />
  );
};

export default NewsAndArticles;