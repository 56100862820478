import { Pagination } from 'interfaces/common';
import {
  EventAndClassesActionTypes, GET_EVENT_DETAILS, GET_LIST_EVENT, GET_LIST_EVENT_SLUG
} from './actionType';

interface DetailsType {
  category: string;
  content: string;
  created_date: string;
  description: string;
  hospital_id: number;
  hospital_name: string;
  id: number;
  img_url_card: string;
  img_url_detail: string;
  information: string;
  is_publish: boolean;
  operational_hour: string;
  phone: string;
  title: string;
  updated_date: string;
}

interface EventAndClassesState {
  data: any;
  details: DetailsType;
  pagination: Pagination;
	slug_data: any;
}

const initState: EventAndClassesState = {
  data: [],
  details: {
    category: '',
    content: '',
    created_date: '',
    description: '',
    hospital_id: 0,
    hospital_name: '',
    id: 0,
    img_url_card: '',
    img_url_detail: '',
    information: '',
    is_publish: false,
    operational_hour: '',
    phone: '',
    title: '',
    updated_date: ''
  },
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
	slug_data: [],
};

const EventAndClassesReducer = (state = initState, action: EventAndClassesActionTypes) => {
  switch (action.type) {
    case GET_LIST_EVENT:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_EVENT_DETAILS:
      return {
        ...state,
        details: action.details
      };
		case GET_LIST_EVENT_SLUG:
			return {
				...state,
				slug_data: action.data
			};
    default:
      return state;
  }
};

export default EventAndClassesReducer;