import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { misc } from 'constant';
import { hooks } from 'helpers';
import { debounce } from 'helpers/misc';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
	addBanner, deleteBanner, editBanner, getAllBanners
} from 'store/actions';

export default function useBanners () {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'DESCRIPTION',
		'IMAGE',
		'LANGUAGE',
		'STATUS'
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		title: '',
		description: '',
		img_url_idn: '',
		img_url_mobile_idn: '',
		img_url_en: '',
		img_url_mobile_en: '',
		url_link_idn: '',
		url_link_en: '',
	});
	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.banners);

	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});

	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);

	const [previewImageDetail, setPreviewImageDetail] = useState<string[]>([]);
	const [imagePayloadDetail, setImagePayloadDetail] = useState<File[]>([]);

	const [previewImageEng, setPreviewImageEng] = useState<string[]>([]);
	const [imagePayloadEng, setImagePayloadEng] = useState<File[]>([]);

	const [previewImageEngDetail, setPreviewImageEngDetail] = useState<string[]>([]);
	const [imagePayloadEngDetail, setImagePayloadEngDetail] = useState<File[]>([]);
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		});
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const refreshData = () => {
		history.push('/banners');
		dispatch(getAllBanners('', 1, ''));
	};

	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.title.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.description.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (!isEdit && !imagePayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 4]);
			}
			if (!isEdit && !imagePayloadDetail.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 5]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (selectedLanguage?.value === 'en' && !isEdit && !imagePayloadEng.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (selectedLanguage?.value === 'en' && !isEdit && !imagePayloadEngDetail.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 9]);
			}
		};
		const formImgIdn = new FormData();
		const formImgIdnMobile = new FormData();

		const formImgEng = new FormData();
		const formImgEngMobile = new FormData();
		let uploadImgIdn, uploadImgIdnMobile, uploadImgEng, uploadImgEngMobile;
		if (isEdit) {
			const validationEdit =
				form.description.length &&
				form.title.length &&
				selectedStatus.name.length &&
				selectedLanguage?.name?.length;
			if (validationEdit) {
				
				const _body = {
					title: form.title,
					description: form.description,
					is_publish: selectedStatus.value,
					url_link_idn: form.url_link_idn,
					url_link_en: form.url_link_en,
				};
				// Cek apakah image berubah / ditambah utk size
				// image desktop language id
				if (imagePayload.length) {
					imagePayload.map(item => formImgIdn.append('upload', item));
					uploadImgIdn = await dispatch(generalUpload(formImgIdn));
				}

				// upload image mobile lang id
				if (imagePayloadDetail.length) {
					imagePayloadDetail.map(item => formImgIdnMobile.append('upload', item));
					uploadImgIdnMobile = await dispatch(generalUpload(formImgIdnMobile));
				}

				// image desktop language eng
				if (imagePayloadEng.length && selectedLanguage.name !== 'idn' && selectedLanguage.name !== 'Indonesia') {
					imagePayloadEng.map(item => formImgEng.append('upload', item));
					uploadImgEng = await dispatch(generalUpload(formImgEng));
				}

				// upload image mobile lang eng
				if (imagePayloadEngDetail.length && selectedLanguage.name !== 'idn' && selectedLanguage.name !== 'Indonesia') {
					imagePayloadEngDetail.map(item => formImgEngMobile.append('upload', item));
					uploadImgEngMobile = await dispatch(generalUpload(formImgEngMobile));
				}

				_body['img_url_idn'] = uploadImgIdn?.data || details.img_url_idn;
				_body['img_url_mobile_idn'] = uploadImgIdnMobile?.data || details.img_url_mobile_idn;
				_body['img_url_en'] = uploadImgEng?.data || details.img_url_en;
				_body['img_url_mobile_en'] = uploadImgEngMobile?.data || details.img_url_mobile_en;

				await dispatch(editBanner(id, _body, () => refreshData()));

			} else {
				validateForm();
			}
		} else {
			const validationPost =
				form.description.length &&
				form.title.length &&
				selectedStatus.name.length &&
				imagePayload.length &&
				selectedLanguage?.name?.length;
			if (validationPost) {
				imagePayload.map(item => formImgIdn.append('upload', item));
				imagePayloadDetail.map(item => formImgIdnMobile.append('upload', item));
				const uploadImgIdn = await dispatch(generalUpload(formImgIdn));
				if (uploadImgIdn?.code === 200) {
					const uploadImgIdnMobile = await dispatch(generalUpload(formImgIdnMobile));

					const _body = {
						title: form.title,
						description: form.description,
						img_url_idn: uploadImgIdn.data,
						img_url_mobile_idn: uploadImgIdnMobile?.data,
						is_publish: selectedStatus.value,
						url_link_idn: form.url_link_idn,
						url_link_en: form.url_link_en,
					};
					if (selectedLanguage.name !== 'idn' && selectedLanguage.name !== 'Indonesia') {
						imagePayloadEng.map(item => formImgEng.append('upload', item));
						imagePayloadEngDetail.map(item => formImgEngMobile.append('upload', item));
						const uploadImgEng = await dispatch(generalUpload(formImgEng));
						const uploadImgEngMobile = await dispatch(generalUpload(formImgEngMobile));

						_body['img_url_en'] = uploadImgEng?.data;
						_body['img_url_mobile_en'] = uploadImgEngMobile?.data;
					}

					await dispatch(addBanner(_body, () => refreshData()));
				}
			} else {
				validateForm();
			}
		}
	};

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllBanners(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
	};

	const onDeleteBanner = (id: string) => {
		dispatch(deleteBanner(id, () => {
			dispatch(getAllBanners('', 1, data?.length === 1 ? '' : selectedStatus?.value));
		}));
	};

	const onSearchBanner = debounce(e => {
		dispatch(getAllBanners(e[0].target.value, 1, selectedStatus?.value));
	});

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'title',
		},
		{
			index: 1,
			label: 'Description',
			name: 'description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'description',
		},
		{
			index: 2,
			label: 'Url Link ID (Optional)',
			name: 'url_link_idn',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'url link',
		},
		{
			index: 3,
			label: 'Url Link ENG (Optional)',
			name: 'url_link_en',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'url link',
		},
		{
			index: 4,
			label: 'Upload Image Desktop Size',
			name: 'url_img_desktop',
			isMandatory: true,
			disabledCondition: previewImage.length >= 1,
			renderType: 'file',
			inputType: 'file',
			maxImage: 1,
			imageType: 'card'
		},
		{
			index: 5,
			label: 'Upload Image Mobile Size',
			name: 'url_img_mobile',
			isMandatory: true,
			disabledCondition: previewImageDetail.length >= 1,
			renderType: 'file',
			inputType: 'file',
			maxImage: 1,
			imageType: 'detail'
		},
		{
			index: 6,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 7,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 8,
			label: 'Upload Image ENG Desktop Size',
			name: 'url_img_eng_desktop',
			isMandatory: true,
			disabledCondition: previewImageEng.length >= 1,
			renderType: 'file_eng',
			inputType: 'file',
			maxImage: 1,
			imageType: 'card'
		},
		{
			index: 9,
			label: 'Upload Image ENG Mobile Size',
			name: 'url_img_eng_mobile',
			isMandatory: true,
			disabledCondition: previewImageEngDetail.length >= 1,
			renderType: 'file_eng',
			inputType: 'file',
			maxImage: 1,
			imageType: 'detail',
		},
	];

	const effectFunctions = () => {
		if (details && (id === details?.id.toString())) {
			setForm({
				...form,
				title: details.title,
				description: details.description,
				img_url_idn: details.img_url_idn,
				img_url_mobile_idn: details.img_url_mobile_idn,
				url_link_idn: details.url_link_idn,
				url_link_en: details.url_link_en,
				img_url_en: details?.img_url_en,
				img_url_mobile_en: details?.img_url_mobile_en,
			});

			setSelectedLanguage(
				details?.img_url_en === '' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
				// set image preview path
			setPreviewImage([details.img_url_idn]);
			setPreviewImageDetail([details.img_url_mobile_idn]);
			setPreviewImageEng([details.img_url_en]);
			setPreviewImageEngDetail([details.img_url_mobile_en]);
		}
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onDeleteBanner,
		onSearchBanner,
		formArray,
		effectFunctions,
		errorForm,
		setErrorForm,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		previewImageDetail,
		setPreviewImageDetail,
		imagePayloadDetail,
		setImagePayloadDetail,
		imagePayloadEngDetail,
		setImagePayloadEngDetail,
		imagePayloadEng,
		setImagePayloadEng,
		previewImageEng,
		setPreviewImageEng,
		previewImageEngDetail,
		setPreviewImageEngDetail,
	};
}
