import { Select } from 'components';
import { styleHelper } from 'helpers';
import React, { useEffect } from 'react';
import {
	useTable, useGlobalFilter, useAsyncDebounce, usePagination, useFilters
} from 'react-table';
export const statusOption = [
	{
		id: 0,
		name: 'All Status'
	},
	{
		id: 1,
		name: 'CF PAYMENT'
	},
	{
		id: 2,
		name: 'SITE SURVEY'
	},
	{
		id: 3,
		name: 'DESIGN PROCESS'
	},
	{
		id: 4,
		name: 'INSTALLATION'
	},
	{
		id: 5,
		name: 'PAYMENT'
	},
	{
		id: 6,
		name: 'FILL FORM'
	},
	{
		id: 7,
		name: 'COMPLETED'
	},
];

export function StatusPill ({ value }) {
	// const status = value ? value.toLowerCase() : "unknown";
	let stylePill = '';
	switch (value) {
		case 'CF PAYMENT':
			stylePill = 'bg-red-100 text-red-800';
			break;
		case 'SITE SURVEY':
		case 'INSTALLATION':
			stylePill = 'bg-blue-100 text-blue-800';
			break;
		case 'DESIGN PROCESS':
		case 'ON PROGRESS':
			stylePill = 'bg-yellow-100 text-yellow-800';
			break;
		case 'FILL FORM':
			stylePill = 'bg-purple-100 text-purple-800';
			break;
		default:
			stylePill = 'bg-green-100 text-green-800';
	}
	return (
		<span
			className={ styleHelper.classNames(
				'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm',
				stylePill
			) }
		>{ value }</span>
	);
}

export function SelectColumnFilter ({
	column: {
		filterValue, setFilter, preFilteredRows, id
	},
}) {
	// Render a multi-select box
	return (
		<Select
			list={ statusOption }
			keyObj='name'
			placeholder='All Status'
			onChange={ e => {
				if (e) {
					setFilter(e.name || undefined);
				}
			} }
		/>
	);
}

function GlobalFilter ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 200);
	return (
		<span>
      Search:{ ' ' }
			<input
				value={ value || '' }
				onChange={ e => {
					setValue(e.target.value);
					onChange(e.target.value);
				} }
				placeholder={ `${count} records...` }
			/>
		</span>
	);
}

function Table ({ columns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		pageOptions,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,

		state,
		preGlobalFilteredRows,
		setGlobalFilter,
	} =
    useTable({
    	columns,
    	data,
    },
    useFilters,
    useGlobalFilter,
    usePagination);

	const count = data.length;

	// setPageSize(5)
	useEffect(() => {
		setPageSize(5);
	});

	// Render the UI for your table
	return (
		<>
			{ /* {headerGroups.map((headerGroup, index) =>
        headerGroup.headers.map((column, i) =>
          column.Filter ? (
            <div key={column.id}>
              <label htmlFor={column.id} key={i}>{column.render("Header")}: </label>
              {column.render("Filter")}
            </div>
          ) : null
        )
      )} */ }
			<div className='shadow overflow-hidden sm:rounded-lg border border-gray-200 border-solid drop-shadow shadow-gray-200'>
				{ /* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */ }
      
				<table { ...getTableProps() } className='min-w-full divide-y divide-y divide-gray-200'>
					<thead className='bg-gray-50'>
						{ headerGroups.map((headerGroup, index) => (
							<tr
								{ ...headerGroup.getHeaderGroupProps() }
								key={ index }>
								{ headerGroup.headers.map((column, index) => (
									<th { ...column.getHeaderProps() }
										scope='col'
										className='text-left text-xs font-semibold text-gray-500 uppercase tracking-wide px-6 py-4'
										key={ index }>{ column.render('Header') }</th>
								)) }
							</tr>
						)) }
					</thead>
					<tbody
						{ ...getTableBodyProps() }
						className='bg-white divide-y divide-gray-200'>
						{ page.map((row, index) => {
							prepareRow(row);
							return (
								<tr
									key={ index }
									{ ...row.getRowProps() }>
									{ row.cells.map((cell, i) => {
										return <td
											key={ i }
											{ ...cell.getCellProps() }
											className='px-6 p-25'>{ cell.render('Cell') }</td>;
									}) }
								</tr>
							);
						}) }
					</tbody>
				</table>
				<div className='bg-gray-50 sticky sm:flex items-center w-full sm:justify-between bottom-0 right-0 border-t border-gray-200 p-4 pagination'>
					<div className='flex items-center mb-4 sm:mb-0'>
						<span className='text-sm font-normal text-gray-700'>Showing { state.pageIndex + 1 } of { pageOptions.length }</span>
					</div>
					<div className='flex items-center space-x-3'>
						<button className='flex-1 text-gray-700 bg-white hover:bg-gray-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center border border-gray-300 border-solid drop-shadow-sm'
							onClick={ () => previousPage() }
							disabled={ !canPreviousPage }>
            Previous
						</button>
						<button className='flex-1 text-gray-700 bg-white hover:bg-gray-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center border border-gray-300 border-solid drop-shadow-sm'
							onClick={ () => nextPage() }>
            Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default {
	Table,
	StatusPill,
	SelectColumnFilter
};