import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import React, { ReactNode, useState } from 'react';
import { setShowBurgerMenu } from 'store/Misc/action';
import { selectors } from 'store/selectors';
import { BellIcon } from '@heroicons/react/24/outline';
import { Text } from 'components';

type ContainerProps = {
	header?: ReactNode;
	mainClassName?: string;
	children: any;
};

const Container: React.FC<ContainerProps> = ({
	children, header, mainClassName = 'p-6',
}) => {
	const { showBurgerMenu } = useAppSelector(selectors.showBurgerMenu);

	const dispatch = useAppDispatch();

	const renderBurgerMenu = () => {
		const lineStyle = showBurgerMenu ? 'ease duration-300 w-8 h-0.5 bg-gray-100' : 'ease duration-300 w-8 h-0.5 bg-gray-600';
		return (
			<div
				className={ `space-y-2 px-2 py-3 cursor-pointer lg:hidden ease duration-500 rounded ${ showBurgerMenu ? 'bg-gray-600' : '' }` }
				onClick={ () => dispatch(setShowBurgerMenu(!showBurgerMenu)) }
			>
				<div className={ lineStyle } />
				<div className={ lineStyle } />
				<div className={ lineStyle } />
			</div>
		);
	};

	const [notif, showNotif] = useState<boolean>(false);

	const notifItem = [
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: true,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: true,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: true,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: false,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: false,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: false,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: false,
			date: '17 Agustus 1945 08:00 AM'
		},
		{
			name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis mattis neque id fames non duis pulvinar quisque.',
			new: false,
			date: '17 Agustus 1945 08:00 AM'
		}
	];

	const renderNotification = (show: boolean) => {
		return show ? (
			<div className='notification-box p-3'>
				<Text className='font-bold ml-6' text='Notification' />
				<div className='p-1'>
					{ notifItem.map((item, index) => {
						return (
							<div className='flex items-center border-b-2 p-3' key={ index }>
								<div className={ `notif-dot mr-3 ${ item.new ? 'notif-show' : 'notif-hide' }` } />
								<div>
									<Text text={ item.name } className='mb-1' />
									<Text
										text={ item.date }
										size='text-xs'
										className='notif-date-text'
									/>
								</div>
							</div>
						);
					}) }
				</div>
			</div>
		) : null;
	};

	/** === REMOVED ===
	 *  Date removed : 4 Dec 23
	 *  Reason : Not used yet
	 *  Task : FCMS-098
	 *  PIC : Zulfikar
	 */
	
	// const renderBellIcon = () => {
	// 	return (
	// 		<>
	// 			<BellIcon
	// 				className='w-5 h-5 mr-4 cursor-pointer'
	// 				onClick={ () => {
	// 					showNotif(!notif);
	// 				}
	// 				}
	// 			/>
	// 			{ renderNotification(notif) }
	// 		</>
	// 	);
	// };
	
	return (
		<div className='flex flex-row overflow-hidden bg-[#FCFDFF] font-sans'>
			<div className='bg-gray-900 opacity-50 hidden fixed inset-0 z-10' id='sidebarBackdrop' />
			<div id='main-content' className='h-full w-full bg-background relative overflow-y-auto lg:ml-64'>
				<main className={ mainClassName }>
					<div className='flex justify-between items-center'>
						{ header }
						<div className='flex items-center'>
							{ renderBurgerMenu() }
						</div>
					</div>
					{ children }
				</main>
			</div>
		</div>
	);
};

export default Container;
