import useBookingSettings from './useBookingSetting';
import AdminForm from 'components/AdminView/AdminForm';

const BookingSettings = () => {
	const {
		formArrayDoctorSetting, formArrayGlobalSetting, isDoctorSetting, getBookingConfigDetail
	} = useBookingSettings();

	return (
		<AdminForm
			backLink={ isDoctorSetting ? '/booking-doctor' : '' }
			formArray={ isDoctorSetting ? formArrayDoctorSetting : formArrayGlobalSetting }
			isAbleToSubmit={ true }
			localHooks={ useBookingSettings }
			getDetailAction={ getBookingConfigDetail }
			title={ isDoctorSetting ? 'Doctor Settings Add' : 'Global Settings Add' }
			type='book-settings'
		/>
	);
};

export default BookingSettings;