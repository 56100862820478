export interface Loading {
  isLoading?: boolean;
  loadingMsg?: string;
}

export interface LazyLoadField {
  fieldName: string;
  isLoading: boolean;
}

export interface LazyLoad {
  [key: string]: boolean;
}

export enum HomeList {
  Custom = 'custom',
  Hydeready = 'hydeready'
}

export enum ProductionSteps {
  EstimatedHandover = 'estimatedHandoverDate',
  FinalPayment = 'finalPayment',
  WaitingPayment = 'WaitingPayment',
  WeeklyReport = 'weeklyReport',
  ProductionVoReady = 'productionVoReady'
}

export type VOType = {
  id: number;
  item: string;
  total: string;
  price_item: string;
  quantity: string;
};

export type timeObj = {
  id: number;
  name: string;
  value: string;
};

export type user = {
  delete_at: string | null,
  email: string,
  id: number,
  is_active: boolean,
  name: string,
  phone: string,
  role: string;
};

export type ProgressPreviewType = {
  preview: {
    id: string;
    images: string[];
    name: string;
    percentage: number;
  };
  index: number;
  modal?: boolean;
};

export type SelectedItemType = {
  id: number,
  name: string;
};

export enum KitchenLetterEnums {
  LETTER_I = 'Letter I',
  LETTER_L = 'Letter L',
  LETTER_U = 'Letter U',
  LETTER_ISLAND = 'Letter Island'
}

export type BirthDateType = {
  start: string;
  end: string;
};

export type CellType = 'project' |
  'pic' |
  'text' |
  'badge' |
  'icon' |
  'button' |
  'edit_delete' |
  'select' |
  'edit' |
  'delete' |
  'ellipsis' |
  'image' |
  'view' |
  'checkbox';

export type MethodType = 'POST' | 'GET' | 'PUT' | 'DELETE' | '';
