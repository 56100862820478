import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

import {
  GET_LIST_CLINICS, GET_CLINIC_DETAILS, GET_CLINIC_CLINICS_HOSPITAL_CODE, GET_LIST_CLINICS_SLUG
} from './actionType';

export const getAllClinics = (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '', slug = '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get clinic list begin'));
      const {
        code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getAllClinics(keyword, page, is_publish, limit, language, slug));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_CLINICS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get clinic list end'));
  };
};

export const getClinicDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get clinic detail begin'));
      const {
        code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getClinicDetails(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_CLINIC_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get clinic detail end'));
  };
};

export const deleteClinic = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete clinic begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteClinic(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Clinic');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete clinic end'));
  };
};

export const addClinic = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add clinic begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.addClinic(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Clinic');
				return {
					code,
					data
				};
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add clinic end'));
  };
};

export const editClinic = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit clinic begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editClinic(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Clinic');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit clinic end'));
  };
};

export const getClinicsByHospitalCode = (hospital_code: string, language: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get clinic by hospital code begin'));
      const {
        code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getClinicsByHospitalCode(hospital_code, language));
      if (code === 200) {
        toastify.success('Berhasil get clinic by hospital code');
        await dispatch({
          type: GET_CLINIC_CLINICS_HOSPITAL_CODE,
          dataClinicsByHospitalCode: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('clinic by hospital code end'));
  };
};

export const getAllClinicSlug = (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '', slug = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get clinic list begin'));
			const {
				code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getAllClinics(keyword, page, is_publish, limit, language, slug));
			if (code === 200) {
				await dispatch({
					type: GET_LIST_CLINICS_SLUG,
					data: data,
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get clinic list end'));
	};
};