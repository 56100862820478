import { Pagination } from 'interfaces/common';
import { PatientActionTypes, GET_LIST_PATIENTS, } from './actionType';

interface PatientState {
  data: any;
  pagination: Pagination;
}

const initState: PatientState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  }
};

const PatientsReducer = (state = initState, action: PatientActionTypes) => {
  switch (action.type) {
    case GET_LIST_PATIENTS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    default:
      return state;
  }
};

export default PatientsReducer;