import React from 'react';
import Modal from 'components/Modal';
import { Button, Text } from 'components';
import { SelectedItemType } from 'interfaces/misc';

type AdminModalsType = {
	showOpen?: boolean;
	setShowOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	type: string;
	isDelete: boolean;
	message?: string;
	selectedItem: SelectedItemType;
	setSelectedItem: React.Dispatch<React.SetStateAction<SelectedItemType>>;
	onClickModalDelete?: any;
	onClickModalActive?: any;
	status?: any;
};

const AdminModals: React.FC<AdminModalsType> = ({
	showOpen = false,
	setShowOpen,
	type,
	isDelete,
	message,
	selectedItem,
	setSelectedItem,
	onClickModalDelete,
	onClickModalActive,
	status
}) => {
	return (
		<Modal.BasicModal open={ showOpen } setOpen={ setShowOpen }>
			<div className='flex justify-center'>
				<Text
					weight='font-bold'
					text={ type === 'catalog' ? `Are you sure want to ${ isDelete ? 'delete this package' : `${ status === 'ACTIVE' ? 'deactivate' : 'activate' } this catalog item` }?` : `Apakah anda yakin ingin menghapus ${ 'entry' + ` ${ selectedItem?.name }` } ?` }
				/>
			</div >
			{ type === 'catalog' ? <>
				<p className='text-gray-400 mt-2'><span className='font-bold text-hydeBlack-500'>Important: </span>{ message }</p>
			</> : null }
			<div className='flex justify-center mt-3'>
				<Button
					text='Cancel'
					className='mr-3'
					type='secondary'
					onClick={ () => {
						setShowOpen && setShowOpen(false);
						setSelectedItem({
							id: 0,
							name: ''
						});
					} }
				/>
				<Button
					text={ isDelete ? 'Delete' : `${ status === 'ACTIVE' ? 'Deactivate' : 'Activate' }` }
					type='delete'
					onClick={ () => {
						isDelete ? onClickModalDelete && onClickModalDelete(selectedItem?.id) : onClickModalActive && onClickModalActive();
						setShowOpen && setShowOpen(false);
						setSelectedItem({
							id: 0,
							name: ''
						});
					} }
				/>
			</div>
		</Modal.BasicModal >
	);
};

export default AdminModals;