import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import {
	GET_ALL_MEDICAL_SPECIALITIES, GET_ALL_MEDICAL_SPECIALITIES_SLUG, GET_MEDICAL_SPECIALITIES_DETAIL, I_GetMedicalSpecialitiesParam, I_MedicalSpecialitiesPayload, MedicalSpecialityResponse
} from './actionTypes';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getMedicalSpecialitiesPages = (params: I_GetMedicalSpecialitiesParam) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get medical specialities pages begin'));
			const {
				code, data, pagination
			}: ApiData<MedicalSpecialityResponse[]> = await api.request(Endpoints.getMedicalSpecialitiesPages(params));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_MEDICAL_SPECIALITIES,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get medical specialities pages end'));
	};
};

export const checkMedicalSpecialitiesSlug = (params: I_GetMedicalSpecialitiesParam) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get medical specialities slug begin'));
			const {
				code, data, pagination
			}: ApiData<MedicalSpecialityResponse[]> = await api.request(Endpoints.getDetailMedicalSpecialitiesPages(params?.slug ?? '', params.language));
			await dispatch({
				type: GET_ALL_MEDICAL_SPECIALITIES_SLUG,
				slug_data: data,
				pagination: pagination
			});
		} catch (error) {
		}
		dispatch(loadingEnd('get medical specialities slug end'));
	};
};

export const addMedicalSpecialities = (body: I_MedicalSpecialitiesPayload, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('add Medical Specialities Page begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.addMedicalSpecialities(), body);
			if (code === 200) {
				toastify.success('Berhasil Menambah Medical Specialities Page');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add Medical Specialities Page end'));
	};
};

export const editMedicalSpecialities = (id: string, body: I_MedicalSpecialitiesPayload, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('edit Medical Specialities Page begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.editMedicalSpecialities(id), body);
			if (code === 200) {
				toastify.success('Berhasil Mengubah Medical Specialities Page');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('edit Medical Specialities Page end'));
	};
};

export const deleteMedicalSpecialities = (id: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('delete Medical Specialities Page begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.deleteMedicalSpecialities(id));
			if (code === 200) {
				toastify.success('Berhasil delete Medical Specialities Page');
				setTimeout(function () { window.location.reload(); }, 3000);
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete Medical Specialities Page end'));
	};
};

export const getDetailMedicalSpecialitiesPages = (id: string, lang?: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get medical specialities pages begin'));
			const {
				code, data, pagination
			}: ApiData<MedicalSpecialityResponse> = await api.request(Endpoints.getDetailMedicalSpecialitiesPages(id, lang));
			if (code === 200) {
				await dispatch({
					type: GET_MEDICAL_SPECIALITIES_DETAIL,
					details: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get medical specialities pages end'));
	};
};
