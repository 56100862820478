/* eslint-disable no-undef */
import { ReactComponent as Akupuntur } from 'assets/images/icons/specialities/akupuntur_icon.svg';
import { ReactComponent as Alergi } from 'assets/images/icons/specialities/alergi_icon.svg';
import { ReactComponent as Anak } from 'assets/images/icons/specialities/anak_icon.svg';
import { ReactComponent as Anestesi } from 'assets/images/icons/specialities/anestesi_icon.svg';
import { ReactComponent as Bedah } from 'assets/images/icons/specialities/bedah_icon.svg';
import { ReactComponent as Gizi } from 'assets/images/icons/specialities/gizi_klinik_icon.svg';
import { ReactComponent as Jantung } from 'assets/images/icons/specialities/jantung_icon.svg';
import { ReactComponent as Kelamin } from 'assets/images/icons/specialities/kelamin_icon.svg';
import { ReactComponent as Laktasi } from 'assets/images/icons/specialities/laktasi_icon.svg';
import { ReactComponent as Mata } from 'assets/images/icons/specialities/mata_icon.svg';
import { ReactComponent as Nerve } from 'assets/images/icons/specialities/nerve_icon.svg';
import { ReactComponent as Paru } from 'assets/images/icons/specialities/paru_icon.svg';
import { ReactComponent as PenyakitDalam } from 'assets/images/icons/specialities/penyakit_dalam_icon.svg';
import { ReactComponent as Psikiatri } from 'assets/images/icons/specialities/psikiatri_icon.svg';
import { ReactComponent as Psikologi } from 'assets/images/icons/specialities/psikologi_icon.svg';
import { ReactComponent as Rehabilitiasi } from 'assets/images/icons/specialities/rehabilitasi_icon.svg';
import { ReactComponent as Tht } from 'assets/images/icons/specialities/tht_icon.svg';
import { ReactComponent as TumbuhKembang } from 'assets/images/icons/specialities/tumbuh_icon.svg';
import { ReactComponent as Saraf } from 'assets/images/icons/specialities/nerve_icon.svg';

export const IconComponent = {
	Paru,
	PenyakitDalam,
	Psikiatri,
	Psikologi,
	Rehabilitiasi,
	Tht,
	TumbuhKembang,
	Saraf,
	Akupuntur,
	Alergi,
	Anak,
	Anestesi,
	Bedah,
	Gizi,
	Jantung,
	Kelamin,
	Nerve,
	Laktasi,
	Mata,
};

export default {
	dummyProfile: require('assets/images/dummy.png'),
	logo: require('assets/images/logo.png'),
	ic_search: require('assets/images/ico_search.png'),
	pic1: require('assets/images/pic1.png'),
	pic2: require('assets/images/pic2.png'),
	progressUpload: require('assets/images/progress_upload_dummy.png'),
	upload_image: require('assets/images/icons/upload_image.svg'),
	empty_chat: require('assets/images/chat_empty_illustration.png'),
	scanIcon: require('assets/images/icons/scan.png'),

	icons: {
		attachment: require('assets/images/icons/attachment.png'),
		file: require('assets/images/icons/file.png'),
		close: require('assets/images/icons/close.png'),
		chevronDown: require('assets/images/icons/chevron-down.png'),
	}
};
