import React, {
	useEffect, useMemo, useState
} from 'react';
import {
	Link, useHistory, useLocation
} from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';

import { Images } from 'constant';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { UserRoles } from 'interfaces';
import Text from 'components/Text';

import { itemsLeftSidebar } from './itemsLeftSidebar';

const SideBar: React.FC = () => {
	const location = useLocation();
	const history = useHistory();
	const [userRole, setUserRole] = useState('');
	const [activeLabel, setActiveLabel] = useState('Custom Package');
	const [isLoginPage, setIsLoginPage] = useState(true);
	// const roleStorage = LocalStorage.getItem('roles', true);
	const roleStorage = 'any role type';

	const { showBurgerMenu } = hooks.useAppSelector(selectors.showBurgerMenu);
	const { user } = hooks.useAppSelector(selectors.auth);

	useEffect(() => {
		setUserRole(roleStorage);
	}, [roleStorage]);

	useEffect(() => {
		if (!location?.pathname?.includes('signin') && !location?.pathname?.includes('reset-password')) {
			setIsLoginPage(false);
		} else {
			setIsLoginPage(true);
		}
	}, [location.pathname]);

	const getUserRoleLabel = userRole => {
		const { RoleTypes } = UserRoles;

		switch (userRole) {
			// case RoleTypes.CustomerService:
			// 	return 'Customer Service';
			// case RoleTypes.Finance:
			// 	return 'Finance';
			// case RoleTypes.ProjectExecutive:
			// 	return 'Project Executive';
			// case RoleTypes.Telemarketing:
			// 	return 'Telemarketing';
			// case RoleTypes.ProjectCoordinator:
			// 	return 'Project Coordinator';
			// default:
			// 	return 'Super Admin';
			default:
				return true;
		}
	};

	const onClickLogout = () => {
		localStorage.clear();
		setIsLoginPage(true);
		history.push('/signin');
	};

	const styleBurgerMenu = useMemo(() => {
		if (showBurgerMenu) {
			return 'md:translate-x-0';
		} else {
			return 'md:-translate-x-full';
		}
	}, [showBurgerMenu]);

	useEffect(() => {
		// for highligting menu if they are active
		// if path contains id params
		if (location.pathname.includes('hospital')) {
			setActiveLabel('Hospital');
		}
		if (location.pathname.includes('facilities')) {
			setActiveLabel('Facilities');
		}
		if (location.pathname.includes('portfolio')) {
			setActiveLabel('Portfolio');
		}
		if (location.pathname.includes('katalog')) {
			setActiveLabel('Katalog');
		}
		if (location.pathname.includes('event-classes')) {
			setActiveLabel('Event and Classes');
		}
		if (location.pathname.includes('news-article')) {
			setActiveLabel('News and Articles');
		}
		if (location.pathname.includes('banners')) {
			setActiveLabel('Banners');
		}
		if (location.pathname.includes('admins')) {
			setActiveLabel('Admins');
		}
		if (location.pathname.includes('awards')) {
			setActiveLabel('Awards and Accreditations');
		}
		if (location.pathname.includes('doctors')) {
			setActiveLabel('Doctors');
		}
		if (location.pathname.includes('clinics')) {
			setActiveLabel('Clinics');
		}
		if (location.pathname.includes('patients')) {
			setActiveLabel('Patients');
		}
		if (location.pathname.includes('rbac')) {
			setActiveLabel('RBAC');
		}
		if (location.pathname.includes('footer')) {
			setActiveLabel('Footer');
		}
		if (location.pathname.includes('doctor-settings')) {
			setActiveLabel('Doctor Settings');
		}
		if (location.pathname.includes('global-settings')) {
			setActiveLabel('Global Settings');
		}
		if (location.pathname.includes('contact-us')) {
			setActiveLabel('Contact Us');
		}
		if (location.pathname.includes('booking-doctor')) {
			setActiveLabel('Booking Doctor');
		}
		// if (location.pathname.includes('list-specialties')) {
		// 	setActiveLabel('List Specialties');
		// }
	}, [location.pathname]);

	const renderLeftSidebar = () => {
		return itemsLeftSidebar(userRole, label => setActiveLabel(label), activeLabel).map((item, idx) => {
			const getRole = item.roleType.includes(userRole);
			if (typeof item.render === 'function' && getRole) {
				return (
					<div key={ idx }>
						<Disclosure defaultOpen={ location.pathname.includes('settings') ? idx === 7 : idx === 0 } as='li'>
							{ status => {
								return (
									<>
										<Disclosure.Button className='w-full' onClick={ () => setActiveLabel(item.label) }>
											<div
												className={ `flex items-center justify-between p-2 text-sm font-medium text-gray-600 rounded-lg hover:bg-gray-100 group cursor-pointer ${ activeLabel === item.label ? 'bg-gray-100' : ''
													}` }
											>
												<div className='flex items-center'>
													{ item.icon }
													<span className='flex-1 ml-3 cursor-pointer'>{ item.label }</span>
												</div>
												<ChevronDownIcon className={ `${ status.open ? 'transform rotate-180 w-5 h-5' : 'w-5 h-5' }` } />
											</div>
										</Disclosure.Button>
										<Disclosure.Panel as='ul' className='cursor-pointer'>
											{ item.render(userRole) }
										</Disclosure.Panel>
									</>
								);
							} }
						</Disclosure>
					</div>
				);
			} else if (getRole) {
				return (
					<li key={ idx }>
						<Link
							to={ item.href }
							onClick={ () => setActiveLabel(item.label) }
							className={ `flex items-center p-2 text-sm font-medium text-gray-600 rounded-lg hover:bg-gray-100 group ${ activeLabel === item.label ? 'bg-gray-100' : ''
								}` }
						>
							{ item.icon }
							<span className='flex-1 ml-3 whitespace-nowrap'>{ item.label }</span>
						</Link>
					</li>
				);
			}

			return null;
		});
	};

	if (isLoginPage) return <></>;

	return (
		<aside
			id='sidebar'
			role='navigation'
			className={ `${ isLoginPage ? 'bg-red' : ''
				} sidebar-menu-hl fixed top-0 left-0 flex flex-col flex-shrink-0 w-64 h-full pt-5 bg-white border-r border-gray-200 border-solid lg:flex transition-width transition-all ease-in-out duration-500 z-10 ${ styleBurgerMenu } lg:translate-x-0 xl:translate-x-0` }
			aria-label='Sidebar'
		>
			<Link to='#' className='text-xl font-bold flex items-center lg:ml-2.5 mb-5'>
				<img src={ Images.logo } className='h-8 ml-4' alt='Hydeliving Logo' />
			</Link>
			<div className='relative flex flex-col flex-1 min-h-0 pt-0 bg-white'>
				<div className='flex flex-col flex-1 pt-5 pb-4 overflow-y-auto'>
					<div className='flex-1 px-3 space-y-1 bg-white divide-y'>
						<ul className='pb-2 space-y-2'>{ renderLeftSidebar() }</ul>
					</div>
					<div className='pt-4 pl-4 border-t border-solid border-gray-200'>
						<div className='flex'>
							<div className='w-8 h-8 rounded-full border-gray-200 border-solid mr-4'>
								<img className='rounded-full' src={ Images.dummyProfile } alt='profile' />
							</div>
							<div>
								<Text text={ user.username || 'username' } size='text-sm' />
								<Text size='text-xs' color='text-gray-500' text={ user.role_name || 'Super Admin' } />
								<Text
									text='Logout'
									size='text-sm'
									weight='font-medium'
									cursor='cursor-pointer'
									color='text-red-400'
									className='mt-2'
									onClick={ onClickLogout }
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</aside>
	);
};

export default SideBar;
