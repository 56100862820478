
import { getDetailMedicalSpecialitiesPages } from 'store/MedicalSpecialities/action';
import useMedicalSpecialities from './useMedicalSpecialities';
import AdminForm from 'components/AdminView/AdminForm';

const EditMedicalSpecialities = () => {

	const { formArray } = useMedicalSpecialities();

	return (
		<AdminForm
			backLink='/medical-specialities'
			formArray={ formArray }
			isAbleToSubmit={ true }
			getDetailAction={ getDetailMedicalSpecialitiesPages }
			localHooks={ useMedicalSpecialities }
			title='Medical Specialities'
			type='medical-specialities'
		/>
	);
};

export default EditMedicalSpecialities;