import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { misc } from 'constant';
import { ParamsType, FormArrayType, FormFacilitiesType } from 'interfaces/common';
import { hooks } from 'helpers';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { selectors } from 'store/selectors';
import {
	getAllClinics, stopLoading, editClinic, addClinic, deleteClinic, getAllHospitals
} from 'store/actions';
import { generalUpload } from 'store/Common/action';
import {
	addRelatedNewsClinic, getRelatedNewsClinic, deleteRelatedNewsClinic
} from 'store/NewsAndArticles/action';
import { getAllClinicSlug } from 'store/Clinics/action';

export default function useClinics() {

	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const isEdit = location.pathname.includes('edit');
	const isView = location.pathname.includes('view');
	const {
		pagination, details, data, slug_data
	} = hooks.useAppSelector(selectors.clinics);
	const { dropdown: doctors } = hooks.useAppSelector(selectors.doctors);
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'DOCTOR',
		'LANGUAGE',
		'STATUS'
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		title: '',
		short_description: '',
		slug: '',
		seo_h1: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const [conditions, setConditions] = useState({ editorHtml: '' });
	const [treatments, setTreatments] = useState({ editorHtml: '' });
	const [technology, setTechnology] = useState({ editorHtml: '' });
	const [available, setAvailable] = useState({ editorHtml: '' });
	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});

	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const { relatedNews: relatedNewsClinic } = hooks.useAppSelector(selectors.news);
	const { data: news } = hooks.useAppSelector(selectors.news);
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [previewImageDetail, setPreviewImageDetail] = useState<string[]>([]);
	const [imagePayloadDetail, setImagePayloadDetail] = useState<File[]>([]);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [uploaded, setUploaded] = hooks.useStateCallback([]);
	const [allTags, setAllTags] = useState<Record<string, any>[]>([]);
	const [relatedNews, setRelatedNews] = useState<{ id: number, name: string; id_news?: number; }[]>([]);
	const [availableAt, setAvailableAt] = useState<{ id: number, name: string, value: string; }[]>([]);
	const [titleAvailableAt, setTitleAvailableAt] = useState('');
	const [availableAtLength, setAvailableAtLength] = useState(0);
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);
	const onDeleteTags = (tagIndex: number) => setAllTags(allTags.filter((_, i) => i !== tagIndex));
	const onAddTags = (newTag: Record<string, any>) => {
		const alreadyAddTag = allTags.find(tag => tag.name === newTag.name)?.name;
		if (!alreadyAddTag) setAllTags([...allTags, newTag]);
	};
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const onChangeConditions = e => {
		setConditions({
			...editor,
			editorHtml: e
		});
	};
	const onChangeTreatments = e => {
		setTreatments({
			...editor,
			editorHtml: e
		});
	};
	const onChangeTechnology = e => {
		setTechnology({
			...editor,
			editorHtml: e
		});
	};
	const onChangeAvailable = e => {
		setAvailable({
			...editor,
			editorHtml: e
		});
	};

	const refreshData = () => {
		history.push('/clinics');
		dispatch(getAllClinics('', 1, ''));
	};

	const isFacilitesFilled = useRef(true);

	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.title.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (editor.editorHtml.length <= 11) {
				setErrorForm(prev => [...prev, 2]);
			}
			if (!form.short_description.length || form.short_description.length >= 255) {
				setErrorForm(prev => [...prev, 3]);
			}
			if (!allTags.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!isEdit && !imagePayload.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (!selectedStatus.name.length) {
				setErrorForm(prev => [...prev, 9]);
			}
			if (!selectedLanguage.name.length) {
				setErrorForm(prev => [...prev, 10]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 11]);
			}
			
		};
		if (clinicFacilitesHospital?.length) {
			clinicFacilitesHospital.forEach(element => {
				if (element['hospital_code'] == undefined || element['hospital_code'] == '') {
					isFacilitesFilled.current = false;
					return;
				}
			});
		}
		if (isFacilitesFilled.current) {
			clinicFacilitesHospital?.forEach(element => {
				if (element.operational_hour_tag === undefined || element.operational_hour_tag === null)
					return false;
				element.operational_hour = [];
				element.operational_hour_tag?.forEach(values => {
					element.operational_hour = [...element.operational_hour, values.name];
				}, '');
			});
		}
		let tempAvailableAt: Array<any> = [];
		tempAvailableAt = [...tempAvailableAt, titleAvailableAt];
		availableAt?.map(obj => {
			tempAvailableAt = [...tempAvailableAt, obj.name + ' :+split+: ' + obj.value];
		});
		if (isEdit) {
			const validEdit =
				form.title.length &&
				editor.editorHtml.length > 11 &&
				(form.short_description.length && form.short_description.length < 255) &&
				selectedStatus?.name &&
				allTags.length &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug;
			if (validEdit) {
				if (imagePayload.length) {
					for (let i = 0; i < imagePayload.length; i++) {
						const newForm = new FormData();
						newForm.append('upload', imagePayload[i]);
						const uploadImg = await dispatch(generalUpload(newForm));
						if (uploadImg?.code === 200) {
							setUploaded(prev => [...prev, uploadImg.data]);
							dispatch(stopLoading('upload end'));
						}
					}
				} else {
					await dispatch(editClinic(details.id, {
						title: form.title,
						slug: form.slug,
						content: editor.editorHtml,
						conditions: conditions.editorHtml,
						treatments: treatments.editorHtml,
						technology: technology.editorHtml,
						short_description: form.short_description,
						doctor_codes: allTags.map(item => item.id),
						img_url: previewImage,
						is_publish: selectedStatus.value,
						available_at: tempAvailableAt,
						language: selectedLanguage?.value,
						coe_hospitals: [...clinicFacilitesHospital],
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => {
						const uniqueElement = relatedNews?.filter(fil => !relatedNewsClinic?.some(({ news }) => fil?.id === news.id));
						if (uniqueElement.length == 0) {
							refreshData();
						} else {
							uniqueElement?.map(item => (
								dispatch(addRelatedNewsClinic({
									news_id: item?.id || item?.id_news,
									center_of_excellence_id: details?.id
								}, () => {
									refreshData();
								}))
							));
						}
					}));
				}
			} else {
				validateForm();
			}
		} else {
			const validationPost =
				form.title.length &&
				editor.editorHtml.length > 11 &&
				(form.short_description.length && form.short_description.length < 255) &&
				selectedStatus?.name &&
				imagePayload.length &&
				allTags.length &&
				selectedLanguage?.name?.length &&
				isFacilitesFilled.current &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug;
			if (validationPost) {
				for (let i = 0; i < imagePayload.length; i++) {
					const newForm = new FormData();
					newForm.append('upload', imagePayload[i]);
					const uploadImg = await dispatch(generalUpload(newForm));
					if (uploadImg?.code === 200) {
						setUploaded(prev => [...prev, uploadImg.data]);
						dispatch(stopLoading('upload end'));
					}
				}
			} else {
				validateForm();
			}
		}
	};

	const onSearchClinic = debounce(e => {
		dispatch(getAllClinics(e[0].target.value, 1, selectedStatus?.value));
	});

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllClinics(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
	};

	const onDeleteClinic = (id: string) => {
		dispatch(deleteClinic(id, () => {
			dispatch(getAllClinics('', 1, selectedStatus?.value));
		}));
	};

	const effectFunctions = () => {
		if (details && (id === details?.slug)) {
			setForm({
				...form,
				title: details?.title,
				short_description: details?.short_description,
				slug: details?.slug,
				seo_h1: details?.seo_h1
			});
			setSelectedStatus(details?.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
			setEditor({ editorHtml: details?.content });
			setConditions({ editorHtml: details?.conditions });
			setTreatments({ editorHtml: details?.treatments });
			setTechnology({ editorHtml: details?.technology });
			setTitleAvailableAt(details?.available_at?.[0]);
			// to remove title available at from iterate variable
			const available_at = details?.available_at;
			available_at?.shift();

			setAvailableAt(available_at?.map((item: any, index: number) => (
				{
					id: index,
					name: item?.split(':+split+:')[0],
					value: item?.split(':+split+:')[1]
				}
			)));
			setAvailableAtLength(available_at?.length ?? 0);
			setAllTags(details?.doctors?.map(item => ({
				id: item?.doctor_code,
				name: item?.doctor_name
			})));
			if (details?.img_url?.length) {
				setPreviewImage([...details?.img_url]);
			}
			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.title
				});
			}

			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	useEffect(() => {
		if (imagePayload.length === uploaded.length && imagePayload.length !== 0) {
			let tempAvailableAt: Array<any> = [];
			tempAvailableAt = [...tempAvailableAt, titleAvailableAt];
			availableAt?.map(obj => {
				tempAvailableAt = [...tempAvailableAt, obj.name + ' :+split+: ' + obj.value];
			});
			if (id) {
				const editUpload = [...previewImage.filter(item => item.includes(misc.UploadBaseUrl)), ...uploaded];
				dispatch(editClinic(details.id, {
					title: form.title,
					slug: form.slug,
					content: editor.editorHtml,
					conditions: conditions.editorHtml,
					treatments: treatments.editorHtml,
					technology: technology.editorHtml,
					short_description: form.short_description,
					doctor_codes: allTags.map(item => item.id),
					img_url: editUpload,
					is_publish: selectedStatus.value,
					available_at: tempAvailableAt,
					language: selectedLanguage?.value,
					coe_hospitals: [...clinicFacilitesHospital],
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => {
					const uniqueElement = relatedNews?.filter(fil => !relatedNewsClinic?.some(({ news }) => fil?.id === news.id));
					if (uniqueElement.length == 0) {
						refreshData();
					} else {
						uniqueElement?.map(item => (
							dispatch(addRelatedNewsClinic({
								news_id: item?.id || item?.id_news,
								center_of_excellence_id: details?.id
							}, () => {
								refreshData();
							}))
						));
					}
				}));
			} else {
				userAddClinic(tempAvailableAt);
			}

		}
	}, [imagePayload.length, uploaded.length]);

	useEffect(() => {
		if ((isEdit || isView) && details && details.id !== undefined) {
			dispatch(getRelatedNewsClinic(details.id));
		}
	}, [details]);

	const userAddClinic = async tempAvailableAt => {
		const response = await dispatch(addClinic({
			title: form.title,
			slug: form.slug,
			content: editor.editorHtml,
			conditions: conditions.editorHtml,
			treatments: treatments.editorHtml,
			technology: technology.editorHtml,
			short_description: form.short_description,
			doctor_codes: allTags.map(item => item.id),
			img_url: uploaded,
			is_publish: selectedStatus.value,
			available_at: tempAvailableAt,
			language: selectedLanguage?.value,
			coe_hospitals: [...clinicFacilitesHospital],
			parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
			seo_h1: form.seo_h1,
			seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
		}, null));
		const uniqueElement = relatedNews?.filter(fil => !relatedNewsClinic?.some(({ news }) => fil?.id === news.id));
		if (uniqueElement.length == 0) {
			refreshData();
		} else {
			uniqueElement?.map(item => (
				dispatch(addRelatedNewsClinic({
					news_id: item?.id || item?.id_news,
					center_of_excellence_id: response?.data?.['id']
				}, () => {
					refreshData();
				}))
			));
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'title',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Content',
			name: 'editor',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'content'
		},
		{
			index: 3,
			label: 'Short Description',
			name: 'short_description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'short description',
		},
		{
			index: 4,
			label: 'Conditions',
			name: 'conditions',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'conditions'
		},
		{
			index: 5,
			label: 'Treatment',
			name: 'treatments',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'treatments'
		},
		{
			index: 6,
			label: 'Technology',
			name: 'technology',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'technology'
		},
		{
			index: 7,
			label: 'Doctors',
			name: 'tags',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: doctors,
			renderType: 'tags',
			placeholder: 'input doctors'
		},
		{
			index: 8,
			label: 'Upload Image',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: previewImage.length >= 100,
			renderType: 'file',
			inputType: 'file',
			maxImage: 100,
			imageType: ''
		},
		{
			index: 9,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 10,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 11,
			label: 'Parent Clinic',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Clinic'
		},
		{
			index: 12,
			label: 'Slug Clinic',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		
		{
			index: 13,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	const addRelatedNews = () => {
		setRelatedNews([
			...relatedNews, {
				id: 0,
				name: ''
			}
		]);
	};

	const addAvailableAt = () => {
		setAvailableAt([
			...availableAt, {
				id: availableAtLength,
				name: '',
				value: ''
			}
		]);
		setAvailableAtLength(availableAtLength + 1);
	};

	const changeAvailableAt = (value: string, index: number, type: string) => {
		if (type === 'title_available_at') {
			setTitleAvailableAt(value);
			return;
		}
		const newItem = [...availableAt];
		newItem.map(obj => {
			if (obj.id === index) {
				type === 'title' ?
					obj.name = value : obj.value = value;
			}
		});
		setAvailableAt(newItem);
	};

	const changeRelatedNews = (value: { id: number; title: string; }, index: string | number) => {
		const newItem = [...relatedNews];
		newItem[index].id = value?.id;
		newItem[index].name = value?.title;
		setRelatedNews(newItem);
	};

	const removeRelatedNews = (newsId: string, index: number) => {
		dispatch(deleteRelatedNewsClinic(newsId, () => {
			dispatch(getRelatedNewsClinic(details.id));
			removeRelatedNewsFromState(index);
		}));
	};

	const removeRelatedNewsFromState = (index: number) => {
		const tempRelatedNews = relatedNews.filter(function (el) { return el.id != relatedNews[index].id; });
		setRelatedNews(tempRelatedNews);
	};

	const removeFieldAvailableAtFromState = (id: number) => {
		const tempAvailableAt = availableAt.filter(function (el) { return el.id !== id; });
		setAvailableAt(tempAvailableAt);
	};

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllClinicSlug('', 1, '', 1000, selectedLanguage.value, form.slug));
	};

	// facilities hospital
	const isFormView = location.pathname.includes('edit') || location.pathname.includes('post');
	const { data: hospitals } = hooks.useAppSelector(selectors.hospitals);
	const [clinicFacilitesHospital, setClinicFacilitiesHospital] = useState<FormFacilitiesType[]>([]);
	const defaultFormFacilitiesHospitalClinic: FormFacilitiesType = {
		hospital_code: '',
		name: 'Select Hospital',
		floor: '',
		information: '',
		operational_hour_tag: [],
		unit: '',
		operational_hour: [],
		appointment: '',
	};
	const addFacilitiesHospitalFormClinic = () => {
		setClinicFacilitiesHospital([...clinicFacilitesHospital, defaultFormFacilitiesHospitalClinic]);
	};
	const onChangeFormClinic = (e: { target: { name: string | number; value: any; }; }, index: string | number) => {
		const form = [...clinicFacilitesHospital];
		form[index][e.target.name] = e.target.value;
		setClinicFacilitiesHospital(form);
	};
	const onChangeSelectForm = (e: { hospital_code: string; name: string; }, index: string | number) => {
		const form = [...clinicFacilitesHospital];
		form[index].hospital_code = e?.hospital_code;
		form[index].name = e?.name;
		setClinicFacilitiesHospital(form);
	};
	const onAddOperationalHourHospital = (newTag, formIndex: number) => {
		const form = [...clinicFacilitesHospital];
		form[formIndex].operational_hour_tag = [...form[formIndex]?.operational_hour_tag ?? [], newTag];
		setClinicFacilitiesHospital(form);
	};
	const onDeleteOperationalHourHospital = (tagIndex: number, formIndex: number) => {
		const form = [...clinicFacilitesHospital];
		form[formIndex].operational_hour_tag = form[formIndex].operational_hour_tag.filter((_, i) => i !== tagIndex);
		setClinicFacilitiesHospital(form);
	};
	useEffect(() => {
		if (isFormView)
			dispatch(getAllHospitals('', 1, true, 10, selectedLanguage.value));
	}, [selectedLanguage]);
	const removeFacilitiesHospitalForm = (index: number) => {
		const newItem = [...clinicFacilitesHospital];
		newItem.splice(index, 1);
		setClinicFacilitiesHospital(newItem);
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		editor,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onChangeEditor,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		onSearchClinic,
		paginationFunction,
		setEditor,
		onDeleteClinic,
		imagePayload,
		setImagePayload,
		previewImageDetail,
		setPreviewImageDetail,
		imagePayloadDetail,
		setImagePayloadDetail,
		details,
		data,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		onChangeConditions,
		onChangeTreatments,
		onChangeTechnology,
		conditions,
		treatments,
		technology,
		available,
		onChangeAvailable,
		doctors,
		allTags,
		onDeleteTags,
		onAddTags,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		relatedNewsClinic,
		relatedNews,
		addRelatedNews,
		news,
		changeRelatedNews,
		removeRelatedNews,
		isEdit,
		setRelatedNews,
		addAvailableAt,
		availableAt,
		removeFieldAvailableAtFromState,
		changeAvailableAt,
		setAvailableAtLength,
		availableAtLength,
		titleAvailableAt,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		isView,
		slug_data,
		addFacilitiesHospitalFormClinic,
		setClinicFacilitiesHospital,
		clinicFacilitesHospital,
		onChangeFormClinic,
		hospitals,
		isFacilitesFilled,
		onChangeSelectForm,
		onAddOperationalHourHospital,
		onDeleteOperationalHourHospital,
		removeFacilitiesHospitalForm,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
}
