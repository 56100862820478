import { Pagination } from 'interfaces/common';

export const GET_LIST_SPECIALTIES = 'GET_LIST_SPECIALTIES';
export const GET_SPECIALTIES_DETAIL = 'GET_SPECIALTIES_DETAIL';

export interface GetListSpecialties {
	type: typeof GET_LIST_SPECIALTIES;
  data: any;
  pagination: Pagination;
}

export interface GetSpecialtiesDetail {
  type: typeof GET_SPECIALTIES_DETAIL;
  details: any;
}

export type SpecialtiesActionTypes = GetListSpecialties | GetSpecialtiesDetail;
