import { Pagination } from 'interfaces/common';

export const GET_ALL_MEDICAL_SPECIALITIES = 'GET_ALL_MEDICAL_SPECIALITIES';
export const GET_ALL_MEDICAL_SPECIALITIES_CATEGORIES = 'GET_ALL_MEDICAL_SPECIALITIES_CATEGORIES';
export const GET_MEDICAL_SPECIALITIES_DETAIL = 'GET_MEDICAL_SPECIALITIES_DETAIL';
export const GET_ALL_MEDICAL_SPECIALITIES_SLUG = 'GET_ALL_MEDICAL_SPECIALITIES_SLUG';

export interface I_GetMedicalSpecialitiesParam {
  page?: number,
  keyword?: string,
  limit: number,
  is_publish?: boolean,
	slug?: string,
	language?: string,
}

export interface I_MedicalSpecialitiesPayload {
	footer_category: string,
	title: string,
	sub_title: string,
	content: string,
	author: string,
	img_url: string[],
	is_publish: boolean,
	slug: string,
	language: string,
	parent_id?: any,
	seo_h1?: string,
	seo_robots?: string,
}

export interface MedicalSpecialityResponse {
  title: string;
  slug: string;
	sub_string: string;
	content: string;
	author: string;
  id: string;
  img_url: [];
	footer_category: string;
	is_publish: boolean;
	posted_date: string;
	created_date: string;
	updated_date: string;
	name: string;
	language: string;
}

export interface GetMedicalSpecialitiesPages {
	type: typeof GET_ALL_MEDICAL_SPECIALITIES;
  data: MedicalSpecialityResponse[];
  pagination: Pagination;
}

export interface GetMedicalSpecialitiesCategory {
	type: typeof GET_ALL_MEDICAL_SPECIALITIES_CATEGORIES;
	data: MedicalSpecialityResponse[];
	pagination: Pagination;
}

export interface GetMedicalSpecialityDetail {
	type: typeof GET_MEDICAL_SPECIALITIES_DETAIL;
	details: MedicalSpecialityResponse;
	pagination: Pagination;
}

export interface GetMedicalSpecialitySlug {
	type: typeof GET_ALL_MEDICAL_SPECIALITIES_SLUG;
	slug_data: MedicalSpecialityResponse[];
	pagination: Pagination;
}

export type MedicalSpecialitiesActionTypes = GetMedicalSpecialitiesPages | GetMedicalSpecialitiesCategory | GetMedicalSpecialityDetail | GetMedicalSpecialitySlug;
