import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_ALL_FACILITIES, GET_ALL_FACILITIES_SLUG, GET_FACILITY_DETAILS } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';
import { FacilitiesInterface } from 'interfaces/facilities';

export const getAllFacilities = (keyword: string, page: number, is_publish: boolean | '', is_home_page: boolean | '', limit = 10, language = '', slug = '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get facilities list begin'));
      const {
        code, data, pagination
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.getFacilitiesList(keyword, page, is_publish, is_home_page, limit, language, slug));
      if (code === 200) {
        await dispatch({
          type: GET_ALL_FACILITIES,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get facilities list end'));
  };
};

export const getFacilityDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get facility detail begin'));
      const {
        code, data, pagination
      }: ApiData<FacilitiesInterface> = await api.request(Endpoints.getFacilitiesDetail(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_FACILITY_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get facility detail end'));
  };
};

export const deleteFacility = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete facility begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.deleteFacilities(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Facility');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete facility end'));
  };
};

export const addFacility = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add facility begin'));
      const { code, data }: ApiData<any> = await api.request(Endpoints.addFacilites(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Facility');
				cb && cb(data);
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add facility end'));
  };
};

export const editFacility = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit facility begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.editFacilites(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Facility');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit facility end'));
  };
};

export const getAllFacilitieSlug = (keyword: string, page: number, is_publish: boolean | '', is_home_page: boolean | '', limit = 10, language = '', slug = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get facilities list begin'));
			const {
				code, data, pagination
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.getFacilitiesList(keyword, page, is_publish, is_home_page, limit, language, slug));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_FACILITIES_SLUG,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get facilities list end'));
	};
};