import { getAdminDetails } from 'store/actions';
import useAdmins from '../useAdmins';
import AdminForm from 'components/AdminView/AdminForm';

const AdminsForm = () => {
  const { formArray } = useAdmins();

  return (
    <AdminForm
      backLink='/admins'
      formArray={ formArray }
      getDetailAction={ getAdminDetails }
      isAbleToSubmit={ true }
      localHooks={ useAdmins }
      title='Admin'
      type='admins'
    />
  );
};

export default AdminsForm;