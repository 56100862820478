import { useEffect } from 'react';

import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllBanners } from 'store/actions';

import useBanners from './useBanners';

const Banners = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteBanner,
    onSearchBanner,
    formStatus,
    selectedStatus,
    setSelectedStatus
  } = useBanners();

  useEffect(() => {
    dispatch(getAllBanners('', 1, ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Banner'
      thTitles={ thTitles }
      editLink='banners/edit'
      viewLink='banners/view'
      items={ data }
      title='Banners'
      type='banners'
      createLink='banners/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteBanner }
      onChangeSearch={ onSearchBanner }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllBanners }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
    />
  );
};

export default Banners;