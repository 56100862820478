import React, { useEffect, useState } from 'react';
import { Input, Button } from 'components';
import { Images } from 'constant';
import {
 hooks, toastify, LocalStorage
} from 'helpers';
import {
 useLocation, Link, useHistory
} from 'react-router-dom';
import { selectors } from 'store/selectors';
import {
	forgotPassword,
	newPassword,
	verifyToken
} from 'store/Auth/action';

type ResetEmailType = {
	new_password: string;
	confirm_password: string;
};

const ResetPassword: React.FC = () => {
	const auth = LocalStorage.getToken('token');
	const history = useHistory();
	const [emailForgot, setEmailForgot] = useState<string>('');
	const [resetEmail, setResetEmail] = useState<ResetEmailType>({
		confirm_password: '',
		new_password: ''
	});
	const dispatch = hooks.useAppDispatch();
	const loading = hooks.useAppSelector(selectors.loading);
	const location = useLocation();
	const isInputPassword = location?.pathname?.split('/')[2] === 'input-password';
	const token = location?.search?.split('=')[1];
	const handlePassword = () => {
		if (resetEmail.confirm_password !== resetEmail.new_password) {
			toastify.error('Kata sandi baru dan konfirmasi tidak sama');
			setResetEmail({
				confirm_password: '',
				new_password: ''
			});
		} else if (
			(resetEmail.confirm_password.length < 8 && isInputPassword) ||
			(resetEmail.new_password.length < 8 && isInputPassword)
		) {
			toastify.error('Kata sandi harus minimal 8 karakter');
			setResetEmail({
				confirm_password: '',
				new_password: ''
			});
		} else {
			if (isInputPassword) {
				dispatch(newPassword(token, resetEmail.new_password, resetEmail.confirm_password));
			} else {
				dispatch(forgotPassword({ email: emailForgot }));
			}
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(verifyToken(token));
		}
		if (auth || location.pathname === '/reset-password/input-password' && !location?.search?.length || token === '') {
			history.push('/');
		}
	}, [location?.search]);
	return (
		<div className='flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8'>
			<div className='w-full max-w-md space-y-8'>
				<img className='w-auto h-12 mx-auto' src={ Images.logo } alt='Workflow' />
				<h2 className='mt-6 text-3xl font-extrabold text-center text-gray-900'>Reset Password</h2>
				<div className='mt-8 space-y-6'>
					{ isInputPassword ? (
						<>
							<Input
								label='Enter your new password'
								value={ resetEmail.new_password }
								onChange={ (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
									setResetEmail({
										...resetEmail,
										new_password: e.target.value
									})
								}
								type='password'
							/>
							<Input
								label='Confirm your new password'
								value={ resetEmail.confirm_password }
								onChange={ (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
									setResetEmail({
										...resetEmail,
										confirm_password: e.target.value
									})
								}
								type='password'
							/>
						</>
					) : (
						<>
							<Input
								label='Enter your email'
								onChange={ (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setEmailForgot(e.target.value) }
							/>
						</>
					) }

					<div className='flex space-x-7.5'>
						{ location?.pathname?.split('/')?.length === 3 ? null : (
							<Link to='/signin'>
								<Button text='Cancel' type='secondary' disabled={ loading.isLoading } loading={ loading.isLoading } />
							</Link>
						) }
						<Button
							className={ location?.pathname?.split('/')?.length === 3 ? 'w-full' : '' }
							text={ location?.pathname?.split('/')?.length === 3 ? 'Reset Your Password' : 'Send Email Confirmation' }
							type='primary'
							disabled={ loading.isLoading }
							loading={ loading.isLoading }
							onClick={ () => handlePassword() }
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
