import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import {
  GET_ALL_RELATED_NEWS,
  GET_ALL_TAGS, GET_LIST_NEWS, GET_LIST_NEWS_SLUG, GET_NEWS_DETAILS
} from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllNews = (keyword: string, page: number, is_publish: boolean | '', category: string, limit = 10, language = '', slug = '', healthfirst = false) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get news list begin'));
      const {
        code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getAllNews(keyword, page, is_publish, category, limit, language, slug, healthfirst));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_NEWS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get news list end'));
  };
};

export const getNewsDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get news detail begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.getNewsDetail(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_NEWS_DETAILS,
          details: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get news detail end'));
  };
};

export const deleteNews = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete news begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteNews(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus News/Article');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete news end'));
  };
};

export const addNews = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add news begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addNews(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah News/Article');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add news end'));
  };
};

export const editNews = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit news begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editNews(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit News/Article');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit news end'));
  };
};

export const getAllTags = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get all tags begin'));
      const { code, data }: ApiData<any> = await api.request(Endpoints.getAllTags());
      if (code === 200) {
        await dispatch({
          type: GET_ALL_TAGS,
          tags: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get all tags end'));
  };
};

export const addRelatedNewsFacilities = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add related news begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.addRelatedNewsFacilities(), body);
      if (code === 200) {
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add related news end'));
  };
};

export const getRelatedNewsFacilities = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get related news begin'));
      const { code, data }: ApiData<any> = await api.request(Endpoints.getRelatedNewsFacilities(id));
      if (code === 200) {
        await dispatch({
          type: GET_ALL_RELATED_NEWS,
          relatedNews: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get related news end'));
  };
};

export const deleteRelatedNewsFacilities = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete related news begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.deleteRelatedNewsFacilities(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Related News');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete related news end'));
  };
};

export const addRelatedNewsClinic = (body, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('add related news begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.addRelatedNewsClinic(), body);
			if (code === 200) {
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add related news end'));
	};
};

export const getRelatedNewsClinic = (id: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get related news begin'));
			const { code, data }: ApiData<any> = await api.request(Endpoints.getRelatedNewsClinic(id));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_RELATED_NEWS,
					relatedNews: data,
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get related news end'));
	};
};

export const deleteRelatedNewsClinic = (id: string, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('delete related news begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.deleteRelatedNewsClinic(id));
			if (code === 200) {
				toastify.success('Berhasil Menghapus Related News');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete related news end'));
	};
};

export const getAllNewsSlug = (keyword: string, page: number, is_publish: boolean | '', category: string, limit = 10, language = '', slug = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get news list begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getAllNews(keyword, page, is_publish, category, limit, language, slug));
			if (code === 200) {
				await dispatch({
					type: GET_LIST_NEWS_SLUG,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get news list end'));
	};
};