import { Pagination } from 'interfaces/common';
import {
  DoctorActionTypes, GET_LIST_DOCTORS, GET_DOCTOR_DETAILS, GET_LIST_DOCTORS_DROPDOWN, GET_LIST_ALL_DOCTORS_DROPDOWN
} from './actionType';

interface DoctorsState {
  data: any;
  details: any;
  pagination: Pagination;
  list: any;
	listDoctorBySpecialties: any;
  dropdown: any;
}

const initState: DoctorsState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  list: [],
	listDoctorBySpecialties: [],
  dropdown: []
};

const DoctorsReducer = (state = initState, action: DoctorActionTypes) => {
  switch (action.type) {
    case GET_LIST_DOCTORS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_DOCTOR_DETAILS:
      return {
        ...state,
        details: action.details
      };
    case GET_LIST_DOCTORS_DROPDOWN:
      return {
        ...state,
        list: action?.list?.map(item => ({
          id: item?.doctor_code,
					name: item?.fullname_doctor
        })),
        pagination: action.pagination
      };

    case GET_LIST_ALL_DOCTORS_DROPDOWN:
      return {
        ...state,
        dropdown: action?.dropdown?.map(item => ({
          id: item?.doctor_code,
					name: item?.fullname_doctor
        })),
        pagination: action.pagination
      };
    default:
      return state;
  }
};

export default DoctorsReducer;