import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { useEffect } from 'react';
import { getAllFooters, getFooterBySlug } from 'store/actions';
import useFooter from './useFooter';

const Footer = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteFooter,
    onSearchFooter,
    formStatus,
		selectedStatus,
		setSelectedStatus
	} = useFooter();

  useEffect(() => {
		dispatch(getAllFooters('', 1, ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Footer'
      thTitles={ thTitles }
      editLink='footer/edit'
      viewLink='footer/view'
      items={ data }
      title='Footer'
      type='footer'
      createLink='footer/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteFooter }
			onChangeSearch={ onSearchFooter }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
			selectedStatus={ selectedStatus }
			setSelectedStatus={ setSelectedStatus }
      getData={ getAllFooters }
    />
  );
};

export default Footer;