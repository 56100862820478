import React, { Fragment, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import Modal from 'components/Modal';
import { Text, Spinner } from 'components';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';

interface ModalTypes {
	open: boolean;
	buttonRef: React.MutableRefObject<null>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedImage: string,
	setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
}

const PreviewImageModal = (props: ModalTypes) => {
	const {
		open,
		buttonRef,
		setOpen,
		selectedImage,
		setSelectedImage
	} = props;
	const { isLoading } = hooks.useAppSelector(selectors.loading);

	useEffect(() => {
		if (!open) {
			setSelectedImage('');
		}
	}, [open]);

	return (
		<Modal.BasicModal open={ open } setOpen={ setOpen }>
				{ isLoading ?
					<div className='flex justify-center items-center pt-[40%]'>
						<Spinner />
					</div> : <div className='bg-white p-4 pt-5'>
						<div className='p-4 flex justify-center'>
							<img src={ selectedImage } />
						</div>
					</div> }
		</Modal.BasicModal>
	);
};

export default PreviewImageModal;
