import { useEffect } from 'react';

import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllFacilities } from 'store/actions';
import { AdminView } from 'components';

import useFacilities from './useFacilities';

const Facilites = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteFacility,
    onSearchFacility,
    formStatus,
    selectedStatus,
    setSelectedStatus
  } = useFacilities();

  useEffect(() => {
    dispatch(getAllFacilities('', 1, '', ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Facility'
      thTitles={ thTitles }
      editLink='facilities/edit'
      viewLink='facilities/view'
      items={ data }
      title='Facilities'
      type='facilities'
      createLink='facilities/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteFacility }
      onChangeSearch={ onSearchFacility }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllFacilities }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
    />
  );
};

export default Facilites;