import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Text } from 'components';
import { useHistory } from 'react-router-dom';

type HeaderTitleProps = {
	title?: string;
	back?: boolean;
	preview?: boolean;
};

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title = '', back = false, }) => {
	const backButton = back === false ? 'hidden' : '';
	const history = useHistory();
	return (
		<div className='flex flex-row items-baseline mb-5'>
			<a onClick={ () => history.goBack() } className={ backButton }>
				<ArrowLeftIcon className='w-5 h-5 mr-4 ' />
			</a>
			<Text size='text-2xl' weight='font-semibold'>
				{ title }
			</Text>
		</div>
	);
};

export default HeaderTitle;
