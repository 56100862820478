import axios, { Method } from 'axios';

import { Endpoints } from 'constant';
import { auth, navigation } from 'helpers';
import {
	ApiData, ApiResponseData, Pagination
} from 'interfaces/common';
import { AuthCredential } from 'interfaces/auth';
import { ApiPaginationResponse } from 'typings';

import axiosApi from './axios';
import * as Sentry from "@sentry/react";

export interface Endpoint {
	path: string;
	method: Method;
}

/* eslint-disable no-console */

const request = async (endpoint: Endpoint, body?: any, type = 'json') => {
	const url = endpoint.path;
	const method = endpoint.method;

	try {
		const response = await axiosApi.request({
			url,
			method,
			data: (method !== 'GET' && body) || null,
			headers: { 'Content-Type': type === 'json' ? 'application/json' : 'multipart/form-data' }
		});

		const result: ApiResponseData<any> = await response.data;

		// console.log('==== RESULT ====', result);

		const data: ApiData<any> = await {
			code: response.status,
			stat_code: result.stat_code,
			stat_msg: result.stat_msg,
			data: result.data,
			pagination: result.pagination
		};

		return data;
	} catch (error) {
		// Axios has a property isAxiosError that is used to detect types
		if (axios.isAxiosError(error)) {
			// Access to config, request, and response
			if (error.response) {
				// Request made and server responded
				const result: ApiResponseData<any> = await error.response.data;

				console.log('==== ERR ====', result);

				const dataError: ApiData<any> = {
					code: error.response.status,
					stat_code: result.stat_code,
					stat_msg: result.stat_msg,
					data: result.data,
					pagination: convertPaginationResponse(result.pagination)
				};
				if (result.stat_msg === 'token is invalid' || result.stat_msg === 'Authentication Error') {
					localStorage.clear();
					navigation.push('/signin');
				}

				return dataError;
			} else {
				/**
				 * The request was made but no response was received
				 * OR
				 * Something happened in setting up the request that triggered an Error
				 */
				Sentry.captureException(error);
				throw error;
			}
		} else {
			// Just a stock error
			Sentry.captureException(error);
			throw error;
		}
	}
};

const refreshAccessToken = async () => {
	try {
		const url = Endpoints.refreshToken().path;
		const method = Endpoints.refreshToken().method;

		const accessToken = await auth.getAuthCredentials('token');
		const response = await axiosApi.request({
			url,
			method,
			data: { access_token: accessToken }
		});

		const result: ApiResponseData<AuthCredential> = await response.data;

		auth.setAuthCredentials(result.data.token);

		return result.data.token;
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
};

const convertPaginationResponse = (pagination: ApiPaginationResponse) => {
	let count, offset, total_page, category, is_publish, keyword;
	let paginationConverted: Pagination = {
		count: 0,
		limit: 10,
		offset: 0,
		order: '',
		page: 1,
		total_page: 1,
		sort: 'desc',
		category: '',
		is_publish: '',
		keyword: ''
	};

	if (pagination && !Array.isArray(pagination)) {
		if ('count' in pagination && 'offset' in pagination && 'total_page' in pagination) {
			count = pagination.count;
			offset = pagination.offset;
			total_page = pagination.total_page;
			category = pagination.category,
				is_publish = pagination.is_publish;
			keyword = pagination.keyword;
		} else if ('record_total' in pagination && 'start' in pagination) {
			count = pagination.record_total;
			offset = pagination.start;
			total_page = Math.ceil((pagination.total_page || 0) / pagination.limit) || 1;
		}

		paginationConverted = {
			count,
			offset,
			total_page,
			limit: pagination.limit,
			order: pagination.order,
			page: pagination.page,
			sort: pagination.sort,
			category,
			is_publish,
			keyword,
		};
	}

	return paginationConverted;
};

export { request, refreshAccessToken };
