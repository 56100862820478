import { Pagination } from 'interfaces/common';

export const GET_LIST_DOCTORS = 'GET_LIST_DOCTORS';
export const GET_DOCTOR_DETAILS = 'GET_DOCTOR_DETAILS';
export const GET_LIST_DOCTORS_DROPDOWN = 'GET_LIST_DOCTORS_DROPDOWN';
export const GET_LIST_ALL_DOCTORS_DROPDOWN = 'GET_LIST_ALL_DOCTORS_DROPDOWN';

export interface GetDoctorList {
  type: typeof GET_LIST_DOCTORS;
  data: any;
  pagination: Pagination;
}

export interface GetDoctorListDropdown {
  type: typeof GET_LIST_DOCTORS_DROPDOWN;
  list: any;
  pagination: any;
}

export interface GetDoctorDetails {
  type: typeof GET_DOCTOR_DETAILS;
  details: any;
}

export interface GetListDoctorsDropdown {
  type: typeof GET_LIST_ALL_DOCTORS_DROPDOWN;
  dropdown: any;
  pagination: Pagination;

}

export type DoctorActionTypes = GetDoctorList | GetDoctorDetails | GetDoctorListDropdown | GetListDoctorsDropdown;
