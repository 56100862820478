import { useEffect } from 'react';

import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllEventList, getAllHospitals } from 'store/actions';
import { selectors } from 'store/selectors';
import { AdminView } from 'components';

import useEventAndClasses from './useEventAndClasses';

const EventAndClasses = () => {
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    onSearchEvent,
    paginationFunction,
    onDeleteEvent,
    selectedStatus,
    setSelectedStatus,
    formStatus,
    formType,
    selectedType,
    setSelectedType,
    selectedHospital,
    setSelectedHospital
  } = useEventAndClasses();

  const dispatch = hooks.useAppDispatch();
  const { data, pagination } = hooks.useAppSelector(selectors.eventAndClasses);
  const { data: dataHospital } = hooks.useAppSelector(selectors.hospitals);

  useEffect(() => {
    dispatch(getAllEventList('', 1, '', '', ''));
    dispatch(getAllHospitals('', 1, '', 100, 'id'));
  }, []);
  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Event/Class'
      thTitles={ thTitles }
      editLink='event-classes/edit'
      viewLink='event-classes/view'
      items={ data }
      title='Events and Classes'
      type='events'
      createLink='event-classes/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onChangeSearch={ onSearchEvent }
      pagination={ pagination }
      onClickPagination={ paginationFunction }
      onDeleteModalClick={ onDeleteEvent }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      filterType={ formType }
      getData={ getAllEventList }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
      selectedTypes={ selectedType }
      setSelectedTypes={ setSelectedType }
      selectedHospital={ selectedHospital }
      setSelectedHospital={ setSelectedHospital }
      hospitalData={ [
        ...dataHospital, {
          id: '',
          name: 'All',
          value: ''
        }
      ] }
    />
  );
};

export default EventAndClasses;