import axios, { AxiosResponse } from 'axios';
import { Endpoints } from 'constant';
import { toastify } from 'helpers';
import { JsonProvider } from 'leaflet-geosearch';

export interface ResultReverse {
  x: number;
  y: number;
  label: string;
}

export interface MapsLocation {
  lat: number;
  lng: number;
}

export const getReverseGeocoding = async (location: MapsLocation, callback?: (result: ResultReverse) => void) => {
  const lat = location.lat;
  const lng = location.lng;

  try {
    const response: AxiosResponse<any> = await axios.get(Endpoints.getEsriReverseGeocode().path, {
      params: {
        location: `${lng},${lat}`,
        f: 'json'
      }
    });

    const result = await response.data;

    const resultParse: ResultReverse = await {
      x: result.location.x,
      y: result.location.y,
      label: result.address.Match_addr || result.address.LongLabel
    };

    callback ? callback(resultParse) : null;

    return resultParse;
  } catch (error) {
    toastify.error('Error request find an address');

    callback ? callback({
      x: location.lng,
      y: location.lat,
      label: ''
    }) : null;
  }
};

class EsriCustomProvider extends JsonProvider {
  endpoint ({ query }) {
    return this.getUrl(Endpoints.getEsriGeocode().path, {
      address: query,
      f: 'json',
      outFields: 'City,LongLabel',
      sourceCountry: 'IDN'
    });
  }

  parse ({ data }) {
    // Note that `data` is the raw result returned by the server. This
    // method should return data in the SearchResult format.
    if (data) {
      return data?.candidates?.map(candidate => ({
        x: candidate.location.x,
        y: candidate.location.y,
        label: candidate.attributes.LongLabel,
        bounds: null,
      }));
    }

    return [];
  }
}

export default EsriCustomProvider;