import { Pagination } from 'interfaces/common';

export const GET_LIST_SPECIALITIES = 'GET_LIST_SPECIALITIES';

export interface GetSpecialitiesList {
  type: typeof GET_LIST_SPECIALITIES;
  data: any;
  pagination: Pagination;
}

export type SpecialitiesActionTypes = GetSpecialitiesList;
