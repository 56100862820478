import { Pagination } from 'interfaces/common';

export const GET_LIST_BANNERS = 'GET_LIST_BANNERS';
export const GET_BANNER_DETAILS = 'GET_BANNER_DETAILS';

export interface GetBannerList {
  type: typeof GET_LIST_BANNERS;
  data: any;
  pagination: Pagination;
}

export interface GetBannerDetails {
  type: typeof GET_BANNER_DETAILS;
  details: any;
}

export type BannersActionTypes = GetBannerList | GetBannerDetails;
