import { store } from 'store';

import * as LocalStorage from './localStorage';
import * as SessionStorage from './sessionStorage';

export const removeAuthCredentials = () => {
	const isSaveToken = store.getState().auth.saveToken;

	if (isSaveToken) {
		return LocalStorage.clearToken();
	}

	return SessionStorage.clearToken();
};

export const setAuthCredentials = (
	token: string,
) => {
	const isSaveToken = store.getState().auth.saveToken;

	if (isSaveToken) {
		LocalStorage.setToken(token);
	} else {
		SessionStorage.setToken(token, token);
	}
};

export const getAuthCredentials = (
	tokenType: 'token'
) => {
	return LocalStorage.getToken(tokenType) || SessionStorage.getToken(tokenType);
};
