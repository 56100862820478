import React, { useState, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { toastify } from 'helpers';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { PreviewImage, Text } from 'components';
import {
	formatByteSize, checkIsFileValid, checkIsFilePDF
} from 'helpers/misc';

type FileUploadProps = {
	handleImageFunction: any;
	previewImageState: string[] | any;
	setPreviewImageState: any;
	imagePayload: any;
	setImagePayload: any;
	category?: string;
	disabled: boolean | any;
	type?: 'image' | 'mixed' | 'pdf';
	containerClassName?: string;
	max?: number;
	index?: number;
	withLabel?: boolean;
	onClickPin?: any;
	pin?: any;
	name?: any;
	filterPreviousImage?: any,
	stateType?: 'images' | 'maps';
	label?: string;
	isMandatory?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = ({
	handleImageFunction,
	previewImageState,
	setPreviewImageState,
	imagePayload,
	setImagePayload,
	disabled,
	type = 'mixed',
	containerClassName,
	max = 5,
	index = 0,
	withLabel = true,
	onClickPin,
	pin,
	name,
	filterPreviousImage,
	stateType,
	label = 'Upload',
	isMandatory = false
}) => {
	const [openPreview, setOpenPreview] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
	const refModal = useRef(null);
	const indexDiff = previewImageState?.length - imagePayload?.length || 0;
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const deleteImage = (name: string, index: number) => {
		const newPreview = previewImageState;
		if (newPreview.length > index) {
			newPreview.splice(index, 1);
		}
		setPreviewImageState(newPreview);
		setImagePayload(imagePayload.filter(item => item.name !== name));
	};

	const handleMultipleImages = files => {
		if (handleImageFunction) {
			const imageFiles = Array.from(files)?.filter((item: any) => item?.type === 'image/png' || item?.type === 'image/jpeg');
			const pdfFiles = Array.from(files)?.filter((item: any) => item?.type === 'application/pdf');
			if ((type === 'image' && pdfFiles?.length > 0) || (type === 'pdf' && imageFiles?.length > 0)) {
				toastify.error(`terdapat file selain ${ type === 'image' ? 'gambar' : 'pdf' }`);
			} else {
				handleImageFunction(Array.from(files), imagePayload, setImagePayload, type === 'pdf', max, previewImageState.length);
				const dupes = Array.from(files).map((item: any) => imagePayload.filter(items => items.name === item.name));
				const selectedFiles: any = [];
				const targetFiles: File[] = Array.from(files);
				if (previewImageState.length + Array.from(files).length <= max) {
					const filteredItem = targetFiles.filter((item: File) =>
						item.size < 1999999 && type === 'pdf' ? checkIsFilePDF(item) : checkIsFileValid(item)
					);
					if (dupes[0].length) {
						return;
					} else if (filteredItem.length === targetFiles.length) {
						filteredItem.map((file: any) => {
							return selectedFiles.push(URL.createObjectURL(file));
						});
						setPreviewImageState([...previewImageState, ...selectedFiles]);
					}
				}
			}
		} else return;

	};

	const maxNameLength = max >= 5 ? 15 : 25;

	return (
		<div className='border-2 p-2'>
			<div
				onDrop={ (e: React.DragEvent<HTMLDivElement>) => {
					e.preventDefault();
					handleMultipleImages(e.dataTransfer.files);
				} }
				onDragOver={ (e: React.DragEvent<HTMLDivElement>) => {
					e.preventDefault();
				} }
				className={ containerClassName + ' mt-2 mb-2' }
			>
				{ withLabel ?
					<div className='flex'>
						<label className='block text-sm font-medium text-gray-700'>{ label }</label>
						{ isMandatory ? <Text text='*' color='text-red-500' size='text-xs' className='ml-1' /> : null }
					</div>
					: null }
				<div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-mossCream-50'>
					<div className='space-y-1 text-center'>
						<svg className='mx-auto h-12 w-12 text-moss-100' stroke='currentColor' fill='none' viewBox='0 0 48 48' aria-hidden='true'>
							<path
								d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
								strokeWidth={ 2 }
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
						<div className='flex text-sm text-gray-600 items-center justify-center'>
							<label
								htmlFor={ `file-upload[${ index }]` }
								className={ `relative cursor-pointer rounded-md font-medium ${ disabled ? 'text-gray-400' : 'text-moss-100'
									}  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500` }
							>
								<span>Upload a file</span>
								<input
									id={ `file-upload[${ index }]` }
									name={ `file-upload[${ index }]` }
									type='file'
									accept={
										type === 'image'
											? 'image/png, image/jpeg'
											: type === 'pdf'
												? 'application/pdf'
												: 'image/png, image/jpeg, application/pdf'
									}
									className='sr-only'
									onChange={ (e: any) => {
										handleMultipleImages(e.target.files);
									} }
									onClick={ (e: any) => {
										e.target.value = null;
									} }
									disabled={ disabled }
									multiple={ max > 1 }
								/>
							</label>
							<p className='pl-1'>or drag and drop</p>
						</div>
						{
							!label.includes('Mobile') &&
							<p className='text-xs text-gray-500'>
								{ type === 'image' ? 'For Desktop PNG and JPG 1440 x 580, up to 10MB' : type === 'pdf' ? 'PDF up to 10MB' : 'PNG, JPG, and PDF up to 10MB' }
							</p>
						}
						{
							label.includes('Mobile') &&
							<p className='text-xs text-gray-500'>
								{ type === 'image' && 'For Mobile PNG and JPG 360 x 360, up to 10MB' }
							</p>
						}

					</div>
				</div>
			</div>
			<div className='grid grid-cols-3 gap-5'>
				{ previewImageState?.map((item, index) => {
					return (
						<div className='flex flex-row' key={ index }>
							<div className='w-17 h-17 rounded-2sm bg-gray-50 relative' onClick={ () => {
								setSelectedImage(item);
								setOpenPreview(true);
							} }>
								{ type === 'pdf' ?
									item?.length > 0 &&
									(
										<DocumentTextIcon className='w-full h-full' />
									) : (
										<img src={ item } className='preview-image-size w-full h-full object-contain' />
									) }
							</div>
							<div className='flex flex-col justify-between ml-2.5 flex-1'>
								<div className='flex flex-row'>
									<Text size='text-xs' color='text-gray-700' weight='font-medium' className='flex-1'>
										{ index < indexDiff ? type === 'pdf' ? 'Previous Pdf' : 'Previous Image' : imagePayload[index - indexDiff]?.name.length > maxNameLength ? imagePayload[index - indexDiff]?.name.slice(0, maxNameLength) + '...' : imagePayload[index - indexDiff]?.name }
									</Text>
									{ disabled ? null : <XMarkIcon
										className='w-4 h-4 text-black-300'
										onClick={ () => {
											deleteImage(imagePayload[index]?.name, index);
											(pin && pin[name]?.index === index ? onClickPin && onClickPin({
												...pin,
												[name]: {
													name: '',
													index: null
												},
											}) : null);
											(indexDiff > 0 ? filterPreviousImage && filterPreviousImage(
												name, stateType, item
											) : null);

										} }

									/> }
								</div>
								<Text size='text-xs' color='text-gray-500' weight='font-bold' className='mb-1 mt-1' onClick={ () => {
									onClickPin && onClickPin({
										...pin,
										[name]: {
											name: item,
											index: index
										},
									});
								} }>
								</Text>
								<Text size='text-xs' color='text-gray-400' weight='font-medium'>
									{ index < indexDiff ? '' : formatByteSize(imagePayload[index - indexDiff]?.size) }
								</Text>
							</div>
						</div>
					);
				})
				}
			</div>
			<PreviewImage buttonRef={ refModal } open={ openPreview } setOpen={ setOpenPreview } selectedImage={ selectedImage } setSelectedImage={ setSelectedImage } />
		</div>
	);
};

export default FileUpload;
