import { useEffect } from 'react';

import useHospital from '../useHospital';
import { getAllHospitals, getHospitalDetails } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import { hooks } from 'helpers';

const HospitalForm = () => {
  const dispatch = hooks.useAppDispatch();

  const { formArray } = useHospital();

  useEffect(() => {
    dispatch(getAllHospitals('', 1, '', 1000, 'idn'));
  }, []);

  return (
    <AdminForm
      formArray={ formArray }
      backLink='/hospital'
      getDetailAction={ getHospitalDetails }
      isAbleToSubmit={ true }
      title='Hospital'
      localHooks={ useHospital }
      type='hospital'
    />
  );
};

export default HospitalForm;