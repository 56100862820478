import { Pagination } from 'interfaces/common';

export const GET_LIST_NEWS = 'GET_LIST_NEWS';
export const GET_NEWS_DETAILS = 'GET_NEWS_DETAILS';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const GET_ALL_RELATED_NEWS = 'GET_ALL_RELATED_NEWS';
export const GET_LIST_NEWS_SLUG = 'GET_LIST_NEWS_SLUG';

export interface GetNewsList {
  type: typeof GET_LIST_NEWS;
  data: any;
  pagination: Pagination;
}

export interface GetNewsDetails {
  type: typeof GET_NEWS_DETAILS;
  details: any;
}

export interface GetAllTags {
  type: typeof GET_ALL_TAGS;
  tags: any;
}

export interface GetAllRelatedNews {
  type: typeof GET_ALL_RELATED_NEWS;
  relatedNews: any;
}

export interface GetAllNewsSlug {
	type: typeof GET_LIST_NEWS_SLUG;
	data: any;
}

export type NewsAndArticlesActionTypes = GetNewsList | GetNewsDetails | GetAllTags | GetAllRelatedNews | GetAllNewsSlug;
