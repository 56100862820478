import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button, Cell, Container, CustomTable, HeaderTitle, Spinner, Text
} from 'components';
import Modal from 'components/Modal';
import { hooks } from 'helpers';
import {
  addRole,
  addRoleAccess,
  deleteRole,
  deleteRoleAccess,
  editRole,
  getAllAccess,
  getAllRoleAccess,
  getAllRoles,
  getRoleDetail
} from 'store/RBAC/actions';
import { selectors } from 'store/selectors';
import { Input } from 'components';

export const RBAC = () => {
  const dispatch = hooks.useAppDispatch();
  const getAllData = () => {
    dispatch(getAllRoleAccess());
    dispatch(getAllRoles());
    dispatch(getAllAccess());
  };
  useEffect(() => {
    getAllData();
  }, []);
  const [open, setOpen] = useState<boolean>(false);
  const [role, setRole] = useState({
    id: '',
    name: ''
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const {
    data, roles, access
  } = hooks.useAppSelector(selectors.RBAC);
  const { isLoading, loadingMsg } = hooks.useAppSelector(selectors.loading);

  useEffect(() => {
    if (!open) {
      setIsEdit(false);
      setRole({
        id: '',
        name: ''
      });
      setConfirmDelete(false);
    }
  }, [open]);

  return (
    <Container header={ <HeaderTitle back={ false } title='RBAC' /> }>
      <div className='flex'>
        <Button text='Add New Role' onClick={ () => setOpen(true) } />
        <Link to='/rbac/form'><Button text='Add New Route' className='ml-2' /></Link>
      </div>
      { isLoading && !(loadingMsg === 'add role access begin' || loadingMsg === 'delete role access begin' || loadingMsg === 'add role access end' || loadingMsg === 'delete role access end') ?
        <div className='flex justify-center mt-10'><Spinner height='w-10' width='w-10' /></div> : <div className='flex flex-col mt-8'>
          <div className='overflow-x-auto rounded-lg'>
            <div className='inline-block min-w-full align-middle'>
              <div className='overflow-hidden border border-gray-200 border-solid shadow sm:rounded-lg drop-shadow shadow-gray-200'>
                <CustomTable thTitles={ ['', ...access?.map(item => item.name)] } hideLastRow={ true } linkView='rbac/form/view/' thTitleArray={ access }>
                  { roles.length && roles.map((item, index) => {
                    return (
                      <tr key={ index } className='align-middle border border-gray-200 border-solid cursor-pointer'>
                        <Cell cellType='text' text={ item.name } className='cursor-pointer' onClick={ () => {
                          dispatch(getRoleDetail(item.id));
                          setOpen(true);
                          setIsEdit(true);
                          setRole({
                            id: item.id,
                            name: item.name
                          });
                        } } />
                        { access && access.map((items, indexes) => {
                          return (
                            <Cell cellType='checkbox' key={ indexes } checked={ data?.findIndex(fin => fin?.access_id === items?.id && fin?.role_id === item?.id) !== -1 } onCheck={ e => {
                              if (e.target.checked) {
                                dispatch(addRoleAccess({
                                  role_id: item?.id,
                                  access_id: items?.id
                                }, () => getAllData()));
                              } else {
                                dispatch(deleteRoleAccess(data?.find(fin => fin?.access_id === items?.id && fin?.role_id === item?.id)?.id, () => getAllData()));
                              }
                            } }
                              disabled={ loadingMsg === 'add role access begin' || loadingMsg === 'delete role access begin' }
                            />
                          );
                        }) }
                      </tr>
                    );
                  }) }
                </CustomTable>
              </div>
            </div>
          </div>
        </div>
      }
      <Modal.BasicModal open={ open } setOpen={ setOpen }>
        { isLoading ?
          <div className='flex justify-center items-center pt-[40%]'>
            <Spinner />
          </div> : <div className='bg-white p-4 pt-5'>
            <Text text={ `${ isEdit ? 'Edit' : 'Input' } Role Name` } size='text-xl' weight='font-bold' />
            <div className='flex mt-4'>
              <Input type='text' placeholder='role name' containerClassName='w-full' value={ role.name } onChange={ (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
                setRole({
                  ...role,
                  name: e.target.value
                });
              } } />
            </div>
            { confirmDelete &&
              <Text className='pl-2' size='text-lg' color='text-red-500' weight='font-bold' text='This action cannot be undone. Click Delete button again to delete role.' />
            }
            <div className='flex mt-4'>
              <Button text={ isEdit ? 'Edit' : 'Submit' } className='mr-4' onClick={ () => {
                setOpen(false);
                setIsEdit(false);
                setConfirmDelete(false);
                if (isEdit) {
                  dispatch(editRole(role.id, { name: role.name }, () => {
                    getAllData();
                    window.location.reload();
                  }));
                } else {
                  dispatch(addRole({ name: role.name }, () => {
                    getAllData();
                  }));
                }

              } } />
              { isEdit && <Button text='Delete' type='delete' onClick={ () => {
                if (!confirmDelete) {
                  setConfirmDelete(true);
                }
                if (confirmDelete) {
                  dispatch(deleteRole(role.id, () => {
                    getAllData();
                    setOpen(false);
                  }));
                }
              } } /> }
            </div>
          </div> }
      </Modal.BasicModal>
    </Container>
  );
};