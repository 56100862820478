import { Pagination } from 'interfaces/common';

export const GET_ALL_FACILITIES = 'GET_ALL_FACILITIES';
export const GET_FACILITY_DETAILS = 'GET_FACILITY_DETAILS';
export const GET_ALL_FACILITIES_SLUG = 'GET_ALL_FACILITIES_SLUG';

export interface GetAllFacilities {
  type: typeof GET_ALL_FACILITIES;
  data: any;
  pagination: Pagination;
}

export interface GetFacilityDetails {
  type: typeof GET_FACILITY_DETAILS;
  details: any;
}

export interface GetAllFacilitiesSlug {
	type: typeof GET_ALL_FACILITIES_SLUG;
	data: any;
}

export type FacilitiesActionTypes = GetAllFacilities | GetFacilityDetails | GetAllFacilitiesSlug;
