import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import {
	GET_ALL_FOOTER, GET_ALL_FOOTER_CATEGORY, GET_ALL_FOOTER_SLUG, GET_DETAIL_FOOTER
} from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllFooters = (keyword: string, page: number, is_publish: boolean | '', limit = 10, slug = '', lang = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get footer list begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getFootersList(keyword, page, is_publish, limit, slug, lang));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_FOOTER,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get footer list end'));
	};
};

export const getFooterBySlug = (slug: string, lang?: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get footer detail begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getFooterBySlug(slug, lang));
			if (code === 200) {
				await dispatch({
					type: GET_DETAIL_FOOTER,
					details: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get footer detail end'));
	};
};

export const getAllFootersCategory = (keyword: string, page: number, is_publish: boolean | '', limit = 10) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get footer category begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getFootersCategory(keyword, page, is_publish, limit));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_FOOTER_CATEGORY,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get footer category end'));
	};
};

export const addFooter = (body, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('add footer begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.addFooter(), body);
			if (code === 200) {
				toastify.success('Berhasil Menambah Footer');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add footer end'));
	};
};

export const editFooter = (id: string, body, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('edit footer begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.editFooter(id), body);
			if (code === 200) {
				toastify.success('Berhasil Mengubah Footer');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('edit footer end'));
	};
};

export const deleteFooter = (id: string, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('delete footer begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.deleteFooter(id));
			if (code === 200) {
				toastify.success('Berhasil Menghapus Footer');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete footer end'));
	};
};

export const getAllFooterSlug = (keyword: string, page: number, is_publish: boolean | '', limit = 10, slug = '', lang = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get footer list begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getFooterBySlug(slug, lang));
			await dispatch({
				type: GET_ALL_FOOTER_SLUG,
				data: data,
			});
		} catch (error) {
		}
		dispatch(loadingEnd('get footer list end'));
	};
};