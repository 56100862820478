import { Pagination } from 'interfaces/common';

export const GET_LIST_CLINICS = 'GET_LIST_CLINICS';
export const GET_CLINIC_DETAILS = 'GET_CLINIC_DETAILS';
export const GET_CLINIC_CLINICS_HOSPITAL_CODE = 'GET_CLINIC_CLINICS_HOSPITAL_CODE';
export const GET_LIST_CLINICS_SLUG = 'GET_LIST_CLINICS_SLUG';

export interface GetListClinics {
  type: typeof GET_LIST_CLINICS;
  data: any;
  pagination: Pagination;
}

export interface GetClinicDetails {
  type: typeof GET_CLINIC_DETAILS;
  details: any;
}

export interface GetListClinicsHospitalCode {
	type: typeof GET_CLINIC_CLINICS_HOSPITAL_CODE;
	dataClinicsByHospitalCode: any;
}

export interface GetListClinicSlug {
	type: typeof GET_LIST_CLINICS_SLUG;
	data: any;
}

export type ClinicActionTypes = GetListClinics | GetClinicDetails | GetListClinicsHospitalCode | GetListClinicSlug;
