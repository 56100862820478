import { Pagination } from 'interfaces/common';
import { SpecialitiesActionTypes, GET_LIST_SPECIALITIES, } from './actionType';

interface SpecialitiesState {
  data: any;
  pagination: Pagination;
}

const initState: SpecialitiesState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  }
};

const SpecialitiesReducer = (state = initState, action: SpecialitiesActionTypes) => {
  switch (action.type) {
    case GET_LIST_SPECIALITIES:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    default:
      return state;
  }
};

export default SpecialitiesReducer;