import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

import {
  GET_ALL_ROLES, GET_ALL_ROLE_ACCESS, GET_ALL_ACCESS, GET_ROLE_DETAIL, GET_ACCESS_DETAIL
} from './actionTypes';
import { MethodType } from 'interfaces/misc';

export const getAllRoleAccess = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get all role access begin'));
      const { code, data }: ApiData<any> = await api.request(Endpoints.getAllRoleAccesses());
      if (code === 200) {
        await dispatch({
          type: GET_ALL_ROLE_ACCESS,
          role: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get all role access end'));
  };
};

export const getAllRoles = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get all role access begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.getAllRoles());
      if (code === 200) {
        await dispatch({
          type: GET_ALL_ROLES,
          data: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get all role access end'));
  };
};

export const getAllAccess = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get all access begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.getAllAccess());
      if (code === 200) {
        await dispatch({
          type: GET_ALL_ACCESS,
          access: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get all access end'));
  };
};

export const addRole = (body: { name: string; }, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add role begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addRole(), body);
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil Menambah Role');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add role end'));
  };
};

export const editRole = (id: string, body: { name: string; }, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit role begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editRole(id), body);
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil Mengedit Role');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit role end'));
  };
};

export const deleteRole = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete role begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteRole(id));
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil Menghapus Role');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete role end'));
  };
};

export const getRoleDetail = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get role detail begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.getRoleDetail(id));
      if (code === 200) {
        await dispatch({
          type: GET_ROLE_DETAIL,
          detail: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get role detail end'));
  };
};

export const addRoleAccess = (body: { role_id: number, access_id: number; }, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add role access begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addRoleAccess(), body);
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil Menambah Role Access');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('add role access end'));
  };
};

export const deleteRoleAccess = (id: number, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete role access begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteRoleAccess(id));
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil menghapus Role Access');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('delete role access end'));

  };
};

export const addAccess = (body: { name: string, method: MethodType, route_name: string; }, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add access begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addAccess(), body);
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil Menambah Route');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('add access end'));
  };
};

export const deleteAccess = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete access begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.DeleteAccess(id));
      if (code === 200) {
        cb && cb();
        toastify.success('Berhasil menghapus Route');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('delete access end'));

  };
};

export const getAccessDetail = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get access detail begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.GetAccessDetail(id));
      if (code === 200) {
        await dispatch({
          type: GET_ACCESS_DETAIL,
          accessDetail: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('get access detail end'));

  };
};
