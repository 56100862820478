import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

import { GET_LIST_PATIENTS, REMOVE_BLACKLIST } from './actionType';

export const getAllPatients = (
  keyword: string,
  page: number,
  is_verified: boolean | '',
  start_date: string,
  end_date: string,
  limit = 10
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get patient list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllPatients(keyword, page, is_verified, start_date, end_date, limit));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_PATIENTS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get patient list end'));
  };
};

export const unBlockPatient = (
	id: string,
) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('unblock patient begin'));
			const {
				code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.removeBlackList(id));
			if (code === 200) {
				toastify.success('Berhasil Unblock Pasien');
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('unblock patient end'));
	};
};