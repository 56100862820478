import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_LIST_SPECIALTIES, GET_SPECIALTIES_DETAIL } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';
import { FacilitiesInterface } from 'interfaces/facilities';

export const getAllListSpecialties = (keyword: string, page: number, limit = 10) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get specialties list begin'));
      const {
        code, data, pagination
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.getAllSpecialties(keyword, page, limit));
      if (code === 200) {
        await dispatch({
					type: GET_LIST_SPECIALTIES,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
		dispatch(loadingEnd('get specialties list end'));
  };
};

export const getDetailGroupSpecialties = (id: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get specialties list begin'));
			const {
				code, data, pagination
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.getDetailGroupSpecialties(id));
			if (code === 200) {
				await dispatch({
					type: GET_SPECIALTIES_DETAIL,
					details: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get specialties list end'));
	};
};

export const addGroupSpecialties = (id: string, body, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('begin specialties add begin'));
			const {
				code
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.addGroupSpecialties(id), body);
			if (code === 200) {
				toastify.success('Berhasil Menambah Group Specialties');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add specialties end'));
	};
};

export const deleteGroupSpecialties = (id: string, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('begin specialties delete begin'));
			const {
				code
			}: ApiData<FacilitiesInterface> = await api.request(Endpoints.deleteGroupSpecialties(id));
			if (code === 200) {
				toastify.success('Berhasil Menghapus Group Specialties');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete specialties end'));
	};
};