import { Pagination } from 'interfaces/common';

export const GET_LIST_EVENT = 'GET_LIST_EVENT';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const GET_LIST_EVENT_SLUG = 'GET_LIST_EVENT_SLUG';

export interface GetEventList {
  type: typeof GET_LIST_EVENT;
  data: any;
  pagination: Pagination;
}

export interface GetEventDetails {
  type: typeof GET_EVENT_DETAILS;
  details: any;
}

export interface GetEventListSlug {
	type: typeof GET_LIST_EVENT_SLUG;
	data: any;
}

export type EventAndClassesActionTypes = GetEventList | GetEventDetails | GetEventListSlug;
