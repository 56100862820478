import { Pagination } from 'interfaces/common';

export const GET_LIST_PATIENTS = 'GET_LIST_PATIENTS';
export const REMOVE_BLACKLIST = 'REMOVE_BLACKLIST';

export interface GetPatientList {
  type: typeof GET_LIST_PATIENTS;
  data: any;
  pagination: Pagination;
}

export interface RemoveBlackList {
	type: typeof REMOVE_BLACKLIST;
	pagination: Pagination;
}

export type PatientActionTypes = GetPatientList | RemoveBlackList;
