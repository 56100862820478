import { Dispatch } from 'redux';

import { AppActions } from 'store';
import {
	LOADING,
	LAZY_LOAD,
	SetLoading,
	SetLazyLoad,
	SET_SHOW_BURGERMENU,
} from './actionTypes';

export const loadingStart = (loadingMsg: string): SetLoading => {
	return {
		type: LOADING,
		data: {
			isLoading: true,
			loadingMsg
		}
	};
};

export const loadingEnd = (loadingMsg: string): SetLoading => {
	return {
		type: LOADING,
		data: {
			isLoading: false,
			loadingMsg
		}
	};
};

export const lazyLoadStart = (fieldName: string): SetLazyLoad => {
	return {
		type: LAZY_LOAD,
		data: {
			fieldName,
			isLoading: true
		}
	};
};

export const lazyLoadEnd = (fieldName: string): SetLazyLoad => {
	return {
		type: LAZY_LOAD,
		data: {
			fieldName,
			isLoading: false
		}
	};
};

export const startLoading = (loadingMsg: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch(loadingStart(loadingMsg));
	};
};

export const stopLoading = (loadingMsg: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch(loadingEnd(loadingMsg));
	};
};

export const startLazyLoading = (fieldName: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch(lazyLoadStart(fieldName));
	};
};

export const stopLazyLoading = (fieldName: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch(lazyLoadEnd(fieldName));
	};
};

export const setShowBurgerMenu = (isShow: boolean) => {
	return async (dispatch: Dispatch<AppActions>) => {
		dispatch({
			type: SET_SHOW_BURGERMENU,
			payload: isShow
		});
	};
};