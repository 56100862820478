import useNewsAndArticles from '../useNewsAndActicles';
import AdminForm from 'components/AdminView/AdminForm';
import { getNewsDetails } from 'store/actions';

const NewsAndArticlesForm = () => {
  const { formArray } = useNewsAndArticles();
  return (
    <AdminForm
      backLink='/news-articles'
      formArray={ formArray }
      getDetailAction={ getNewsDetails }
      isAbleToSubmit={ true }
      localHooks={ useNewsAndArticles }
      title='News and Article'
      type='articles'
    />
  );
};

export default NewsAndArticlesForm;