import {
 createStore, applyMiddleware, combineReducers
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import reducers from './reducers';
import { ActionTypes } from './actionTypes';

export const history = createBrowserHistory();
export const rootReducer = combineReducers({
	...reducers,
	router: connectRouter(history)
});

const persistConfig = {
	key: 'root',
	storage: storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))));

export const persistor = persistStore(store);

export type AppActions = ActionTypes;
export type AppDispatch = ThunkDispatch<any, any, AppActions>;
export type RootState = ReturnType<typeof store.getState>; // eslint-disable-line no-undef
