import { useState } from 'react';

export const useSignin = () => {
  const [formLogin, setFormLogin] = useState({
    email: '',
    password: ''
  });
  return {
    formLogin,
    setFormLogin
  };
};