import { Pagination } from 'interfaces/common';

import {
	SET_PAGINATION, LOGOUT, PaginationActionTypes
} from './actionTypes';

interface PaginationState {
	fieldName: string;
	pagination: Pagination;
}

const initState: PaginationState = {
	fieldName: '',
	pagination: {
		count: 0,
		limit: 10,
		offset: 0,
		order: 'id',
		sort: 'asc',
		page: 1,
		total_page: 1,
		category: '',
		is_publish: 'true',
		keyword: ''
	}
};

const paginationReducer = (state = initState, action: PaginationActionTypes): PaginationState => {
	switch (action.type) {
		case SET_PAGINATION:
			return {
				...state,
				fieldName: action.data.fieldName,
				pagination: { ...action.data.pagination }
			};

		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default paginationReducer;
