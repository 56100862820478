import { Dispatch } from 'redux';
import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

export const generalUpload = (image: FormData) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('upload start'));
      const { code, data }: any = await api.request(Endpoints.generalUpload(), image);
      if (code === 200) {
        return {
          code,
          data
        };
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    await dispatch(loadingEnd('upload end'));
  };
};