import { useEffect } from 'react';
import { useAppDispatch } from 'helpers/hooks';
import useFacilities from '../useFacilities';
import {
	getAllFacilities, getAllNews, getFacilityDetails, getAllHospitals
} from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import { getRelatedNewsFacilities } from 'store/NewsAndArticles/action';
import { useParams } from 'react-router-dom';
import { ParamsType } from 'interfaces/common';

const FacilitiesForm = () => {
	const { formArray, isEdit, details } = useFacilities();
  const dispatch = useAppDispatch();
  const { id } = useParams<ParamsType>();

  useEffect(() => {
    dispatch(getAllFacilities('', 1, '', '', 1000, 'idn'));
    dispatch(getAllNews('', 1, true, '', 1000, 'idn'));
  }, []);

	useEffect(() => {
		if (id && isEdit && details && details.id !== undefined) {
			dispatch(getRelatedNewsFacilities(details.id));
		}
	}, [details]);

  return (
		
    <AdminForm
      localHooks={ useFacilities }
      formArray={ formArray }
      backLink='/facilities'
      isAbleToSubmit={ true }
      title='Facility'
      getDetailAction={ getFacilityDetails }
      type='facilities'
    />
  );
};

export default FacilitiesForm;