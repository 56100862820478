import React from 'react';
import { Link } from 'react-router-dom';

type TableProps = {
	thTitles: any;
	className?: string;
	hideLastRow?: boolean;
	noSpace?: boolean;
	children: any;
	linkView?: string;
	thTitleArray?: any[];
};

const CustomTable: React.FC<TableProps> = ({
	children, thTitles, className, hideLastRow, noSpace, linkView, thTitleArray
}) => {
	return (
		<table className='min-w-full divide-y divide-gray-200'>
			<thead className='bg-gray-50'>
				<tr>
					{ thTitles.map((thTitle, idx) => {
						return (
							<th
								key={ idx }
								scope='col'
								className={ `${ hideLastRow && idx === thTitles.length - 1 || noSpace ? 'table-head-auto' : 'table-head'
									} text-left text-xs font-semibold text-gray-500 uppercase tracking-wide px-6 py-4` }
							>
								{ thTitleArray?.length ? <Link to={ `${ linkView }${ thTitleArray[idx - 1]?.id }` }>{ thTitle }</Link> : thTitle }
							</th>
						);
					}) }
					{ !hideLastRow && (
						<th scope='col' className='text-left text-xs font-semibold text-gray-500 uppercase tracking-wide px-6 py-4'>
							&nbsp;
						</th>
					) }
				</tr>
			</thead>
			<tbody className='bg-white '>{ children }</tbody>
		</table>
	);
};

export default CustomTable;
