import axios, { AxiosInstance } from 'axios';

import { Endpoints } from 'constant';
import {
	auth, toastify, navigation
} from 'helpers';
import { store } from 'store';

import configApi from './config';
import { refreshAccessToken } from './api';

/* eslint-disable no-console */

const axiosApiInstance: AxiosInstance = axios.create({ baseURL: configApi.baseUrl, });

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
	async config => {
		// console.log("==== ENDPOINT ====", config.baseURL, config.url);
		const accessToken = await auth.getAuthCredentials('token');
		const refreshToken = await auth.getAuthCredentials('token');

		// If interceptors calls refreshAccessToken() API, change authorization with refreshToken
		const authorization =
			config.url === Endpoints.refreshToken().path
				? refreshToken
				: accessToken
					? `${ accessToken }`
					: null;

		const hasAuth = accessToken ? authorization : '';
		config.headers = {
			Authorization: hasAuth,
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
			'X-channel': 'cms'
		};

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
	response => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		// const status = error.response.status;
		const data = error.response.data;
		const responseData = error.response.data.data;
		console.log('==== Interceptors Error Response ====', error.response);
		if (responseData && responseData.type === 'token') {
			if (error.response.config.url !== Endpoints.refreshToken().path) {
				if (!originalRequest._retry) {
					originalRequest._retry = true;
					try {
						const access_token = await refreshAccessToken();
						axios.defaults.headers.common['Authorization'] = 'Bearer' + access_token;
						return axiosApiInstance(originalRequest);
					} catch (error) {
						toastify.errorDefaultApi();
						auth.removeAuthCredentials();
						store.dispatch({ type: 'LOGOUT' });
						navigation.push('/');
						return new Promise(() => { }); // eslint-disable-line @typescript-eslint/no-empty-function
					}
				}
			} else {
				auth.removeAuthCredentials();
				store.dispatch({ type: 'LOGOUT' });
				navigation.push('/');
				toastify.error('silahkan login kembali');

				return new Promise(() => { }); // eslint-disable-line @typescript-eslint/no-empty-function
			}
		} else if (
			responseData && responseData.type === 'login'
		) {
			await auth.removeAuthCredentials();
			store.dispatch({ type: 'LOGOUT' });
			navigation.push('/');
			toastify.error('Token tidak valid. Silakan login kembali');

			return new Promise(() => { }); // eslint-disable-line @typescript-eslint/no-empty-function
		} else {
			if (data.stat_msg === 'ERR:EMPTY_DATA') {
				toastify.error('Data tidak ditemukan');
			} else {
				if (data.stat_msg !== 'footer is not exist')
				toastify.error(data.stat_msg);
			}
		}

		return Promise.reject(error);
	}
);

export default axiosApiInstance;
