import { AdminView } from 'components';
import useMedicalSpecialities from './useMedicalSpecialities';

const tableHeader = [
  'no',
  'title',
	'language',
  'status'
  ];

const MedicalSpecialitiesScreen = () => {

  const {
    onDelete, onSearch,
 medicalSpecialities, paginationFunction, pagination, selectedItem, setSelectedItem, setOpenModalDelete, openModalDelete, getMedicalSpecialities
} = useMedicalSpecialities();

  return <AdminView
  cellType='edit_delete'
  createText='Create Medical Specialities'
  thTitles={ tableHeader }
  editLink='medical-specialities/edit'
  viewLink='medical-specialities/view'
  items={ medicalSpecialities }
  title='Medical Specialities'
  type='medical-specialities'
  createLink='medical-specialities/new'
  showModalDelete={ openModalDelete }
  setShowModalDelete={ setOpenModalDelete }
  selectedItem={ selectedItem }
  setSelectedItem={ setSelectedItem }
  onClickPagination={ paginationFunction }
  pagination={ pagination }
  onDeleteModalClick={ onDelete }
  onChangeSearch={ onSearch }
  // withFilter={ true }
  // filter={ createFilterStatus(formStatus) }
  // selectedStatus={ selectedStatus }
  // setSelectedStatus={ setSelectedStatus }
  getData={ getMedicalSpecialities }
/>;
};

export default MedicalSpecialitiesScreen;