import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Dashboard: React.FC = () => {
	const history = useHistory();
	useEffect(() => {
		history.replace('/hospital');
	}, []);
	return <div />;
};

export default Dashboard;