import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { misc } from 'constant';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { hooks } from 'helpers';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { selectors } from 'store/selectors';
import {
	getAllEventList, deleteEvent, addEvent, editEvent, getAllHospitals
} from 'store/actions';
import { generalUpload } from 'store/Common/action';
import { getAllEventListSlug } from 'store/EventAndClasses/action';

export default function useEventAndClasses () {

	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const {
		pagination, details, data, slug_data
	} = hooks.useAppSelector(selectors.eventAndClasses);
	const { data: hospitals } = hooks.useAppSelector(selectors.hospitals);
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const isFormView = location.pathname.includes('edit') || location.pathname.includes('post');
	const thTitles: string[] = [
		'ID',
		'NAME',
		'TYPE',
		'HOSPITAL NAME',
		'LANGUAGE',
		'STATUS'
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		title: '',
		img_url_card: '',
		img_url_detail: '',
		short_description: '',
		slug: '',
		seo_h1: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const [phone, setPhone] = useState({ editorHtml: '' });
	const [information, setInformation] = useState({ editorHtml: '' });
	const [operationalHour, setOperationalHour] = useState({ editorHtml: '' });
	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const formType: any[] = [
		{
			id: 0,
			name: 'All',
			value: ''
		},
		{
			id: 1,
			name: 'Event',
			value: 'Event'
		},
		{
			id: 2,
			name: 'Class',
			value: 'Class',
		},
		{
			id: 3,
			name: 'Promo',
			value: 'Promo',
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedType, setSelectedType] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});

	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [previewImageDetail, setPreviewImageDetail] = useState<string[]>([]);
	const [imagePayloadDetail, setImagePayloadDetail] = useState<File[]>([]);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [selectedHospitalForm, setSelectedHospitalForm] = useState<string[]>([]);
	const [selectedHospital, setSelectedHospital] = useState({
		id: '',
		name: ''
	});
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);

	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const onChangePhone = e => {
		setPhone({
			...editor,
			editorHtml: e
		});
	};
	const onChangeInformation = e => {
		setInformation({
			...editor,
			editorHtml: e
		});
	};
	const onChangeOperationalHour = e => {
		setOperationalHour({
			...editor,
			editorHtml: e
		});
	};

	const refreshData = () => {
		history.push('/event-classes');
		dispatch(getAllEventList('', 1, '', '', ''));
	};
	const onClickSubmit = async (isEdit: boolean) => {
		const formCard = new FormData();
		const formDetail = new FormData();
		const validateForm = () => {
			if (!form.title.length || form.title.length > 200) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (!form.short_description.length || form.short_description.length > 200) {
				setErrorForm(prev => [...prev, 3]);
			}
			if (editor.editorHtml.length <= 11) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!isEdit && !imagePayload.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!isEdit && !imagePayloadDetail.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (!selectedType.name.length) {
				setErrorForm(prev => [...prev, 9]);
			}
			if (!selectedStatus.name.length) {
				setErrorForm(prev => [...prev, 10]);
			}
			if (!selectedHospitalForm.length) {
				setErrorForm(prev => [...prev, 11]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 12]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 13]);
			}
			
		};
		if (isEdit) {
			const validEdit =
				(form.title.length && form.title.length < 200) &&
				(form.short_description.length && form.short_description.length < 200) &&
				editor.editorHtml.length > 11 &&
				selectedType?.name && selectedStatus?.name &&
				selectedHospitalForm?.length &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug;
			if (validEdit) {
				if (imagePayload.length && imagePayloadDetail.length) {
					imagePayload.map(item => formCard.append('upload', item));
					imagePayloadDetail.map(item => formDetail.append('upload', item));
					const uploadCard = await dispatch(generalUpload(formCard));
					if (uploadCard?.code === 200) {
						const uploadDetail = await dispatch(generalUpload(formDetail));
						if (uploadDetail?.code === 200) {
							await dispatch(editEvent(details.id, {
								title: form.title,
								slug: form.slug,
								content: editor.editorHtml,
								img_url_detail: uploadDetail?.data,
								img_url_card: uploadCard?.data,
								category: selectedType?.name?.toLowerCase(),
								phone: phone.editorHtml,
								short_description: form.short_description,
								operational_hour: operationalHour.editorHtml,
								information: information.editorHtml,
								hospital_codes: Array.from(new Set(selectedHospitalForm)),
								is_publish: selectedStatus?.value,
								language: selectedLanguage?.value,
								parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
								seo_h1: form.seo_h1,
								seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
							}, () => refreshData()));
						}
					}
				} else if (imagePayload.length) {
					imagePayload.map(item => formCard.append('upload', item));
					const uploadCard = await dispatch(generalUpload(formCard));
					if (uploadCard?.code === 200) {
						await dispatch(editEvent(details.id, {
							title: form.title,
							slug: form.slug,
							content: editor.editorHtml,
							img_url_detail: details.img_url_detail,
							img_url_card: uploadCard?.data,
							category: selectedType?.name?.toLowerCase(),
							phone: phone.editorHtml,
							short_description: form.short_description,
							operational_hour: operationalHour.editorHtml,
							information: information.editorHtml,
							hospital_codes: Array.from(new Set(selectedHospitalForm)),
							is_publish: selectedStatus?.value,
							language: selectedLanguage?.value,
							parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
							seo_h1: form.seo_h1,
							seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
						}, () => refreshData()));
					}
				} else if (imagePayloadDetail.length) {
					imagePayloadDetail.map(item => formDetail.append('upload', item));
					const uploadDetail = await dispatch(generalUpload(formDetail));
					if (uploadDetail?.code === 200) {
						await dispatch(editEvent(details.id, {
							title: form.title,
							slug: form.slug,
							content: editor.editorHtml,
							img_url_detail: uploadDetail?.data,
							img_url_card: details.img_url_card,
							category: selectedType?.name?.toLowerCase(),
							phone: phone.editorHtml,
							short_description: form.short_description,
							operational_hour: operationalHour.editorHtml,
							information: information.editorHtml,
							hospital_codes: Array.from(new Set(selectedHospitalForm)),
							is_publish: selectedStatus?.value,
							language: selectedLanguage?.value,
							parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
							seo_h1: form.seo_h1,
							seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
						}, () => refreshData()));
					}
				} else {
					await dispatch(editEvent(details.id, {
						title: form.title,
						slug: form.slug,
						content: editor.editorHtml,
						img_url_detail: details.img_url_detail,
						img_url_card: details.img_url_card,
						category: selectedType?.name?.toLowerCase(),
						phone: phone.editorHtml,
						short_description: form.short_description,
						operational_hour: operationalHour.editorHtml,
						information: information.editorHtml,
						hospital_codes: Array.from(new Set(selectedHospitalForm)),
						is_publish: selectedStatus?.value,
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		} else {
			const validForm =
				(form.title.length && form.title.length < 200) &&
				(form.short_description.length && form.short_description.length < 200) &&
				selectedStatus?.name &&
				imagePayload.length &&
				imagePayloadDetail.length &&
				selectedHospitalForm?.length &&
				editor.editorHtml.length > 11 &&
				selectedType?.name &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug;
			imagePayload.map(item => formCard.append('upload', item));
			imagePayloadDetail.map(item => formDetail.append('upload', item));
			if (validForm) {
				const uploadCard = await dispatch(generalUpload(formCard));
				if (uploadCard?.code === 200) {
					const uploadDetail = await dispatch(generalUpload(formDetail));
					if (uploadDetail?.code === 200) {
						await dispatch(addEvent({
							title: form.title,
							slug: form.slug,
							content: editor.editorHtml,
							img_url_detail: uploadDetail?.data,
							img_url_card: uploadCard?.data,
							category: selectedType?.name?.toLowerCase(),
							phone: phone.editorHtml,
							short_description: form.short_description,
							operational_hour: operationalHour.editorHtml,
							information: information.editorHtml,
							hospital_codes: Array.from(new Set(selectedHospitalForm)),
							is_publish: selectedStatus?.value,
							language: selectedLanguage?.value,
							parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
							seo_h1: form.seo_h1,
							seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
						}, () => refreshData()));
					}
				}
			} else {
				validateForm();
			}
		}
	};

	const onSearchEvent = debounce(e => {
		dispatch(getAllEventList(e[0].target.value, 1, selectedStatus?.value, selectedType?.value, selectedHospital?.id?.toString() || ''));
	});

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllEventList(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value, selectedType?.value, selectedHospital?.id?.toString() || ''));
	};

	const onDeleteEvent = (id: string) => {
		dispatch(deleteEvent(id, () => {
			dispatch(getAllEventList('', 1, data?.length === 1 ? '' : selectedStatus?.value, data?.length === 1 ? '' : selectedType?.value, selectedHospital?.id?.toString() || ''));
		}));
	};

	const effectFunctions = () => {
		if (details && (id === details?.slug)) {
			setForm({
				...form,
				img_url_card: details.img_url_card,
				img_url_detail: details.img_url_detail,
				title: details.title,
				short_description: details.short_description,
				slug: details.slug,
				seo_h1: details.seo_h1,
			});
			setSelectedType(formType.find(item => item.name.toLowerCase() === details.category));
			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
			setEditor({ editorHtml: details.content });
			setPhone({ editorHtml: details.phone });
			setInformation({ editorHtml: details.information });
			setOperationalHour({ editorHtml: details.operational_hour });
			setPreviewImage([details.img_url_card]);
			setPreviewImageDetail([details.img_url_detail]);
			setSelectedHospitalForm(details?.hospitals?.map(item => item?.hospital_code));
			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.title
				});
			}
			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'title',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Phone',
			name: 'phone',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			placeholder: 'phone',
			contentType: 'phone'
		},
		{
			index: 3,
			label: 'Short Description',
			name: 'short_description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'short description ',
		},
		{
			index: 4,
			label: 'Information',
			name: 'information',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			placeholder: 'information ',
			contentType: 'information'
		},
		{
			index: 5,
			label: 'Operational Hour',
			name: 'operational_hour',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'editor',
			placeholder: 'operational hour ',
			contentType: 'operational_hour'
		},
		{
			index: 6,
			label: 'Content',
			name: 'editor',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			placeholder: 'editor ',
		},
		{
			index: 7,
			label: 'Upload Image Card',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: previewImage.length >= 1,
			renderType: 'file',
			inputType: 'file',
			maxImage: 1,
			imageType: 'card'
		},
		{
			index: 8,
			label: 'Upload Image Detail',
			name: 'url_img_detail',
			isMandatory: true,
			disabledCondition: previewImageDetail.length >= 1,
			renderType: 'file',
			inputType: 'file',
			maxImage: 1,
			imageType: 'detail'
		},
		{
			index: 9,
			label: 'Type',
			name: 'type',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formType.filter(item => item.value !== ''),
			renderType: 'select',
			placeholder: 'Type ',
			selectType: 'type'
		},
		{
			index: 10,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 11,
			label: 'Hospital',
			name: 'hospital',
			isMandatory: true,
			disabledCondition: isLoading,
			checkboxList: hospitals,
			renderType: 'checkbox',
		},
		{
			index: 12,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 13,
			label: 'Parent Event/Class',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Event/Class'
		},
		{
			index: 14,
			label: 'Slug Article',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		
		{
			index: 15,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (selectedHospitalForm.findIndex(item => item === e.target.value) !== -1) {
			const newState = selectedHospitalForm.filter(fil => fil !== e.target.value);
			setSelectedHospitalForm(newState);
		} else {
			setSelectedHospitalForm([...selectedHospitalForm, e.target.value]);
		}
	};

	useEffect(() => {
		if (isFormView)
			dispatch(getAllHospitals('', 1, true, 10, selectedLanguage.value));
	}, [selectedLanguage]);

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllEventListSlug('', 1, '', '', '', 1000, selectedLanguage.value, form.slug));
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		editor,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onChangeEditor,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		onSearchEvent,
		paginationFunction,
		formType,
		selectedType,
		setSelectedType,
		setEditor,
		onDeleteEvent,
		imagePayload,
		setImagePayload,
		previewImageDetail,
		setPreviewImageDetail,
		imagePayloadDetail,
		setImagePayloadDetail,
		selectedHospital,
		setSelectedHospital,
		details,
		data,
		hospitals,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		onChangePhone,
		onChangeInformation,
		onChangeOperationalHour,
		phone,
		information,
		operationalHour,
		onChangeCheckbox,
		selectedHospitalForm,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		isDuplicateSlug,
		setIsDuplicateSlug,
		checkSlugDuplicate,
		slug_data,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
}
