import React from 'react';
import {
	NewspaperIcon,
	UserGroupIcon,
	HomeIcon,
	CalendarIcon,
	PhotoIcon,
	UserIcon,
	DocumentChartBarIcon,
	BuildingOfficeIcon,
	UsersIcon,
	WrenchIcon,
	BuildingOffice2Icon,
	UserCircleIcon,
	GlobeAltIcon,
	PhoneIcon,
	CalendarDaysIcon,
	ListBulletIcon
} from '@heroicons/react/24/outline';
import { navigation } from 'helpers';
'helpers';

type objLeftSidebar = {
	href: string;
	label: string;
	icon: JSX.Element;
	roleType: string[];
	arrow?: JSX.Element;
	render?: any;
};
const iconStyle = 'w-6 h-6 text-gray-500 group-hover:text-gray-600 transition duration-75';
const renderList = (route, cb, activeLabel, label) => (
	<li>
		<div
			className={ `flex items-center p-2 pl-8 text-sm font-medium text-gray-600 rounded-lg hover:bg-gray-100 ${ activeLabel === label ? 'bg-gray-100' : '' }` }
			onClick={ () => {
				cb();
				navigation.push(route);
			} }
		>
			<span className='ml-3'>{ label }</span>
		</div>
	</li>
);
export const itemsLeftSidebar: (role: string, cb: (label) => void, activeLabel) => objLeftSidebar[] = (role: string, cb, activeLabel) => ([
	{
		href: '/',
		label: 'Hospital',
		icon: <HomeIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type'],
	},
	{
		href: '/facilities',
		label: 'Facilities',
		icon: <BuildingOfficeIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type'],
	},
	{
		href: '/clinics',
		label: 'Clinics',
		icon: <BuildingOffice2Icon className={ `${ iconStyle }` } />,
		roleType: ['any role type'],
	},
	{
		href: '/event-classes',
		label: 'Event and Classes',
		icon: <CalendarIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/news-articles',
		label: 'News and Articles',
		icon: <NewspaperIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/banners',
		label: 'Banners',
		icon: <PhotoIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/awards',
		label: 'Awards and Accreditations',
		icon: <DocumentChartBarIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/admins',
		label: 'Admins',
		icon: <UserIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/doctors',
		label: 'Doctors',
		icon: <UserGroupIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/patients',
		label: 'Patients',
		icon: <UserCircleIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/medical-specialities',
		label: 'Medical Specialities',
		icon: <UserCircleIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/footer',
		label: 'Footer',
		icon: <WrenchIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/contact-us',
		label: 'Contact Us',
		icon: <PhoneIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/booking-doctor',
		label: 'Booking Doctor Setting',
		icon: <CalendarDaysIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/global-settings',
		label: 'Booking Global Setting',
		icon: <CalendarDaysIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	{
		href: '/rbac',
		label: 'Role Config',
		icon: <UsersIcon className={ `${ iconStyle }` } />,
		roleType: ['any role type']
	},
	// {
	// 	href: '/list-specialties',
	// 	label: 'List Specialties',
	// 	icon: <ListBulletIcon className={ `${ iconStyle }` } />,
	// 	roleType: ['any role type']
	// },
	// {
	// 	href: '',
	// 	label: 'Settings',
	// 	icon: <CogIcon className={ `${ iconStyle }` } />,
	// 	roleType: ['any role type'],
	// 	render: (): React.ReactElement => (
	// 		<>
	// 			{ renderList('/settings/package-types', () => cb('Package Types'), activeLabel, 'Package Types') }
	// 			{ renderList('/settings/room-types', () => cb('Room Types'), activeLabel, 'Room Types') }
	// 			{ renderList('/settings/property-types', () => cb('Property Types'), activeLabel, 'Property Types') }
	// 		</>
	// 	)
	// }
]);
