/* eslint-disable no-undef */
export const setItem = (key: string, value: any) => {
	return sessionStorage.setItem(`${ key }`, JSON.stringify(value));
};

export const getItem = (key: string) => {
	const jsonValue = sessionStorage.getItem(`${ key }`);

	return jsonValue !== null ? JSON.parse(jsonValue) : null;
};

export const clearItem = (key: string) => {
	return sessionStorage.removeItem(`${ key }`);
};

export const clearStorage = () => {
	return sessionStorage.clear();
};

export const clearToken = () => {
	const keysToRemove = ['token'];

	return keysToRemove.forEach(k => clearItem(k));
};

export const getToken = (tokenType: 'token') => {
	return getItem(tokenType);
};

export const setToken = (accessToken: string, refreshToken: string) => {
	const objToSet = [
		{
			key: 'access_token',
			value: accessToken
		}, {
			key: 'refresh_token',
			value: refreshToken
		}
	];

	return objToSet.forEach(item => setItem(item.key, item.value));
};
