// interface Config {
//   baseUrl: string;
//   version: string;
// }

const environment = {
	// baseUrl: process.env.REACT_APP_API_URL || '',
	// version: process.env.REACT_APP_API_VERSION || '',
	dev: {
		baseUrl: process.env.REACT_APP_BASE_URL_DEV,
		version: '1.0.85'
	},
	staging: {
		baseUrl: process.env.REACT_APP_BASE_URL_STAGING,
		version: '1.0.85'
	},
	production: {
		baseUrl: process.env.REACT_APP_BASE_URL_PRODUCTION,
		version: 'n/a'
	}
};

export const appStage = process.env.REACT_APP_STAGE as keyof typeof environment;

export const config = environment[appStage];

export const baseUrl = config.baseUrl;

export default config;
