import { getContactUsDetail } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import useContactUs from '../useContactUs';

const ContactUsForm = () => {
	const { formArray } = useContactUs();

  return (
    <AdminForm
      formArray={ formArray }
      backLink='/contact-us'
			getDetailAction={ getContactUsDetail }
      isAbleToSubmit={ true }
      title='Contact Us Detail'
      localHooks={ useContactUs }
      type='contact_us'
    />
  );
};

export default ContactUsForm;