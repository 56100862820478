import { Dispatch } from 'redux';
import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import {
  GET_DOCTOR_DETAILS, GET_LIST_ALL_DOCTORS_DROPDOWN, GET_LIST_DOCTORS, GET_LIST_DOCTORS_DROPDOWN
} from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllDoctors = (keyword: string, page: number, is_active: boolean | '', is_pinned: boolean | '', limit = 10, lang = 'idn') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get doctor list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllDoctors(keyword, page, is_active, is_pinned, limit, lang));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_DOCTORS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get doctor list end'));
  };
};

export const getDoctorDetails = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get doctor detail begin'));
      const {
        code, data, pagination
      }: ApiData<any> = await api.request(Endpoints.getDoctorDetails(id));
      if (code === 200) {
        await dispatch({
          type: GET_DOCTOR_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get doctor detail end'));
  };
};

export const setActiveDoctor = (id: string, body) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('set active doctor begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.setActiveDoctor(id), body);
      if (code === 200) {
        toastify.success('Berhasil merubah data dokter');
        return { code };
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('set active doctor end'));
  };
};

export const setPinnedDoctor = (id: string, body, cb?) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('set pinned doctor begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.setPinnedDoctor(id), body);
      if (code === 200) {
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('set pinned doctor end'));
  };
};

export const getAllDoctorsDropdown = (hospital_code = '', limit = 10, lang = 'idn') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(loadingStart('get doctor list dropdown begin'));
			// reset data, reassign after success call api
			dispatch({
				type: GET_LIST_DOCTORS_DROPDOWN,
				list: [],
				pagination: null,
			});
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getListDoctors(hospital_code, limit, lang));
      if (code === 200) {
        dispatch({
          type: GET_LIST_DOCTORS_DROPDOWN,
          list: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get doctor list dropdown end'));
  };
};

export const getListDoctorsDropdown = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get all doctor list dropdown begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getListDoctorsDropdown());
      if (code === 200) {
        await dispatch({
          type: GET_LIST_ALL_DOCTORS_DROPDOWN,
          dropdown: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get all doctor list dropdown end'));
  };
};

export const syncDoctor = () => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('sync doctor begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.syncDoctor());
			if (code === 200) {
				toastify.success('Berhasil sinkronisasi! Mohon tunggu, data akan tampil dalam beberapa menit.');
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('sync doctor end'));
	};
};
