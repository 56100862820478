export const classNames = (...classes: (false | null | undefined | string)[]) => {
	return classes.filter(Boolean).join(' ');
};

export const getColorProjectStatus = (title: string | undefined) => {
	switch (title) {
		case 'CF PAYMENT':
		case 'on process':
		case 'waiting':
		case 'rejected':
			return 'bg-red-100 text-red-800';
		case 'SITE SURVEY':
		case 'INSTALLATION':
			return 'bg-blue-100 text-blue-800';
		case 'DESIGN PROCESS':
		case 'ON PROGRESS':
			return 'bg-yellow-100 text-yellow-800';
		case 'FILL FORM':
			return 'bg-purple-100 text-purple-800';
		default:
			return 'bg-green-100 text-green-800';
	}
};
