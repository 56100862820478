import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_ALL_HOSPITALS, GET_ALL_HOSPITALS_SLUG, GET_HOSPITAL_DETAILS } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllHospitals = (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '', slug = '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get hospital list begin'));
      const {
        code, data, pagination
      }: ApiData<any> = await api.request(Endpoints.getHospitalsList(keyword, page, is_publish, limit, language, slug));
      if (code === 200) {
        await dispatch({
          type: GET_ALL_HOSPITALS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get hospital list end'));
  };
};

export const getHospitalDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get hospital detail begin'));
      const {
        code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getHospitalDetail(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_HOSPITAL_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get hospital detail end'));
  };
};

export const deleteHospital = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete hospital begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.deleteHospital(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Hospital');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete hospital end'));
  };
};

export const addHospital = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add hospital begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.addHospital(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Hospital');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add hospital end'));
  };
};

export const editHospital = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit hospital begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.editHospital(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Hospital');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit hospital end'));
  };
};

export const getAllHospitalSlug = (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '', slug = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get hospital list begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getHospitalsList(keyword, page, is_publish, limit, language, slug));
			if (code === 200) {
				await dispatch({
					type: GET_ALL_HOSPITALS_SLUG,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get hospital list end'));
	};
};