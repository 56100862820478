import { Pagination } from 'interfaces/common';

export const GET_LIST_ADMIN = 'GET_LIST_ADMIN';
export const GET_ADMIN_DETAILS = 'GET_ADMIN_DETAILS';

export interface GetAdminList {
  type: typeof GET_LIST_ADMIN;
  data: any;
  pagination: Pagination;
}

export interface GetAdminDetails {
  type: typeof GET_ADMIN_DETAILS;
  details: any;
}

export type AdminsActionTypes = GetAdminList | GetAdminDetails;
