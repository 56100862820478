import React, { useEffect, useState } from 'react';
import {
  useHistory, useLocation, useParams
} from 'react-router-dom';

import {
  Container,
  HeaderTitle,
  Card,
  Input,
  Select,
  Text,
  Button,
  Spinner
} from 'components';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import {
  addAccess,
  deleteAccess,
  getAccessDetail,
  getAllAccess,
  getAllRoleAccess,
  getAllRoles
} from 'store/RBAC/actions';
import { MethodType } from 'interfaces/misc';
import { ParamsType } from 'interfaces/common';

type RouteStateType = {
  name: string,
  route_name: string;
};

type SelectedMethodType = {
  id: number | undefined,
  name: MethodType;
};

const RouteForm: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<ParamsType>();
  const isView = location.pathname.includes('view');
  const { isLoading } = hooks.useAppSelector(selectors.loading);
  const { accessDetail } = hooks.useAppSelector(selectors.RBAC);
  const [route, setRoute] = useState<RouteStateType>({
    name: '',
    route_name: ''
  });
  const [selectedMethod, setSelectedMethod] = useState<SelectedMethodType>({
    id: 0,
    name: ''
  });
  const method = [
    {
      id: 1,
      name: 'GET'
    },
    {
      id: 2,
      name: 'DELETE'
    },
    {
      id: 3,
      name: 'POST'
    },
    {
      id: 4,
      name: 'PUT'
    }
  ];

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRoute({
      ...route,
      [e.target.name]: e.target.value
    });
  };
  const [error, setError] = useState<{
    index: number,
    name: string;
  }[]>([]);
  const filteredForm = error.filter((item, idx, self) => idx === self.findIndex(items => (
    items.index === item.index && items.name === item.name
  )));
  const getAllData = () => {
    dispatch(getAllRoleAccess());
    dispatch(getAllRoles());
    dispatch(getAllAccess());
  };

  const onClickSubmit = () => {
    if (route.name.length && route.route_name.length && selectedMethod.name.length) {
      dispatch(addAccess({
        name: route.name,
        method: selectedMethod?.name,
        route_name: route.route_name
      },
        () => {
          getAllData();
          history.push('/rbac');
        }));
    } else {
      if (!route.name.length) {
        setError(prev => [
          ...prev, {
            index: 0,
            name: 'Name'
          }
        ]);
      }
      if (!route.route_name.length) {
        setError(prev => [
          ...prev, {
            index: 2,
            name: 'Route Name'
          }
        ]);
      }
      if (!selectedMethod.name.length) {
        setError(prev => [
          ...prev, {
            index: 1,
            name: 'Method'
          }
        ]);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllAccess());
    if (params.id) {
      dispatch(getAccessDetail(params.id));
    }
  }, []);

  useEffect(() => {
    if (route?.name?.length) {
      setError(error.filter(item => item.index !== 0));
    }
  }, [route.name]);
  useEffect(() => {
    if (route?.route_name?.length) {
      setError(error.filter(item => item.index !== 2));
    }
  }, [route.route_name]);
  useEffect(() => {
    if (selectedMethod?.name) {
      setError(error.filter(item => item.index !== 1));
    }
  }, [selectedMethod.name]);

  useEffect(() => {
    if (accessDetail && isView) {
      setRoute({
        name: accessDetail?.name,
        route_name: accessDetail?.route_name
      });
      setSelectedMethod({
        id: accessDetail?.id,
        name: accessDetail?.method
      });
    }
  }, [accessDetail]);
  return (
    <Container header={ <HeaderTitle back={ false } title='Add New Route' /> }>
      <Card>
        { isLoading ? <div className='flex justify-center mt-10'><Spinner height='w-10' width='w-10' /></div> :
          <>
            <div className='min-w-full min-h-full px-4 py-12 sm:px-6 lg:px-8'>
              <div className='space-y-6'>
                <Input
                  type='text'
                  label='Name'
                  placeholder='name'
                  name='name'
                  onChange={ (e: React.ChangeEvent<HTMLInputElement
                    | HTMLTextAreaElement>) => onChangeInput(e) }
                  disabled={ isView }
                  value={ route.name }
                />
                <div>
                  <Text size='text-xs' text='Method' />
                  <Select
                    state={ selectedMethod }
                    setState={ setSelectedMethod }
                    list={ method }
                    keyObj='name'
                    placeholder='Route Method'
                    disabled={ isView }
                  />
                </div>
                <Input
                  type='text'
                  label='Route Name'
                  placeholder='route name'
                  name='route_name'
                  onChange={ (e: React.ChangeEvent<HTMLInputElement
                    | HTMLTextAreaElement>) => onChangeInput(e) }
                  disabled={ isView }
                  value={ route.route_name }
                />
              </div>
            </div>
            { !isView && <Button
              text='Submit'
              disabled={ isLoading }
              onClick={ () => onClickSubmit() }
            /> }
            { isView &&
              <Button
                text='Delete'
                type='delete'
                disabled={ isLoading }
                className='ml-2'
                onClick={ () => dispatch(deleteAccess(params?.id, () => {
                  getAllData();
                  history.push('/rbac');
                })) }
              /> }
            <Button
              text='Back'
              type='secondary'
              disabled={ isLoading }
              className='ml-2'
              onClick={ () => history.push('/rbac') }
            />
            { error.length ?
              <div>
                <Text text='Terdapat form yang belum lengkap: ' />
                { filteredForm.map(item => (
                  <Text key={ item.index } text={ item.name } weight='font-bold' color='text-red-500' />
                )) }
              </div>
              : null }
          </>
        }

      </Card>

    </Container>
  );
};

export default RouteForm;