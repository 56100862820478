import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_LIST_AWARDS, GET_AWARD_DETAILS } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllAwards = (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get award list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllAwards(keyword, page, is_publish, limit, language));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_AWARDS,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get award list end'));
  };
};

export const getAwardDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get award detail begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAwardDetails(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_AWARD_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get award detail end'));
  };
};

export const deleteAward = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete award begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteAward(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Award/Accreditation');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete award end'));
  };
};

export const addAward = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add award begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addAward(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Award/Accreditation');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add award end'));
  };
};

export const editAward = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit award begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editAward(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Banner');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit award end'));
  };
};