import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

import {
  GET_BOOKING_CONFIG, GET_BOOKING_CONFIG_DETAIL
} from './actionType';

export const getBookingConfig = (keyword: string, page: number, limit = 10, hospital: string | '', is_active: boolean | '', type: string | '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get booking list begin'));
      const {
        code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getBookingConfig(keyword, page, limit, hospital === undefined ? '' : hospital, is_active, type));
      if (code === 200) {
        await dispatch({
					type: GET_BOOKING_CONFIG,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get clinic list end'));
  };
};

export const getBookingConfigDetail = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get booking detail begin'));
      const {
        code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getBookingConfigDetail(id));
      if (code === 200) {
        await dispatch({
          type: GET_BOOKING_CONFIG_DETAIL,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get clinic detail end'));
  };
};

export const deleteBookingConfig = (id: string,cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('delete booking begin'));
			const {
				code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.deleteBookingConfig(id));
			if (code === 200) {
				toastify.success('Berhasil Menghapus Booking');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete booking begin'));
	};
};