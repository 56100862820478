import {
	Switch, Route, RouteProps
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import {
	Dashboard,
	Signin,
	ResetPassword,
	EventAndClasses,
	EventAndClassesForm,
	NotFound,
	NewsAndArticles,
	NewsAndArticlesForm,
	Banners,
	BannerForm,
	Admins,
	AdminsForm,
	AwardsAndAccreditation,
	AwardsAndAccreditationForm,
	Hospital,
	HospitalForm,
	Doctors,
	DoctorForm,
	Clinics,
	ClinicsForm,
	Patients,
	PatientsForm,
	RBAC,
	RouteForm,
	ContactUs,
	BookingDoctor
} from 'screens';
import Facilites from 'screens/Dashboard/Facilities';
import FacilitiesForm from 'screens/Dashboard/Facilities/FacilitesForm';
import Footer from 'screens/Dashboard/Footer';
import FooterForm from 'screens/Dashboard/Footer/FooterForm';

import PrivateRoute from './private';
import BookingSettings from 'screens/Dashboard/BookingSettings';
import ContactUsForm from 'screens/Dashboard/ContactUs/ContactUsForm';
import ListSpecialties from 'screens/Dashboard/ListSpecialties';
import SpecialtiesForm from 'screens/Dashboard/ListSpecialties/SpecialtiesForm';
import MedicalSpecialitiesPage from 'screens/Dashboard/MedicalSpecialities';
import CreateMedicalSpecialities from 'screens/Dashboard/MedicalSpecialities/create';
import EditMedicalSpecialities from 'screens/Dashboard/MedicalSpecialities/edit';

export type ProjectDetailParams = {
	id: string;
	mode: string;
};

const privateRoutes: RouteProps[] = [
	{
		path: '/',
		component: Dashboard
	},
	{
		path: '/hospital',
		component: Hospital
	},
	{
		path: '/hospital/post',
		component: HospitalForm
	},
	{
		path: '/hospital/edit/:id/:detailLang',
		component: HospitalForm
	},
	{
		path: '/hospital/view/:id/:detailLang',
		component: HospitalForm
	},
	{
		path: '/facilities',
		component: Facilites
	},
	{
		path: '/facilities/post',
		component: FacilitiesForm
	},
	{
		path: '/facilities/edit/:id/:detailLang',
		component: FacilitiesForm
	},
	{
		path: '/facilities/view/:id/:detailLang',
		component: FacilitiesForm
	},
	{
		path: '/event-classes',
		component: EventAndClasses
	},
	{
		path: '/event-classes/post',
		component: EventAndClassesForm
	},
	{
		path: '/event-classes/edit/:id/:detailLang',
		component: EventAndClassesForm
	},
	{
		path: '/event-classes/view/:id/:detailLang',
		component: EventAndClassesForm
	},
	{
		path: '/news-articles',
		component: NewsAndArticles
	},
	{
		path: '/news-articles/post',
		component: NewsAndArticlesForm
	},
	{
		path: '/news-articles/edit/:id/:detailLang',
		component: NewsAndArticlesForm
	},
	{
		path: '/news-articles/view/:id/:detailLang',
		component: NewsAndArticlesForm
	},
	{
		path: '/banners',
		component: Banners
	},
	{
		path: '/banners/post',
		component: BannerForm
	},
	{
		path: '/banners/edit/:id',
		component: BannerForm
	},
	{
		path: '/banners/view/:id',
		component: BannerForm
	},
	{
		path: '/admins',
		component: Admins
	},
	{
		path: '/admins/post',
		component: AdminsForm
	},
	{
		path: '/admins/edit/:id',
		component: AdminsForm
	},
	{
		path: '/admins/view/:id',
		component: AdminsForm
	},
	{
		path: '/awards',
		component: AwardsAndAccreditation
	},
	{
		path: '/awards/post',
		component: AwardsAndAccreditationForm
	},
	{
		path: '/awards/edit/:id/:detailLang',
		component: AwardsAndAccreditationForm
	},
	{
		path: '/awards/view/:id/:detailLang',
		component: AwardsAndAccreditationForm
	},
	{
		path: '/doctors',
		component: Doctors
	},
	{
		path: '/doctors/edit/:id',
		component: DoctorForm
	},
	{
		path: '/doctors/view/:id',
		component: DoctorForm
	},
	{
		path: '/clinics',
		component: Clinics
	},
	{
		path: '/clinics/post',
		component: ClinicsForm
	},
	{
		path: '/clinics/edit/:id/:detailLang',
		component: ClinicsForm
	},
	{
		path: '/clinics/view/:id/:detailLang',
		component: ClinicsForm
	},
	{
		path: '/patients',
		component: Patients
	},
	{
		path: '/patients/view/:id',
		component: PatientsForm
	},
	{
		path: '/rbac',
		component: RBAC
	},
	{
		path: '/rbac/form',
		component: RouteForm
	},
	{
		path: '/rbac/form/view/:id',
		component: RouteForm,
	},
	{
		path: '/footer',
		component: Footer
	},
	{
		path: '/footer/post',
		component: FooterForm
	},
	{
		path: '/footer/view/:id/:detailLang',
		component: FooterForm
	},
	{
		path: '/footer/edit/:id/:detailLang',
		component: FooterForm
	},
	{
		path: '/doctor-settings',
		component: BookingSettings
	},
	{
		path: '/global-settings',
		component: BookingSettings
	},
	{
		path: '/contact-us',
		component: ContactUs
	},
	{
		path: '/contact_us/view/:id',
		component: ContactUsForm
	},
	{
		path: '/booking-doctor',
		component: BookingDoctor
	},
	{
		path: '/booking-global-setting',
		component: BookingDoctor
	},
	{
		path: '/doctor-settings/edit/:id',
		component: BookingSettings
	},
	{
		path: '/global-settings/edit/:id',
		component: BookingSettings
	},
	{
		path: '/doctor-settings/view/:id',
		component: BookingSettings
	},
	{
		path: '/global-settings/view/:id',
		component: BookingSettings
	},
	{
		path: '/list-specialties',
		component: ListSpecialties
	},
	{
		path: '/list-specialties/edit/:id',
		component: SpecialtiesForm
	},
	{
		path: '/list-specialties/view/:id',
		component: SpecialtiesForm
	},
	{
		path: '/list-specialties/post',
		component: SpecialtiesForm
	},
	{
		path: '/medical-specialities',
		component: MedicalSpecialitiesPage
	},
	{
		path: '/medical-specialities/new',
		component: CreateMedicalSpecialities
	},
	{
		path: '/medical-specialities/edit/:id/:detailLang',
		component: EditMedicalSpecialities
	},
	{
		path: '/medical-specialities/view/:id/:detailLang',
		component: EditMedicalSpecialities
	}
];

interface Props {
	history: any;
}

const Router = (props: Props) => {
	return (
		<ConnectedRouter history={ props.history }>
			<Switch>
				{ privateRoutes.map(route => (
					<PrivateRoute key={ route.path as any } exact path={ route.path } component={ route.component } />
				)) }
				<Route path='/signin' component={ Signin } />
				<Route path='/reset-password' component={ ResetPassword } exact />
				<Route path='/reset-password/input-password' component={ ResetPassword } />
				<Route path='*' component={ NotFound } />
			</Switch>
		</ConnectedRouter>
	);
};

export default Router;
