import { useEffect } from 'react';

import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllClinics } from 'store/actions';
import { selectors } from 'store/selectors';
import { AdminView } from 'components';

import useClinics from './useClinics';

const Clinics = () => {
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    onSearchClinic,
    paginationFunction,
    onDeleteClinic,
    selectedStatus,
    setSelectedStatus,
    formStatus,
  } = useClinics();

  const dispatch = hooks.useAppDispatch();
  const { data, pagination } = hooks.useAppSelector(selectors.clinics);

  useEffect(() => {
    dispatch(getAllClinics('', 1, ''));
  }, []);
  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Clinics'
      thTitles={ thTitles }
      editLink='clinics/edit'
      viewLink='clinics/view'
      items={ data }
      title='Clinics (Center of Excellence)'
      type='clinics'
      createLink='clinics/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onChangeSearch={ onSearchClinic }
      pagination={ pagination }
      onClickPagination={ paginationFunction }
      onDeleteModalClick={ onDeleteClinic }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllClinics }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
    />
  );
};

export default Clinics;