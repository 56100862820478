import { useState } from 'react';

import { hooks } from 'helpers';
import { debounce } from 'helpers/misc';
import { selectors } from 'store/selectors';
import { getBookingConfig, deleteBookingConfig } from 'store/actions';

const useBookingDoctor = () => {
	const dispatch = hooks.useAppDispatch();
	const thTitles: string[] = [
		'ID',
		'HOSPITAL NAME',
		'DOCTOR CODE',
		'DOCTOR NAME',
		'INTERVAL TIME',
		'BLACKLIST DURATION',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		id: '',
		email: '',
		full_name: '',
		phone: '',
		gender: '',
		title: '',
		content: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const [author, setAuthor] = useState<any[]>([]);
	const [selectedHospital, setSelectedHospital] = useState({
		id: '',
		name: ''
	});
	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Active',
			value: true
		}, {
			id: 2,
			name: 'Inactive',
			value: false
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});

	const formTypeBooking: Record<string, any>[] = [
		{
			id: 1,
			name: 'Doctor',
			value: 'doctor'
		}, {
			id: 2,
			name: 'Global',
			value: 'global'
		}
	];
	const [selectedTypeBooking, setSelectedTypeBooking] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const onDeleteAuthor = (tagIndex: number) => setAuthor(author.filter((_, i) => i !== tagIndex));
	const onAddAuthor = newTag => {
		setAuthor([newTag]);
	};
	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.bookingConfig);

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		const isGlobalSetting = location.pathname.includes('booking-global-setting');
		dispatch(getBookingConfig(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, 10, '', '', isGlobalSetting ? 'global' : 'doctor'));
	};

	const onDeleteBooking = (id: string) => {
		dispatch(deleteBookingConfig(id, () => {
			const isGlobalSetting = location.pathname.includes('booking-global-setting');
			dispatch(getBookingConfig('', 1, 10, '', '', isGlobalSetting ? 'global' : 'doctor'));
		}));
	};

	const onSearchBooking = debounce(e => {
		const isGlobalSetting = location.pathname.includes('booking-global-setting');
		dispatch(getBookingConfig(e[0].target.value, 1, 10, '', '', isGlobalSetting ? 'global' : 'doctor'));
	});


	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		data,
		details,
		paginationFunction,
		pagination,
		onDeleteBooking,
		onSearchBooking,
		editor,
		onChangeEditor,
		setEditor,
		onDeleteAuthor,
		onAddAuthor,
		author,
		setAuthor,
		selectedHospital, 
		setSelectedHospital,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		formTypeBooking,
		selectedTypeBooking,
		setSelectedTypeBooking,
	};
};

export default useBookingDoctor;