import miscReducer from './Misc/reducer';
import paginationReducer from './Pagination/reducer';
import userReducer from './Auth/reducer';
import EventAndClassesReducer from './EventAndClasses/reducer';
import HospitalsReducer from './Hospitals/reducer';
import NewsAndArtcileReducer from './NewsAndArticles/reducer';
import BannersReducer from './Banners/reducer';
import AdminsReducer from './Admins/reducer';
import AwardsAndAccreditationReducer from './AwardsAndAccreditation/reducer';
import FacilitiesReducer from './Facilities/reducer';
import DoctorsReducer from './Doctors/reducer';
import ClinicReducer from './Clinics/reducer';
import PatientsReducer from './Patients/reducer';
import RBACReducer from './RBAC/reducer';
import FooterReducer from './Footers/reducer';
import SpecialitiesReducer from './Specialities/reducer';
import ContactUsReducer from './ContactUs/reducer';
import BookingReducer from './Booking/reducer';
import SpecialtiesReducer from './Specialties/reducer';
import MedicalSpecialitiesReducer from './MedicalSpecialities/reducer';

export default {
	misc: miscReducer,
	pagination: paginationReducer,
	auth: userReducer,
	eventAndClasses: EventAndClassesReducer,
	hospitals: HospitalsReducer,
	news: NewsAndArtcileReducer,
	banners: BannersReducer,
	admins: AdminsReducer,
	awards: AwardsAndAccreditationReducer,
	facilities: FacilitiesReducer,
	doctors: DoctorsReducer,
	clinic: ClinicReducer,
	patients: PatientsReducer,
	RBAC: RBACReducer,
	footers: FooterReducer,
	specialities: SpecialitiesReducer,
	contactUs: ContactUsReducer,
	bookingConfig: BookingReducer,
	groupSpecialties: SpecialtiesReducer,
	medicalSpecialities: MedicalSpecialitiesReducer
};
