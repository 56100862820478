import {
	useEffect, useRef, useState
} from 'react';
import {
	useHistory, useLocation, useParams
} from 'react-router-dom';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import {
	Container,
	HeaderTitle,
	Card,
	Text,
	Button,
	Select,
	Spinner,
	MapMarker,
	AsyncSelect,
	Editor,
	FileUpload,
	Input,
	DateTime
} from 'components';
import { hooks } from 'helpers';
import { handleImage } from 'helpers/misc';
import { selectors } from 'store/selectors';
import {
	ParamsType, FormArrayType, SelectType, ScreenType
} from 'interfaces/common';
import { SelectValueType } from 'interfaces/geocodes';
import {
	getAllFooters, getAllPatients, getMedicalSpecialitiesPages
} from 'store/actions';
import { getAllNews, getAllTags } from 'store/NewsAndArticles/action';
import { getAllRoles } from 'store/RBAC/actions';
import { getListDoctorsDropdown } from 'store/Doctors/action';
import { TrashIcon } from '@heroicons/react/24/outline';
import { GET_CLINIC_DETAILS } from 'store/Clinics/actionType';
import { GET_FACILITY_DETAILS } from 'store/Facilities/actionType';
import DropdownSearch from 'components/DropdownSearch';

type AdminFormType = {
	title: string;
	localHooks: any;
	formArray: FormArrayType[];
	getDetailAction?: any;
	isAbleToSubmit: boolean;
	backLink: string;
	type: ScreenType;
};

const AdminForm: React.FC<AdminFormType> = ({
	title,
	localHooks,
	formArray,
	getDetailAction,
	isAbleToSubmit,
	backLink,
	type
}) => {
	const { id, detailLang } = useParams<ParamsType>();
	const loc = useLocation();
	const isView = loc.pathname.includes('/view/');
	const isEdit = loc.pathname.includes('/edit/');
	const tagsRef = useRef(null);

	const {
		onClickSubmit,
		details,
		detailBooking,
		effectFunctions,
		form,
		selectedStatus,
		setSelectedStatus,
		valueGeoSearch,
		setValueGeoSearch,
		position,
		setPosition,
		onChangeLocation,
		resultGeoSearch,
		promiseSelectGeoSearch,
		loadingGeoSearch,
		openMap,
		setOpenMap,
		imagePayload,
		previewImage,
		setImagePayload,
		setPreviewImage,
		imagePayloadDetail,
		previewImageDetail,
		setImagePayloadDetail,
		setPreviewImageDetail,
		onChangeForm,
		onChangeEditor,
		editor,
		selectedHomeStatus,
		setSelectedHomeStatus,
		selectedType,
		setSelectedType,
		selectedHospital,
		setSelectedHospital,
		selectedPinned,
		setSelectedPinned,
		onChangeSelect,
		errorForm,
		setErrorForm,
		gender,
		setGender,
		onChangeConditions,
		onChangeTreatments,
		onChangeTechnology,
		conditions,
		treatments,
		technology,
		available,
		onChangeAvailable,
		allTags,
		tags,
		onDeleteTags,
		onAddTags,
		selectedRole,
		setSelectedRole,
		onChangePhone,
		onChangeOperationalHour,
		onChangeInformation,
		phone,
		information,
		operationalHour,
		doctors,
		hospitals,
		facilitesHospital,
		addFacilitiesHospitalForm,
		removeFacilitiesHospitalForm,
		onChangeFormFacilities,
		onChangeFacilitiesHour,
		onChangeSelectForm,
		onChangeCheckbox,
		selectedHospitalForm,
		author,
		onDeleteAuthor,
		onAddAuthor,
		footerCategory,
		setFooterCategory,
		selectedLanguage,
		setSelectedLanguage,
		selectedSpeciality,
		setSelectedSpeciality,
		selectedDoctor,
		setSelectedDoctor,
		timeHourData,
		timeMinuteData,
		selectedHourStart,
		setSelectedHourStart,
		selectedMinuteStart,
		setSelectedMinuteStart,
		selectedHourEnd,
		setSelectedHourEnd,
		selectedMinuteEnd,
		setSelectedMinuteEnd,
		data,
		selectedParent,
		setSelectedParent,
		doctor,
		onDeleteDoctor,
		onAddDoctor,
		relatedNews,
		addRelatedNews,
		removeRelatedNews,
		changeRelatedNews,
		news,
		relatedNewsFacilities,
		setRelatedNews,
		setFacilitiesHospital,
		isFacilitesFilled,
		removeRelatedNewsFromState,
		onAddOperationalHourHospital,
		onDeleteOperationalHourHospital,
		relatedNewsClinic,
		addAvailableAt,
		availableAt,
		removeFieldAvailableAtFromState,
		changeAvailableAt,
		titleAvailableAt,
		dropdownSpecialties,
		onDeleteSpecialties,
		onAddSpecialties,
		specialties,
		setIsTimeChanged,
		isTimeChanged,
		changeTimeHour,
		changeTimeMinute,
		resetTime,
		imagePayloadEngDetail,
		setImagePayloadEngDetail,
		imagePayloadEng,
		setImagePayloadEng,
		previewImageEng,
		setPreviewImageEng,
		previewImageEngDetail,
		setPreviewImageEngDetail,
		isDoctorSetting,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		slug_data,
		previewPdf,
		setPreviewPdf,
		pdfPayload,
		setPdfPayload,
		addFacilitiesHospitalFormClinic,
		setClinicFacilitiesHospital,
		clinicFacilitesHospital,
		onChangeFormClinic,
		setDoctor,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	} = localHooks();
	const history = useHistory();
	const dispatch = hooks.useAppDispatch();
	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const handleChangeCheckIndex = e => {
		if (e.target.value === 'Yes') {
			setIsSeoIndex && setIsSeoIndex(true);
		} else {
			setIsSeoIndex && setIsSeoIndex(false);
		}
	};
	const handleChangeCheckFollow = e => {
		if (e.target.value === 'Yes') {
			setIsSeoFollow && setIsSeoFollow(true);
		} else {
			setIsSeoFollow && setIsSeoFollow(false);
		}
	};
	useEffect(() => {
		if (type === 'admins') {
			dispatch(getAllRoles());
		}
		if (type === 'articles') {
			dispatch(getAllTags());
			dispatch(getListDoctorsDropdown());
			dispatch(getAllNews('', 1, '', '', 1000, 'idn'));
		}
		if (id) {
			if (type === 'patients') {
				dispatch(getAllPatients('', 1, '', '', '', 10000));
			} else if (type === 'hospital' || type === 'clinics' || type === 'facilities' || type === 'medical-specialities' || type === 'footer' || type === 'articles' || type === 'events' || type === 'awards') {
				if (type === 'clinics') {
					dispatch({
						type: GET_CLINIC_DETAILS,
						details: {},
					});
				} else if (type === 'facilities') {
					dispatch({
						type: GET_FACILITY_DETAILS,
						details: {},
					});
				}
				getDetailAction && dispatch(getDetailAction(id, detailLang));
			} else {
				getDetailAction && dispatch(getDetailAction(id));
			}
		}
	}, [id]);

	useEffect(() => {
		if (id && type !== 'book-settings') {
			effectFunctions && effectFunctions();
		}

		if (type === 'articles' || type === 'events' || type === 'clinics' || type === 'facilities' || type === 'hospital' || type === 'footer' || type === 'medical-specialities') {
			setIsDuplicateSlug(false);
		}
	}, [details]);

	useEffect(() => {
		if (id || !isDoctorSetting && type === 'book-settings') {
			effectFunctions && effectFunctions();
		}
	}, [detailBooking]);

	useEffect(() => {
		if (form?.address && form?.address?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'location')?.index));
		}
	}, [form]);

	useEffect(() => {
		if (imagePayload && imagePayload?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'url_img')?.index));
		}
	}, [imagePayload]);

	useEffect(() => {
		if (imagePayloadDetail && imagePayloadDetail?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'url_img_detail')?.index));
		}
	}, [imagePayloadDetail]);

	useEffect(() => {
		if (selectedStatus && selectedStatus?.name) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'status')?.index));
		}
	}, [selectedStatus]);

	useEffect(() => {
		if (selectedHomeStatus && selectedHomeStatus?.name) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'home page status')?.index));
		}
	}, [selectedHomeStatus]);

	useEffect(() => {
		if (selectedType && selectedType?.name) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'type')?.index));
		}
	}, [selectedType]);

	useEffect(() => {
		if (selectedHospital && selectedHospital?.name) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'hospital')?.index));
		}
	}, [selectedHospital]);

	useEffect(() => {
		if (editor && editor?.editorHtml?.length > 11) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'editor')?.index));
		}
	}, [editor]);
	useEffect(() => {
		if (available && available?.editorHtml?.length > 11) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'available_at')?.index));
		}
	}, [available]);

	useEffect(() => {
		if (allTags && allTags?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'tags')?.index));
		}
	}, [allTags]);

	useEffect(() => {
		if (selectedLanguage && selectedLanguage?.name?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'language')?.index));
			if (type === 'clinics' || type === 'facilities' || type === 'awards') {
				dispatch(getAllNews('', 1, true, '', 1000, selectedLanguage.value));
			} else if (type === 'articles') {
				dispatch(getAllFooters('', 1, '', 1000, '', 'idn'));
			} else if (type === 'footer') {
				dispatch(getAllFooters('', 1, '', 1000, '', 'idn'));
			} else if (type === 'medical-specialities') {
				dispatch(getMedicalSpecialitiesPages({
					limit: 1000,
					language: 'idn',
					is_publish: true
				}));
			} else {
				if (selectedLanguage.value === 'en') {
					dispatch(getAllNews('', 1, '', '', 1000, 'idn'));
				}
			}
		}
	}, [selectedLanguage]);

	useEffect(() => {
		if (selectedLanguage?.value === 'en' && selectedParent?.name?.length) {
			setErrorForm && setErrorForm(errorForm && errorForm.filter(items => items !== formArray.find((item: FormArrayType) => item.name === 'parent')?.index));
		}
	}, [selectedParent]);

	useEffect(() => {
		if (!isEdit) {
			setSelectedStatus && setSelectedStatus({
				id: 0,
				name: '',
				value: ''
			});
			setSelectedType && setSelectedType({
				id: 0,
				name: '',
				value: ''
			});
		}
	}, []);

	useEffect(() => {
		if (type === 'facilities' && (isEdit || isView) && relatedNewsFacilities?.length) {
			let tempArr: Array<any> = [];
			relatedNewsFacilities?.map(element => {
				tempArr = [
					...tempArr, {
						id: element?.id,
						name: element?.title_news,
						id_news: element?.news_id.id,
					}
				];
			});
			setRelatedNews(tempArr);
		} else if (type === 'facilities' && (isEdit || isView)) {
			setRelatedNews([]);
		}
	}, [relatedNewsFacilities]);

	useEffect(() => {
		if (type === 'medical-specialities' || type === 'footer') {
			if (slug_data !== null && slug_data?.length === undefined && details.slug !== form.slug) {
				setIsDuplicateSlug(true);
			} else {
				setIsDuplicateSlug(false);
			}
		}
		if (type === 'hospital' || type === 'facilities' || type === 'articles' || type === 'events' || type === 'clinics') {
			if (slug_data !== null && slug_data?.length > 0 && slug_data?.[0].slug !== details.slug) {
				setIsDuplicateSlug(true);
			} else {
				setIsDuplicateSlug(false);
			}
		}
	}, [slug_data]);

	useEffect(() => {
		if (type === 'clinics' && (isEdit || isView) && relatedNewsClinic?.length) {
			let tempArr: Array<any> = [];

			relatedNewsClinic?.map(element => {
				tempArr = [
					...tempArr, {
						id: element?.news?.id,
						name: element?.news?.title,
						id_news: element?.id,
					}
				];
			});
			setRelatedNews(tempArr);
		} else if (type === 'clinics' && (isEdit || isView)) {
			setRelatedNews([]);
		}
	}, [relatedNewsClinic]);

	useEffect(() => {
		if (type === 'clinics') {
			if (id !== details.slug) {
				setClinicFacilitiesHospital([]);
				return;
			} else {
				if (details.coe_hospitals !== null && details?.coe_hospitals?.length) {
					let tempArr: Array<any> = [];
					details?.coe_hospitals[0]?.operational_hour?.map(element => {
						tempArr = [...tempArr, { name: element }];
					});

					setClinicFacilitiesHospital(details?.coe_hospitals?.map((item: { hospital_name: any; operational_hour: []; }) => ({
						...item,
						name: item?.hospital_name,
						operational_hour_tag: tempArr
					})));
					return;
				}
			}
		}
	}, [details]);

	useEffect(() => {
		if (type === 'facilities') {
			if (id !== details.slug) {
				setFacilitiesHospital([]);
				return;
			} else {
				if (details?.facilities_hospitals?.length) {
					let tempArr: Array<any> = [];
					details?.facilities_hospitals[0]?.operational_hour?.map(element => {
						tempArr = [...tempArr, { name: element }];
					});

					setFacilitiesHospital(details?.facilities_hospitals?.map((item: { hospital_name: any; operational_hour: []; }) => ({
						...item,
						name: item?.hospital_name,
						operational_hour_tag: tempArr
					})));
				}
			}
		}
	}, [details]);

	const returnState = (typeDropdown: SelectType | undefined) => {
		switch (typeDropdown) {
			case 'homepage':
				return {
					state: selectedHomeStatus,
					setState: setSelectedHomeStatus
				};
			case 'hospital':
				return {
					state: selectedHospital,
					setState: setSelectedHospital
				};
			case 'pinned':
				return {
					state: selectedPinned,
					setState: setSelectedPinned
				};
			case 'status':
				return {
					state: selectedStatus,
					setState: setSelectedStatus
				};
			case 'gender':
				return {
					state: gender,
					setState: setGender
				};
			case 'footer_category':
				return {
					state: footerCategory,
					setState: setFooterCategory
				};
			case 'language':
				return {
					state: selectedLanguage,
					setState: setSelectedLanguage
				};
			case 'doctor':
				return {
					state: selectedDoctor,
					setState: setSelectedDoctor
				};
			case 'speciality':
				return {
					state: selectedSpeciality,
					setState: setSelectedSpeciality
				};
			case 'role':
				return {
					state: selectedRole,
					setState: setSelectedRole
				};
			case 'footer_category':
				return {
					state: footerCategory,
					setState: setFooterCategory
				};
			case 'doctor':
				return {
					state: selectedDoctor,
					setState: setSelectedDoctor
				};
			case 'speciality':
				return {
					state: selectedSpeciality,
					setState: setSelectedSpeciality
				};
			default:
				return {
					state: selectedType,
					setState: setSelectedType
				};
		}
	};

	const returnEditor = (item: FormArrayType) => {
		switch (item.contentType) {
			case 'content':
				return {
					onChange: onChangeEditor,
					state: editor.editorHtml
				};
			case 'conditions':
				return {
					onChange: onChangeConditions,
					state: conditions.editorHtml
				};
			case 'technology':
				return {
					onChange: onChangeTechnology,
					state: technology.editorHtml
				};
			case 'treatments':
				return {
					onChange: onChangeTreatments,
					state: treatments.editorHtml
				};
			case 'available_at': {
				return {
					onChange: onChangeAvailable,
					state: available.editorHtml
				};
			}
			case 'information': {
				return {
					onChange: onChangeInformation,
					state: information.editorHtml
				};
			}
			case 'operational_hour': {
				return {
					onChange: onChangeOperationalHour,
					state: operationalHour.editorHtml
				};
			}
			case 'phone': {
				return {
					onChange: onChangePhone,
					state: phone.editorHtml
				};
			}
			default:
				return {
					onChange: onChangeEditor,
					state: editor.editorHtml
				};
		}
	};

	const renderAsterisk = (isMandatory: boolean) => {
		return isMandatory ? <Text text='*' color='text-red-500' size='text-xs' className='ml-1' /> : null;
	};
	const filteredForm: [] = errorForm && [...new Set(errorForm.filter(item => formArray.map(items => items.index === item)))];
	const findError = (index: number) => {
		return (filteredForm.find(item => item === index));
	};

	const renderInput = (formArray: FormArrayType[]) => {
		return (formArray.map((item: FormArrayType, index: number) => {
			return (
				item.renderType === 'input' ? <div key={ index }>
					<div className='flex'>
						<Text
							text={ item.label }
							className='mb-1'
						/>
						{ renderAsterisk(item.isMandatory) }
					</div>
					<Input
						name={ item.name }
						type={ item.inputType }
						inputClassName={ `block px-3 py-2 text-gray-900 placeholder-gray-500 border ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } rounded-md focus:ring-moss-100 focus:border-moss-100 sm:text-sm article-title` }
						placeholder={ item.placeholder || item.name }
						value={ form[item.name] }
						onChange={ e => {
							onChangeForm && onChangeForm(e, item.index);
							if (type === 'articles' || type === 'events' || type === 'clinics' || type === 'facilities' || type === 'hospital' || type === 'footer' || type === 'medical-specialities') {
								if (item.name === 'seo_h1') {
									setIsDuplicateSlug(true);
								}
							}
						} }
						maxLength={ 255 }
						disabled={ type === 'book-settings'
							? item.name === 'max_slot'
								? isView || (form.percent_slot && form.percent_slot > -1)
								: item.name === 'percent_slot'
									? isView || (form.max_slot && form.max_slot > -1)
									: item.name === 'slot_availability'
										? isView || (form.max_slot_availability && form.max_slot_availability > -1)
										: item.name === 'max_slot_availability'
											? isView || (form.slot_availability && form.slot_availability > -1)
											: isView || item.disabledCondition
							: isView || item.disabledCondition }
						notice={ item.notice }
					/>
					{
						item.name === 'short_description' &&
						<Text
							text={ 'Max Character 255' }
							color={ 'text-red-400' }
							size='text-xs'
							weight='font-medium'
						/>
					}
				</div> : item.renderType === 'editor' ?
					<div key={ index }>
						<div className='flex'>
							<Text
								text={ item.label }
								className='mb-1'
							/>
							{ renderAsterisk(item.isMandatory) }
						</div>
						<Editor handleChange={ returnEditor(item)?.onChange } editorHtml={ returnEditor(item)?.state } className={ `create-article-content ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-2 p-2 border-red-600' : '' }` } disabled={ isView } />
					</div> :
					item.renderType === 'file' ?
						<FileUpload
							key={ index }
							handleImageFunction={ isView ? null : handleImage }
							type='image'
							imagePayload={ item.imageType === '' || item.imageType === 'card' ? imagePayload && imagePayload : imagePayloadDetail && imagePayloadDetail }
							setImagePayload={ item.imageType === '' || item.imageType === 'card' ? setImagePayload && setImagePayload : setImagePayloadDetail && setImagePayloadDetail }
							previewImageState={ item.imageType === '' || item.imageType === 'card' ? previewImage && previewImage : previewImageDetail && previewImageDetail }
							setPreviewImageState={ item.imageType === '' || item.imageType === 'card' ? setPreviewImage && setPreviewImage : setPreviewImageDetail && setPreviewImageDetail }
							disabled={ isView || (item.imageType === '' || item.imageType === 'card' ? previewImage && previewImage?.length >= item && item?.maxImage : previewImageDetail && previewImageDetail?.length >= item && item?.maxImage) }
							max={ item.maxImage }
							stateType='images'
							label={ item.label }
							index={ index }
							isMandatory={ item.isMandatory }
							containerClassName={ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-2 p-2 border-red-600' : '' }
						/> : item.renderType === 'pdf' ?

							<FileUpload
								key={ index }
								handleImageFunction={ isView ? null : handleImage }
								type='pdf'
								imagePayload={ pdfPayload }
								setImagePayload={ setPdfPayload }
								previewImageState={ previewPdf }
								setPreviewImageState={ setPreviewPdf }
								disabled={ isView || selectedType?.value !== 'HealthFirst' }
								max={ item.maxImage }
								stateType='images'
								label={ item.label }
								index={ index }
								isMandatory={ item.isMandatory }
								containerClassName={ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-2 p-2 border-red-600' : '' }
							/>

							: item.renderType === 'file_eng' ?
								<div className={ selectedLanguage?.value === 'idn' ? 'hidden' : '' }>
									<FileUpload
										key={ index }
										handleImageFunction={ isView ? null : handleImage }
										type='image'
										imagePayload={ item.imageType === '' || item.imageType === 'card' ? imagePayloadEng && imagePayloadEng : imagePayloadEngDetail && imagePayloadEngDetail }
										setImagePayload={ item.imageType === '' || item.imageType === 'card' ? setImagePayloadEng && setImagePayloadEng : setImagePayloadEngDetail && setImagePayloadEngDetail }
										previewImageState={ item.imageType === '' || item.imageType === 'card' ? previewImageEng && previewImageEng : previewImageEngDetail && previewImageEngDetail }
										setPreviewImageState={ item.imageType === '' || item.imageType === 'card' ? setPreviewImageEng && setPreviewImageEng : setPreviewImageEngDetail && setPreviewImageEngDetail }
										disabled={ isView || (item.imageType === '' || item.imageType === 'card' ? previewImageEng && previewImageEng?.length >= item && item?.maxImage : previewImageEngDetail && previewImageEngDetail?.length >= item && item?.maxImage) }
										max={ item.maxImage }
										stateType='images'
										label={ item.label }
										index={ index }
										isMandatory={ item.isMandatory }
										containerClassName={ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-2 p-2 border-red-600' : '' }
									/>
								</div>
								:
								item.renderType === 'map' ?
									<div key={ index }>
										<AsyncSelect
											label='Map'
											labelClassName='text-left'
											placeholder={ isView ? '' : 'Enter address' }
											value={ valueGeoSearch && valueGeoSearch }
											queryVal={ valueGeoSearch && valueGeoSearch?.value }
											onChange={ (selected: SelectValueType) => {
												setValueGeoSearch && setValueGeoSearch(selected);
												setPosition && setPosition(selected.value);
												onChangeLocation && onChangeLocation(selected.value, selected.label);
											} }
											defaultOptions={ resultGeoSearch && resultGeoSearch }
											loadOptions={ promiseSelectGeoSearch && promiseSelectGeoSearch }
											loading={ loadingGeoSearch && loadingGeoSearch }
											isMandatory={ item.isMandatory }
											disabled={ isView }
											inputClassName={ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' }
										/>
										<div className='max-w-[75%]'>
											<MapMarker open={ openMap || false } height='300px' width='100%' position={ position || {
												lat: -6.175403066579251,
												lng: 106.82714738255616
											} }
												setPosition={ setPosition }
												setOpen={ setOpenMap }
												mapControl='bottomleft'
												editable={ false }
												onChangeLocation={ onChangeLocation }
												isView={ isView }
											/>
										</div>
									</div> : item.renderType === 'select' ?
										<div key={ index }>
											<div className='flex'>
												<Text
													text={ item.label }
													className='mb-1'
												/>
												{ renderAsterisk(item.isMandatory) }
											</div>
											<Select
												onChange={ e => {
													onChangeSelect && onChangeSelect(e);
													if (type == 'facilities' && item.selectType === 'language') {
														facilitesHospital.forEach(element => {
															delete element['hospital_code'];
															delete element['hospital_email'];
															delete element['hospital_name'];
															delete element['hospital_phone'];
															delete element['name'];
															element['placeholder'] = 'Select Hospital';
														});
														setFacilitiesHospital(facilitesHospital);
													}
													if (type == 'clinics' && item.selectType === 'language') {
														clinicFacilitesHospital.forEach(element => {
															delete element['hospital_code'];
															delete element['hospital_email'];
															delete element['hospital_name'];
															delete element['hospital_phone'];
															delete element['name'];
															element['placeholder'] = 'Select Hospital';
														});
														setClinicFacilitiesHospital(clinicFacilitesHospital);
													}
												} }
												list={ item.selectType === 'speciality' ? item?.listSelect?.map(
													obj => {
														return {
															'id': obj.clinic_code,
															'name': obj.clinic_name
														};
													}
												) : item.listSelect }
												state={ returnState(item.selectType).state }
												setState={ returnState(item.selectType).setState }
												keyObj='name'
												placeholder={ item.placeholder || item.name }
												shadow='shadow-sm'
												sizing='w-full'
												width='w-full'
												wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
												disabled={ type === 'doctors' ? (isView || item.disabledCondition) : isView }
											/>
										</div>
										: item?.renderType === 'parent' ?
											<div key={ index }>
												<div className='flex'>
													<Text
														text={ item.label }
														className='mb-1'
													/>
													{ renderAsterisk(item.isMandatory) }
												</div>
												<Select
													onChange={ e => {
														onChangeSelect && onChangeSelect(e);
													} }
													list={ data?.map(obj => ({
														id: obj?.id,
														name: obj?.title || obj?.name
													})) }
													state={ selectedParent }
													setState={ setSelectedParent }
													keyObj='name'
													placeholder={ item.placeholder || item.name }
													shadow='shadow-sm'
													sizing='w-full'
													width='w-full'
													wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
													disabled={ isView || selectedLanguage?.value === 'idn' }
												/>
											</div>
											: item?.renderType === 'dropdown_search' ?
												<div key={ index }>
													<div className='flex'>
														<Text
															text={ item.label }
															className='mb-1'
														/>
														{ renderAsterisk(item.isMandatory) }
													</div>
													<DropdownSearch
														isDisable={ isView }
														onChange={ e => {
															console.log(e);
															setDoctor && setDoctor(e);
														} }
														value={ doctor }
														list={ item.listSelect?.map(obj => ({
															value: obj?.id,
															label: obj?.title || obj?.name
														})) } />
												</div> :
												item.renderType === 'img' ? <img src={ form[item.name] } alt='doctor potrait' className='my-4' key={ index } /> :
													item.renderType === 'tags' ?
														(type === 'articles' && selectedType?.value === 'HealthFirst' && item?.name === 'author') ?
															<></> :
															<div key={ index } className='relative z-2'>
																<div className='flex'>
																	<Text text={ item.label } className='mb-2' />
																	{ item.isMandatory ? renderAsterisk(true) : null }
																</div>
																<div className={ `border-2 rounded-lg relative z-2 ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-transparent' }` }>
																	<ReactTags
																		ref={ tagsRef }
																		tags={ item?.tagType === 'article' ? author as any : item?.tagType === 'doctor' ? doctor as any : item?.tagType === 'specialties' ? specialties : allTags as any }
																		suggestions={ isView ? [] : type === 'clinics' || item?.tagType === 'article' || item?.tagType === 'doctor' ? doctors as any : type === 'events' ? hospitals as any : item?.tagType === 'specialties' ? dropdownSpecialties : tags as any }
																		onDelete={ isView ? null : item?.tagType === 'article' ? onDeleteAuthor : item?.tagType === 'doctor' ? onDeleteDoctor : item?.tagType === 'specialties' ? onDeleteSpecialties : onDeleteTags }
																		onAddition={ isView ? null : item?.tagType === 'article' ? onAddAuthor : item?.tagType === 'doctor' ? onAddDoctor : item?.tagType === 'specialties' ? onAddSpecialties : onAddTags }
																		placeholderText={ item.placeholder }
																		allowNew={ item?.allowNewTag }
																	/>
																</div>
															</div> : item.renderType === 'checkbox' && item?.checkboxList?.length ?
															<div key={ index }>
																<Text text={ item.label } className='mb-2' />
																<div className={ `border-2 rounded-lg relative z-2 p-2 ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' }` }>
																	{ item?.checkboxList?.map((check, idx) => {
																		return (
																			<div key={ idx } className='my-2'>
																				<input
																					type='checkbox'
																					id={ check?.name }
																					name={ check?.name }
																					value={ check?.hospital_code }
																					onChange={ (e: React.ChangeEvent<HTMLInputElement>) => onChangeCheckbox(e) }
																					checked={ selectedHospitalForm?.findIndex(res => res === check?.hospital_code) !== -1 }
																					disabled={ isView || isLoading }
																				/>
																				<label htmlFor={ check?.name } className='ml-2'>{ check?.name }</label>
																			</div>
																		);
																	}) }
																</div>
															</div> :
															item.renderType === 'timepicker' ?
																<div key={ index }>
																	<div className='flex'>
																		<Text
																			text={ item.label }
																			className='mb-1'
																		/>
																		{ renderAsterisk(item.isMandatory) }
																		{
																			item.name === 'start_time' ?
																				<div onClick={ () => resetTime() } className='ml-2 text-red-500'>
																					<Text
																						text={ 'Reset' }
																						className='text-red-500'
																					/>
																				</div> : <></>
																		}
																	</div>
																	<div className='flex flex-row gap-x-4'>
																		<Select
																			onChange={ e => {
																				changeTimeHour && changeTimeHour(e, item);
																			} }
																			list={ timeHourData }
																			state={ item.name === 'start_time' ? selectedHourStart : selectedHourEnd }
																			keyObj='name'
																			placeholder={ item.placeholder || item.name }
																			shadow='shadow-sm'
																			sizing='w-4'
																			width='w-4'
																			wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-4 sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
																			disabled={ isView ||
																				(form.percent_slot > 0 && form.percent_slot.length > 0) ||
																				(form.max_slot > 0 && form.max_slot.length > 0) }
																		/>
																		<Text
																			className='flex items-center'
																			text={ ' : ' }
																		/>
																		<Select
																			onChange={ e => {
																				changeTimeMinute && changeTimeMinute(e, item);
																			} }
																			list={ timeMinuteData }
																			state={ item.name === 'start_time' ? selectedMinuteStart : selectedMinuteEnd }
																			keyObj='name'
																			placeholder={ item.placeholder || item.name }
																			shadow='shadow-sm'
																			sizing='w-[100px]'
																			width='w-[100px]'
																			wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-[100px] sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
																			disabled={ isView ||
																				(form.percent_slot > 0 && form.percent_slot.length > 0) ||
																				(form.max_slot > 0 && form.max_slot.length > 0) }
																		/>
																	</div>
																</div> :
																item.renderType === 'slug' ? <div key={ index }>
																	<div className='flex'>
																		<Text
																			text={ item.label }
																			className='mb-1'
																		/>
																		{ renderAsterisk(item.isMandatory) }
																	</div>

																	<Input
																		name={ item.name }
																		type={ item.inputType }
																		inputClassName={ `block px-3 py-2 text-gray-900 placeholder-gray-500 border ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } rounded-md focus:ring-moss-100 focus:border-moss-100 sm:text-sm article-title` }
																		placeholder={ item.placeholder || item.name }
																		value={ form[item.name] }
																		onChange={ e => {
																			onChangeForm && onChangeForm(e, item.index);
																			if (type === 'articles' || type === 'events' || type === 'clinics' || type === 'facilities' || type === 'hospital' || type === 'footer' || type === 'medical-specialities') {
																				setIsDuplicateSlug(true);
																			}
																		} }
																		disabled={ item.disabledCondition }
																		notice={ item.notice }
																	/>
																	<Text
																		text={ isDuplicateSlug ? 'Slug sudah terdaftar/tidak bisa digunakan' : 'Slug bisa digunakan' }
																		color={ isDuplicateSlug ? 'text-red-400' : 'text-green-800' }
																		size='text-sm'
																		weight='font-bold'
																	/>
																	<Button
																		type='primary'
																		text='Check Slug'
																		onClick={ () => checkSlugDuplicate() }
																		className='my-4'
																		disabled={ isView || isLoading }
																	/>
																</div> :
																	item.renderType === 'seo_robot' ?
																		<div className='flex flex-col' key={ index }>
																			<Text
																				text={ item.label }
																				className='mb-3'
																			/>
																			<Text
																				text={ 'Index' }
																				size='12'
																			/>
																			<div className='flex pb-2'>
																				<div className='form-check form-check-inline mr-4'>
																					<input
																						onChange={ handleChangeCheckIndex }
																						checked={ isSeoIndex }
																						className='form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
																						type='radio'
																						name='inlineRadioOptions'
																						id='inlineRadio1'
																						value='Yes'
																					/>
																					<label className='form-check-label inline-block text-gray-800 text-sm' htmlFor='inlineRadio1'>
																						Yes
																					</label>
																				</div>
																				<div className='form-check form-check-inline'>
																					<input
																						onChange={ handleChangeCheckIndex }
																						checked={ !isSeoIndex }
																						className='form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
																						type='radio'
																						name='inlineRadioOptions'
																						id='inlineRadio2'
																						value='No'
																					/>
																					<label className='form-check-label inline-block text-gray-800 text-sm' htmlFor='inlineRadio2'>
																						No
																					</label>
																				</div>
																			</div>

																			<Text
																				text={ 'Follow' }
																				size='12'
																			/>
																			<div className='flex'>
																				<div className='form-check form-check-inline mr-4'>
																					<input
																						onChange={ handleChangeCheckFollow }
																						checked={ isSeoFollow }
																						className='form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
																						type='radio'
																						name='inlineRadioOptions2'
																						id='inlineRadio3'
																						value='Yes'
																					/>
																					<label className='form-check-label inline-block text-gray-800 text-sm' htmlFor='inlineRadio3'>
																						Yes
																					</label>
																				</div>
																				<div className='form-check form-check-inline'>
																					<input
																						onChange={ handleChangeCheckFollow }
																						checked={ !isSeoFollow }
																						className='form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
																						type='radio'
																						name='inlineRadioOptions2'
																						id='inlineRadio4'
																						value='No'
																					/>
																					<label className='form-check-label inline-block text-gray-800 text-sm' htmlFor='inlineRadio4'>
																						No
																					</label>
																				</div>
																			</div>
																		</div>
																		:
																		null
			);
		}));
	};

	const renderSpinner = () => {
		return (
			<div className='flex items-center justify-center'>
				<Spinner />
			</div>
		);
	};

	const renderContainerInput = () => {
		return (
			<div className='min-w-full min-h-full px-4 py-12 sm:px-6 lg:px-8'>
						<div className='space-y-6'>
							{ renderInput(formArray) }
						</div>
						{ facilitesHospital?.length ? facilitesHospital?.map((item, index) => {
							return (
								<Card key={ index } className='my-4'>
									<div>
										<div className='flex'>
											<Text
												text='Hospital'
												className='mb-1'
											/>
										</div>
										<Select
											onChange={ e => {
												onChangeSelectForm && onChangeSelectForm(e, index);
											} }
											list={ hospitals }
											state={ facilitesHospital[index] }
											keyObj='name'
											placeholder={ item.placeholder || item.name }
											shadow='shadow-sm'
											sizing='w-full'
											wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
											disabled={ isView }
										/>
									</div>
									<Input
										name='unit'
										placeholder='Unit'
										label='Unit'
										containerClassName='my-4'
										onChange={ e => onChangeFormFacilities(e, index) }
										value={ facilitesHospital[index].unit }
										disabled={ isView }
									/>
									<Input
										name='floor'
										placeholder='Floor'
										label='Floor'
										containerClassName='my-4'
										onChange={ e => onChangeFormFacilities(e, index) }
										value={ facilitesHospital[index].floor }
										disabled={ isView }
									/>
									<Input
										name='information'
										placeholder='Information'
										label='Information'
										containerClassName='my-4'
										onChange={ e => onChangeFormFacilities(e, index) }
										value={ facilitesHospital[index].information }
										disabled={ isView }
									/>
									<div key={ index }>
										<Text
											text={ 'Operational Hour' }
											className='mb-1'
										/>
										<div className={ `border-2 rounded-lg relative z-2 ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-transparent' }` }>
											<ReactTags
												ref={ tagsRef }
												tags={ facilitesHospital[index].operational_hour_tag }
												onDelete={ tagIndex => onDeleteOperationalHourHospital(tagIndex, index) }
												onAddition={ tag => onAddOperationalHourHospital(tag, index) }
												placeholderText={ 'Senin-Jumat: pukul 10.00 - 17.00 WIB, dipisahkan dengan tombol Enter' }
												allowNew={ true }
											/>
										</div>
									</div>
									<Button
										type='delete'
										text='Delete Form'
										onClick={ () => removeFacilitiesHospitalForm(index) }
										className='my-4'
										disabled={ isView || isLoading }
									/>
								</Card>
							);
						}) : null }
						{ clinicFacilitesHospital?.length ? clinicFacilitesHospital?.map((item, index) => {
							return (
								<Card key={ index } className='my-4'>
									<div>
										<div className='flex'>
											<Text
												text='Hospital'
												className='mb-1'
											/>
										</div>
										<Select
											onChange={ e => {
												onChangeSelectForm && onChangeSelectForm(e, index);
											} }
											list={ hospitals }
											state={ clinicFacilitesHospital[index] }
											keyObj='name'
											placeholder={ item.placeholder || item.name }
											shadow='shadow-sm'
											sizing='w-full'
											wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
											disabled={ isView }
										/>
									</div>
									<Input
										name='unit'
										placeholder='Unit'
										label='Unit'
										containerClassName='my-4'
										onChange={ e => onChangeFormClinic(e, index) }
										value={ clinicFacilitesHospital[index].unit }
										disabled={ isView }
									/>
									<Input
										name='floor'
										placeholder='Floor'
										label='Floor'
										containerClassName='my-4'
										onChange={ e => onChangeFormClinic(e, index) }
										value={ clinicFacilitesHospital[index].floor }
										disabled={ isView }
									/>
									<Input
										name='appointment'
										placeholder='appointment'
										label='Appointment'
										containerClassName='my-4'
										onChange={ e => onChangeFormClinic(e, index) }
										value={ clinicFacilitesHospital[index].appointment }
										disabled={ isView }
									/>
									<Input
										name='information'
										placeholder='Information'
										label='Information'
										containerClassName='my-4'
										onChange={ e => onChangeFormClinic(e, index) }
										value={ clinicFacilitesHospital[index].information }
										disabled={ isView }
									/>
									<div key={ index }>
										<Text
											text={ 'Operational Hour' }
											className='mb-1'
										/>
										<div className={ `border-2 rounded-lg relative z-2 ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-transparent' }` }>
											<ReactTags
												ref={ tagsRef }
												tags={ clinicFacilitesHospital[index].operational_hour_tag }
												onDelete={ tagIndex => onDeleteOperationalHourHospital(tagIndex, index) }
												onAddition={ tag => onAddOperationalHourHospital(tag, index) }
												placeholderText={ 'Senin-Jumat: pukul 10.00 - 17.00 WIB, dipisahkan dengan tombol Enter' }
												allowNew={ true }
											/>
										</div>
									</div>
									<Button
										type='delete'
										text='Delete Form'
										onClick={ () => removeFacilitiesHospitalForm(index) }
										className='my-4'
										disabled={ isView || isLoading }
									/>
								</Card>
							);
						}) : null }
						{ type === 'facilities' ?
							<div className='my-8'>
								<Button
									text='Add Facilities Hospital'
									onClick={ () => addFacilitiesHospitalForm() }
									disabled={ isView || isLoading }
								/>
							</div> : null }
						{ type === 'clinics' ?
							<div className='my-8'>
								<Button
									text='Add Available At'
									onClick={ () => addFacilitiesHospitalFormClinic() }
									disabled={ isView || isLoading }
								/>
							</div> : null }
						{ type === 'facilities' && relatedNews?.length ? relatedNews?.map((item, index) => {
							return (
								<div key={ index } className='my-4'>
									<Text size='text-xl' weight='font-bold' text='Related News' />
									<Select
										onChange={ e => {
											changeRelatedNews && changeRelatedNews(e, index);
										} }
										list={ news }
										state={ relatedNews[index] }
										keyObj='name'
										placeholder='news id'
										shadow='shadow-sm'
										sizing='w-full'
										wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
										disabled={ isView }
									/>
									<Button type='delete' text='Delete Related News' className='mt-4' onClick={ () => isEdit ? removeRelatedNews(item?.id, index) : removeRelatedNewsFromState(index) } />
								</div>
							);
						}) : null }

						{
							type === 'clinics' && relatedNews?.length ? relatedNews?.map((item, index) => {
								return (
									<div key={ index } className='my-4'>
										<Text size='text-xl' weight='font-bold' text='Related News' />
										<Select
											onChange={ e => {
												changeRelatedNews && changeRelatedNews(e, index);
											} }
											list={ news }
											state={ relatedNews[index] }
											keyObj='name'
											placeholder='news id'
											shadow='shadow-sm'
											sizing='w-full'
											wrapperClassName={ `mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-red-600' : 'border-gray-300' } border-solid border rounded-md py-[9px] px-[13px]` }
											disabled={ isView }
										/>
										<Button type='delete' text='Delete Related News' className='mt-4' onClick={ () => isEdit ? removeRelatedNews(item?.id_news, index) : removeRelatedNewsFromState(index) } />
									</div>
								);
							}) : null }
						{ type === 'facilities' || type === 'clinics' ? <div className='my-8'>
							<Button
								text='Add Related News'
								onClick={ () => addRelatedNews() }
								disabled={ isView || isLoading }
							/>
						</div> : null }
						{ /* {
							type === 'clinics' || type === 'facilities' && availableAt?.length ?
								<div className='flex flex-col'>
									<Text>Title Available At</Text>
									<Input placeholder='Title ex: RS Pondok Indah - Pondok Indah Lantai 1' onChange={ e => changeAvailableAt(e.target.value, 0, 'title_available_at') } value={ titleAvailableAt } disabled={ isView } />
									{
										availableAt?.map((item, index) => {
											return (
												<div key={ index } className='flex flex-col gap-y-3 items-start py-2'>
													<Text>Item Title Name</Text>
													<Input placeholder='Title ex: Pelayanan' onChange={ e => changeAvailableAt(e.target.value, item.id, 'title') } value={ item.name } disabled={ isView } />
													<Text>Item Title Description</Text>
													<Editor handleChange={ e => changeAvailableAt(e, item.id, 'value') } editorHtml={ item.value } className={ `create-article-content ${ errorForm && errorForm.length && (findError(item.index) !== undefined) ? 'border-2 p-2 border-red-600' : '' }` } disabled={ isView } />
													<div onClick={ () => removeFieldAvailableAtFromState(item.id) } className='flex flex-row items-center gap-x-2 bg-red-600 rounded-md cursor-pointer leading-5 py-2 px-11'>
														<TrashIcon stroke='white' className='w-6 h-6 text-white-500 group-hover:text-white-600 transition duration-75' />
														<Text className='text-white text-sm font-medium '>Delete Field</Text>
													</div>
												</div>
											);
										})
									}
								</div>
								: null } */ }
						{ /* { type === 'clinics' || type === 'facilities' ? <div className='my-8'>
							<Button
								text='Add Available at'
								onClick={ () => addAvailableAt() }
								disabled={ isView || isLoading }
							/>
						</div> : null } */ }
						<div className='space-x-2 mt-4'>
							{ !isView && isAbleToSubmit ? <Button
								text={ isEdit ? 'Update' : 'Create' }
								onClick={ () => {
									onClickSubmit(isEdit);
								} }
								loading={ isLoading }
								disabled={ isLoading }
							/> : null }
							{
								backLink.length ?
									<Button
										text='Back'
										type='secondary'
										onClick={ () => history.push(backLink) }
									/> : <></>
							}
						</div>
						{ errorForm && errorForm.length ?
							<div>
								<Text text='Terdapat field yang belum lengkap:' />
								{ filteredForm.map(item => {
									return (
										<Text text={ `${ formArray[item].label } ${ formArray[item].renderType === 'editor' ? 'minimum 11 karakter' : formArray[item].inputType === 'text' ? '(maximum 200 karakter)' : formArray[item].name === 'embed_link' || formArray[item].name === 'share_link' ? ' harus valid' : '' }` } key={ item } className='text-red-600' />
									);
								}) }
							</div>
							: null }
					</div>
		);
	};
	
	const isEmptyForm = !Object.values(form).some(x => x !== null && x !== '');
	
	return (
		<Container header={ <HeaderTitle back={ false } title={ `${ (isEdit && !isView) ? 'Edit' : isView ? 'View' : 'Create' } ${ title }` } /> }>
			<Card>
			{
				(isEdit || isView)
				?
					(!isEmptyForm)
						? renderContainerInput() // if form is exist then call render input function
						: renderSpinner() // show spinner if form is empty
				:
					// isAdd mode
					isLoading ? renderSpinner()
					: renderContainerInput() // show render input if isLoading completed
			}
			</Card>
		</Container >
	);
};

export default AdminForm;