import { Pagination } from 'interfaces/common';

export const GET_BOOKING_CONFIG = 'GET_BOOKING_CONFIG';
export const GET_BOOKING_CONFIG_DETAIL = 'GET_BOOKING_CONFIG_DETAIL';

export interface GetBookingConfig {
	type: typeof GET_BOOKING_CONFIG;
  data: any;
  pagination: Pagination;
}

export interface GetBookingConfigDetail {
	type: typeof GET_BOOKING_CONFIG_DETAIL;
  details: any;
}

export type BookingConfigActionTypes = GetBookingConfig | GetBookingConfigDetail;
