import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_ALL_CONTACT_US, GET_DETAIL_CONTACT_US } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllContactUs = (keyword: string, page: number, limit = 10) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get contact us list begin'));
      const {
        code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getContactUsList(keyword, page, limit));
      if (code === 200) {
        await dispatch({
					type: GET_ALL_CONTACT_US,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
		dispatch(loadingEnd('get contact us list end'));
  };
};

export const getContactUsDetail = (id: string) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get contact us detail begin'));
			const {
				code, data, pagination
			}: ApiData<any> = await api.request(Endpoints.getContactUsDetail(id));
			if (code === 200) {
				await dispatch({
					type: GET_DETAIL_CONTACT_US,
					details: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get contact us detail end'));
	};
};

export const deleteContactUs = (id: string, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('delete contact us begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.deleteContactUs(id));
			if (code === 200) {
				toastify.success('Berhasil Menghapus Data');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('delete contact us end'));
	};
};