import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { hooks } from 'helpers';
import { debounce } from 'helpers/misc';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { selectors } from 'store/selectors';
import { getAllPatients } from 'store/actions';
import { BirthDateType } from 'interfaces/misc';
import { unBlockPatient } from 'store/Patients/action';

export default function usePatients () {
  const dispatch = hooks.useAppDispatch();
  const { id } = useParams<ParamsType>();
  const thTitles: string[] = [
    'ID',
    'NAME',
    'EMAIL',
    'GENDER',
    'STATUS',
  ];
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [errorForm, setErrorForm] = useState<number[]>([]);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    name: ''
  });
  const [form, setForm] = useState({
    name: '',
    email: '',
    img_url: '',
    phone: '',
    birthdate: ''
  });
  const { data, pagination } = hooks.useAppSelector(selectors.patients);

  const { isLoading } = hooks.useAppSelector(selectors.loading);

  const formStatus: Record<string, any>[] = [
    {
      id: 1,
      name: 'Verified',
      value: true
    }, {
      id: 2,
      name: 'Unverified',
      value: false
    }
  ];
  const formGender: Record<string, any>[] = [
    {
      id: 1,
      name: 'Male',
    }, {
      id: 2,
      name: 'Female',
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: 0,
    name: 'All',
    value: ''
  });
  const [birthDate, setBirthDate] = useState<BirthDateType>({
    start: '',
    end: ''
  });
  const [gender, setGender] = useState<any>({
    id: 0,
    name: '',
  });
  const [previewImage, setPreviewImage] = useState<string[]>([]);
  const [imagePayload, setImagePayload] = useState<File[]>([]);
  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
    if (errorForm.length) {
      setErrorForm(errorForm.filter(item => item !== index));
    }
  };

  const paginationFunction = (action: 'next' | 'prev', search?: string) => {
    dispatch(getAllPatients(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value, birthDate.start, birthDate.end));
  };

  const onSearchPatients = debounce(e => {
    dispatch(getAllPatients(e[0].target.value, 1, selectedStatus?.value, birthDate.start, birthDate.end));
  });

	const removeBlacklistPatient = (id: string) => {
		dispatch(unBlockPatient(id));
	};

  const formArray: FormArrayType[] = [
    {
      index: 0,
      label: 'Name',
      name: 'name',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'name',
    },
    {
      index: 1,
      label: 'Email',
      name: 'email',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'email',
    },
    {
      index: 2,
      label: 'Phone',
      name: 'phone',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'phone',
    },
    {
      index: 3,
      label: 'Birth Date',
      name: 'birthdate',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'date',
      placeholder: 'birthdate',
    },
    {
      index: 4,
      label: 'Upload Image',
      name: 'url_img',
      isMandatory: true,
      disabledCondition: previewImage.length >= 1,
      renderType: 'file',
      inputType: 'file',
      maxImage: 1,
      imageType: ''
    },
    {
      index: 5,
      label: 'Gender',
      name: 'gender',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: formGender,
      renderType: 'select',
      placeholder: 'gender ',
      selectType: 'gender'
    },
    {
      index: 6,
      label: 'Status',
      name: 'status',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: formStatus,
      renderType: 'select',
      placeholder: 'Status ',
      selectType: 'status'
    },
  ];

  const details = data?.find(item => item?.id === parseInt(id));

  const effectFunctions = () => {
    if (details && (id === details?.id.toString())) {
      setForm({
        ...form,
        name: details?.name,
        email: details?.email,
        img_url: details?.img_url,
        phone: details?.phone,
        birthdate: details?.birthdate
      });
      setSelectedStatus(details?.is_verified ?
        {
          id: 1,
          name: 'Verified',
          value: true
        } : {
          id: 2,
          name: 'Unverified',
          value: false
        });
      setGender(details?.gender ?
        {
          id: 1,
          name: details?.gender,
        } : {
          id: 0,
          name: ''
        });
      setPreviewImage([details?.img_url]);
    }
  };

  return {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    form,
    setForm,
    formStatus,
    selectedStatus,
    setSelectedStatus,
    onChangeForm,
    previewImage,
    setPreviewImage,
    data,
    imagePayload,
    setImagePayload,
    paginationFunction,
    pagination,
    onSearchPatients,
    formArray,
    errorForm,
    setErrorForm,
    birthDate,
    setBirthDate,
    effectFunctions,
    details,
    gender,
    setGender,
		removeBlacklistPatient,
  };
}
