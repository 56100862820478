/* eslint-disable react/jsx-no-bind */
import { Route, Redirect } from 'react-router-dom';

import { LocalStorage } from 'helpers';
import { SideBar } from 'components';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = LocalStorage.getToken('token');
  return (
    <>
      <SideBar />
      <Route
        { ...rest }
        render={ props => {
          return token ? (
            <Component { ...props } />
          ) : (
            <Redirect to={ { pathname: '/signin' } } />
          );
        } }
      />
    </>
  );
};

export default PrivateRoute;
