import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
	addNews, deleteNews, editNews, getAllNews
} from 'store/actions';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { getAllNewsSlug } from 'store/NewsAndArticles/action';
import { error } from 'console';

export default function useNewsAndArticles() {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'TYPE',
		'PUBLISHED ON',
		'AUTHOR',
		'LANGUAGE',
		'STATUS',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		title: '',
		img_url: '',
		short_description: '',
		slug: '',
		seo_h1: '',
	});
	const {
		data, details, pagination, tags, slug_data
	} = hooks.useAppSelector(selectors.news);

	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const { dropdown: doctors } = hooks.useAppSelector(selectors.doctors);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);



	const [editor, setEditor] = useState({ editorHtml: '' });
	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const formType: any[] = [
		{
			id: 0,
			name: 'All',
			value: ''
		},
		{
			id: 1,
			name: 'News',
			value: 'News',
		},
		{
			id: 2,
			name: 'Article',
			value: 'Article',
		},
		{
			id: 3,
			name: 'HealthFirst',
			value: 'HealthFirst',
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedType, setSelectedType] = useState({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});

	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});

	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [previewPdf, setPreviewPdf] = useState<string[]>([]);
	const [pdfPayload, setPdfPayload] = useState<File[]>([]);
	const [allTags, setAllTags] = useState<Record<string, any>[]>([]);
	const [doctor, setDoctor] = useState<any>({});
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);
	const onDeleteTags = (tagIndex: number) => setAllTags(allTags.filter((_, i) => i !== tagIndex));
	const onAddTags = (newTag: Record<string, any>) => {
		const alreadyAddTag = allTags.find(tag => tag.name === newTag.name)?.name;

		if (!alreadyAddTag) setAllTags([...allTags, newTag]);
	};
	const onDeleteDoctor = (tagIndex: number) => setDoctor(doctor.filter((_, i) => i !== tagIndex));
	const onAddDoctor = newTag => {
		setDoctor([newTag]);
	};
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};

	useEffect(() => {
		if (doctors) {
			const findRspiTeam = doctors.find(o => o.name === 'Tim RS Pondok Indah');
			if (!findRspiTeam) {
				doctors.push({ id: "team_rspi", name: "Tim RS Pondok Indah" });
			}
		}
	}, [doctors]);

	const refreshData = () => {
		history.push('/news-articles');
		dispatch(getAllNews('', 1, '', ''));
	};
	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.title.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (!doctor.length && selectedType.value !== 'HealthFirst' && !errorForm.length) {
				setErrorForm(prev => [...prev, 3]);
			}
			if (!form.short_description.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 4]);
			}
			if (!selectedType.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 2]);
			}
			if (editor.editorHtml.length <= 11 && !errorForm.length) {
				setErrorForm(prev => [...prev, 5]);
			}
			if (!isEdit && !imagePayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!isEdit && selectedType.value === 'HealthFirst' && !pdfPayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!allTags.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 9]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 10]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 11]);
			}
		};

		const formImg = new FormData();
		const formPdf = new FormData();
		if (isEdit) {
			const validationEdit =
				(selectedType.value === 'HealthFirst' ? true : doctor.value) &&
				form.title.length &&
				form.short_description.length &&
				selectedType.name &&
				editor.editorHtml.length > 11 &&
				selectedStatus.name.length &&
				allTags.length &&
				selectedLanguage.name.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) &&
				!isDuplicateSlug;
				
			if (validationEdit) {
				if (imagePayload.length || pdfPayload.length) {
					// upload img
					let uploadImg = details.img_url;
					if (imagePayload.length) {
						imagePayload.map(item => formImg.append('upload', item));
						uploadImg = await dispatch(generalUpload(formImg));
					}

					// upload pdf
					let uploadPdf = details.pdf_url;
					if (pdfPayload.length && selectedType.value === 'HealthFirst') {
						pdfPayload.map(item => formPdf.append('upload', item));
						uploadPdf = await dispatch(generalUpload(formPdf));
					}
					if (uploadImg?.code === 200) {
						await dispatch(editNews(details.id, {
							title: form.title,
							slug: form.slug,
							author: selectedType.value === 'HealthFirst' ? '' :
								doctor.value,
							short_description: form.short_description,
							img_url: uploadImg.data,
							pdf_url: uploadPdf?.data,
							category: selectedType.name.toLowerCase(),
							is_publish: selectedStatus.value,
							posted_date: dayjs().format('YYYY-MM-DD'),
							content: editor.editorHtml,
							tags: [...allTags.map(item => item.name)],
							language: selectedLanguage?.value,
							parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
							seo_h1: form.seo_h1,
							seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
						}, () => refreshData()));
					}
				} else {
					await dispatch(editNews(details.id, {
						title: form.title,
						slug: form.slug,
						author: selectedType.value === 'HealthFirst' ? '' :
							doctor.value,
						short_description: form.short_description,
						img_url: details.img_url,
						pdf_url: details.pdf_url,
						category: selectedType.name.toLowerCase(),
						is_publish: selectedStatus.value,
						posted_date: dayjs().format('YYYY-MM-DD'),
						content: editor.editorHtml,
						tags: [...allTags.map(item => item.name)],
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		} else {
			const validationPost =
				(selectedType.value === 'HealthFirst' ? true : doctor.value) &&
				form.title.length &&
				selectedType.name &&
				editor.editorHtml.length > 11 &&
				form.short_description.length &&
				selectedStatus.name.length &&
				imagePayload.length &&
				allTags.length &&
				selectedLanguage.name.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) &&
				!isDuplicateSlug;
			if (validationPost) {
				imagePayload.map(item => formImg.append('upload', item));
				const uploadImg = await dispatch(generalUpload(formImg));

				// upload pdf
				let uploadPdf;
				if (selectedType.value === 'HealthFirst') {
					pdfPayload.map(item => formPdf.append('upload', item));
					uploadPdf = await dispatch(generalUpload(formPdf));
				}
				if (uploadImg?.code === 200) {
					await dispatch(addNews({
						title: form.title,
						slug: form.slug,
						author: selectedType.value === 'HealthFirst' ? '' : doctor.value,
						short_description: form.short_description,
						img_url: uploadImg.data,
						pdf_url: uploadPdf?.data,
						category: selectedType.name.toLowerCase(),
						is_publish: selectedStatus.value,
						posted_date: dayjs().format('YYYY-MM-DD'),
						content: editor.editorHtml,
						tags: [...allTags.map(item => item.name)],
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		}
	};

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllNewsSlug('', 1, '', '', 1000, selectedLanguage.value, form.slug));
	};

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllNews(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value, selectedType?.value));
	};

	const onDeleteNews = (id: string) => {
		dispatch(deleteNews(id, () => {
			dispatch(getAllNews('', 1, '', ''));
		}));
	};

	const onSearchNews = debounce(e => {
		dispatch(getAllNews(e[0].target.value, 1, selectedStatus?.value, ''));
	});

	const effectFunctions = () => {
		if (details && (id === details?.slug)) {
			setForm({
				...form,
				title: details.title,
				img_url: details.img_url,
				short_description: details.short_description,
				slug: details.slug,
				seo_h1: details?.seo_h1
			});
			setSelectedType(formType.find(item => item.name.toLowerCase() === details.category));
			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
			setEditor({ editorHtml: details.content });
			setPreviewImage([details.img_url]);

			setPreviewPdf([details.pdf_url]);
			setAllTags(details?.tags?.map((item, index) => ({
				id: index,
				name: item
			})));
			if (details?.news_author) {
				setDoctor(
					{
						value: details?.author,
						label: details?.news_author?.doctor_name
					}
				);
			} else if (details?.author === 'team_rspi') {
					setDoctor(
						{
							value: 'team_rspi',
							label: 'Tim RS Pondok Indah',
						}
					);
			}

			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.title
				});
			}
			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'title',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Type',
			name: 'type',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formType.filter(item => item.value !== ''),
			renderType: 'select',
			placeholder: 'Type ',
			selectType: 'type'
		},
		{
			index: 3,
			label: 'Author',
			name: 'author',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: doctors,
			renderType: 'dropdown_search',
			placeholder: 'author',
			tagType: 'article'
		},
		{
			index: 4,
			label: 'Short Description',
			name: 'short_description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'short description',
		},
		{
			index: 5,
			label: 'Content',
			name: 'editor',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			inputType: 'text',
			placeholder: 'editor ',
		},
		{
			index: 6,
			label: 'Upload Image',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: previewImage.length >= 1,
			renderType: 'file',
			inputType: 'file',
			maxImage: 1,
			imageType: ''
		},
		{
			index: 7,
			label: 'Upload Pdf (Healthfirst only)',
			name: 'url_pdf',
			isMandatory: true,
			disabledCondition: previewImage.length >= 1,
			renderType: 'pdf',
			inputType: 'file',
			maxImage: 1,
			imageType: ''
		},
		{
			index: 8,
			label: 'Tags',
			name: 'tags',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'tags',
			placeholder: 'Input tags',
			allowNewTag: true
		},
		{
			index: 9,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 10,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 11,
			label: 'Parent Article',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Article'
		},
		{
			index: 12,
			label: 'Slug Article',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},

		{
			index: 13,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		editor,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onChangeEditor,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		formType,
		selectedType,
		setSelectedType,
		setEditor,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onDeleteNews,
		onSearchNews,
		formArray,
		effectFunctions,
		errorForm,
		setErrorForm,
		allTags,
		tags,
		onDeleteTags,
		onAddTags,
		doctor,
		setDoctor,
		onDeleteDoctor,
		onAddDoctor,
		doctors,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		slug_data,
		previewPdf,
		setPreviewPdf,
		pdfPayload,
		setPdfPayload,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
}
