import { Pagination } from 'interfaces/common';
import {
	GET_ALL_FOOTER, FooterData, FooterActionTypes, GET_ALL_FOOTER_CATEGORY, GET_DETAIL_FOOTER, GET_ALL_FOOTER_SLUG
} from './actionType';

interface FooterState {
  data: FooterData[];
	category: FooterData[];
  pagination: Pagination;
  details: any;
	slug_data: FooterData[],
}

const initState: FooterState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  details: {},
	category: [],
	slug_data: [],
};

const FootersReducer = (state = initState, action: FooterActionTypes) => {
  switch (action.type) {
		case GET_ALL_FOOTER:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
		case GET_ALL_FOOTER_CATEGORY:
			return {
				...state,
				category: action.data,
				pagination: action.pagination
			};
		case GET_DETAIL_FOOTER:
			return {
				...state,
				details: action.details,
				pagination: action.pagination
			};
		case GET_ALL_FOOTER_SLUG:
			return {
				...state,
				slug_data: action.data,
			};
    default:
      return state;
  }
};

export default FootersReducer;