import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { getAllDoctors } from 'store/actions';
import { debounce } from 'helpers/misc';
import { FormArrayType, ParamsType } from 'interfaces/common';
import { setActiveDoctor, setPinnedDoctor, syncDoctor } from 'store/Doctors/action';

export default function useDoctors() {
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const dispatch = hooks.useAppDispatch();
	const thTitles: string[] = [
		'ID',
		'NAME',
		'HOSPITAL',
		'SPECIALITY',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		name: '',
		experience: '',
		img_url: '',
		doctor_code: '',
		schedule_type: ''
	});
	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.doctors);

	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Active',
			value: true
		}, {
			id: 2,
			name: 'Inactive',
			value: false
		}
	];
	const formPinned: Record<string, any>[] = [
		{
			id: 1,
			name: 'Pinned',
			value: true
		}, {
			id: 2,
			name: 'Unpinned',
			value: false
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: '',
		value: ''
	});
	const [selectedFilterPinned, setSelectedFilterPinned] = useState<any>({
		id: 0,
		name: '',
		value: ''
	});
	const [selectedPinned, setSelectedPinned] = useState({
		id: 0,
		name: '',
		value: false
	});
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		});
	};

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllDoctors(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, '', ''));
	};

	const onSearchDoctor = debounce(e => {
		dispatch(getAllDoctors(e[0].target.value, 1, selectedStatus?.value, ''));
	});

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Name',
			name: 'name',
			isMandatory: true,
			disabledCondition: true,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'name',
		},
		{
			index: 1,
			label: 'potrait',
			name: 'img_url',
			isMandatory: true,
			disabledCondition: true,
			renderType: 'img',
			placeholder: 'img_url',
		},
		{
			index: 2,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: true,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 3,
			label: 'Pinned',
			name: 'pinned',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formPinned,
			renderType: 'select',
			placeholder: 'Pinned ',
			selectType: 'pinned'
		},
	];

	const effectFunctions = () => {
		if (details && (id === details?.doctor_code.toString())) {
			setForm({
				...form,
				name: details.full_name_doctor,
				experience: details.experience,
				img_url: details.img_url,
				doctor_code: details.doctor_code,
			});
			setSelectedStatus(details.is_active ?
				{
					id: 1,
					name: 'Active',
					value: true
				} : {
					id: 2,
					name: 'Inactive',
					value: false
				});
			setSelectedPinned(details.is_pinned ? {
				id: 1,
				name: 'Pinned',
				value: true
			} : {
				id: 2,
				name: 'Unpinned',
				value: false
			});
			setPreviewImage([details.img_url]);
		}
	};

	const onClickSyncDoctor = async () => {
		await dispatch(syncDoctor());
	};

	const onClickSubmit = async () => {
		await dispatch(setPinnedDoctor(id, { is_pinned: selectedPinned?.value }, () => {
			history.push('/doctors');
			dispatch(getAllDoctors('', 1, '', ''));
		}));
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onSearchDoctor,
		formPinned,
		selectedPinned,
		setSelectedPinned,
		formArray,
		effectFunctions,
		onClickSubmit,
		onClickSyncDoctor,
		setSelectedFilterPinned,
		selectedFilterPinned,
	};
}