import { Pagination } from 'interfaces/common';

export const GET_ALL_HOSPITALS = 'GET_ALL_HOSPITALS';
export const GET_HOSPITAL_DETAILS = 'GET_HOSPITAL_DETAILS';
export const GET_ALL_HOSPITALS_SLUG = 'GET_ALL_HOSPITALS_SLUG';

export interface HospitalsData {
  address: string;
  created_date: string;
  description: string;
  email: string;
  hospital_code: string;
  id: string;
  img_url: { url: string; };
  is_active: boolean;
  latitude: string;
  longitude: string;
  name: string;
  phone: string;
  updated_date: string;
	language: string;
}

export interface GetAllHospitals {
  type: typeof GET_ALL_HOSPITALS;
  data: HospitalsData[];
  pagination: Pagination;
}

export interface GetHospitalDetails {
  type: typeof GET_HOSPITAL_DETAILS;
  details: any;
}

export interface GetHospitalDetailSlug {
	type: typeof GET_ALL_HOSPITALS_SLUG;
	data: any;
}

export type HospitalActionTypes = GetAllHospitals | GetHospitalDetails | GetHospitalDetailSlug;
