import { Loading, LazyLoadField } from 'interfaces/misc';

export const LOADING = 'LOADING';
export const LOADING_MSG = 'LOADING_MSG';
export const LAZY_LOAD = 'LAZY_LOAD';
export const LOGOUT = 'LOGOUT';
export const SET_SHOW_BURGERMENU = 'SET_SHOW_BURGERMENU';

export interface SetLoading {
	type: typeof LOADING;
	data: Loading;
}

export interface SetLazyLoad {
	type: typeof LAZY_LOAD;
	data: LazyLoadField;
}

export interface Logout {
	type: typeof LOGOUT;
}

export interface ShowBurgerMenu {
	type: typeof SET_SHOW_BURGERMENU;
	payload: boolean;
}

export type MiscActionTypes = SetLoading | SetLazyLoad | Logout | ShowBurgerMenu;
