import React from 'react';
import { styleHelper } from 'helpers';

export type CardStatusProps = {
    background?: string;
    color?: string;
    text?: string;
}

const CardStatus: React.FC<CardStatusProps> = ({
	background = 'bg-green-100',
	text
}) => {
	const selectClassNames = styleHelper.classNames(
		'rounded-2xl px-2 py-1 text-center text-xs font-semibold',
		background,
	);
	return (
		<span className={ selectClassNames }>{ text }</span>
	);
};

export default CardStatus;