import { useEffect } from 'react';
import { hooks } from 'helpers';
import useEventAndClasses from '../useEventAndClasses';
import { getAllEventList, getEventDetails } from 'store/EventAndClasses/action';
import AdminForm from 'components/AdminView/AdminForm';

const EventAndClassesForm = () => {
  const dispatch = hooks.useAppDispatch();
  const { formArray } = useEventAndClasses();

  useEffect(() => {
    dispatch(getAllEventList('', 1, '', '', '', 10, 'idn'));
  }, []);

  return (
    <AdminForm
      backLink='/event-classes'
      formArray={ formArray }
      getDetailAction={ getEventDetails }
      isAbleToSubmit={ true }
      localHooks={ useEventAndClasses }
      title='Event and Classes'
      type='events'
    />
  );
};

export default EventAndClassesForm;