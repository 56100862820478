import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

export const addBookingSetting = (body, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('add doctor setting begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.addBookingSetting(), body);
			if (code === 200) {
				toastify.success('Berhasil Menyimpan Booking Setting');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add doctor setting end'));
	};
};

export const updateBookingSetting = (body, id, cb) => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('add doctor setting begin'));
			const { code }: ApiData<any> = await api.request(Endpoints.updateBookingConfig(id), body);
			if (code === 200) {
				toastify.success('Berhasil Menyimpan Booking Setting');
				cb && cb();
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('add doctor setting end'));
	};
};