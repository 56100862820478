import React, {
	ReactNode,
	useRef,
	useState
} from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';

import {
	EyeIcon,
	EyeSlashIcon
} from '@heroicons/react/24/outline';

import { styleHelper } from 'helpers';

import Text from '../Text';
import {
	CalendarIcon,
	ChevronDownIcon,
	ClockIcon,
	ExclamationCircleIcon,
	PhotoIcon,
	PlusIcon,
	MinusIcon
} from '@heroicons/react/24/solid';
import { Button, Select } from 'components';

import 'react-datepicker/dist/react-datepicker.css';

type InputProps = {
	id?: string;
	name?: string;
	containerClassName?: string;
	labelClassName?: string;
	inputClassName?: string;
	placeholder?: string;
	label?: string;
	value?: any;
	onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
	type?: InputType;
	errorText?: string;
	textSize?: FontSize;
	labelSize?: FontSize;
	leadingText?: string;
	leadingIcon?: () => ReactNode;
	trailingIcon?: () => ReactNode;
	valueSelect?: ObjectKey[];
	spacing?: string;
	required?: boolean;
	onDropFile?: (e: React.DragEvent<HTMLDivElement>) => void;
	onChangeFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClickPlus?: () => void;
	onClickMinus?: () => void;
	disabled?: boolean;
	min?: any;
	max?: any;
	step?: any;
	maxLength?: number;
	error?: boolean,
	flexLabel?: boolean;
	notice?: string;
	isSlug?: boolean;
	isDuplicateSlug?: boolean;
	checkSlugDuplicate?: () => void;
};

registerLocale('pt-BR', pt);

const Input: React.FC<InputProps> = ({
	id,
	name,
	containerClassName = 'space-y-2.5',
	labelClassName = '',
	inputClassName = '',
	placeholder = '',
	label = '',
	type = 'text',
	errorText = '',
	value,
	textSize = 'text-sm',
	labelSize = 'text-sm',
	leadingText,
	leadingIcon,
	trailingIcon,
	onChange,
	required = false,
	spacing = '',
	valueSelect,
	onDropFile,
	onChangeFile,
	onClickPlus,
	onClickMinus,
	disabled = false,
	min = '',
	max = '',
	maxLength,
	step,
	error,
	flexLabel = false,
	notice,
	isSlug = false,
	isDuplicateSlug = true,
	checkSlugDuplicate,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);

	const [startDate, setStartDate] = useState(new Date());
	const [focus, setFocus] = useState<boolean>(false);
	const [inputType, setInputType] = useState<InputType>(type);

	const containerClassNames = styleHelper.classNames('space-y-2.5', containerClassName);

	const labelClassNames = styleHelper.classNames(
		'block font-primary text-hydeBlack-500 font-normal',
		labelSize,
		labelClassName,
		`${ flexLabel ? 'whitespace-nowrap w-15' : 'whitespace-normal' }`
	);
	const inputClassNames = styleHelper.classNames(
		'font-primary font-normal text-hydeBlack-900',
		textSize,
		leadingText ? 'rounded-r-md' : 'rounded-md',
		'border-gray-300 border-solid border',
		'block w-full border py-2 px-3',
		'transition duration-150 ease-in-out',
		'placeholder:text-[#A0A0A0] placeholder:font-primary placeholder:font-normal',
		`placeholder:${ textSize }`,
		spacing,
		inputClassName,
		errorText ? 'focus:ring-0 focus:border-hydeRed border-hydeRed' : 'focus:ring-0',
		leadingIcon ? 'pl-10' : '',
		type === 'date' || type === 'time' ? 'pl-10 pr-10' : '',
		type === 'textarea' ? 'min-h-[105px]' : ''
	);

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		onDropFile ? onDropFile(e) : null;
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

	const renderLabel = () => {
		return (
			<label htmlFor={ id } className={ labelClassNames }>
				{ label } { required && <span className='text-hydeRed'>*</span> }
			</label>
		);
	};

	const renderdate = () => {
		return (
			<div className='flex'>
				<DatePicker selected={ startDate } onChange={ date => setStartDate(date) } />
			</div>
		);
	};

	const renderNotice = () => {
		if (notice) {
			return (
				<div className='ml-2'>
					<Text text={ notice } className='text-sm break-words' color='text-gray-600' />
				</div>
			);
		}
	};

	const renderInput = () => {
		return (
			<div className='flex'>
				{ leadingText ? (
					<span className='inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 border-solid rounded-l-md bg-gray-50'>
						{ leadingText }
					</span>
				) : null }
				<input
					id={ id }
					ref={ inputRef }
					disabled={ disabled }
					type={ inputType }
					placeholder={ placeholder }
					name={ name }
					value={ value }
					onChange={ onChange }
					className={ inputClassNames }
					pattern='^\$\d{1,3}(,\d{3})*(\.\d+)?$'
					onWheel={ (e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur() }
					min={ min }
					max={ max }
					step={ step }
					maxLength={maxLength}
					{ ...rest }
				/>

				{ errorText && error && (
					<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
						<ExclamationCircleIcon className='w-5 h-5 text-hydeRed' aria-hidden='true' />
					</div>
				) }
			</div>
		);
	};

	const renderTextArea = () => {
		return (
			<>
				<textarea
					id={ id }
					ref={ textareaRef }
					placeholder={ placeholder }
					name={ name }
					value={ value }
					onChange={ onChange }
					className={ inputClassNames }
					maxLength={ maxLength }
					{ ...rest }
				/>

				{ errorText && error && (
					<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
						<ExclamationCircleIcon className='w-5 h-5 text-hydeRed' aria-hidden='true' />
					</div>
				) }
			</>
		);
	};

	const onClickTogglePassword = () => {
		setInputType(prevInput => {
			if (prevInput === 'password') {
				return 'text';
			} else {
				return 'password';
			}
		});
	};

	const renderEyeIcon = () => {
		const iconClassName = 'w-5 h-5 text-[#A0A0A0] cursor-pointer';

		if (inputType === 'password') {
			return <EyeIcon className={ iconClassName } />;
		}

		return <EyeSlashIcon className={ iconClassName } />;
	};

	const renderInputBasedOnType = () => {
		switch (type) {
			case 'password':
				return (
					<div className='relative w-full'>
						<div className='absolute inset-y-0 right-0 flex items-center px-5 py-[0.813rem]'>
							<input className='hidden js-password-toggle' id={ `toggle-${ id }` } type='checkbox' />

							<label className='cursor-pointer js-password-label' htmlFor={ `toggle-${ id }` } onClick={ onClickTogglePassword }>
								{ renderEyeIcon() }
							</label>
						</div>

						{ renderInput() }
					</div>
				);

			case 'search':
				return (
					<div className='relative'>
						<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
							{ /* <SearchIcon className='w-5 h-5' /> */ }
						</div>

						{ renderInput() }
					</div>
				);
			case 'select':
				return (
					<Select
						list={ valueSelect }
						keyObj='name'
						placeholder={ placeholder }
						shadow='shadow-sm'
						sizing='w-full'
						wrapperClassName='mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm border-gray-300 border-solid border rounded-md py-[9px] px-[13px]'
					/>
				);
			case 'date':
				return (
					<div className='relative shadow-sm'>
						<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
							<CalendarIcon className='w-5 h-5 text-gray-400' />
						</div>
						{ renderInput() }

						<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
							<ChevronDownIcon className='w-5 h-5 text-gray-500' />
						</div>
					</div>
				);
			case 'time':
				return (
					<div className='relative shadow-sm'>
						<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
							<ClockIcon className='w-5 h-5 text-gray-400' />
						</div>

						{ renderInput() }

						<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
							<ChevronDownIcon className='w-5 h-5 text-gray-500' />
						</div>
					</div>
				);
			case 'textarea':
				return (
					<div className='relative shadow-sm'>
						{ leadingIcon ? (
							<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>{ leadingIcon() }</div>
						) : null }

						{ renderTextArea() }

						{ trailingIcon ? (
							<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>{ trailingIcon() }</div>
						) : null }
					</div>
				);
			case 'currency':
				return (
					<div className='relative shadow-sm'>
						{ leadingIcon ? (
							<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>{ leadingIcon() }</div>
						) : null }

						{ renderInput() }

						{ trailingIcon ? (
							<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>{ trailingIcon() }</div>
						) : null }
					</div>
				);
			case 'file':
				return (
					<div className='relative'>
						<label htmlFor={ id } className='cursor-pointer'>
							<div
								className={ styleHelper.classNames(
									'flex items-center justify-center px-6 py-7 bg-white border-dashed border-2 rounded-2lg',
									spacing
								) }
								onDrop={ handleDrop }
								onDragOver={ handleDragOver }
							>
								<div className='flex flex-col items-center space-y-1.5 text-center'>
									<PhotoIcon className='w-14 h-14 text-zinc-400' />
									<div className='flex'>
										<span className='relative font-bold text-xxs font-primary text-[#1D1D1D] hover:underline underline-offset-1 hover:text-opacity-80'>
											{ placeholder }
										</span>
										<input id={ id } name={ name } type='file' className='sr-only' onChange={ onChangeFile } multiple { ...rest } />
									</div>
									<Text size='text-sm' weight='font-normal' color='text-[#A0A0A0]' align='text-center'>
										Maximum 5 Picture. PNG, JPG, GIF up to 10MB.
									</Text>
								</div>
							</div>
						</label>
					</div>
				);
			case 'counter':
				return (
					<div className='relative shadow-sm flex items-center border-solid rounded-md'>
						<span onClick={ onClickMinus } className=' w-10 h-full rounded-full flex justify-center items-center flex-1'>
							<MinusIcon className='cursor-pointer w-4 h-4 text-zinc-400 ' />
						</span>
						<input
							id={ id }
							ref={ inputRef }
							type='number'
							placeholder={ placeholder }
							name={ name }
							value={ value }
							onChange={ onChange }
							className={ `${ inputClassNames } w-17 rounded-none counter-input-number` }
							min={ 0 }
							max={ 999 }
							pattern='^\$\d{1,3}(,\d{3})*(\.\d+)?$'
							maxLength={maxLength}
							{ ...rest }
						/>
						<span onClick={ onClickPlus } className=' w-10 h-6 rounded-full flex justify-center items-center flex-1'>
							<PlusIcon className='cursor-pointer w-4 h-4 text-zinc-400 ' />
						</span>
					</div>
				);
			case 'custom-date':
				return (
					<div className='relative shadow-sm'>
						<DatePicker
							selected={ startDate }
							onChange={ time => {
								setStartDate(time);
							} }
							// value=''
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={ 30 }
							timeCaption='Time'
							dateFormat='HH:MM'
							locale='pt-BR'
						/>
					</div>
				);
			default:
				return (
					<div className='shadow-sm'>
						{ leadingIcon ? (
							<div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>{ leadingIcon() }</div>
						) : null }

						{ renderInput() }

						{ trailingIcon ? (
							<div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>{ trailingIcon() }</div>
						) : null }
					</div>
				);
		}
	};

	return (
		<div
			className={ containerClassNames }
			onClick={ () => inputRef.current?.focus() }
			onFocus={ () => setFocus(true) }
			onBlur={ () => setFocus(false) }
		>
			{ flexLabel ?
				<div className='flex align-middle mt-3'>
					<div className='flex items-center w-32'>
						{ renderLabel() }
					</div>
					<div className='w-full'>
						{ renderInputBasedOnType() }
						{ renderNotice() }
					</div>
				</div> : (
					<div>
						{ renderLabel() }
						{ renderInputBasedOnType() }
						{
							isSlug && (
								<div className='flex flex-col'>
									<Button
										type='primary'
										text='Check Slug'
										className='my-2 w-40'
										onClick={ checkSlugDuplicate }
									/>
									<label className={ `${ isDuplicateSlug ? 'text-red-400' : 'text-green-800' } text-sm font-bold` }>
										{ isDuplicateSlug ? 'Slug sudah terdaftar/tidak bisa digunakan' : 'Slug bisa digunakan' }
									</label>
								</div>

							)
						}
						{ renderNotice() }
					</div>
				)
			}

			{ errorText && error && (
				<Text color='text-hydeRed' size='text-xs' spacing='pl-2 mt-4'>
					{ errorText }
				</Text>
			) }
		</div>
	);
};

export default Input;
