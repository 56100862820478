import { useEffect } from 'react';

import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllPatients } from 'store/actions';

import usePatients from './usePatients';

const Patients = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onSearchPatients,
    formStatus,
    selectedStatus,
    setSelectedStatus,
    birthDate,
    setBirthDate,
		removeBlacklistPatient
  } = usePatients();

  useEffect(() => {
    dispatch(getAllPatients('', 1, '', birthDate.start, birthDate.end));
  }, []);

  return (
    <AdminView
      cellType='view'
      thTitles={ thTitles }
      editLink='patients/edit'
      viewLink='patients/view'
      items={ data }
      title='Patients'
      type='patients'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onChangeSearch={ onSearchPatients }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllPatients }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
      birthDate={ birthDate }
      setBirthDate={ setBirthDate }
			onClickUnBlock={ removeBlacklistPatient }
    />
  );
};

export default Patients;