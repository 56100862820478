import { useEffect } from 'react';

import useBanners from '../useBanners';
import { getAllBanners, getBannerDetails } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import { hooks } from 'helpers';

const BannerForm = () => {
  const { formArray } = useBanners();
  const dispatch = hooks.useAppDispatch();
  useEffect(() => {
    dispatch(getAllBanners('', 1, ''));
  }, []);

  return (
    <AdminForm
      backLink='/banners'
      formArray={ formArray }
      getDetailAction={ getBannerDetails }
      isAbleToSubmit={ true }
      localHooks={ useBanners }
      title='Banner'
      type='banners'
    />
  );
};

export default BannerForm;