import { Loading, LazyLoad } from 'interfaces/misc';
import { ActionModalForm } from 'typings';
import { toastify } from 'helpers';

export const getKeyByValue = (obj: any, value: any) => {
	return Object.keys(obj).find(key => obj[key] === value);
};

export const getMultipleKeyByValue = (obj: any, value: any, value2?: any) => {
	return Object.keys(obj).filter(key => obj[key] === value || obj[key] === value2);
};

export const getMultipleKeyByArrOfValue = (obj: any, values: any[]) => {
	// array of value
	return Object.keys(obj).filter(key => values.includes(obj[key]));
};

export const flatten = (arr: any) => arr.reduce((flat, next) => flat.concat(next), []);

export const capitalizeFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const titleCase = (str: string) => {
	const splitStr = str.toLowerCase().split(' ');

	for (let i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}

	return splitStr.join(' ');
};

export const phoneNumberFormat = (number: string, phoneCode = '0') => {
	let newNumber = number;

	if (number.substring(0, 3) === '+62') {
		// +628xxx
		newNumber = phoneCode + number.substring(3);
	} else if (number.substring(0, 2) === '08') {
		// 08xxx
		if (phoneCode === '0') {
			newNumber = number;
		} else {
			newNumber = phoneCode + number.substring(1);
		}
	} else if (number.substring(0, 2) === '62') {
		// 628xxxx
		newNumber = phoneCode + number.substring(2);
	} else if (number.substring(0, 1) === '8') {
		// 8xxxx
		newNumber = phoneCode + number;
	}

	return newNumber;
};

export const isLoading = (loading: Loading, message: string) => {
	if (loading) {
		return loading.isLoading && loading.loadingMsg === message;
	}

	return false;
};

export const isLazyLoading = (lazyLoad: LazyLoad, keyword: string) => {
	if (lazyLoad) {
		const keyLazy = Object.keys(lazyLoad).find(key => key === keyword);

		return keyLazy ? lazyLoad[keyword] : false;
	}

	return false;
};

export const setModalFormTitle = (actionModalForm: ActionModalForm, field: string) => {
	const fieldTitle = titleCase(field);

	switch (actionModalForm) {
		case 'add':
			return `Add ${ fieldTitle }`;
		case 'edit':
			return `Edit ${ fieldTitle }`;
		case 'detail':
			return `${ fieldTitle } Details`;
		default:
			return '';
	}
};

export const isEmptyString = (text: string) => {
	return text !== null && text !== '' ? text : '-';
};

export const formatByteSize = (amount: number) => {
	return amount < 1000000 ? `${ amount.toLocaleString('ID-id') } kb` : `${ (amount / 1000000).toFixed(2) } mb`;
};

export const timeOption = [
	{
		id: 0,
		name: '7.00 - 8.00 WIB',
		value: '07:00-08:00'
	},
	{
		id: 1,
		name: '7.30 - 8.30 WIB',
		value: '07:30-08:30'
	},
	{
		id: 2,
		name: '8.00 - 9.00 WIB',
		value: '08:00-09:00'
	},
	{
		id: 3,
		name: '8.30 - 9.30 WIB',
		value: '08:30-09:30'
	},
	{
		id: 4,
		name: '9.00 - 10.00 WIB',
		value: '09:00-10:00'
	},
	{
		id: 5,
		name: '9.30 - 10.30 WIB',
		value: '09:30-10:30'
	},
	{
		id: 6,
		name: '10.00 - 11.00 WIB',
		value: '10:00-11:00'
	},
	{
		id: 7,
		name: '10.30 - 11.30 WIB',
		value: '10:30-11:30'
	},
	{
		id: 8,
		name: '11.00 - 12.00 WIB',
		value: '11:00-12:00'
	},
	{
		id: 9,
		name: '11.30 - 12.30 WIB',
		value: '11:30-12:30'
	},
	{
		id: 10,
		name: '12.00 - 13.00 WIB',
		value: '12:00-13:00'
	},
	{
		id: 11,
		name: '12.30 - 13.30 WIB',
		value: '12:30-13:30'
	},
	{
		id: 12,
		name: '13.00 - 14.00 WIB',
		value: '13:00-14:00'
	},
	{
		id: 13,
		name: '13.30 - 14.30 WIB',
		value: '13:30-14:30'
	},
	{
		id: 14,
		name: '14.00 - 15.00 WIB',
		value: '14:00-15:00'
	},
	{
		id: 15,
		name: '14.30 - 15.30 WIB',
		value: '14:30-15:30'
	},
	{
		id: 16,
		name: '15.00 - 16.00 WIB',
		value: '15:00-16:00'
	},
	{
		id: 17,
		name: '15.30 - 16.30 WIB',
		value: '15:30-16:30'
	},
	{
		id: 18,
		name: '16.00 - 17.00 WIB',
		value: '16:00-17:00'
	},
	{
		id: 19,
		name: '16.30 - 17.30 WIB',
		value: '16:30-17:30'
	},
	{
		id: 20,
		name: '17.00 - 18.00 WIB',
		value: '17:00-18:00'
	},
	{
		id: 21,
		name: '18.30 - 19.30 WIB',
		value: '18:30-19:30'
	},
	{
		id: 22,
		name: '19.00 - 20.00 WIB',
		value: '19:00-20:00'
	},
	{
		id: 23,
		name: '20.30 - 21.30 WIB',
		value: '20:30-21:30'
	},
	{
		id: 24,
		name: '21.00 - 22.00 WIB',
		value: '21:00-22:00'
	},
	{
		id: 25,
		name: '21.30 - 22.30 WIB',
		value: '21:30-22:30'
	},
	{
		id: 26,
		name: '22.00 - 23.00 WIB',
		value: '22:00-23:00'
	}
];

export const statusOption = [
	{
		id: 0,
		name: 'DUMMY',
		status: 'DUMMY'
	}, {
		id: 1,
		name: 'DUMMY2',
		status: 'DUMMY2'
	}
];

export const currencyFormatIDR = value => {
	if (value) return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
	return '';
};

export const formatDayIndonesia = (index: number) => {
	switch (index) {
		case 0:
			return 'Minggu';
		case 1:
			return 'Senin';
		case 2:
			return 'Selasa';
		case 3:
			return 'Rabu';
		case 4:
			return 'Kamis';
		case 5:
			return 'Jumat';
		case 6:
			return 'Sabtu';
	}
};

export const checkIsFileValid = (file: Blob) => {
	switch (file.type) {
		case 'image/jpeg':
		case 'image/gif':
		case 'image/png':
		case 'application/pdf':
			return true;
		default:
			return false;
	}
};

export const checkIsFilePDF = (file: Blob) => {
	if (file.type === 'application/pdf') {
		return true;
	} else {
		return false;
	}
};

export const isVOPaid = (arrVOPaid, voID) => {
	return arrVOPaid?.some(project => project.content.transaction?.id === voID);
};

export const handleImage = async (image: File[], imageProperties: File[], setImageProperties: any, pdfOnly = false, max = 5, previewLength) => {
	const dupes = image.map(item => imageProperties.filter(fil => fil.name === item.name));
	if (dupes[0].length > 0) {
		toastify.error('Terdapat file duplikat');
	} else {
		if (imageProperties.length + image.length + previewLength <= max) {
			const filteredImage = image.filter(item => {
				return item.size < 1999999 && pdfOnly ? checkIsFilePDF(item) : checkIsFileValid(item);
			});
			if (filteredImage.length === image.length) {
				setImageProperties([...imageProperties, ...filteredImage]);
			} else {
				toastify.error('Terdapat file yang tidak valid');
			}
		} else {
			toastify.error(`Maksimum ${ max } item`);
		}
	}
};

export const detectProvider = phone => {
	let prefix = '';
	if (phone[0] === '0') {
		prefix = phone.slice(1, 4);
	} else {
		prefix = phone.slice(2, 5);
	}

	if ([
		'831',
		'832',
		'833',
		'838'
	].includes(prefix)) return 'axis';
	if ([
		'895',
		'896',
		'897',
		'898',
		'899'
	].includes(prefix)) return 'three';
	if ([
		'817',
		'818',
		'819',
		'859',
		'878',
		'877'
	].includes(prefix)) return 'xl';
	if ([
		'814',
		'815',
		'816',
		'855',
		'856',
		'857',
		'858'
	].includes(prefix)) return 'indosat';
	if ([
		'812',
		'813',
		'852',
		'853',
		'821',
		'823',
		'822',
		'851',
		'811'
	].includes(prefix)) return 'telkomsel';
	if ([
		'881',
		'882',
		'883',
		'884',
		'885',
		'886',
		'887',
		'888',
		'889'
	].includes(prefix)) return 'smartfren';
	return null;
};

export const statusProject = [
	{
		id: 1,
		type: 'Active'
	}, {
		id: 2,
		type: 'Not Active'
	}
];

export const debounce = (func, timeout = 300) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func(args);
		}, timeout);
	};
};

export const patternUrl = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;

export const pattern = /([https://meet.google.com/]{23,256}[a-z]{3}-[a-z]{3,4}-[a-z]{3})/ig;

export const patternZoom = /^https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+zoom.us\/j\/\S+$/;

export const jadenCase = (str: string, split: ' ' | '-') => {
	const newStr = str.toLowerCase().split(split);
	for (let i = 0; i < newStr.length; i++) {
		newStr[i] = newStr[i][0].toUpperCase() + newStr[i].substr(1);
	}
	return newStr.join(' ');
};

export const formatNumber = (num = '0') => {
	if (num) {
		return num?.replace(/\D/g, '')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}
	return '';
};

export const formatStringToNumber = (num: string) => {
	return num?.replaceAll('.', '');
};

export const renderLongName = (name: string, length: number) => {
	return name?.length > length ? name?.slice(0, length) + '...' : name;
};

export const createFilterStatus = (filter: any[]) => {
	return ([
		...filter, {
			id: 999,
			name: 'All',
			value: ''
		}
	]);
};

export const createSeoRobotString = (seoIndex: boolean, seoFollow: boolean) => {
	let seoRobotString = '';
	if (seoIndex) {
		if (seoFollow) {
			seoRobotString = 'index,follow';
		} else {
			seoRobotString = 'index,nofollow';
		}
	} else {
		if (seoFollow) {
			seoRobotString = 'noindex,follow';
		} else {
			seoRobotString = 'noindex,nofollow';
		}
	}

	return seoRobotString;
};