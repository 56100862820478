import AdminForm from 'components/AdminView/AdminForm';
import usePatients from '../usePatients';

const PatientsForm = () => {
  const { formArray } = usePatients();

  return (
    <AdminForm
      backLink='/patients'
      formArray={ formArray }
      isAbleToSubmit={ false }
      localHooks={ usePatients }
      title='Patients'
      type='patients'
    />
  );
};

export default PatientsForm;