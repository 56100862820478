import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormArrayType, ParamsType } from 'interfaces/common';
import { hooks } from 'helpers';
import { debounce } from 'helpers/misc';
import { selectors } from 'store/selectors';
import { getAllContactUs, deleteContactUs } from 'store/actions';

const useContactUs = () => {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'NAME',
		'PHONE NUMBER',
		'EMAIL',
		'TITLE',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		id: '',
		email: '',
		full_name: '',
		phone: '',
		gender: '',
		title: '',
		content: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const [author, setAuthor] = useState<any[]>([]);
	const onDeleteAuthor = (tagIndex: number) => setAuthor(author.filter((_, i) => i !== tagIndex));
	const onAddAuthor = newTag => {
		setAuthor([newTag]);
	};
	const {
		data, details, pagination
	} = hooks.useAppSelector(selectors.contactUs);
	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllContactUs(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, 10));
	};

	const onDeleteFooter = (id: string) => {
		dispatch(deleteContactUs(id, () => {
			dispatch(getAllContactUs('', 1, 10));
		}));
	};

	const onSearchFooter = debounce(e => {
		dispatch(getAllContactUs(e[0].target.value, 1, 10));
	});

	useEffect(() => {
		setEditor({ editorHtml: form?.content });
	}, [form]);

	const effectFunctions = () => {
		
		if (details && (id === details?.id.toString())) {
			setForm({
				...form,
				id: details?.id,
				title: details?.title,
				email: details?.email,
				full_name: details?.full_name,
				gender: details?.gender,
				phone: details?.phone,
				content: details?.content
			});
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Email',
			name: 'email',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Email',
		},
		{
			index: 1,
			label: 'Full Name',
			name: 'full_name',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Full Name',
		},
		{
			index: 2,
			label: 'Phone',
			name: 'phone',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Phone',
		},
		{
			index: 3,
			label: 'Gender',
			name: 'gender',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Gender',
		},
		{
			index: 4,
			label: 'Title',
			name: 'title',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Title',
		},
		{
			index: 5,
			label: 'Content',
			name: 'content',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'content'
		}
	];

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		data,
		details,
		paginationFunction,
		pagination,
		onDeleteFooter,
		onSearchFooter,
		effectFunctions,
		formArray,
		editor,
		onChangeEditor,
		setEditor,
		onDeleteAuthor,
		onAddAuthor,
		author,
		setAuthor,
	};
};

export default useContactUs;