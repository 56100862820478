import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
  addAward, deleteAward, editAward, getAllAwards
} from 'store/actions';
import { misc } from 'constant';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { debounce } from 'helpers/misc';

export default function useAwardsAndAccreditation () {
  const dispatch = hooks.useAppDispatch();
  const history = useHistory();
  const { id } = useParams<ParamsType>();
  const thTitles: string[] = [
    'ID',
    'TITLE',
    'DESCRIPTION',
    'IMAGE',
    'LANGUAGE',
		'STATUS'
  ];
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    name: ''
  });
  const [form, setForm] = useState({
    title: '',
    description: '',
    img_url: '',
    url_link: ''
  });
  const {
    data, details, pagination
  } = hooks.useAppSelector(selectors.awards);

  const { isLoading } = hooks.useAppSelector(selectors.loading);

  const formStatus: Record<string, any>[] = [
    {
      id: 1,
      name: 'Publish',
      value: true
    }, {
      id: 2,
      name: 'Unpublish',
      value: false
    }
  ];
  const formLanguage: Record<string, any>[] = [
    {
      id: 1,
      name: 'Indonesia',
      value: 'idn'
    }, {
      id: 2,
      name: 'English',
      value: 'en'
    }
  ];
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: 0,
    name: 'All',
    value: ''
  });
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: 1,
    name: 'Indonesia',
    value: 'idn'
  });

  const [selectedParent, setSelectedParent] = useState({
    id: 0,
    name: '',
  });
  const [previewImage, setPreviewImage] = useState<string[]>([]);
  const [imagePayload, setImagePayload] = useState<File[]>([]);
  const [errorForm, setErrorForm] = useState<number[]>([]);
  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
    if (errorForm.length) {
      setErrorForm(errorForm.filter(item => item !== index));
    }
  };
  const refreshData = () => {
    history.push('/awards');
    dispatch(getAllAwards('', 1, ''));
  };
  const onClickSubmit = async (isEdit: boolean) => {
    const validateForm = () => {
      if (!form.title.length) {
        setErrorForm(prev => [...prev, 0]);
      }
      if (!form.description.length) {
        setErrorForm(prev => [...prev, 1]);
      }
      if (!isEdit && !imagePayload.length) {
        setErrorForm(prev => [...prev, 2]);

      }
      if (!selectedStatus.name.length) {
        setErrorForm(prev => [...prev, 3]);
      }
      if (!selectedLanguage.name.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 4]);
      }
      if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
        setErrorForm(prev => [...prev, 5]);
      }
    };

    const formImg = new FormData();
    if (isEdit) {
      const validationEdit =
        form.description.length &&
        form.title.length &&
        selectedStatus.name.length &&
        selectedLanguage?.name?.length &&
        (selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true);
      if (validationEdit) {
        if (imagePayload.length) {
          imagePayload.map(item => formImg.append('upload', item));
          const uploadImg = await dispatch(generalUpload(formImg));
          if (uploadImg?.code === 200) {
            await dispatch(editAward(id, {
              title: form.title,
              description: form.description,
              img_url: uploadImg.data,
              is_publish: selectedStatus.value,
              url_link: form.url_link,
              language: selectedLanguage?.value,
              parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null
            }, () => refreshData()));
          }
        } else {
          await dispatch(editAward(id, {
            title: form.title,
            description: form.description,
            img_url: details.img_url,
            is_publish: selectedStatus.value,
            url_link: form.url_link,
            language: selectedLanguage?.value,
            parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null
          }, () => refreshData()));
        }
      } else {
        validateForm();
      }
    } else {
      const validationPost =
        form.description.length &&
        form.title.length &&
        selectedStatus.name.length &&
        imagePayload.length &&
        selectedLanguage?.name?.length &&
        (selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true);
      if (validationPost) {
        imagePayload.map(item => formImg.append('upload', item));
        const uploadImg = await dispatch(generalUpload(formImg));
        if (uploadImg?.code === 200) {
          await dispatch(addAward({
            title: form.title,
            description: form.description,
            img_url: uploadImg.data,
            is_publish: selectedStatus.value,
            url_link: form.url_link,
            language: selectedLanguage?.value,
            parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null
          }, () => refreshData()));
        }
      } else {
        validateForm();
      }
    }
  };

  const paginationFunction = (action: 'next' | 'prev', search?: string) => {
    dispatch(getAllAwards(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
  };

  const onDeleteAward = (id: string) => {
    dispatch(deleteAward(id, () => {
      dispatch(getAllAwards('', 1, data?.length === 1 ? '' : selectedStatus?.value));
    }));
  };

  const onSearchAward = debounce(e => {
    dispatch(getAllAwards(e[0].target.value, 1, selectedStatus?.value));
  });

  const formArray: FormArrayType[] = [
    {
      index: 0,
      label: 'Title',
      name: 'title',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'title',
    },
    {
      index: 1,
      label: 'Description',
      name: 'description',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'description',
    },
    {
      index: 2,
      label: 'Upload Image',
      name: 'url_img',
      isMandatory: true,
      disabledCondition: previewImage.length >= 1,
      renderType: 'file',
      inputType: 'file',
      maxImage: 1,
      imageType: ''
    },
    {
      index: 3,
      label: 'Status',
      name: 'status',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: formStatus,
      renderType: 'select',
      placeholder: 'Status ',
      selectType: 'status'
    },
    {
      index: 4,
      label: 'Language',
      name: 'language',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: formLanguage,
      renderType: 'select',
      placeholder: 'Status ',
      selectType: 'language'
    },
    {
      index: 5,
      label: 'Parent Award',
      name: 'parent',
      isMandatory: false,
      disabledCondition: isLoading,
      renderType: 'parent',
      placeholder: 'Select Parent Award'
    }
  ];

  const effectFunctions = () => {
    if (details && (id === details?.id.toString())) {
      setForm({
        ...form,
        title: details.title,
        description: details.description,
        img_url: details.img_url,
        url_link: details.url_link
      });
      setSelectedStatus(details.is_publish ?
        {
          id: 1,
          name: 'Publish',
          value: true
        } : {
          id: 2,
          name: 'Unpublish',
          value: false
        });
      setPreviewImage([details.img_url]);
      setSelectedLanguage(
        details?.language === 'idn' ?
          {
            id: 1,
            name: 'Indonesia',
            value: 'idn'
          } : {
            id: 2,
            name: 'English',
            value: 'en'
          }
      );
      if (details?.parent_detail) {
        setSelectedParent({
          id: details?.parent_detail?.id,
          name: details?.parent_detail?.title
        });
      }
    }
  };

  return {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    form,
    setForm,
    formStatus,
    selectedStatus,
    setSelectedStatus,
    onChangeForm,
    onClickSubmit,
    previewImage,
    setPreviewImage,
    data,
    imagePayload,
    setImagePayload,
    details,
    paginationFunction,
    pagination,
    onDeleteAward,
    onSearchAward,
    formArray,
    effectFunctions,
    errorForm,
    setErrorForm,
    selectedLanguage,
    setSelectedLanguage,
    selectedParent,
    setSelectedParent
  };
}
