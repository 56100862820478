import { AdminLogin } from 'interfaces/auth';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export interface Login {
  type: typeof LOGIN;
  data: {
    user: AdminLogin;
    saveToken: boolean;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}

export type AuthActionTypes = Login | Logout;