import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_EVENT_DETAILS, GET_LIST_EVENT, GET_LIST_EVENT_SLUG } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllEventList = (keyword: string, page: number, is_publish: boolean | '', category: 'event' | 'class' | 'promo' | '', hospital_id: string, limit = 10, language = '', slug = '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get event list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getEventList(keyword, page, is_publish, category, hospital_id, limit, language, slug));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_EVENT,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get event list end'));
  };
};

export const getEventDetails = (id: string, lang?: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get event detail begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getEventDetails(id, lang));
      if (code === 200) {
        await dispatch({
          type: GET_EVENT_DETAILS,
          details: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get event detail end'));
  };
};

export const deleteEvent = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete event begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteEvent(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Event/Class');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete event end'));
  };
};

export const addEvent = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add event begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addEvent(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Event/Class');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add event end'));
  };
};

export const editEvent = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit event begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editEvent(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Event/Class');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit event end'));
  };
};

export const getAllEventListSlug = (keyword: string, page: number, is_publish: boolean | '', category: 'event' | 'class' | 'promo' | '', hospital_id: string, limit = 10, language = '', slug = '') => {
	return async (dispatch: Dispatch<AppActions>) => {
		try {
			await dispatch(loadingStart('get event list begin'));
			const {
				code, data, pagination
			}: ApiData<EventsInterface> = await api.request(Endpoints.getEventList(keyword, page, is_publish, category, hospital_id, limit, language, slug));
			if (code === 200) {
				await dispatch({
					type: GET_LIST_EVENT_SLUG,
					data: data,
					pagination: pagination
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();
		}
		dispatch(loadingEnd('get event list end'));
	};
};