import {
  RBACActionTypes, GET_ALL_ACCESS, GET_ALL_ROLES, GET_ALL_ROLE_ACCESS, GET_ROLE_DETAIL, GET_ACCESS_DETAIL
} from './actionTypes';

interface RBACState {
  data: any;
  roles: any;
  access: any;
  detail: any;
  accessDetail: any;
}

const initState: RBACState = {
  data: [],
  roles: [],
  access: [],
  detail: {},
  accessDetail: {}
};

const RBACReducer = (state = initState, action: RBACActionTypes) => {
  switch (action.type) {
    case GET_ALL_ROLE_ACCESS:
      return {
        ...state,
        data: action.role,
      };
    case GET_ALL_ACCESS:
      return {
        ...state,
        access: action.access,
      };
    case GET_ALL_ROLES:
      return {
        ...state,
        roles: action.data,
      };
    case GET_ROLE_DETAIL:
      return {
        ...state,
        detail: action.detail
      };
    case GET_ACCESS_DETAIL:
      return {
        ...state,
        accessDetail: action.accessDetail
      };
    default:
      return state;
  }
};

export default RBACReducer;