import { I_GetMedicalSpecialitiesParam, I_MedicalSpecialitiesPayload } from 'store/MedicalSpecialities/actionTypes';
import { Endpoint } from 'utils/api';

/* eslint-disable object-property-newline */

function toQueryString(paramsObject, exclude: string[] = []) {
	return Object.keys(paramsObject)
		.filter(key => (exclude.length > 0 ? !exclude.includes(key) : true))
		.map(key => `${ encodeURIComponent(key) }=${ encodeURIComponent(paramsObject[key]) }`)
		.join('&');
}

export default {
	// Auth
	login: (): Endpoint => ({
		path: '/admins/login',
		method: 'POST'
	}),
	refreshToken: (): Endpoint => ({
		path: '/users/token/refresh',
		method: 'POST'
	}),
	forgotPassword: (): Endpoint => ({
		path: '/admins/forgot-pass',
		method: 'POST'
	}),
	verifyToken: (token: string): Endpoint => ({
		path: `/admins/verify-token?token=${ token }`,
		method: 'POST'
	}),
	newPassword: (token: string): Endpoint => ({
		path: `/admins/new-pass?token=${ token }`,
		method: 'POST'
	}),

	// Event
	getEventList: (
		keyword: string,
		page: number,
		is_publish: boolean | '',
		category: 'event' | 'class' | 'promo' | '',
		hospital_id: string,
		limit = 10,
		language = '',
		slug = '',
	): Endpoint => ({
		path: `/events?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_publish=${ is_publish }&category=${ category }&hospital_id=${ hospital_id }&language=${ language }&slug=${ slug }`,
		method: 'GET'
	}),
	addEvent: (): Endpoint => ({
		path: '/events',
		method: 'POST'
	}),
	editEvent: (id: string): Endpoint => ({
		path: `/events/${ id }`,
		method: 'PUT'
	}),
	deleteEvent: (id: string): Endpoint => ({
		path: `/events/${ id }`,
		method: 'DELETE'
	}),
	getEventDetails: (id: string, lang?: string): Endpoint => ({
		path: `/events/${ id }?language=${ lang }`,
		method: 'GET'
	}),

	// Hospitals
	getHospitalsList: (keyword: string, page: number, is_active: boolean | '', limit = 10, language = '', slug = ''): Endpoint => ({
		path: `/hospital?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_active=${ is_active }&language=${ language }&slug=${ slug }`,
		method: 'GET'
	}),
	getHospitalDetail: (id: string, lang?: string): Endpoint => ({
		path: `/hospital/${ id }?language=${ lang }`,
		method: 'GET'
	}),
	addHospital: (): Endpoint => ({
		path: '/hospital',
		method: 'POST'
	}),
	editHospital: (id: string): Endpoint => ({
		path: `/hospital/${ id }`,
		method: 'PUT'
	}),
	deleteHospital: (id: string): Endpoint => ({
		path: `/hospital/${ id }`,
		method: 'DELETE'
	}),

	// Facilities
	getFacilitiesList: (keyword: string, page: number, is_publish: boolean | '', is_home_page: boolean | '', limit = 10, language = '', slug = ''): Endpoint => ({
		path: `/facilities?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_publish=${ is_publish }&is_home_page=${ is_home_page }&language=${ language }&slug=${ slug }`,
		method: 'GET'
	}),
	getFacilitiesDetail: (id: string, lang?: string): Endpoint => ({
		path: `/facilities/${ id }?language=${ lang }`,
		method: 'GET'
	}),
	addFacilites: (): Endpoint => ({
		path: '/facilities',
		method: 'POST'
	}),
	editFacilites: (id: string): Endpoint => ({
		path: `/facilities/${ id }`,
		method: 'PUT'
	}),
	deleteFacilities: (id: string): Endpoint => ({
		path: `/facilities/${ id }`,
		method: 'DELETE'
	}),

	// Upload
	generalUpload: (): Endpoint => ({
		path: '/uploads',
		method: 'POST'
	}),

	// News
	getAllNews: (keyword: string, page: number, is_publish: boolean | '', category: string, limit = 10, language = '', slug = '', healthfirst = false): Endpoint => ({
		path: `/news?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_publish=${ is_publish }&category=${ category }&language=${ language }&slug=${ slug }&with_healthfiirst=${ healthfirst }`,
		method: 'GET'
	}),
	getNewsDetail: (id: string, lang?: string): Endpoint => ({
		path: `/news/${ id }?language=${ lang }`,
		method: 'GET'
	}),
	addNews: (): Endpoint => ({
		path: '/news',
		method: 'POST'
	}),
	editNews: (id: string): Endpoint => ({
		path: `/news/${ id }`,
		method: 'PUT'
	}),
	deleteNews: (id: string): Endpoint => ({
		path: `/news/${ id }`,
		method: 'DELETE'
	}),

	// Banners
	getAllBanners: (keyword: string, page: number, is_publish: boolean | ''): Endpoint => ({
		path: `/banners?keyword=${ keyword }&page=${ page }&limit=10&sort&is_publish=${ is_publish }&language=`,
		method: 'GET'
	}),
	getBannerDetails: (id: string): Endpoint => ({
		path: `/banners-detail/${ id }`,
		method: 'GET'
	}),
	addBanner: (): Endpoint => ({
		path: '/banners',
		method: 'POST'
	}),
	editBanner: (id: string): Endpoint => ({
		path: `/banners/${ id }`,
		method: 'PUT'
	}),
	deleteBanner: (id: string): Endpoint => ({
		path: `/banners/${ id }`,
		method: 'DELETE'
	}),

	// Admins
	getAllAdmin: (keyword: string, page: number, is_active: boolean | ''): Endpoint => ({
		path: `/admins?keyword=${ keyword }&page=${ page }&limit=10&sort&is_active=${ is_active }`,
		method: 'GET'
	}),
	getAdminDetails: (id: string): Endpoint => ({
		path: `/admins/${ id }`,
		method: 'GET'
	}),
	addAdmin: (): Endpoint => ({
		path: '/admins',
		method: 'POST'
	}),
	editAdmin: (id: string): Endpoint => ({
		path: `/admins/${ id }`,
		method: 'PUT'
	}),
	deleteAdmin: (id: string): Endpoint => ({
		path: `/admins/${ id }`,
		method: 'DELETE'
	}),

	// Awards
	getAllAwards: (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = ''): Endpoint => ({
		path: `/awards?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_publish=${ is_publish }&language=${ language }`,
		method: 'GET'
	}),
	getAwardDetails: (id: string, lang?: string): Endpoint => ({
		path: `/awards/${ id }?language=${ lang }`,
		method: 'GET'
	}),
	addAward: (): Endpoint => ({
		path: '/awards',
		method: 'POST'
	}),
	editAward: (id: string): Endpoint => ({
		path: `/awards/${ id }`,
		method: 'PUT'
	}),
	deleteAward: (id: string): Endpoint => ({
		path: `/awards/${ id }`,
		method: 'DELETE'
	}),

	// Geocodes
	getEsriGeocode: (): Endpoint => ({
		path: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
		method: 'GET'
	}),
	getEsriReverseGeocode: (): Endpoint => ({
		path: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
		method: 'GET'
	}),

	// Doctors
	getAllDoctors: (keyword: string, page: number, is_active: boolean | '', is_pinned: boolean | '', limit = 10, lang): Endpoint => ({
		path: `/doctors?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_active=${ true }&is_pinned=${ is_pinned }&language=${ lang }`,
		method: 'GET'
	}),
	getDoctorDetails: (id: string): Endpoint => ({
		path: `/doctors/${ id }?language=idn`,
		method: 'GET'
	}),
	setActiveDoctor: (id: string): Endpoint => ({
		path: `/doctors/active/${ id }`,
		method: 'PUT'
	}),
	setPinnedDoctor: (id: string): Endpoint => ({
		path: `/doctors/pinned/${ id }?language=idn`,
		method: 'PUT'
	}),
	getListDoctors: (hospital_code = '', limit = 10, lang): Endpoint => ({
		path: `/doctors/master?hospital_code=${ hospital_code }&limit=${ limit }&language=${ lang }`,
		method: 'GET'
	}),
	getListDoctorsDropdown: (): Endpoint => ({
		path: '/doctors/list',
		method: 'GET'
	}),
	syncDoctor: (): Endpoint => ({
		path: '/doctors/master/sync',
		method: 'PUT'
	}),
	// Clinics
	getAllClinics: (keyword: string, page: number, is_publish: boolean | '', limit = 10, language = '', slug = ''): Endpoint => ({
		path: `/center-of-excellences?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_publish=${ is_publish }&language=${ language }&slug=${ slug }`,
		method: 'GET'
	}),
	getClinicDetails: (id: string, lang?: string): Endpoint => ({
		path: `/center-of-excellences/${ id }?language=${ lang }`,
		method: 'GET'
	}),
	addClinic: (): Endpoint => ({
		path: '/center-of-excellences',
		method: 'POST'
	}),
	editClinic: (id: string): Endpoint => ({
		path: `/center-of-excellences/${ id }`,
		method: 'PUT'
	}),
	deleteClinic: (id: string): Endpoint => ({
		path: `/center-of-excellences/${ id }`,
		method: 'DELETE'
	}),
	getClinicsByHospitalCode: (hospitalCode: string, language: string): Endpoint => ({
		path: `/clinics/${ hospitalCode }/hospital-code?language=${ language }`,
		method: 'GET'
	}),

	// Patients
	getAllPatients: (keyword: string, page: number, is_verified: boolean | '', start_date: string, end_date: string, limit = 10): Endpoint => ({
		path: `/admins/users?keyword=${ keyword }&page=${ page }&limit=${ limit }&sort&is_verified=${ is_verified }&start_birthdate=${ start_date }&end_birthdate=${ end_date }`,
		method: 'GET'
	}),

	removeBlackList: (id: string): Endpoint => ({
		path: `/admins/patient-blacklist/${ id }`,
		method: 'DELETE'
	}),

	// RBAC
	getAllRoleAccesses: (): Endpoint => ({
		path: '/roles-accesses/?keyword&page=1&role_id&limit=1000&offset=0&sort=asc&order=ra.id',
		method: 'GET'
	}),
	getAllRoles: (): Endpoint => ({
		path: '/roles?keyword&page=1&role_id&limit=1000&offset=0&sort=asc&order=ra.id',
		method: 'GET'
	}),
	getAllAccess: (): Endpoint => ({
		path: '/accesses?keyword&method=&parent_id=0&page=1&limit=1000&sort=desc&order=id',
		method: 'GET'
	}),
	addRole: (): Endpoint => ({
		path: '/roles/',
		method: 'POST'
	}),
	getRoleDetail: (id: string): Endpoint => ({
		path: `/roles/${ id }`,
		method: 'GET'
	}),
	editRole: (id: string): Endpoint => ({
		path: `/roles/${ id }`,
		method: 'PUT'
	}),
	deleteRole: (id: string): Endpoint => ({
		path: `/roles/${ id }`,
		method: 'DELETE'
	}),
	addRoleAccess: (): Endpoint => ({
		path: '/roles-accesses/',
		method: 'POST'
	}),
	deleteRoleAccess: (id: number): Endpoint => ({
		path: `/roles-accesses/${ id }`,
		method: 'DELETE'
	}),
	addAccess: (): Endpoint => ({
		path: '/accesses',
		method: 'POST'
	}),
	DeleteAccess: (id: string): Endpoint => ({
		path: `/accesses/${ id }`,
		method: 'DELETE'
	}),
	GetAccessDetail: (id: string): Endpoint => ({
		path: `/accesses/${ id }`,
		method: 'GET'
	}),

	// Tags
	getAllTags: (): Endpoint => ({
		path: '/tags/?keyword=&page=1&limit=100&offset=0&sort=desc&order=id',
		method: 'GET'
	}),

	// Medical specialities
	getMedicalSpecialitiesPages: (params: I_GetMedicalSpecialitiesParam): Endpoint => ({
		path: `/footer-pages/medical-speciality?keyword=${ params.keyword ?? '' }&page=${ params.page ?? '' }&limit=${ params.limit ?? 10 }&is_publish=${ params.is_publish ?? '' }&order=id&sort=desc&slug=${ params.slug ?? '' }&language=${ params.language ?? '' }`,
		method: 'GET'
	}),

	getDetailMedicalSpecialitiesPages: (slug: string, lang?: string): Endpoint => ({
		path: `/footer-pages/${ slug }?language=${ lang }`,
		method: 'GET'
	}),

	addMedicalSpecialities: (): Endpoint => ({
		path: '/footer-pages',
		method: 'POST'
	}),
	editMedicalSpecialities: (id: string): Endpoint => ({
		path: '/footer-pages/' + id,
		method: 'PUT'
	}),
	deleteMedicalSpecialities: (id: string): Endpoint => ({
		path: '/footer-pages/' + id,
		method: 'DELETE'
	}),

	// Footer
	getFootersList: (keyword: string, page: number, is_active: boolean | '', limit = 10, slug = '', lang = ''): Endpoint => ({
		path: `/footer-pages?keyword=${ keyword }&page=${ page }&limit=${ limit }&is_publish=${ is_active }&slug=${ slug }&language=${ lang }`,
		method: 'GET'
	}),

	getFooterBySlug: (slug: string, lang?: string): Endpoint => ({
		path: `/footer-pages/${ slug }?language=${ lang }`,
		method: 'GET'
	}),

	addFooter: (): Endpoint => ({
		path: '/footer-pages',
		method: 'POST'
	}),

	editFooter: (id: string): Endpoint => ({
		path: `/footer-pages/${ id }`,
		method: 'PUT'
	}),

	getFootersCategory: (keyword: string, page: number, is_active: boolean | '', limit = 10): Endpoint => ({
		path: `/footer-categories?keyword=${ keyword }&page=${ page }&limit=${ limit }`,
		method: 'GET'
	}),

	deleteFooter: (id: string): Endpoint => ({
		path: `/footer-pages/${ id }`,
		method: 'DELETE'
	}),
	// Specialities
	getAllSpecialities: (): Endpoint => ({
		path: '/specialities/list',
		method: 'GET'
	}),

	// Booking Setting
	addBookingSetting: (): Endpoint => ({
		path: '/booking-configs',
		method: 'POST'
	}),

	// Related News
	getRelatedNewsFacilities: (id: string): Endpoint => ({
		path: `/news-facilities/${ id }`,
		method: 'GET'
	}),
	addRelatedNewsFacilities: (): Endpoint => ({
		path: '/news-facilities',
		method: 'POST'
	}),
	deleteRelatedNewsFacilities: (id: string): Endpoint => ({
		path: `/news-facilities/${ id }`,
		method: 'DELETE'
	}),
	addRelatedNewsClinic: (): Endpoint => ({
		path: '/center-of-excellences-news',
		method: 'POST'
	}),
	getRelatedNewsClinic: (id: string): Endpoint => ({
		path: `/center-of-excellences-news/news/${ id }`,
		method: 'GET'
	}),
	deleteRelatedNewsClinic: (id: string): Endpoint => ({
		path: `/center-of-excellences-news/${ id }`,
		method: 'DELETE'
	}),
	// Contact Us
	getContactUsList: (keyword: string, page: number, limit = 10): Endpoint => ({
		path: `/contact-us?keyword=${ keyword }&page=${ page }&limit=${ limit }`,
		method: 'GET'
	}),

	getContactUsDetail: (id: string): Endpoint => ({
		path: `/contact-us/${ id }`,
		method: 'GET'
	}),

	deleteContactUs: (id: string): Endpoint => ({
		path: `/contact-us/${ id }`,
		method: 'DELETE'
	}),

	// booking config
	getBookingConfig: (keyword: string, page: number, limit = 10, hospital: string | '', is_active: boolean | '', type: string | ''): Endpoint => ({
		path: `booking-configs?keyword=${ keyword }&page=${ page }&limit=${ limit }&order=id&sort=desc&hospital_code=${ hospital }&is_active=${ is_active }&type=${ type }&language=idn`,
		method: 'GET'
	}),
	getBookingConfigDetail: (id: string): Endpoint => ({
		path: `booking-configs/${ id }?language=idn`,
		method: 'GET'
	}),
	deleteBookingConfig: (id: string): Endpoint => ({
		path: `booking-configs/${ id }`,
		method: 'DELETE'
	}),
	updateBookingConfig: (id: string): Endpoint => ({
		path: `booking-configs/${ id }`,
		method: 'PUT'
	}),

	getAllSpecialties: (keyword: string, page: number, limit = 10): Endpoint => ({
		path: `/specialities?keyword=${ keyword }&page=${ page }&limit=${ limit }`,
		method: 'GET'
	}),

	addGroupSpecialties: (id: string): Endpoint => ({
		path: `/specialities/${ id }`,
		method: 'PUT'
	}),

	getDetailGroupSpecialties: (id: string): Endpoint => ({
		path: `/specialities/${ id }`,
		method: 'GET'
	}),

	deleteGroupSpecialties: (id: string): Endpoint => ({
		path: `/specialities/${ id }`,
		method: 'DELETE'
	}),
};
