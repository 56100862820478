import React from 'react';
import {
	Button,
	Cell,
	Container,
	CustomTable,
	HeaderTitle,
	Pagination,
	Select,
	Text,
	Spinner,
	Input
} from 'components';
import { useHistory } from 'react-router-dom';
import { hooks } from 'helpers';
import AdminModals from './AdminModals';
import { selectors } from 'store/selectors';
import dayjs from 'dayjs';
import { renderLongName } from 'helpers/misc';
import { Pagination as Paginations, ScreenType } from 'interfaces/common';
import { BirthDateType, CellType } from 'interfaces/misc';

type AdminViewProps = {
	onChangeSearch?: any;
	thTitles: string[];
	items: any;
	selectedItem?: any;
	setSelectedItem?: any;
	setShowModalDelete?: any;
	showModalDelete?: any;
	createLink?: string;
	createText?: string;
	title: string;
	editLink: string;
	viewLink: string;
	type: ScreenType;
	name?: string;
	pagination?: Paginations;
	onClickPagination?: (action: 'next' | 'prev', search?: string) => void;
	onDeleteModalClick?: any;
	enterKey?: boolean;
	cellType: CellType;
	search?: string,
	setSearch?: React.Dispatch<React.SetStateAction<string>>;
	hideLastRow?: boolean;
	userRole?: string;
	withFilter?: boolean;
	filter?: any;
	filterType?: any;
	selectedTypes?: any;
	setSelectedTypes?: React.Dispatch<React.SetStateAction<any>>;
	selectedRooms?: any;
	setSelectedFilterPinned?: React.Dispatch<React.SetStateAction<any>>;
	selectedFilterPinned?: any;
	setSelectedRooms?: React.Dispatch<React.SetStateAction<any>>;
	selectedStatus?: any;
	setSelectedStatus?: React.Dispatch<React.SetStateAction<any>>;
	openEllipsis?: number | null;
	setOpenEllipsis?: React.Dispatch<React.SetStateAction<number | null>>;
	openAddModal?: boolean;
	setOpenAddModal?: React.Dispatch<React.SetStateAction<boolean>>;
	openActiveModal?: boolean;
	setOpenActiveModal?: React.Dispatch<React.SetStateAction<boolean>>;
	onActiveModalClick?: any;
	getData?: any;
	onEditCatalog?: any;
	setAddModal?: any;
	status?: any;
	isRowLink?: boolean;
	detailLink?: string;
	isEditOrder?: boolean;
	setIsEditOrder?: React.Dispatch<React.SetStateAction<boolean>>;
	birthDate?: BirthDateType;
	setBirthDate?: React.Dispatch<React.SetStateAction<BirthDateType>>;
	selectedHospital?: any;
	setSelectedHospital?: React.Dispatch<React.SetStateAction<any>>;
	hospitalData?: any;
	onClickSyncDoctor?: any;
	isBookingGlobal?: boolean;
	onClickUnBlock?: any;
};

const AdminView: React.FC<AdminViewProps> = ({
	onChangeSearch,
	thTitles,
	items,
	selectedItem,
	setSelectedItem,
	setShowModalDelete,
	showModalDelete,
	createLink,
	createText = 'Create',
	title,
	editLink,
	viewLink,
	type,
	name = 'Admin View',
	pagination,
	onClickPagination,
	onDeleteModalClick,
	cellType,
	search,
	setSearch,
	hideLastRow = false,
	withFilter,
	filter,
	filterType,
	selectedTypes,
	setSelectedTypes,
	selectedStatus,
	setSelectedStatus,
	getData,
	isRowLink,
	detailLink,
	birthDate = {
		start: '',
		end: ''
	},
	setBirthDate,
	selectedHospital,
	setSelectedHospital,
	hospitalData,
	onClickSyncDoctor,
	isBookingGlobal,
	onClickUnBlock,
	setSelectedFilterPinned,
	selectedFilterPinned,
}) => {
	const history = useHistory();
	const dispatch = hooks.useAppDispatch();
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const handleRouter = id => {
		history.push(`${ detailLink }/${ id }`);
	};
	const clearInput = () => {
		setSearch && setSearch('');
		const doc = document?.getElementById('users-search') as any;
		doc.value = '';
	};
	const today = dayjs().format('YYYY-MM-DD');

	return (
		<Container header={ <HeaderTitle back={ false } title={ `${ title }` } /> }>
			<div className='flex my-6 font-sans items-center justify-between'>
				{ withFilter ? <>
					<div className='flex items-center'>
						<Select list={ filter } state={ selectedStatus } setState={ setSelectedStatus } keyObj='name' placeholder='Filter by Status' minWidth='150px' wrapperClassName='mr-4' isFullWidth={ false } onChange={ e => {
							clearInput();
							if (type === 'booking_doctor') {
								dispatch(getData('', 1, 10, selectedHospital?.hospital_code === undefined ? '' : selectedHospital?.hospital_code, e?.value, isBookingGlobal ? 'global' : 'doctor'));
							} else
								dispatch(getData(
									'',
									1,
									e?.value,
									type === 'doctors' ? selectedFilterPinned?.value :
										type === 'facilities' ||
											type === 'banners' ||
											type === 'footer' ||
											type === 'awards' ? '' :
											type === 'events' ||
												type === 'articles' ?
												selectedTypes?.value :
												type === 'patients' ?
													birthDate.start :
													null,
									type === 'patients' ?
										birthDate.end : type === 'events' ?
											selectedHospital?.id || '' :
											null,));
						} } />
						{ type === 'events' || type === 'articles' ?
							<Select
								list={ filterType }
								state={ selectedTypes }
								setState={ setSelectedTypes }
								keyObj='name'
								placeholder='Filter by Type'
								minWidth='150px'
								wrapperClassName='mr-4'
								isFullWidth={ false }
								onChange={ e => {
									clearInput();
									dispatch(getData('', 1, selectedStatus.value, e.value, type === 'events' ? selectedHospital?.id : ''));
								} } /> :
							null
						}
						{ type === 'doctors' ?
							<Select list={ filter }
								state={ selectedFilterPinned }
								setState={ setSelectedFilterPinned }
								keyObj='name'
								placeholder='All'
								minWidth='150px'
								wrapperClassName='mr-4'
								isFullWidth={ false }
								onChange={ e => {
									clearInput();
									dispatch(getData(
										'',
										1,
										selectedTypes?.value ?? '',
										e?.value ?? ''));
								} } /> :
							null
						}
						{ type === 'events' || type === 'booking_doctor' ?
							<Select list={ hospitalData }
								state={ selectedHospital }
								setState={ setSelectedHospital }
								keyObj='name'
								placeholder='All'
								minWidth='150px'
								wrapperClassName='mr-4'
								isFullWidth={ false }
								onChange={ e => {
									clearInput();
									if (type === 'events') {
										dispatch(getData('', 1, selectedStatus.value, e.value, selectedHospital?.id));
									} else {
										dispatch(getData('', 1, 10, e?.hospital_code, selectedStatus?.value === undefined ? '' : selectedStatus?.value, isBookingGlobal ? 'global' : 'doctor'));
									}
								} } /> :
							null
						}
						<div className='border-l-2 ml-6 pl-6'>
							<Text
								color='text-red-500'
								className='items-center cursor-pointer '
								onClick={ () => {
									setSelectedStatus && setSelectedStatus({
										id: 0,
										name: 'All',
										value: ''
									});
									setSelectedTypes && setSelectedTypes({
										id: 0,
										name: 'All',
										value: ''
									});
									setSelectedFilterPinned && setSelectedFilterPinned({
										id: 0,
										name: 'All',
										value: ''
									});
									setSelectedHospital && setSelectedHospital({
										id: '',
										name: 'All'
									});
									setBirthDate && setBirthDate({
										start: '',
										end: ''
									});
									clearInput();
									if (type === 'booking_doctor')
										dispatch(getData('', 1, '', '', '', isBookingGlobal ? 'global' : 'doctor'));
									else
										dispatch(getData('', 1, '', '', type === 'events' ? selectedHospital?.id || '' : ''));
								}
								}
							>
								Clear All
							</Text>
						</div>
						{/* { type === 'doctors' ? <div className='flex divide-x divide-gray-300 item-center divide-solid ml-6'>
							<div className='cursor-pointer'>
								<Button text={ 'Sync Data Doctor' } onClick={ onClickSyncDoctor }
									loading={ isLoading } />
							</div>
						</div> : null } */}
						{ type === 'patients' ? <div className='flex ml-8'>
							<Input
								type='date'
								containerClassName='mr-4'
								onChange={ e => {
									setBirthDate && setBirthDate({
										...birthDate,
										start: e.target.value
									});
									dispatch(getData('', 1, selectedStatus?.value, e.target.value, birthDate.end));
								} }
								max={ today }
								value={ birthDate.start }
							/>
							<Input
								type='date'
								onChange={ e => {
									setBirthDate && setBirthDate({
										...birthDate,
										end: e.target.value
									});
									dispatch(getData('', 1, selectedStatus?.value, birthDate.start, e.target.value));
								} }
								max={ today }
								value={ birthDate.end }
							/>
						</div> : null }
					</div>
				</> : null }
				{ withFilter ? <>
					<div className={ `flex items-center ${ type === 'patients' ? '' : 'search-katalog' }` }>
						<div className='relative w-full'>
							<input
								type='text'
								name='title'
								id='users-search'
								className={ `block px-3 py-2 text-gray-900 bg-white border rounded-lg sm:text-sm ${ type === 'patients' ? '' : 'w-full' }` }
								placeholder='Search'
								onChange={ e => {
									onChangeSearch(e);
								} }
							/>
						</div>
						{ createLink ? <div className='flex divide-x divide-gray-300 item-center divide-solid ml-6'>
							<div className='cursor-pointer'>
								<Button text={ createText } onClick={ () => {
									history.push(`${ createLink }`);
								} }
									loading={ isLoading } />
							</div>
						</div> : null }
						{ type === 'booking_doctor' ?
							<div className='flex divide-x divide-gray-300 item-center divide-solid ml-6'>
								{
									isBookingGlobal ?
										<div className='cursor-pointer'>
											<Button text={ 'Global Settings' } onClick={ () => {
												history.push('/global-settings');
											} }
												loading={ isLoading } />
										</div> :
										<div className='cursor-pointer'>
											<Button text={ 'Doctor Settings' } onClick={ () => {
												history.push('/doctor-settings');
											} }
												loading={ isLoading } />
										</div>
								}
							</div> : null }
					</div>

				</> : <><div className='flex divide-x divide-gray-300 item-center divide-solid'>
					{ createLink ? <div className='pr-6 cursor-pointer'>
						<Button text={ createText } onClick={ () => history.push(`${ createLink }`) } loading={ isLoading } />
					</div> : null }
				</div>
					<div className='flex ml-auto item-center'>
						<div className='relative lg:w-64 xl:w-96'>
							<input
								type='text'
								name='title'
								id='users-search'
								className='block w-full px-3 py-2 text-gray-900 bg-white border rounded-lg sm:text-sm'
								placeholder='Search'
								onChange={ e => {
									onChangeSearch(e);
								} }
							/>
						</div>
					</div> </> }
			</div>
			{ isLoading ? <div className='flex justify-center mt-10'><Spinner height='w-10' width='w-10' /></div> : <div className='flex flex-col mt-8'>
				<div className='overflow-x-auto rounded-lg'>
					<div className='inline-block min-w-full align-middle'>
						<div className='overflow-hidden border border-gray-200 border-solid shadow sm:rounded-lg drop-shadow shadow-gray-200'>
							<CustomTable thTitles={ thTitles } noSpace={ true } hideLastRow={ hideLastRow }>
								{ items?.length ? items?.map((data: Record<string, any>, index: number) => {
									const showText = {
										admins: data.email,
										patients: data.email,
										events: data.category,
										hospital: data.phone,
										doctors: type === 'clinics' ? data.doctors : data.hospital,
										clinics: renderLongName(data?.doctors?.map(item => item?.fullname_doctor).join(','), 50),
									};

									return (
										<tr className={ `align-middle border border-gray-200 border-solid ${ isRowLink ? 'cursor-pointer' : 'cursor-default' }` } key={ index } >
											{ type !== 'list_specialties' ?
												<Cell cellType='text' text={ data.id || data.doctor_code } className='font-medium text-black' onClick={ () => isRowLink ? handleRouter(data?.id) : null } /> : <></> }
											{ type === 'list_specialties' ?
												<Cell
													cellType='text'
													text={ data?.speciality_category ?? '-' }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'list_specialties' ?
												<Cell
													cellType='text'
													text={ data?.specialty?.length ?? 0 }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'booking_doctor' ?
												<Cell
													cellType='text'
													text={ data?.hospital?.hospital_name }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'booking_doctor' ?
												<Cell
													cellType='text'
													text={ data?.doctor?.doctor_code }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'booking_doctor' ?
												<Cell
													cellType='text'
													text={ data?.doctor?.fullname_doctor }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'booking_doctor' ?
												<Cell
													cellType='text'
													text={ data?.interval_time }
													className='font-medium text-gray-500'
												/> : <></> }
											{ type === 'booking_doctor' ?
												<Cell
													cellType='text'
													text={ data?.blacklist_duration }
													className='font-medium text-gray-500'
												/> : <></> }
											<Cell
												cellType='text'
												text={ renderLongName(type === 'contact_us' ? data.full_name : data.seo_h1 || data.title || data.name || data.fullname_doctor, type === 'facilities' || type === 'doctors' ? 100 : 35) }
												className='font-medium text-gray-500'
												onClick={ () => isRowLink ? handleRouter(data?.id) : null }
											/>
											{ type === 'doctors' ?
												<Cell
													cellType='text'
													text={ data?.locations != null ? renderLongName(data?.locations[0]?.hospital_name + '...', 100) : '' }
													className='font-medium text-gray-500'
													onClick={ () => isRowLink ? handleRouter(data?.id) : null }
												/> : <></> }
											{ type === 'doctors' ?
												<Cell cellType='text'
													text={ data?.specialty }
													className='font-medium'
												/>
												: null }
											{
												type === 'clinics' ?
													<Cell cellType='text'
														text={ data?.doctors !== null ? data?.doctors?.[0]?.doctor_name ?? '' : '-' }
														className='font-medium'
													/>
													: null }
											{
												type !== 'footer' && type !== 'contact_us' && type !== 'clinics' && type !== 'medical-specialities' ?
													<Cell
														cellType='text'
														text={
															[
																'banners',
																'awards',
																'facilities',
															].indexOf(type) >= 0 ?
																renderLongName(type === 'facilities' ? data.short_description : data.description, type === 'facilities' ? 25 : 50) :
																showText[type] ?? data?.category
														}
														className={ `font-medium ${ type !== 'admins' ? 'capitalize' : '' }` }
														onClick={ () => isRowLink ? handleRouter(data?.id) : null }
													/> : <></>
											}
											{
												[
													'events',
													'articles',
													'patients'
												].indexOf(type) >= 0 ?
													<Cell cellType='text'
														text={ type === 'events' ?
															renderLongName(data?.hospitals?.map(item => item?.hospital_name).join(', '), 50) :
															type === 'patients' ? data.gender :
																dayjs(data.posted_date).format('YYYY-MM-DD') }
														className='font-medium'
														onClick={ () => isRowLink ? handleRouter(data?.id) : null }
													/> : null }
											{ ['articles',].indexOf(type) >= 0 ?
												<Cell cellType='text'
													text={ renderLongName(data?.news_author?.doctor_name || data?.author, 25) }
													className='font-medium'
													onClick={ () => isRowLink ? handleRouter(data?.id) : null }
												/> : null }
											{ type === 'admins' || type === 'contact_us' ?
												<Cell
													cellType='text'
													text={ data.phone }
													className='font-medium'
													onClick={ () => isRowLink ? handleRouter(data?.id) : null }
												/> :
												null }
											{ type === 'awards' ?
												<Cell
													cellType='image'
													image={ data.img_url }
													imageAlt={ `image ${ index }` }
												/>
												: null }
											{ type === 'banners' ?
												<Cell
													cellType='image'
													image={ data.img_url_idn }
													imageAlt={ `image ${ index }` }
												/>
												: null }
											{/* { type === 'footer' ?
												<Cell
													cellType='text'
													text={ data.author }
													className='font-medium'
												/> :
												null } */}
											{ type === 'contact_us' ?
												<Cell
													cellType='text'
													text={ data.email }
													className='font-medium'
												/> :
												null }
											{ type === 'contact_us' ?
												<Cell
													cellType='text'
													text={ data.title }
													className='font-medium'
												/> :
												null }
											{
												data.language && <Cell
													cellType='text'
													text={ data.language === 'en' ? 'English' : 'Indonesia' }
													className={ 'font-medium rounded-xl py-1 text-center bg-slate-200' }

												/>
											}
											{
												type === 'banners' && <Cell
													cellType='text'
													text={ data.img_url_en.length ? 'Indonesia, English' : 'Indonesia' }
													className={ 'font-medium rounded-xl py-1 text-center bg-slate-200' }

												/>
											}
											{ type === 'admins' ||
												type === 'hospital' ||
												type === 'clinics' ||
												type === 'patients' ||
												type === 'articles' ||
												type === 'facilities' ||
												type === 'banners' ||
												type === 'events' ||
												type === 'footer' ||
												type === 'medical-specialities' ||
												type === 'awards'
												?
												< Cell cellType='text'
													text={ type === 'admins' ||
														type === 'hospital' ?
														data?.is_active ? 'Active' : 'Inactive' :
														type === 'patients' ?
															data?.is_verified ? 'Verified' : 'Unverified' :
															type === 'footer' ?
																data?.is_publish ? 'Published' : 'Unpublished' :
																data?.is_publish ? 'Published' : 'Unpublished' }
													className={ `rounded-xl py-1 text-center ${ data?.is_publish || data?.is_active || data?.is_verified ? 'text-green-500 bg-[#D1FAE5]' : 'text-[#991B1B] bg-[#FEE2E2]' } ` }
												/> : null }
											{ type === 'hospital' ?
												<Cell cellType='text'
													text={ renderLongName(data.address, 35) }
													className='font-medium'
												/>
												: null }
											{ type === 'patients' ?
												<Cell cellType='text'
													text={ data.is_blacklist ? 'UNBLACKLIST' : '' }
													className='inline-block text-sm font-bold no-underline cursor-pointer mr-3 text-rose-700'
													onClick={ () => onClickUnBlock(data?.id) }
												/>
												: null }
											{
												<Cell
													cellType={ cellType }
													type={ cellType === 'delete' ? 'p' : 'a' }
													text={ cellType === 'delete' ? 'Delete' : 'Edit' }
													secondText='Delete'
													className='inline-block text-sm font-bold no-underline cursor-pointer mr-3'
													href={ cellType === 'delete' ? '#' :
														type == 'booking_doctor' ?
															data.type == 'doctor' ? `/doctor-settings/edit/${ data?.id }` : `/global-settings/edit/${ data?.id }` :
															`${ editLink }/${ type == 'doctors' ? data?.doctor_code : type == 'list_specialties' ? data?.speciality_category : type == 'events' || type === 'hospital' || type == 'clinics' || type == 'facilities' || type === 'medical-specialities' || type === 'footer' || type == 'articles' ? data?.slug + '/' + data?.language : type == 'awards' ? data?.id + '/' + data?.language : data?.id !== null ? data.id : '' }` }
													secondHref='#'
													textColor='text-green-500'
													tdClassName={ `p-2 ${ 'w-10' }` }
													onClick={ () => {
														window.scrollTo({
															top: 0,
															behavior: 'smooth'
														});
													} }
													secondOnClick={ () => {
														setShowModalDelete(true);
														if (type == 'list_specialties') {
															setSelectedItem({
																id: data?.speciality_category,
																name: data?.speciality_category
															});
														} else {
															setSelectedItem({
																id: data?.id,
																name: data?.title || data?.name
															});
														}

													} }
													viewLink={ type == 'booking_doctor' ?
														data.type == 'doctor' ? `/doctor-settings/view/${ data?.id }` : `/global-settings/view/${ data?.id }` :
														`${ viewLink }/${ type == 'doctors' ? data?.doctor_code : type == 'list_specialties' ? data?.speciality_category : type == 'events' || type === 'hospital' || type == 'clinics' || type == 'facilities' || type === 'medical-specialities' || type === 'footer' || type == 'articles' ? data?.slug + '/' + data?.language : type == 'awards' ? data?.id + '/' + data?.language : data?.id !== null ? data.id : '' }`
													}
												/>
											}
											{ /* { type === 'catalog' ?
												<Cell cellType='ellipsis'
													tdClassName='p-2'
													openEllipsis={ openEllipsis }
													setOpenEllipsis={ setOpenEllipsis }
													indexEllipsis={ index }
													ellipsisClick={ () => setSelectedItem({
														id: user?.id,
														name: user?.looks_name
													}) }
													onClick={ () => {
														onEditCatalog();
														setAddModal(true);
													} }
													secondOnClick={ () => {
														setOpenActiveModal && setOpenActiveModal(!openActiveModal);
													} }
													isActive={ user?.status === 'ACTIVE' }
													setIsEditOrder={ setIsEditOrder }
													isEditOrder={ isEditOrder }
												/> : null } */ }
										</tr>
									);
								}) :
									thTitles.map((item, index) => {
										return (
											<Cell
												key={ index }
												cellType='text'
												text=''
												className='font-medium text-gray-500'
											/>
										);
									})
								}
								{ items?.length === 0 &&
									<Cell
										cellType='text'
										text=''
										className='font-medium text-gray-500'
									/>
								}
							</CustomTable>
							<Pagination name={ name } pagination={ pagination as Paginations } onPressPagination={ onClickPagination } search={ search } />
						</div>
					</div>
				</div>
			</div> }
			{ showModalDelete ? <AdminModals showOpen={ showModalDelete } setShowOpen={ setShowModalDelete } type={ type } message={ 'If you delete the package, it will vanish from the package catalog. But you can add this package again at any time by filling the package form' } isDelete={ true } selectedItem={ selectedItem } setSelectedItem={ setSelectedItem } onClickModalDelete={ onDeleteModalClick } /> : null }
		</Container >
	);
};

export default AdminView;
