import React, { ReactNode } from 'react';
import { styleHelper } from 'helpers';
type CardProps = {
	className?: string;
	isPaid?: boolean;
	dataId?: string;
	children: ReactNode;
};

const Card: React.FC<CardProps> = ({
	children, className, isPaid = false, dataId = ''
}) => {
	const textClassName = styleHelper.classNames(`bg-white rounded-2lg drop-shadow ${ isPaid ? '' : 'p-7' }`, className);

	return <div className={ textClassName } data-id={ dataId }>{ children }</div>;
};

export default Card;
