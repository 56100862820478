import { Pagination } from 'interfaces/common';
import {
  GET_ALL_HOSPITALS, HospitalsData, GET_HOSPITAL_DETAILS, HospitalActionTypes, GET_ALL_HOSPITALS_SLUG
} from './actionType';

interface HospitalState {
  data: HospitalsData[];
  pagination: Pagination;
  details: any;
	slug_data: HospitalsData[];
}

const initState: HospitalState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  details: {},
	slug_data: [],
};

const HospitalsReducer = (state = initState, action: HospitalActionTypes) => {
  switch (action.type) {
    case GET_ALL_HOSPITALS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_HOSPITAL_DETAILS:
      return {
        ...state,
        details: action.details
      };
		case GET_ALL_HOSPITALS_SLUG:
			return {
				...state,
				slug_data: action.data
			};
    default:
      return state;
  }
};

export default HospitalsReducer;