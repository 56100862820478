import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { hooks, styleHelper } from 'helpers';
import { XMarkIcon } from '@heroicons/react/24/solid';

export type ModalProps = {
	open: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	padding?: string;
	title?: React.ReactNode;
	footer?: React.ReactNode;
	width?: string;
	backgroundOverlay?: boolean;
	closeButton?: boolean;
	onCloseButton?: () => void;
	children?: React.ReactNode;
	titleClassName?: string;
};

const BasicModal: React.FC<ModalProps> = ({
	open,
	setOpen,
	padding = 'p-6',
	title,
	width = 'max-w-sm sm:max-w-lg md:max-w-xl lg:max-w-3xl w-full',
	backgroundOverlay = true,
	footer,
	closeButton = true,
	onCloseButton,
	children,
	titleClassName = '',
}) => {
	const completeButtonRef = useRef(null);
	const onCloseModal = (open: boolean) => {
		setOpen && setOpen(open);
		onCloseButton && onCloseButton();
	};

	return (
		<Transition.Root show={ open } as={ Fragment }>
			<Dialog as='div' initialFocus={ completeButtonRef } className='fixed z-[60] inset-0 overflow-y-auto' onClose={ onCloseModal }>
				<div
					className={ styleHelper.classNames('flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0') }
				>
					<Transition.Child
						as={ Fragment }
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay
							className={ styleHelper.classNames(
								'fixed inset-0',
								backgroundOverlay ? ' bg-hydeBlack-900 bg-opacity-60 transition-opacity' : 'bg-transparent'
							) }
						/>
					</Transition.Child>

					{ /* This element is to trick the browser into centering the modal contents. */ }
					<span ref={ completeButtonRef } className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
						&#8203;
					</span>

					<Transition.Child
						as={ Fragment }
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					>
						<div
							className={ styleHelper.classNames(
								'inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle',
								width,
								padding
							) }
						>
							<div className='flex'>
								<div className={ styleHelper.classNames('flex items-start flex-1 ' + titleClassName) }>
									{ title ? <Dialog.Title as='div'>{ title }</Dialog.Title> : null }
								</div>
								<div>
									{ closeButton ? (
										<button
											type='button'
											className='text-gray-400 bg-transparent rounded-md outline-none cursor-pointer'
											onClick={ () => {
												onCloseModal(false);
												onCloseButton ? onCloseButton() : null;
											} }
										>
											<span className='sr-only'>Close</span>
											<XMarkIcon className='w-5 h-5' aria-hidden='true' />
										</button>
									) : null }
								</div>
							</div>
							<div className='items-start text-left'>{ children }</div>

							{ footer ? <div className={ padding }>{ footer }</div> : null }
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default { BasicModal };
