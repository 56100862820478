import { hooks, navigation } from 'helpers';
import {
	ChangeEvent, useEffect, useState
} from 'react';
import { misc } from 'constant';

import { getMedicalSpecialitiesPages, stopLoading } from 'store/actions';
import { selectors } from 'store/selectors';
import {
	addMedicalSpecialities, checkMedicalSpecialitiesSlug, deleteMedicalSpecialities, editMedicalSpecialities
} from 'store/MedicalSpecialities/action';
import { FormArrayType, ParamsType } from 'interfaces/common';
import { useParams } from 'react-router-dom';
import { generalUpload } from 'store/Common/action';
import { createSeoRobotString } from 'helpers/misc';

type MedicalSpecialitiesFields = {
	title: string,
	icon: string,
	slug: string,
	seo_h1: string,
};

const useMedicalSpecialities = () => {

	const { id, detailLang } = useParams<ParamsType>();

	const dispatch = hooks.useAppDispatch();
	const [form, setForm] = useState<MedicalSpecialitiesFields>({
		icon: '',
		title: '',
		slug: '',
		seo_h1: '',
	});

	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [htmlContent, setHtmlContent] = useState<string>('');
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [editor, setEditor] = useState({ editorHtml: '' });
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [uploaded, setUploaded] = hooks.useStateCallback([]);
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});
	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});

	const {
		data, details, pagination, slug_data
	} = hooks.useAppSelector(selectors.medicalSpecialities);
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const isEdit = location.pathname.includes('edit');

	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')
.toLowerCase(),
		});
		await dispatch(checkMedicalSpecialitiesSlug({
			limit: 10,
			slug: form.slug,
			language: selectedLanguage.value
			}));
	};

	useEffect(() => {
		if ((slug_data !== null || slug_data !== undefined) && slug_data?.length > 0) {
			setIsDuplicateSlug(true);
		} else {
			setIsDuplicateSlug(false);
		}
	}, [slug_data]);

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'title',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Slug Article',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		{
			index: 3,
			label: 'Upload Image',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: previewImage.length >= 100,
			renderType: 'file',
			inputType: 'file',
			maxImage: 100,
			imageType: ''
		},
		{
			index: 4,
			label: 'Content',
			name: 'editor',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'content'
		},
		{
			index: 5,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 6,
			label: 'Parent Medical Specialities',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Clinic'
		},
		{
			index: 7,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 8,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	useEffect(() => {
		getMedicalSpecialities();
		// dispatch(checkMedicalSpecialitiesSlug({ limit: 10, slug: form.slug }));
		
	}, []);
	
	const effectFunctions = () => {
		
		if (details && (id === details?.slug) && (detailLang === details?.language)) {
			
			setPreviewImage(details?.img_url?.length ? [...details.img_url] : []);

			setForm({
				...form,
				title: details.title,
				slug: details.slug,
				seo_h1: details.seo_h1
			});
			
			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
			setEditor({ editorHtml: details.content });
			
			setSelectedLanguage(
				details.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details.parent_detail) {
				setSelectedParent({
					id: details.parent_detail?.id,
					name: details.parent_detail?.title
				});
			}
			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getMedicalSpecialitiesPages({
			limit: 10,
			page: action === 'next' ? pagination.page + 1 : pagination.page - 1,
			keyword: search
		}));
	};

	const getMedicalSpecialities = () => {
		dispatch(getMedicalSpecialitiesPages({ limit: 10 }));
	};

	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};

	const onDeleteItem = () => {
		console.log('delete');
	};

	const onClickSubmit = async () => {
		
		const validateForm = () => {
			if (isEdit && details.img_url === null) {
				setErrorForm(prev => [...prev, 3]);
			}

			if ((isEdit && details.img_url === null) || (isEdit && previewImage.length === 0)) {
				setErrorForm(prev => [...prev, 3]);
			}
			
			if (!isEdit && !imagePayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 3]);
			}
			
			if (!form.title.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (editor.editorHtml.length <= 11 && !errorForm.length) {
				setErrorForm(prev => [...prev, 4]);
			}
			
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 5]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 5]);
			}
		};
		
		const noValidatie =
			form.title.length &&
			form.seo_h1.length &&
			(isEdit && details.img_url === null && !imagePayload.length ? false : isEdit && previewImage.length === 0 ? false : isEdit && details.img_url !== null ? true : isEdit && details.img_url === null && (imagePayload.length > 0) ? true : imagePayload.length) &&
			editor.editorHtml.length > 11 &&
			selectedStatus.name.length &&
			selectedLanguage.name.length &&
			(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) &&
			!isDuplicateSlug;

			if (noValidatie) {
				const uploadedImg: string[] = [];
				for (let i = 0; i < imagePayload.length; i++) {
					const newForm = new FormData();
					newForm.append('upload', imagePayload[i]);
					const uploadImg = await dispatch(generalUpload(newForm));
					if (uploadImg?.code === 200) {
						setUploaded(prev => [...prev, uploadImg.data]);
						uploadedImg.push(uploadImg.data);
						
						dispatch(stopLoading('upload end'));
					}
				}
				if (id) {
						// Menghapus Preview Image yang sudah terupload, disini posisinya previewIamge dimasukan, jaga2 jika ada data lama yang tidak diupdate tapi ditambahkan image baru biar tidak hilang, tapi jika datanya 1 trus di replace baru, maka path blobnya akan ikut, makanya perlu di remove
						const indexToRemove = previewImage.findIndex(item => item.includes('blob'));
						if (indexToRemove !== -1) {
							previewImage.splice(indexToRemove, 1);
						}
						const editUpload = [...previewImage, ...uploadedImg];
						
						dispatch(editMedicalSpecialities(details.id, {
							author: 'Admin',
							content: editor.editorHtml,
							img_url: editUpload,
							footer_category: 'medical-specialities',
							is_publish: selectedStatus.value,
							sub_title: '',
							title: form.title,
							slug: form.slug,
							language: selectedLanguage.value,
							parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
							seo_h1: form.seo_h1,
							seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
						}, () => {
							navigation.goBack();
						}));
				} else {
					dispatch(addMedicalSpecialities({
						author: 'Admin',
						content: editor.editorHtml,
						img_url: uploadedImg,
						footer_category: 'medical-specialities',
						is_publish: selectedStatus.value,
						sub_title: '',
						title: form.title,
						slug: form.slug,
						language: selectedLanguage.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => {
						navigation.goBack();
					}));
				}
			} else {
				validateForm();
			}

	};

	const onDelete = (id: string) => {
		dispatch(deleteMedicalSpecialities(id));
	};

	const onCancel = () => {
		navigation.goBack();
	};

	const onSearch = (evt: ChangeEvent<HTMLInputElement>) => {
		dispatch(getMedicalSpecialitiesPages({
 limit: 1000,
keyword: evt.target.value
}));
	};

	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};

	return {
		medicalSpecialities: data,
		selectedItem,
		setSelectedItem,
		paginationFunction,
		pagination,
		htmlContent,
		openModalDelete,
		setOpenModalDelete,
		onDeleteItem,
		getMedicalSpecialities,
		onClickSubmit,
		onCancel,
		setForm,
		setHtmlContent,
		onDelete,
		form,
		onSearch,
		isDuplicateSlug,
		checkSlugDuplicate,
		formArray,
		editor,
		setEditor,
		onChangeForm,
		setIsDuplicateSlug,
		errorForm,
		setErrorForm,
		data,
		selectedLanguage,
		setSelectedLanguage,
		setImagePayload,
		imagePayload,
		previewImage,
		setPreviewImage,
		onChangeEditor,
		effectFunctions,
		setSelectedStatus,
		selectedStatus,
		selectedParent,
		setSelectedParent,
		details,
		isEdit,
		slug_data,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
};

export default useMedicalSpecialities;