import { useEffect } from 'react';

import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { getAllDoctors } from 'store/actions';

import useDoctors from './useDoctors';

const Doctors = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onSearchDoctor,
    formStatus,
    selectedStatus,
    setSelectedStatus,
		onClickSyncDoctor,
		setSelectedFilterPinned,
		selectedFilterPinned,
  } = useDoctors();

  useEffect(() => {
    dispatch(getAllDoctors('', 1, '', ''));
  }, []);

  return (
    <AdminView
      cellType='edit'
      createText='Create Banner'
      thTitles={ thTitles }
      editLink='doctors/edit'
      viewLink='doctors/view'
      items={ data }
      title='Doctors'
      type='doctors'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
			selectedFilterPinned={selectedFilterPinned}
			setSelectedFilterPinned={ setSelectedFilterPinned}
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onChangeSearch={ onSearchDoctor }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllDoctors }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
			onClickSyncDoctor={ onClickSyncDoctor }
    />
  );
};

export default Doctors;