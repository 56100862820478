import useDoctors from '../useDoctors';
import { getDoctorDetails } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';

const DoctorForm = () => {
  const { formArray } = useDoctors();

  return (
    <AdminForm
      backLink='/doctors'
      formArray={ formArray }
      getDetailAction={ getDoctorDetails }
      isAbleToSubmit={ true }
      localHooks={ useDoctors }
      title='Doctor Profile'
      type='doctors'
    />
  );
};

export default DoctorForm;