import React, {
	useState, useEffect, useRef
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { hooks } from 'helpers';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { misc } from 'constant';
import {
	ParamsType, FormArrayType, FormFacilitiesType
} from 'interfaces/common';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
	deleteFacility, getAllFacilities, addFacility, editFacility, stopLoading, getAllHospitals
} from 'store/actions';
import {
	addRelatedNewsFacilities, deleteRelatedNewsFacilities, getRelatedNewsFacilities
} from 'store/NewsAndArticles/action';
import { getAllFacilitieSlug } from 'store/Facilities/action';

export default function useFacilities() {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const isEdit = location.pathname.includes('edit');
	const isFormView = location.pathname.includes('edit') || location.pathname.includes('post');
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'DESCRIPTION',
		'LANGUAGE',
		'STATUS'
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		name: '',
		short_description: '',
		image_url: '',
		order_id: '',
		slug: '',
		seo_h1: '',
	});
	const {
		data, details, pagination, slug_data
	} = hooks.useAppSelector(selectors.facilities);

	const { relatedNews: relatedNewsFacilities } = hooks.useAppSelector(selectors.news);

	const { data: hospitals } = hooks.useAppSelector(selectors.hospitals);
	const { data: news } = hooks.useAppSelector(selectors.news);
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [availableAt, setAvailableAt] = useState<{ id: number, name: string, value: string; }[]>([]);
	const [titleAvailableAt, setTitleAvailableAt] = useState('');
	const [availableAtLength, setAvailableAtLength] = useState(0);
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);

	const onAddOperationalHourHospital = (newTag, formIndex: number) => {
		const form = [...facilitesHospital];
		form[formIndex].operational_hour_tag = [...form[formIndex]?.operational_hour_tag ?? [], newTag];
		setFacilitiesHospital(form);
	};
	const onDeleteOperationalHourHospital = (tagIndex: number, formIndex: number) => {
		const form = [...facilitesHospital];
		form[formIndex].operational_hour_tag = form[formIndex].operational_hour_tag.filter((_, i) => i !== tagIndex);
		setFacilitiesHospital(form);
	};

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Publish',
			value: true
		}, {
			id: 2,
			name: 'Unpublish',
			value: false
		}
	];
	const HomeStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Home Page',
			value: true
		}, {
			id: 2,
			name: 'Non Home Page',
			value: false
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedHomeStatus, setSelectedHomeStatus] = useState({
		id: 0,
		name: '',
		value: false
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});

	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const defaultFormFacilitiesHospital: FormFacilitiesType = {
		hospital_code: '',
		name: 'Select Hospital',
		floor: '',
		information: '',
		operational_hour_tag: [],
		unit: '',
		operational_hour: [],
	};

	const [facilitesHospital, setFacilitiesHospital] = useState<FormFacilitiesType[]>([]);
	const [relatedNews, setRelatedNews] = useState<{ id: number, name: string; }[]>([]);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);
	const isFacilitesFilled = useRef(true);
	const onChangeSelectForm = (e: { hospital_code: string; name: string; }, index: string | number) => {
		const form = [...facilitesHospital];
		form[index].hospital_code = e?.hospital_code;
		form[index].name = e?.name;
		setFacilitiesHospital(form);
	};

	const onChangeFormFacilities = (e: { target: { name: string | number; value: any; }; }, index: string | number) => {
		const form = [...facilitesHospital];
		form[index][e.target.name] = e.target.value;
		setFacilitiesHospital(form);
	};

	const addFacilitiesHospitalForm = () => {
		setFacilitiesHospital([...facilitesHospital, defaultFormFacilitiesHospital]);
	};
	const removeFacilitiesHospitalForm = (index: number) => {
		const newItem = [...facilitesHospital];
		newItem.splice(index, 1);
		setFacilitiesHospital(newItem);
	};
	const addRelatedNews = () => {
		setRelatedNews([
			...relatedNews, {
				id: 0,
				name: ''
			}
		]);
	};
	const removeRelatedNews = (newsId: string, index: number) => {
		dispatch(deleteRelatedNewsFacilities(newsId, () => {
			if (details.id !== undefined) {
				dispatch(getRelatedNewsFacilities(details.id));
			}
			removeRelatedNewsFromState(index);
		}));
	};

	const removeRelatedNewsFromState = (index: number) => {
		const tempRelatedNews = relatedNews.filter(function (el) { return el.id != relatedNews[index].id; });
		setRelatedNews(tempRelatedNews);
	};

	const changeRelatedNews = (value: { id: number; title: string; }, index: string | number) => {
		const newItem = [...relatedNews];
		newItem[index].id = value?.id;
		newItem[index].name = value?.title;
		setRelatedNews(newItem);
	};
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const refreshData = () => {
		history.push('/facilities');
		dispatch(getAllFacilities('', 1, '', ''));
	};
	const onChangeEditor = (e: any) => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const [uploaded, setUploaded] = hooks.useStateCallback([]);

	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (!form.short_description.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 2]);
			}
			if (editor.editorHtml.length <= 11 && !errorForm.length) {
				setErrorForm(prev => [...prev, 4]);
			}
			if (!isEdit && !imagePayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 5]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!selectedHomeStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 9]);
			}
		};

		if (facilitesHospital?.length) {
			facilitesHospital.forEach(element => {
				if (element['hospital_code'] == undefined || element['hospital_code'] == '') {
					isFacilitesFilled.current = false;
					return;
				}
			});
		}

		if (isFacilitesFilled.current) {
			facilitesHospital?.forEach(element => {
				if (element.operational_hour_tag === undefined || element.operational_hour_tag === null)
					return false;
				element.operational_hour = [];
				element.operational_hour_tag?.forEach(values => {
					element.operational_hour = [...element.operational_hour, values.name];
				}, '');
			});
		}

		let tempAvailableAt: Array<any> = [];
		tempAvailableAt = [...tempAvailableAt, titleAvailableAt];
		availableAt?.map(obj => {
			tempAvailableAt = [...tempAvailableAt, obj.name + ' :+split+: ' + obj.value];
		});

		if (isEdit) {
			const validationEdit =
				form.short_description.length &&
				form.name.length &&
				editor.editorHtml.length > 11 &&
				selectedStatus.name.length &&
				selectedHomeStatus.name.length &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) &&
				isFacilitesFilled.current && !isDuplicateSlug;

			if (validationEdit) {
				if (imagePayload.length) {
					for (let i = 0; i < imagePayload.length; i++) {
						const newForm = new FormData();
						newForm.append('upload', imagePayload[i]);
						const uploadImg = await dispatch(generalUpload(newForm));
						if (uploadImg?.code === 200) {
							setUploaded((prev: any) => [...prev, uploadImg.data]);
							dispatch(stopLoading('upload end'));
						}
					}
				} else {
					await dispatch(editFacility(details.id, {
						name: form.name,
						slug: form.slug,
						short_description: form.short_description,
						image_url: details.image_url,
						is_publish: selectedStatus.value,
						is_home_page: selectedHomeStatus.value,
						information: editor.editorHtml,
						order_id: parseInt(form.order_id),
						facilities_hospitals: [...facilitesHospital],
						language: selectedLanguage?.value,
						available_at: tempAvailableAt,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => {
						const uniqueElement = relatedNews?.filter(fil => !relatedNewsFacilities?.some(({ id }) => fil?.id === id));
						if (uniqueElement.length == 0) {
							refreshData();
						} else {
							uniqueElement?.map(item => (
								dispatch(addRelatedNewsFacilities({
									news_id: item?.id,
									facility_id: details?.id
								}, () => {
									refreshData();
								}))
							));
						}
					}));
				}
			} else {
				validateForm();
			}
		} else {
			const validationPost =
				form.short_description.length &&
				form.name.length &&
				editor.editorHtml.length > 11 &&
				selectedStatus.name.length &&
				selectedHomeStatus.name.length &&
				imagePayload.length &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) &&
				isFacilitesFilled.current &&
				!isDuplicateSlug;
			if (validationPost) {
				for (let i = 0; i < imagePayload.length; i++) {
					const newForm = new FormData();
					newForm.append('upload', imagePayload[i]);
					const uploadImg = await dispatch(generalUpload(newForm));
					if (uploadImg?.code === 200) {
						setUploaded((prev: any) => [...prev, uploadImg.data]);
						dispatch(stopLoading('upload end'));
					}
				}
			} else {
				validateForm();
			}
		}
	};

	useEffect(() => {
		if (imagePayload.length === uploaded.length && imagePayload.length !== 0) {
			let tempAvailableAt: Array<any> = [];
			tempAvailableAt = [...tempAvailableAt, titleAvailableAt];
			availableAt?.map(obj => {
				tempAvailableAt = [...tempAvailableAt, obj.name + ' :+split+: ' + obj.value];
			});
			if (id) {
				const editUpload = [...previewImage.filter(item => item.includes(misc.UploadBaseUrl)), ...uploaded];
				dispatch(editFacility(details.id, {
					name: form.name,
					slug: form.slug,
					short_description: form.short_description,
					image_url: editUpload,
					is_publish: selectedStatus.value,
					is_home_page: selectedHomeStatus.value,
					information: editor.editorHtml,
					order_id: parseInt(form.order_id),
					available_at: tempAvailableAt,
					facilities_hospitals: [...facilitesHospital],
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => {
					const uniqueElement = relatedNews?.filter(fil => !relatedNewsFacilities?.some(({ id }) => fil?.id === id));
					if (uniqueElement?.length == 0) {
						refreshData();
					} else {
						uniqueElement?.map(item => (
							dispatch(addRelatedNewsFacilities({
								news_id: item?.id,
								facility_id: details?.id
							}, () => {
								refreshData();
							}))
						));
					}
				}));
			} else {
				dispatch(addFacility({
					name: form.name,
					slug: form.slug,
					short_description: form.short_description,
					image_url: uploaded,
					is_publish: selectedStatus.value,
					is_home_page: selectedHomeStatus.value,
					information: editor.editorHtml,
					order_id: parseInt(form.order_id),
					available_at: tempAvailableAt,
					facilities_hospitals: [...facilitesHospital],
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, (response: { id: any; }) => {
					const uniqueElement = relatedNews?.filter(fil => !relatedNewsFacilities?.some(({ id }) => fil?.id === id));
					if (uniqueElement?.length == 0) {
						refreshData();
					} else {
						uniqueElement?.map(item => (
							dispatch(addRelatedNewsFacilities({
								news_id: item?.id,
								facility_id: response?.id
							}, () => {
								refreshData();
							}))
						));
					}

				}));
			}

		}
	}, [imagePayload.length, uploaded.length]);

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllFacilities(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value, ''));
	};

	const onDeleteFacility = (id: string) => {
		dispatch(deleteFacility(id, () => {
			dispatch(getAllFacilities('', 1, data?.length === 1 ? '' : selectedStatus?.value, ''));
		}));
	};

	const onSearchFacility = debounce((e: { target: { value: string; }; }[]) => {
		dispatch(getAllFacilities(e[0].target.value, 1, selectedStatus?.value, ''));
	});

	const effectFunctions = () => {
		if (details && (id === details?.slug)) {
			setForm({
				...form,
				name: details.name,
				short_description: details.short_description,
				image_url: details.image_url,
				order_id: details.order_id,
				slug: details.slug,
				seo_h1: details?.seo_h1
			});
			setEditor({ editorHtml: details.information });
			setSelectedHomeStatus(details.is_home_page ? {
				id: 1,
				name: 'Home Page',
				value: true
			} : {
				id: 2,
				name: 'Non Home Page',
				value: false
			});
			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Publish',
					value: true
				} : {
					id: 2,
					name: 'Unpublish',
					value: false
				});
			details?.image_url?.length && setPreviewImage(details?.image_url || '[]');
			if (details?.facilities_hospitals?.length) {
				let tempArr: Array<any> = [];
				details?.facilities_hospitals[0]?.operational_hour?.map(element => {
					tempArr = [...tempArr, { name: element }];
				});

				setFacilitiesHospital(details?.facilities_hospitals?.map((item: { hospital_name: any; operational_hour: []; }) => ({
					...item,
					name: item?.hospital_name,
					operational_hour_tag: tempArr
				})));
			}
			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.name
				});
			}
			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'name',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'name',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Short Description',
			name: 'short_description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'short description ',
		},
		{
			index: 3,
			label: 'Order Id(Optional)',
			name: 'order_id',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'number',
			placeholder: 'order id ',
		},
		{
			index: 4,
			label: 'Content',
			name: 'editor',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			inputType: 'text',
			placeholder: 'editor ',
		},
		{
			index: 5,
			label: 'Upload Image',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: isLoading || previewImage.length >= 10000,
			renderType: 'file',
			inputType: 'file',
			maxImage: 10000,
			imageType: ''
		},
		{
			index: 6,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 7,
			label: 'Home Page Status',
			name: 'home page status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: HomeStatus,
			renderType: 'select',
			placeholder: 'Home Page Status ',
			selectType: 'homepage'
		},
		{
			index: 8,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 9,
			label: 'Parent Facilities',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Facilities'
		},
		{
			index: 10,
			label: 'Slug Article',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		{
			index: 11,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	useEffect(() => {
		if (isFormView)
			dispatch(getAllHospitals('', 1, true, 10, selectedLanguage.value));
	}, [selectedLanguage]);

	const addAvailableAt = () => {
		setAvailableAt([
			...availableAt, {
				id: availableAtLength,
				name: '',
				value: ''
			}
		]);
		setAvailableAtLength(availableAtLength + 1);
	};

	const removeFieldAvailableAtFromState = (id: number) => {
		const tempAvailableAt = availableAt.filter(function (el) { return el.id !== id; });
		setAvailableAt(tempAvailableAt);
	};

	const changeAvailableAt = (value: string, index: number, type: string) => {
		if (type === 'title_available_at') {
			setTitleAvailableAt(value);
			return;
		}
		const newItem = [...availableAt];
		newItem.map(obj => {
			if (obj.id === index) {
				type === 'title' ?
					obj.name = value : obj.value = value;
			}
		});
		setAvailableAt(newItem);
	};

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllFacilitieSlug('', 1, '', '', 1000, selectedLanguage.value, form.slug));
	};

	useEffect(() => {
		if ((slug_data !== null || slug_data !== undefined) && slug_data?.length > 0) {
			setIsDuplicateSlug(true);
		} else {
			setIsDuplicateSlug(false);
		}
	}, [slug_data]);

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onDeleteFacility,
		onSearchFacility,
		HomeStatus,
		selectedHomeStatus,
		setSelectedHomeStatus,
		onChangeEditor,
		editor,
		setEditor,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		facilitesHospital,
		hospitals,
		onChangeSelectForm,
		addFacilitiesHospitalForm,
		removeFacilitiesHospitalForm,
		onChangeFormFacilities,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		relatedNews,
		addRelatedNews,
		removeRelatedNews,
		changeRelatedNews,
		news,
		relatedNewsFacilities,
		setRelatedNews,
		isEdit,
		setFacilitiesHospital,
		isFacilitesFilled,
		removeRelatedNewsFromState,
		onAddOperationalHourHospital,
		onDeleteOperationalHourHospital,
		addAvailableAt,
		availableAt,
		titleAvailableAt,
		removeFieldAvailableAtFromState,
		changeAvailableAt,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		slug_data,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
}
