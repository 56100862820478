import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { FormLogin, AuthCredential } from 'interfaces/auth';
import {
  navigation, toastify, auth
} from 'helpers';
import { api } from 'utils';

import { LOGIN, LOGOUT } from './actionTypes';
import { loadingStart, loadingEnd } from '../Misc/action';

export const login = (saveToken: boolean, formLogin: FormLogin) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('login'));
      const { code, data }: ApiData<AuthCredential> = await api.request(Endpoints.login(), formLogin);
      if (code === 200) {
        await dispatch({
          type: LOGIN,
          data: {
						user: { username: data.name, role_name: data.role_name },
            saveToken: saveToken
          }
        });

        await auth.setAuthCredentials(data.token);

        navigation.push('/');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('login'));
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    await auth.removeAuthCredentials();

    dispatch({ type: LOGOUT });

    navigation.push('/');
  };
};

export const forgotPassword = (email: { email: string; }) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('forgot password begin'));
      const { code, stat_msg }: ApiData<any> = await api.request(Endpoints.forgotPassword(), email);
      if (code === 200) {
        toastify.success(stat_msg);
        navigation.push('/signin');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }

    dispatch(loadingEnd('forgot password end'));
  };
};

export const verifyToken = (token: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('verify token begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.verifyToken(token), { token: token });
      if (code === 200) {
        toastify.success('Token is Valid');
      }
      if (code === 400) {
        navigation.push('/signin');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }

    dispatch(loadingEnd('verify token end'));
  };
};

export const newPassword = (token: string, new_password: string, confirm_password: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('new password begin'));
      const { code }: ApiData<any> = await api.request(Endpoints.newPassword(token), {
        new_password: new_password,
        confirm_password: confirm_password
      });
      if (code === 200) {
        toastify.success('Success Change Password');
        navigation.push('/signin');
      }
      if (code === 400) {
        navigation.push('/signin');
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }

    dispatch(loadingEnd('new password end'));
  };
};
