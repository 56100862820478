import { Pagination } from 'interfaces/common';
import {
	GET_ALL_CONTACT_US, GET_DETAIL_CONTACT_US, ContactUsData, ContactUsActionTypes
} from './actionType';

interface ContactUsState {
	data: ContactUsData[];
	category: ContactUsData[];
  pagination: Pagination;
  details: any;
}

const initState: ContactUsState = {
  data: [],
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  details: {},
	category: [],
};

const FootersReducer = (state = initState, action: ContactUsActionTypes) => {
  switch (action.type) {
		case GET_ALL_CONTACT_US:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
		case GET_DETAIL_CONTACT_US:
			return {
				...state,
				details: action.details,
				pagination: action.pagination
			};
    default:
      return state;
  }
};

export default FootersReducer;