import { Pagination } from 'interfaces/common';
import {
  AdminsActionTypes, GET_ADMIN_DETAILS, GET_LIST_ADMIN
} from './actionType';

interface AdminState {
  data: any;
  details: any;
  pagination: Pagination;
}

const initState: AdminState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  }
};

const AdminsReducer = (state = initState, action: AdminsActionTypes) => {
  switch (action.type) {
    case GET_LIST_ADMIN:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_ADMIN_DETAILS:
      return {
        ...state,
        details: action.details
      };
    default:
      return state;
  }
};

export default AdminsReducer;