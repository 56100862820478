import { AdminLogin } from 'interfaces/auth';

import {
  LOGIN,
  LOGOUT,
  AuthActionTypes
} from './actionTypes';

interface AuthState {
  user: AdminLogin,
  saveToken: boolean;
}

const initState: AuthState = {
  user: { username: '', role_name: '' },
  saveToken: false
};

const userReducer = (state = initState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.data.user,
        saveToken: action.data.saveToken
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default userReducer;
