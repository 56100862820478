import React from 'react';
import { Link } from 'react-router-dom';
import { styleHelper } from 'helpers';

export type TextProps = {
	children?: React.ReactNode;
	text?: string;
	style?: React.CSSProperties;
	className?: string;
	size?: FontSize | string;
	weight?: FontWeight | string;
	spacing?: string;
	color?: string;
	lineHeight?: LineHeight;
	lineClamp?: LineClamp;
	whiteSpace?: WhiteSpace;
	align?: TextAlign | string;
	opacity?: Opacity;
	type?: 'h1' | 'h2' | 'h3' | 'p' | 'a' | 'span';
	underlineOnHover?: boolean;
	cursor?: string;
	href?: string;
	textRef?: React.RefObject<any>;
	onClick?: (e: React.MouseEvent) => void;
	element?: string;
	isDefault?: boolean,
	dataId?: string;
};

const Text: React.FC<TextProps> = ({
	children,
	text,
	style,
	className,
	size = 'text-base',
	weight = 'font-normal',
	align = 'text-left',
	opacity = 'opacity-100',
	spacing,
	color = 'text-hydeBlack-900',
	lineHeight,
	lineClamp = 'line-clamp-none',
	whiteSpace,
	type = 'p',
	cursor = 'cursor-default',
	href,
	underlineOnHover,
	textRef,
	onClick,
	element = '',
	isDefault = false,
	dataId = '',
	...restProps
}) => {
	const fontStyle = [
		size,
		weight,
		color,
		align,
		opacity,
		lineHeight
	];

	const cursorStyle = type === 'a' || underlineOnHover || !!onClick ? 'cursor-pointer' : cursor;

	const textClassName = styleHelper.classNames(
		'font-primary',
		...fontStyle,
		spacing,
		className,
		lineClamp,
		align,
		whiteSpace,
		cursorStyle,
		underlineOnHover ? 'hover:underline' : ''
	);

	const h1 = (
		<h1 data-id={ dataId } style={ style } className={ textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</h1>
	);
	const h2 = (
		<h2 data-id={ dataId } style={ style } className={ textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</h2>
	);
	const h3 = (
		<h3 data-id={ dataId } style={ style } className={ textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</h3>
	);
	const p = (
		<p data-id={ dataId } style={ style } className={ isDefault ? className : textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</p>
	);
	const a = (
		<Link to={ `${ href }` } style={ style } className={ textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</Link>
	);
	const span = (
		<span data-id={ dataId } style={ style } className={ textClassName } onClick={ onClick } ref={ textRef } { ...restProps }>
			{ children || text }
		</span>
	);

	const render = () => {
		const textType = element || type;

		switch (textType) {
			case 'h1':
				return h1;
			case 'h2':
				return h2;
			case 'h3':
				return h3;
			case 'a':
				return a;
			case 'span':
				return span;
			default:
				return p;
		}
	};

	return render();
};

export default Text;
