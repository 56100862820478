import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { hooks, toastify } from 'helpers';
import { selectors } from 'store/selectors';
import {
  addAdmin,
  deleteAdmin,
  editAdmin,
  getAllAdmins,
} from 'store/actions';
import { ParamsType, FormArrayType } from 'interfaces/common';
import { debounce } from 'helpers/misc';

export default function useAdmins () {
  const dispatch = hooks.useAppDispatch();
  const history = useHistory();
  const { id } = useParams<ParamsType>();
  const thTitles: string[] = [
    'ID',
    'NAME',
    'EMAIL',
    'PHONE',
    'STATUS',
  ];
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    name: ''
  });
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });
  const {
    data, details, pagination
  } = hooks.useAppSelector(selectors.admins);
  const { isLoading } = hooks.useAppSelector(selectors.loading);
  const { roles: rbac } = hooks.useAppSelector(selectors.RBAC);
  const formStatus: Record<string, any>[] = [
    {
      id: 1,
      name: 'Active',
      value: true
    }, {
      id: 2,
      name: 'Inactive',
      value: false
    }
  ];
  const [errorForm, setErrorForm] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    id: 0,
    name: 'All',
    value: ''
  });
  const [selectedRole, setSelectedRole] = useState<any>({
    id: 0,
    name: ''
  });
  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
    if (errorForm.length) {
      setErrorForm(errorForm.filter(item => item !== index));
    }
  };
  const refreshData = () => {
    history.push('/admins');
    dispatch(getAllAdmins('', 1, ''));
  };
  const onClickSubmit = async (isEdit: boolean) => {
    const validateForm = () => {
      if (!form.name.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 0]);
      }
      if (!form.email.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 1]);
      }
      if (!form.password.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 2]);
      }
      if (!form.phone.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 3]);
      }
      if (!selectedStatus.name.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 4]);
      }
      if (!selectedRole.name.length && !errorForm.length) {
        setErrorForm(prev => [...prev, 5]);
      }

    };
    const body = {
      name: form.name,
      email: form.email,
      password: form.password,
      phone: form.phone,
      is_active: selectedStatus?.value,
      role_id: selectedRole?.id
    };
    const validation =
      form.name.length &&
      form.email.length &&
      form.password.length &&
      (form.phone.length > 0 && form.phone.length <= 15) &&
      selectedStatus.name.length &&
      selectedRole.name.length;
    if (isEdit) {
      if (validation) {
        await dispatch(editAdmin(id, body, () => refreshData()));
      } else {
        validateForm();
      }
    } else {
      if (validation) {
        await dispatch(addAdmin(body, () => refreshData()));
      } else if (form.phone.length > 15) {
        toastify.error('Maksimum digit phone adalah 15');
      } else {
        validateForm();
      }
    }
  };

  const paginationFunction = (action: 'next' | 'prev', search?: string) => {
    dispatch(getAllAdmins(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
  };

  const onDeleteAdmin = (id: string) => {
    dispatch(deleteAdmin(id, () => {
      dispatch(getAllAdmins('', 1, data?.length === 1 ? '' : selectedStatus?.value));
    }));
  };

  const onSearchAdmins = debounce(e => {
    dispatch(getAllAdmins(e[0].target.value, 1, selectedStatus?.value));
  });

  const formArray: FormArrayType[] = [
    {
      index: 0,
      label: 'Name',
      name: 'name',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'name',
    },
    {
      index: 1,
      label: 'Email',
      name: 'email',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'text',
      placeholder: 'email',
    },
    {
      index: 2,
      label: 'Password',
      name: 'password',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'password',
      placeholder: 'password',
    },
    {
      index: 3,
      label: 'Admin Phone',
      name: 'phone',
      isMandatory: true,
      disabledCondition: isLoading,
      renderType: 'input',
      inputType: 'number',
      placeholder: 'admin phone',
    },
    {
      index: 4,
      label: 'Status',
      name: 'status',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: formStatus,
      renderType: 'select',
      placeholder: 'Status ',
      selectType: 'status'
    },
    {
      index: 5,
      label: 'Role',
      name: 'role',
      isMandatory: true,
      disabledCondition: isLoading,
      listSelect: rbac,
      renderType: 'select',
      placeholder: 'Status ',
      selectType: 'role'
    },
  ];

  const effectFunctions = () => {
    if (details && rbac && (id === details?.id.toString())) {
      setForm({
        ...form,
        name: details.name,
        email: details.email,
        phone: details.phone,
      });
      setSelectedStatus(details?.is_active ?
        {
          id: 1,
          name: 'Active',
          value: true
        } : {
          id: 2,
          name: 'Inactive',
          value: false
        });
    }
    setSelectedRole(rbac?.find(item => item?.id === details?.role_id));
  };

  return {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    form,
    setForm,
    formStatus,
    selectedStatus,
    setSelectedStatus,
    onChangeForm,
    onClickSubmit,
    data,
    details,
    paginationFunction,
    pagination,
    onSearchAdmins,
    onDeleteAdmin,
    formArray,
    effectFunctions,
    errorForm,
    setErrorForm,
    selectedRole,
    setSelectedRole
  };
}
