import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { useEffect } from 'react';
import { getAllHospitals } from 'store/actions';
import useHospital from './useHospital';

const Hospital = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteHospital,
    onSearchHospital,
    formStatus,
    selectedStatus,
    setSelectedStatus
  } = useHospital();

  useEffect(() => {
    dispatch(getAllHospitals('', 1, ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Hospital'
      thTitles={ thTitles }
      editLink='hospital/edit'
      viewLink='hospital/view'
      items={ data }
      title='Hospital'
      type='hospital'
      createLink='hospital/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteHospital }
      onChangeSearch={ onSearchHospital }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllHospitals }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
    />
  );
};

export default Hospital;