import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormArrayType, ParamsType } from 'interfaces/common';
import { hooks } from 'helpers';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { misc } from 'constant';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
	getAllFooters, addFooter, stopLoading, getAllFootersCategory, editFooter, deleteFooter
} from 'store/actions';
import { getAllFooterSlug } from 'store/Footers/action';

const useFooter = () => {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'TITLE',
		// 'AUTHOR',
		'LANGUAGE',
		'IS PUBLISH',
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		id: '',
		title: '',
		footer_sub_string: '',
		content: '',
		img_url: '',
		is_publish: '',
		slug: '',
		seo_h1: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	// const [author, setAuthor] = useState<any[]>([]);
	// const onDeleteAuthor = (tagIndex: number) => setAuthor(author.filter((_, i) => i !== tagIndex));
	// const onAddAuthor = newTag => {
	// 	setAuthor([newTag]);
	// };

	const {
		data, details, pagination, category: footerCategoriesRaw, slug_data,
	} = hooks.useAppSelector(selectors.footers);
	const { dropdown: doctors } = hooks.useAppSelector(selectors.doctors);

	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Published',
			value: true
		}, {
			id: 2,
			name: 'Unpublished',
			value: false
		}
	];
	const [footerCategory, setFooterCategory] = useState<any>({
		id: 1,
		name: '',
		slug: '',
	});
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});
	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		}
		if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const refreshData = () => {
		history.push('/footer');
		dispatch(getAllFooters('', 1, selectedStatus?.value));
	};

	const [uploaded, setUploaded] = hooks.useStateCallback([]);
	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.title.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (editor.editorHtml.length < 11 && !errorForm.length) {
				setErrorForm(prev => [...prev, 3]);
			}
			if (!footerCategory.slug.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 9]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 10]);
			}
		};
		if (isEdit) {
			const validationEdit =
				editor.editorHtml.length > 11 &&
				form.title.length &&
				footerCategory.slug.length && !isDuplicateSlug && selectedLanguage.name.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true);

			if (validationEdit) {
				if (imagePayload.length) {
					for (let i = 0; i < imagePayload.length; i++) {
						const newForm = new FormData();
						newForm.append('upload', imagePayload[i]);
						const uploadImg = await dispatch(generalUpload(newForm));
						if (uploadImg?.code === 200) {
							setUploaded(prev => [...prev, uploadImg.data]);
							dispatch(stopLoading('upload end'));
						}
					}
				} else {
					await dispatch(editFooter(form.id, {
						title: form.title,
						slug: form.slug,
						footer_category: footerCategory.slug,
						sub_title: form.footer_sub_string,
						content: editor.editorHtml,
						img_url: details.img_url,
						is_publish: selectedStatus.value,
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		} else {
			const validationPost =
				editor.editorHtml.length > 11 &&
				form.title.length &&
				footerCategory.slug.length &&
				!isDuplicateSlug && selectedLanguage.name.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true);
			if (validationPost) {
				if (imagePayload.length) {
					for (let i = 0; i < imagePayload.length; i++) {
						const newForm = new FormData();
						newForm.append('upload', imagePayload[i]);
						const uploadImg = await dispatch(generalUpload(newForm));
						if (uploadImg?.code === 200) {
							setUploaded(prev => [...prev, uploadImg.data]);
							dispatch(stopLoading('upload end'));
						}
					}
				} else {
					dispatch(addFooter({
						title: form.title,
						slug: form.slug,
						footer_category: footerCategory.slug,
						sub_title: form.footer_sub_string,
						content: editor.editorHtml,
						img_url: uploaded,
						is_publish: selectedStatus.value,
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		}
	};

	useEffect(() => {
		if (imagePayload.length === uploaded.length && imagePayload.length !== 0) {
			if (id) {
				const editUpload = [...previewImage.filter(item => item.includes(misc.UploadBaseUrl)), ...uploaded];
				dispatch(editFooter(form.id, {
					title: form.title,
					slug: form.slug,
					footer_category: footerCategory.slug,
					sub_title: form.footer_sub_string,
					content: editor.editorHtml,
					img_url: editUpload,
					is_publish: selectedStatus.value,
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => refreshData()));
			} else {
				dispatch(addFooter({
					title: form.title,
					slug: form.slug,
					footer_category: footerCategory.slug,
					sub_title: form.footer_sub_string,
					content: editor.editorHtml,
					img_url: uploaded,
					is_publish: selectedStatus.value,
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => refreshData()));
			}

		}
	}, [imagePayload.length, uploaded.length]);

	const footerCategories = footerCategoriesRaw.filter(category => category.name !== 'medical-specialities' && category.name !== 'Privacy Policy');

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllFooters(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
	};

	const onDeleteFooter = (id: string) => {
		dispatch(deleteFooter(id, () => {
			dispatch(getAllFooters('', 1, data?.length === 1 ? '' : selectedStatus?.value));
		}));
	};

	const onSearchFooter = debounce(e => {
		dispatch(getAllFooters(e[0].target.value, 1, selectedStatus?.value));
	});

	const effectFunctions = () => {
		if (details && (id === details?.id.toString())) {
			setForm({
				...form,
				id: details.id,
				slug: details.slug,
				title: details.title,
				footer_sub_string: details.sub_title,
				img_url: details.img_url,
				seo_h1: details?.seo_h1
			});
			setEditor({ editorHtml: details.content });
			footerCategories.forEach(k => {
				if (k.slug === details.footer_category) {
					setFooterCategory({
						id: k.id,
						name: k.name,
						slug: k.slug
					});
				}
			});
			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);

			setSelectedStatus(details.is_publish ?
				{
					id: 1,
					name: 'Published',
					value: true
				} : {
					id: 2,
					name: 'Unpublished',
					value: false
				});
			setPreviewImage(details?.img_url ?? []);
			// if (details?.author) {
			// 	setAuthor([{ name: details?.author }]);
			// } else {
			// 	setAuthor([]);
			// }
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.title
				});
			}
			if (details?.seo_robots) {
				const splitRobot = details?.seo_robots.split(',');
				if (splitRobot[0] == 'index') {
					setIsSeoIndex(true);
				} else if (splitRobot[0] == 'noindex') {
					setIsSeoIndex(false);
				}

				if (splitRobot[1] == 'follow') {
					setIsSeoFollow(true);
				} else if (splitRobot[1] == 'nofollow') {
					setIsSeoFollow(false);
				}
			}
		}
	};

	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'title',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Footer name',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Footer Sub Title',
			name: 'footer_sub_string',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Footer Sub String',
		},
		{
			index: 3,
			label: 'Footer Content',
			name: 'content',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'content'
		},
		// {
		// 	index: 4,
		// 	label: 'Author',
		// 	name: 'author',
		// 	isMandatory: false,
		// 	disabledCondition: isLoading,
		// 	renderType: 'tags',
		// 	placeholder: ' Author',
		// 	tagType: 'article'
		// },
		{
			index: 4,
			label: 'Upload Image',
			name: 'img_url',
			isMandatory: false,
			disabledCondition: isLoading || previewImage?.length >= 3,
			renderType: 'file',
			inputType: 'file',
			maxImage: 3,
			imageType: ''
		},
		{
			index: 5,
			label: 'Footer Category',
			name: 'footer_category',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: footerCategories,
			renderType: 'select',
			placeholder: 'Footer Category ',
			selectType: 'footer_category'
		},
		{
			index: 6,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 7,
			label: 'Slug Footer',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		{
			index: 8,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Language ',
			selectType: 'language'
		},
		{
			index: 9,
			label: 'Parent Article',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Article'
		},
		{
			index: 10,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllFooterSlug('', 1, '', 1000, form.slug, selectedLanguage.value));
	};

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		footerCategory,
		setFooterCategory,
		onChangeForm,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onDeleteFooter,
		onSearchFooter,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		editor,
		onChangeEditor,
		selectedStatus,
		setSelectedStatus,
		getAllFootersCategory,
		setEditor,
		doctors,
		// onDeleteAuthor,
		// onAddAuthor,
		// author,
		// setAuthor,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		setSelectedLanguage,
		setSelectedParent,
		selectedLanguage,
		selectedParent,
		slug_data,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
};

export default useFooter;