import { Pagination } from 'interfaces/common';
import {
  GET_FACILITY_DETAILS, GET_ALL_FACILITIES, FacilitiesActionTypes, GET_ALL_FACILITIES_SLUG
} from './actionType';

interface FacilitiesState {
  data: any;
  details: any;
  pagination: Pagination;
	slug_data: any;
}

const initState: FacilitiesState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
	slug_data: [],
};

const FacilitiesReducer = (state = initState, action: FacilitiesActionTypes) => {
  switch (action.type) {
    case GET_ALL_FACILITIES:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_FACILITY_DETAILS:
      return {
        ...state,
        details: action.details
      };
		case GET_ALL_FACILITIES_SLUG:
			return {
				...state,
				slug_data: action.data
			};
    default:
      return state;
  }
};

export default FacilitiesReducer;