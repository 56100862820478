import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { GET_LIST_ADMIN, GET_ADMIN_DETAILS } from './actionType';
import { loadingStart, loadingEnd } from '../Misc/action';

export const getAllAdmins = (keyword: string, page: number, is_active: boolean | '') => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get admins list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllAdmin(keyword, page, is_active));
      if (code === 200) {
        await dispatch({
          type: GET_LIST_ADMIN,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get admins list end'));
  };
};

export const getAdminDetails = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get admin detail begin'));
      const { code, data }: ApiData<EventsInterface> = await api.request(Endpoints.getAdminDetails(id));
      if (code === 200) {
        await dispatch({
          type: GET_ADMIN_DETAILS,
          details: data,
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('get admin detail end'));
  };
};

export const deleteAdmin = (id: string, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('delete admin begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.deleteAdmin(id));
      if (code === 200) {
        toastify.success('Berhasil Menghapus Admin');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('delete admin end'));
  };
};

export const addAdmin = (body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('add admin begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.addAdmin(), body);
      if (code === 200) {
        toastify.success('Berhasil Menambah Admin');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('add admin end'));
  };
};

export const editAdmin = (id: string, body, cb) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('edit admin begin'));
      const { code }: ApiData<EventsInterface> = await api.request(Endpoints.editAdmin(id), body);
      if (code === 200) {
        toastify.success('Berhasil Edit Admin');
        cb && cb();
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
    dispatch(loadingEnd('edit admin end'));
  };
};