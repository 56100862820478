import {
	toast,
	Theme,
	ToastPosition,
} from 'react-toastify';

type ConfigProps = {
  theme: Theme;
  position: ToastPosition;
  hideProgressBar: boolean;
};

const defaultConfig: ConfigProps = {
	theme: 'colored',
	position: toast.POSITION.TOP_CENTER,
	hideProgressBar: true,
};

export const success = (message: string) => {
	toast.success(message, defaultConfig);
};

export const error = (message: string) => {
	toast.error(message, defaultConfig);
};

export const warning = (message: string) => {
	toast.warning(message, defaultConfig);
};

export const info = (message: string) => {
	toast.info(message, defaultConfig);
};

export const errorDefaultApi = () => {
	error('Maaf, terjadi kesalahan. Silakan muat ulang halaman.');
};
