import { Text } from 'components';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline';

export default function NotFound () {
  return (
    <div className='flex w-full h-full items-center flex-col py-20'>
      <Text text='Page not found. Please Check Your URL.' className='text-xl rspi-main-color' />
      <Link to='/'>
        <div className='flex py-10 items-center'>
          <HomeIcon className='w-5 h-5 rspi-main-color' />
          <Text text='Back to Home' className='rspi-main-color text-xl pl-2' cursor='cursor-pointer' />
        </div>
      </Link>
    </div>
  );
}
