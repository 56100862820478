import React, {
  useState, useRef, useMemo, useEffect
} from 'react';
import {
  Marker,
  MapContainer,
  TileLayer,
  useMapEvents,
  useMap
} from 'react-leaflet';
import L from 'leaflet';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { Text } from 'components';
import EsriCustomProvider, {
  getReverseGeocoding, ResultReverse, MapsLocation
} from './provider';

type MapMarkerTypes = {
  height: string,
  width: string,
  position: MapsLocation,
  setPosition: React.Dispatch<React.SetStateAction<MapsLocation>> | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  mapControl: 'bottomleft' | 'bottomright' | 'topleft' | 'topright';
  editable: boolean;
  onChangeLocation?: (position: MapsLocation, address?: string) => void;
  valueGeoSearch?: SelectValueType;
  setValueGeoSearch?: React.Dispatch<React.SetStateAction<SelectValueType>>;
  isView?: boolean;
};

type SelectValueType = {
  id?: number;
  label: string;
  value: any;
};

const MapMarker: React.FC<MapMarkerTypes> = ({
  height = '300px', width = '300px', position = {
    lat: -6.175403066579251,
    lng: 106.82714738255616
  }, setPosition,
  open = false,
  setOpen,
  mapControl = 'bottomleft',
  editable = true,
  onChangeLocation,
  valueGeoSearch,
  setValueGeoSearch,
  isView = false
}) => {
  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
  };
  const markerRef = useRef<any>(null);
  const [zoomLevel] = useState<number>(15);
  const [resultGeosearch, setResultGeosearch] = useState<SelectValueType[]>([]);
  const [provider, setProvider] = useState<any>(null);
  const [isMarkerDragged, setIsMarkerDragged] = useState<boolean>(false);
  const [loadingGeoSearch, setLoadingGeoSearch] = useState<boolean>(false);
  const markerEventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current;

        if (marker) {
          setIsMarkerDragged(true);

          setPosition && setPosition(marker.getLatLng());

          getReverseGeocoding(marker.getLatLng(), (res: ResultReverse) => {
            setValueGeoSearch && setValueGeoSearch(prev => ({
              ...prev,
              label: res.label
            }));
            onChangeLocation && onChangeLocation(marker.getLatLng(), res.label || valueGeoSearch && valueGeoSearch.label);

            setIsMarkerDragged(false);
          });
        }
      },
    }),
    [valueGeoSearch && valueGeoSearch.label]
  );

  const ChangeView = ({ center }) => {
    const map = useMap();

    if (map) {
      map?.setView(center);
    }

    return null;
  };

  useEffect(() => {
    if (window) {
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('assets/images/maps/marker_icon_2x.png'),
        iconUrl: require('assets/images/maps/marker_icon.png'),
        shadowUrl: require('assets/images/maps/marker_shadow.png')
      });
    }
  }, []);
  const ComponentResize = () => {
    const map = useMap();

    useEffect(() => {
      setTimeout(() => {
        if (map && open) map.invalidateSize();
      }, 1000);
    }, [open]);

    return null;
  };

  useEffect(() => {
    const provider = new EsriCustomProvider();

    setProvider(provider);
  }, []);

  const EditMapControl = ({ position }) => {
    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    // Memoize the ControlContainer so it's not affected by position changes
    const ControlContainer = useMemo(
      () => (
        <div className='bg-white rounded-full py-2 px-4 shadow-md cursor-pointer flex items-center' onClick={ () => setOpen && setOpen(true) }>
          <MapPinIcon className='w-4 h-4 text-hydeGreen-primary' />
          <Text
            size='text-xs'
            weight='font-bold'
            cursor='cursor-pointer'
            spacing='ml-2'
          >Edit map location</Text>
        </div>
      ),
      []
    );

    return (
      <div className={ positionClass }>
        <div className='leaflet-control'>{ ControlContainer }</div>
      </div>
    );
  };

  const CustomMarker = () => {
    const map = useMapEvents({
      dragend: (e: L.DragEndEvent) => {

        setPosition && setPosition(e.target.getCenter());
        getReverseGeocoding(e.target.getCenter(), (res: ResultReverse) => {
          setValueGeoSearch && setValueGeoSearch({
            label: res.label,
            value: e.target.getCenter()
          });

          onChangeLocation && onChangeLocation(e.target.getCenter(), res.label || valueGeoSearch && valueGeoSearch.label);
        });
      }
    });

    return (
      <Marker
        draggable={ !isView }
        eventHandlers={ markerEventHandlers }
        position={ position }
        ref={ markerRef }
      />
    );
  };

  return (
    <div className='flex'>
      <MapContainer
        key='site-survey'
        style={ {
          width: width,
          height: height,
          zIndex: '10'
        } }
        center={ position }
        zoom={ 15 }
        dragging={ !isView }
      >
        <ComponentResize />
        <ChangeView center={ position } />
        { editable ? <EditMapControl position={ mapControl } /> : null }
        <TileLayer
          attribution={ '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <CustomMarker />
      </MapContainer>
    </div >
  );
};

export default MapMarker;