import { Pagination } from 'interfaces/common';

export const GET_LIST_AWARDS = 'GET_LIST_AWARDS';
export const GET_AWARD_DETAILS = 'GET_AWARD_DETAILS';

export interface GetAwardList {
  type: typeof GET_LIST_AWARDS;
  data: any;
  pagination: Pagination;
}

export interface GetAwardDetails {
  type: typeof GET_AWARD_DETAILS;
  details: any;
}

export type AwardsAndAccreditationActionTypes = GetAwardList | GetAwardDetails;
