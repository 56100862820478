import { useEffect } from 'react';

import { hooks } from 'helpers';
import { getAllSpecialities } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import useListSpecialties from '../useListSpecialties';
import { useParams } from 'react-router-dom';
import { ParamsType } from 'interfaces/common';
import { getDetailGroupSpecialties } from 'store/Specialties/action';

const SpecialtiesForm = () => {
	const dispatch = hooks.useAppDispatch();
	const { formArray, isEdit } = useListSpecialties();
	const { id } = useParams<ParamsType>();

	useEffect(() => {
		dispatch(getAllSpecialities());
		if (id && isEdit) {
			dispatch(getDetailGroupSpecialties(id));
		}
	}, []);

  return (
    <AdminForm
      formArray={ formArray }
      backLink='/list-specialties'
      isAbleToSubmit={ true }
      title='Group Specialties'
      localHooks={ useListSpecialties }
			type='list_specialties'
    />
  );
};

export default SpecialtiesForm;