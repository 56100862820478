import React from 'react';
import { Provider } from 'react-redux';
import Router from 'router';
import { history, store } from 'store';
import { ToastContainer } from 'react-toastify';
import 'leaflet/dist/leaflet.css';

const App: React.FC = () => {
	return (
		<Provider store={ store }>
			<ToastContainer
				bodyClassName={ () => 'text-sm font-white font-primary font-medium flex items-center' }
				autoClose={ 4000 }
			/>
			<Router history={ history } />
		</Provider>
	);
};
export default App;
