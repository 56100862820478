import { Dispatch } from 'redux';

import { Endpoints } from 'constant';
import { AppActions } from 'store';
import { ApiData } from 'interfaces/common';
import { EventsInterface } from 'interfaces/events';
import { toastify } from 'helpers';
import { api } from 'utils';
import { loadingStart, loadingEnd } from '../Misc/action';

import { GET_LIST_SPECIALITIES } from './actionType';

export const getAllSpecialities = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      await dispatch(loadingStart('get specialties list begin'));
      const {
        code, data, pagination
      }: ApiData<EventsInterface> = await api.request(Endpoints.getAllSpecialities());
      if (code === 200) {
        await dispatch({
					type: GET_LIST_SPECIALITIES,
          data: data,
          pagination: pagination
        });
      }
    } catch (error) {
      toastify.errorDefaultApi();
    }
		dispatch(loadingEnd('get specialties list end'));
  };
};