import React, { useState, useRef } from 'react';
import { EyeIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import {
	CardStatus, Text, Select, PreviewImage
} from 'components';
import Button from 'components/Button';
import { CellType } from 'interfaces/misc';

type Props = {
	cellType: CellType;
	text?: string;
	onClick?: () => void;
	iconElement?: JSX.Element;
	background?: string;
	className?: string;
	tdClassName?: string;
	color?: string;
	href?: string;
	type?: 'h1' | 'h2' | 'h3' | 'p' | 'a' | 'span';
	projectId?: number | null | string;
	projectName?: string;
	category?: string;
	modalTitle?: string;
	picType?: string;
	hide?: boolean;
	secondText?: string;
	secondOnClick?: () => void;
	secondHref?: string,
	selectedState?: any,
	selectOption?: any,
	onChangeOption?: any;
	refetchData?: () => void;
	textColor?: string;
	openEllipsis?: number | null;
	setOpenEllipsis?: React.Dispatch<React.SetStateAction<number | null>>;
	indexEllipsis?: number;
	ellipsisClick?: any;
	isActive?: boolean;
	isEditOrder?: boolean;
	setIsEditOrder?: React.Dispatch<React.SetStateAction<boolean>>;
	image?: string;
	imageAlt?: string;
	viewLink?: string;
	checked?: boolean;
	onCheck?: any;
	disabled?: boolean;
};

const Cell: React.FC<Props> = ({
	cellType,
	text,
	onClick,
	iconElement,
	background,
	className,
	tdClassName = 'p-2 px-6',
	color,
	href,
	type,
	projectId,
	projectName,
	category,
	modalTitle,
	picType,
	hide,
	secondHref,
	secondOnClick,
	secondText,
	selectedState,
	selectOption,
	onChangeOption,
	refetchData,
	textColor,
	openEllipsis,
	setOpenEllipsis,
	indexEllipsis,
	ellipsisClick,
	isActive,
	isEditOrder = false,
	setIsEditOrder,
	image,
	imageAlt = 'image alt name',
	viewLink,
	checked,
	onCheck,
	disabled
}) => {
	const [showModalChoosePIC, setShowModalChoosePIC] = useState(false);
	const ellipsisRef = useRef<any>(null);
	const previewRef = useRef<any>(null);
	const [selectedImage, setSelectedImage] = useState<string>('');
	const [openPreview, setOpenPreview] = useState<boolean>(false);
	// useEffect(() => {
	// 	const handleOutsideClick = (event) => {
	// 		if (ellipsisRef.current && !ellipsisRef?.current?.contains(event?.target)) {
	// 			{ setOpenEllipsis && setOpenEllipsis(null); }
	// 		}
	// 	};
	// 	document.addEventListener('mousedown', handleOutsideClick);
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleOutsideClick);
	// 	};
	// }, [ellipsisRef]);
	const hidePcStatus = (status: string | undefined) => {
		switch (status) {
			case 'PRECREATED':
			case 'FREE_VERIFIED':
			case 'NEW':
			case 'WAIT_BOOK_FEE':
			case 'READY_TO_PAID_BOOK_FEE':
			case 'PAID_BOOK_FEE':
			case 'SELECTED_SURVEY':
			case 'SELECTED_SDP':
			case 'PE_SELECTED':
			case 'SET_SDP':
			case 'SET_SURVEY':
			case 'RESCHEDULE_SURVEY':
			case 'READY_TO_SURVEY':
			case 'EXT_WAIT_BOOK_FEE':
			case 'EXT_PAID_BOOK_FEE':
			case 'DESIGN_PROCESS':
				return true;
			default:
				return false;
		}
	};

	if (cellType === 'project') {
		return (
			<td className='p-2 px-6 pl-4 text-xs font-normal text-left text-gray-900 whitespace-nowrap cursor-pointer'>
				<a onClick={ onClick ? () => onClick() : undefined }>
					<div className='flex'>
						<span className='text-xs'>
							{ projectId } | <span className='text-xs text-right'>{ category }</span>
						</span>
					</div>
					<Text className='hover:text-blue-500' cursor='cursor-pointer' color='text-black' weight='font-semibold' size='text-sm'>
						{ projectName }
					</Text>
				</a>
			</td >
		);
	} else if (cellType === 'text') {
		return (
			<td className={ `whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<Text text={ text } isDefault={ true } className={ className } href={ href } type={ type } onClick={ onClick } color={ textColor || 'text-hydeblack-900' } />
			</td>
		);
	} else if (cellType === 'badge') {
		return (
			<td className='p-2 px-6 text-sm font-semibold text-gray-900 align-middle whitespace-nowrap'>
				<CardStatus text={ text } background={ background } color={ color } />
			</td>
		);
	} else if (cellType === 'icon') {
		return (
			<td className='font-semibold align-middle'>
				<a className='flex items-center justify-center' onClick={ onClick ? () => onClick() : undefined }>{ iconElement }</a>
			</td>
		);
	} else if (cellType === 'button') {
		return (
			<td className='w-2 p-5 font-semibold align-middle whitespace-nowrap'>
				<Button text={ text || 'Show' } onClick={ onClick ? () => onClick() : undefined } hide={ hide } />
			</td>
		);
	} else if (cellType === 'pic') {
		return (
			<td className={ `whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<div className='flex items-center justify-between w-full font-semibold align-middle whitespace-nowrap'>
					<Text text={ text } isDefault={ true } className={ className } href={ href } type={ type } />
				</div>
			</td>
		);
	} else if (cellType === 'edit_delete') {
		return (
			<td className={ `whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<Link to={ viewLink || '/' }> <EyeIcon className={ `w-5 h-5 mb-[2%] ${ className }` } /></Link>
				<Text text={ text } isDefault={ true } className={ className } href={ href } type={ type } onClick={ onClick } />
				<Text text={ secondText } isDefault={ false } color='text-red-500' className={ className } href={ secondHref } type={ type } onClick={ secondOnClick } />
			</td>
		);
	} else if (cellType === 'edit') {
		return (
			<td className={ `flex justify-around whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<Link to={ viewLink || '/' }> <EyeIcon className={ `w-5 h-5 mb-[2%] ${ className }` } /></Link>
				<Text text={ text } isDefault={ true } className={ className } href={ href } type={ type } onClick={ onClick } />
			</td>
		);
	} else if (cellType === 'delete') {
		return (
			<td className={ `flex justify-around whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<Link to={ viewLink || '/' }> <EyeIcon className={ `w-5 h-5 mb-[2%] ${ className }` } /></Link>
				<Text text={ text } isDefault={ false } color='text-red-500' className={ className } type={ type } onClick={ secondOnClick } />
			</td>
		);
	} else if (cellType === 'view') {
		return (
			<td className={ `flex justify-around whitespace-nowrap text-sm text-gray-500  align-middle ${ tdClassName }` }>
				<Link to={ viewLink || '/' }> <EyeIcon className={ `w-5 h-5 mb-[2%] ${ className }` } /></Link>
			</td>
		);
	} else if (cellType === 'select') {
		return (
			<td>
				<Select
					list={ selectOption }
					state={ selectedState }
					setState={ onChangeOption }
					keyObj='type'
					placeholder='Selected Role'
					shadow='shadow-sm'
					sizing='w-full'
					wrapperClassName='mt-1 focus:ring-moss-100 focus:border-moss-100 block w-full sm:text-sm border-gray-300 border-solid border rounded-md py-[9px] px-[13px]'
				/>
			</td>
		);
	} else if (cellType === 'ellipsis') {
		return (
			<td className={ `whitespace-nowrap text-sm text-gray-500  align-middle cursor-pointer ${ tdClassName }` }>
				<Text text='...' href='#' className={ className } onClick={ (e: React.MouseEvent) => {
					e.preventDefault();
					ellipsisClick();
					setOpenEllipsis && setOpenEllipsis(indexEllipsis === openEllipsis ? null : indexEllipsis || 0);
				} } color='text-gray-500' cursor='cursor-pointer' />
				{ openEllipsis === indexEllipsis ? <div className='bg-gray-50 p-2 rounded-lg divide-y-2 divide-neutral-400' ref={ ellipsisRef }>
					<Text text='Set Order' cursor='cursor-pointer' onClick={ () => {
						onClick && onClick();
						setOpenEllipsis && setOpenEllipsis(null);
						setIsEditOrder && setIsEditOrder(!isEditOrder);
					} } />
					<Text text={ isActive ? 'Deactivate look' : 'Activate look' } cursor='cursor-pointer' color={ isActive ? 'text-red-500' : 'text-black' } onClick={ () => {
						secondOnClick && secondOnClick();
						setOpenEllipsis && setOpenEllipsis(null);
					} } />
				</div> : null }
			</td>
		);
	}
	if (cellType === 'image') {
		return (
			<td className='p-2 px-6 pl-4 text-xs font-normal text-left text-gray-900 whitespace-nowrap cursor-pointer'>
				<img src={ image } alt={ imageAlt } className='w-16' onClick={ () => {
					setOpenPreview(true);
					setSelectedImage(image || '');
				} } />
				<PreviewImage buttonRef={ previewRef } open={ openPreview } setOpen={ setOpenPreview } selectedImage={ selectedImage } setSelectedImage={ setSelectedImage } />
			</td >
		);
	}

	if (cellType === 'checkbox') {
		return (
			<td className='px-16 text-xs font-normal text-left text-gray-900 whitespace-nowrap cursor-pointer'>
				<input type='checkbox' defaultChecked={ checked } onChange={ e => onCheck && onCheck(e) } disabled={ disabled } />
			</td >
		);
	}

	return null;
};

export default Cell;
