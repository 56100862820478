import useMedicalSpecialities from './useMedicalSpecialities';
import AdminForm from 'components/AdminView/AdminForm';

const CreateMedicalSpecialities = () => {

  const {
		formArray
  } = useMedicalSpecialities();

	return (
		<AdminForm
			backLink='/medical-specialities'
			formArray={ formArray }
			isAbleToSubmit={ true }
			localHooks={ useMedicalSpecialities }
			title='Medical Specialities'
			type='medical-specialities'
		/>
	);
};

export default CreateMedicalSpecialities;