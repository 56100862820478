import React, { useCallback, useEffect } from 'react';

import {
	hooks, misc, toastify
} from 'helpers';
import { Images } from 'constant';
import { Button, Spinner } from 'components';
import { getItem } from 'helpers/localStorage';

import { login } from 'store/Auth/action';

import { Link, useHistory } from 'react-router-dom';
import { useSignin } from './useSignIn';
import { selectors } from 'store/selectors';

type SignInType = {
	test?: boolean;
};

const Signin: React.FC<SignInType> = ({ test = false }) => {
	const dispatch = hooks.useAppDispatch();
	const { isLoading } = hooks.useAppSelector(selectors.loading);
	const history = useHistory();
	const { formLogin, setFormLogin } = useSignin();

	useEffect(() => {
		if (getItem('token', false) !== null) {
			history.push('/');
		}
	}, []);

	const onChangeForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFormLogin(prevForm => ({
			...prevForm,
			[e.target.name]: e.target.value
		}));
	}, []);

	const onClickLogin = () => {
		const emptyForm = misc.getMultipleKeyByValue(formLogin, '');
		if (!emptyForm.length) {
			dispatch(login(true, formLogin));
		} else {
			toastify.error('Username / Password cannot be empty');
		}
	};

	return (
		<>
			{ isLoading ?
				<div role='spinbutton' className='h-[100vh] w-[100vw] flex content-center align-middle justify-center items-center'>
					<Spinner />
				</div> :
				<div className='flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8'>
					<div className='w-full max-w-md space-y-8'>
						<div>
							<img className='w-auto h-12 mx-auto' src={ Images.logo } alt='Workflow' />
							<h2 className='mt-6 text-3xl font-extrabold text-center text-gray-900'>Sign in to your account</h2>
						</div>
						<div className='mt-8 space-y-6'>
							<input type='hidden' name='remember' defaultValue='true' />
							<div className='-space-y-px rounded-md shadow-sm'>
								<div>
									<label htmlFor='email-address' className='sr-only'>
										Email address
									</label>
									<input
										id='email-address'
										name='email'
										type='email'
										autoComplete='email'
										required
										className='relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-moss-100 focus:border-moss-100 focus:z-10 sm:text-sm'
										placeholder='Email address'
										onChange={ onChangeForm }
										value={ formLogin.email }
									/>
								</div>
								<div>
									<label htmlFor='password' className='sr-only'>
										Password
									</label>
									<input
										id='password'
										name='password'
										type='password'
										autoComplete='current-password'
										required
										className='relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-moss-100 focus:border-moss-100 focus:z-10 sm:text-sm'
										placeholder='Password'
										onChange={ onChangeForm }
										value={ formLogin.password }
									/>
								</div>
							</div>
							<div className='flex items-center justify-between'>
								<div className='flex items-center'>
									<input
										id='remember-me'
										name='remember-me'
										type='checkbox'
										className='w-4 h-4 border-gray-300 rounded rspi-main-color focus:rspi-main-color'
									/>
									<label htmlFor='remember-me' className='block ml-2 text-sm text-gray-900'>
										Remember me
									</label>
								</div>
								<div className='text-sm'>
									{ test ? <a>
										Forgot your password?
									</a> : <Link to='/reset-password' className='font-medium rspi-main-color hover:rspi-main-color'>
										Forgot your password?
									</Link> }
								</div>
							</div>
							<div>
								<Button text='Sign In' onClick={ onClickLogin } />
							</div>
						</div>
					</div>
				</div> }
		</>
	);
};

export default Signin;
