import React from 'react';
import Select from 'react-select';

type DropdownSearchProps = {
	list?: ObjectKey[];
	onChange?: any;
	value?: any;
	isDisable?: any;
};
const DropdownSearch = (props: DropdownSearchProps) => {
	return <Select options={ props.list } onChange={ props.onChange } defaultValue={ props.value } isDisabled={props?.isDisable} />;
}

export default DropdownSearch;