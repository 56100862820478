import { AdminView } from 'components';
import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { useEffect } from 'react';
import { getAllContactUs, getFooterBySlug } from 'store/actions';
import useContactUs from './useContactUs';

const ContactUs = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteFooter,
    onSearchFooter,
	} = useContactUs();

  useEffect(() => {
		dispatch(getAllContactUs('', 1));
  }, []);

  return (
    <AdminView
      cellType='delete'
      createText='Contact Us'
      thTitles={ thTitles }
      editLink=''
			viewLink='contact_us/view'
      items={ data }
			title='Contact Us'
      type='contact_us'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteFooter }
			onChangeSearch={ onSearchFooter }
      withFilter={ false }
			getData={ getAllContactUs }
    />
  );
};

export default ContactUs;