import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FormArrayType, ParamsType } from 'interfaces/common';
import { hooks } from 'helpers';
import { createSeoRobotString, debounce } from 'helpers/misc';
import { misc } from 'constant';
import { selectors } from 'store/selectors';
import { generalUpload } from 'store/Common/action';
import {
	getAllHospitals, addHospital, deleteHospital, editHospital, stopLoading
} from 'store/actions';
import { getAllHospitalSlug } from 'store/Hospitals/action';

const useHospital = () => {
	const dispatch = hooks.useAppDispatch();
	const history = useHistory();
	const { id } = useParams<ParamsType>();
	const thTitles: string[] = [
		'ID',
		'TITLE',
		'PHONE',
		'LANGUAGE',
		'STATUS',
		'ADDRESS'
	];
	const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({
		id: 0,
		name: ''
	});
	const [form, setForm] = useState({
		name: '',
		address: '',
		hospital_code: '',
		img_url: '',
		phone: '',
		email: '',
		embed_link: '',
		share_link: '',
		slug: '',
		seo_h1: '',
		seo_robots_index: '',
		seo_robots_follow: '',
	});
	const [editor, setEditor] = useState({ editorHtml: '' });
	const onChangeEditor = e => {
		setEditor({
			...editor,
			editorHtml: e
		});
	};
	const {
		data, details, pagination, slug_data
	} = hooks.useAppSelector(selectors.hospitals);
	const { isLoading } = hooks.useAppSelector(selectors.loading);

	const formStatus: Record<string, any>[] = [
		{
			id: 1,
			name: 'Active',
			value: true
		}, {
			id: 2,
			name: 'Inactive',
			value: false
		}
	];
	const formLanguage: Record<string, any>[] = [
		{
			id: 1,
			name: 'Indonesia',
			value: 'idn'
		}, {
			id: 2,
			name: 'English',
			value: 'en'
		}
	];
	const [selectedLanguage, setSelectedLanguage] = useState({
		id: 1,
		name: 'Indonesia',
		value: 'idn'
	});
	const [selectedParent, setSelectedParent] = useState({
		id: 0,
		name: '',
	});
	const [selectedStatus, setSelectedStatus] = useState<any>({
		id: 0,
		name: 'All',
		value: ''
	});
	const [errorForm, setErrorForm] = useState<number[]>([]);
	const [previewImage, setPreviewImage] = useState<string[]>([]);
	const [imagePayload, setImagePayload] = useState<File[]>([]);
	const [isDuplicateSlug, setIsDuplicateSlug] = useState<boolean>(true);
	const [isSeoIndex, setIsSeoIndex] = useState<boolean>(true);
	const [isSeoFollow, setIsSeoFollow] = useState<boolean>(true);
	const [seoH1, setSeoH1] = useState<string>('');
	
	const onChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (e.target.name === 'phone') {
			setForm({
				...form,
				phone: e.target.value.replace(/[^0-9\:ext\=\,\;\+ ]/g, '')
			});
		} else if (e.target.name === 'seo_h1') {
			setForm({
				...form,
				[e.target.name]: e.target.value,
				['slug']: e.target.value.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value
			});
		} if (errorForm.length) {
			setErrorForm(errorForm.filter(item => item !== index));
		}
	};
	const refreshData = () => {
		history.push('/hospital');
		dispatch(getAllHospitals('', 1, ''));
	};

	const validationEmbed = form?.embed_link?.split('src=')?.find(item => item?.includes('https://www.google.com/maps/embed?'))
		?.split(' ')[0];
	const validationShare = form?.share_link?.includes('maps');

	const [uploaded, setUploaded] = hooks.useStateCallback([]);
	const onClickSubmit = async (isEdit: boolean) => {
		const validateForm = () => {
			if (!form.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 0]);
			}
			if (!form.seo_h1.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 1]);
			}
			if (!form.hospital_code.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 2]);
			}
			if (!form.phone.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 3]);
			}
			if (!form.email.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 4]);
			}
			if (editor.editorHtml.length < 11 && !errorForm.length) {
				setErrorForm(prev => [...prev, 5]);
			}
			if (!form.address.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 6]);
			}
			if (!validationEmbed?.includes('https://www.google.com/maps/embed?') && !errorForm.length) {
				setErrorForm(prev => [...prev, 7]);
			}
			if (!validationShare && !errorForm.length) {
				setErrorForm(prev => [...prev, 8]);
			}
			if (!isEdit && !imagePayload.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 9]);
			}
			if (!selectedStatus.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 10]);
			}
			if (!selectedLanguage.name.length && !errorForm.length) {
				setErrorForm(prev => [...prev, 11]);
			}
			if (selectedLanguage?.value === 'en' && !selectedParent?.name?.length) {
				setErrorForm(prev => [...prev, 12]);
			}
			
		};
		if (isEdit) {
			const validationEdit =
				editor.editorHtml.length > 11 &&
				form.name.length &&
				form.hospital_code.length &&
				form.email.length &&
				form.phone.length &&
				form.address.length &&
				validationEmbed?.includes('https://www.google.com/maps/embed?') &&
				validationShare &&
				selectedStatus.name.length &&
				selectedLanguage?.name?.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug && !seoH1;
			if (validationEdit) {
				if (imagePayload.length) {
					for (let i = 0; i < imagePayload.length; i++) {
						const newForm = new FormData();
						newForm.append('upload', imagePayload[i]);
						const uploadImg = await dispatch(generalUpload(newForm));
						if (uploadImg?.code === 200) {
							setUploaded(prev => [...prev, uploadImg.data]);
							dispatch(stopLoading('upload end'));
						}
					}
				} else {
					
					await dispatch(editHospital(details.id, {
						name: form.name,
						slug: form.slug,
						address: form.address,
						hospital_code: form.hospital_code,
						phone: form.phone,
						email: form.email,
						description: editor.editorHtml,
						img_url: previewImage,
						is_active: selectedStatus.value,
						embed_link: validationEmbed,
						share_link: form.share_link,
						language: selectedLanguage?.value,
						parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
						seo_h1: form.seo_h1,
						seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
					}, () => refreshData()));
				}
			} else {
				validateForm();
			}
		} else {
			const validationPost =
				editor.editorHtml.length > 11 &&
				form.name.length &&
				form.hospital_code.length &&
				form.email.length &&
				form.phone.length &&
				form.address.length &&
				validationEmbed?.includes('https://www.google.com/maps/embed?') &&
				validationShare &&
				selectedStatus.name.length &&
				imagePayload.length &&
				(selectedLanguage?.value === 'en' ? selectedParent?.name?.length : true) && !isDuplicateSlug && !seoH1;
			if (validationPost) {
				for (let i = 0; i < imagePayload.length; i++) {
					const newForm = new FormData();
					newForm.append('upload', imagePayload[i]);
					const uploadImg = await dispatch(generalUpload(newForm));
					if (uploadImg?.code === 200) {
						setUploaded(prev => [...prev, uploadImg.data]);
						dispatch(stopLoading('upload end'));
					}
				}
			} else {
				validateForm();
			}
		}
	};

	// const createSeoRobotString = (seoIndex, seoFollow) => {
	// 	let seoRobotString = '';
	// 	if (seoIndex) {
	// 		if (seoFollow) {
	// 			seoRobotString = 'index,follow';
	// 		} else {
	// 			seoRobotString = 'index,nofollow';
	// 		}
	// 	} else {
	// 		if (seoFollow) {
	// 			seoRobotString = 'noindex,follow';
	// 		} else {
	// 			seoRobotString = 'noindex,nofollow';
	// 		}
	// 	}

	// 	return seoRobotString;
	// }

	useEffect(() => {
		if (imagePayload.length === uploaded.length && imagePayload.length !== 0) {
			if (id) {
				const editUpload = [...previewImage.filter(item => item.includes(misc.UploadBaseUrl)), ...uploaded];
				
				dispatch(editHospital(details.id, {
					name: form.name,
					slug: form.slug,
					address: form.address,
					hospital_code: form.hospital_code,
					phone: form.phone,
					email: form.email,
					description: editor.editorHtml,
					img_url: editUpload,
					is_active: selectedStatus.value,
					embed_link: validationEmbed,
					share_link: form.share_link,
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => refreshData()));
			} else {
				dispatch(addHospital({
					name: form.name,
					slug: form.slug,
					address: form.address,
					hospital_code: form.hospital_code,
					phone: form.phone,
					email: form.email,
					description: editor.editorHtml,
					img_url: uploaded,
					is_active: selectedStatus.value,
					embed_link: validationEmbed,
					share_link: form.share_link,
					language: selectedLanguage?.value,
					parent_id: selectedLanguage?.value === 'en' ? selectedParent?.id : null,
					seo_h1: form.seo_h1,
					seo_robots: createSeoRobotString(isSeoIndex, isSeoFollow),
				}, () => refreshData()));
			}
		}
	}, [imagePayload.length, uploaded.length]);

	const paginationFunction = (action: 'next' | 'prev', search?: string) => {
		dispatch(getAllHospitals(search || '', action === 'next' ? pagination.page + 1 : pagination.page - 1, selectedStatus?.value));
	};

	const onDeleteHospital = (id: string) => {
		dispatch(deleteHospital(id, () => {
			dispatch(getAllHospitals('', 1, data?.length === 1 ? '' : selectedStatus?.value));
		}));
	};

	const onSearchHospital = debounce(e => {
		dispatch(getAllHospitals(e[0].target.value, 1, selectedStatus?.value));
	});

	const effectFunctions = () => {
		if (details && (id === details?.slug)) {
			setForm({
				...form,
				name: details.name,
				address: details.address,
				hospital_code: details.hospital_code,
				phone: details.phone,
				email: details.email,
				img_url: details.img_url,
				embed_link: details.embed_link,
				share_link: details.share_link,
				slug: details?.slug,
				seo_h1: details?.seo_h1
			});
			setSelectedStatus(details.is_active ?
				{
					id: 1,
					name: 'Active',
					value: true
				} : {
					id: 2,
					name: 'Inactive',
					value: false
				});
			details?.img_url?.length && setPreviewImage(details.img_url);
			setSelectedLanguage(
				details?.language === 'idn' ?
					{
						id: 1,
						name: 'Indonesia',
						value: 'idn'
					} : {
						id: 2,
						name: 'English',
						value: 'en'
					}
			);
			if (details?.parent_detail) {
				setSelectedParent({
					id: details?.parent_detail?.id,
					name: details?.parent_detail?.name
				});
			}
		}
		setEditor({ editorHtml: details?.description });
		if (details?.seo_robots) {
			const splitRobot = details?.seo_robots.split(',');
			if(splitRobot[0] == 'index') {
				setIsSeoIndex(true);
			} else if (splitRobot[0] == 'noindex') {
				setIsSeoIndex(false);
			}

			if (splitRobot[1] == 'follow') {
				setIsSeoFollow(true);
			} else if (splitRobot[1] == 'nofollow') {
				setIsSeoFollow(false);
			}
		}
	};

	const formArray: FormArrayType[] = [
		{
			index: 0,
			label: 'Title Tag',
			name: 'name',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'hospital name',
		},
		{
			index: 1,
			label: 'H1',
			name: 'seo_h1',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'Input Seo H1'
		},
		{
			index: 2,
			label: 'Hospital Code',
			name: 'hospital_code',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'hospital code',
			notice: 'Code tidak boleh sama kecuali untuk parent yang sama. Contoh: H1,H2,H3'
		},
		{
			index: 3,
			label: 'Hospital Phone',
			name: 'phone',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'tel',
			placeholder: 'hospital phone',
			notice: 'Jika terdapat extension nomor telepon dipisah dengan ; (Titik koma)'
		},
		{
			index: 4,
			label: 'Hospital Email',
			name: 'email',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'hospital email',
		},
		{
			index: 5,
			label: 'Description',
			name: 'description',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'editor',
			contentType: 'content'
		},
		{
			index: 6,
			label: 'Adress',
			name: 'address',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'address'
		},
		{
			index: 7,
			label: 'Embed Link (Maps)',
			name: 'embed_link',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'embed link',
			notice: 'contoh: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.8489166590493!2d106.77904607429765!3d-6.28358289370533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1a5749fd305%3A0x6d5b2c92cd82061b!2sPondok%20Indah%20Hospital!5e0!3m2!1sen!2sid!4v1681697849411!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
		},
		{
			index: 8,
			label: 'Share Link (Direction)',
			name: 'share_link',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'input',
			inputType: 'text',
			placeholder: 'share link',
			notice: 'contoh: https://goo.gl/maps/EH9tXQ1ue9xZsMLe9'
		},
		{
			index: 9,
			label: 'Upload Image',
			name: 'url_img',
			isMandatory: true,
			disabledCondition: isLoading || previewImage.length >= 3,
			renderType: 'file',
			inputType: 'file',
			maxImage: 3,
			imageType: ''
		},
		{
			index: 10,
			label: 'Status',
			name: 'status',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formStatus,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'status'
		},
		{
			index: 11,
			label: 'Language',
			name: 'language',
			isMandatory: true,
			disabledCondition: isLoading,
			listSelect: formLanguage,
			renderType: 'select',
			placeholder: 'Status ',
			selectType: 'language'
		},
		{
			index: 12,
			label: 'Parent Hospital',
			name: 'parent',
			isMandatory: false,
			disabledCondition: isLoading,
			renderType: 'parent',
			placeholder: 'Select Parent Hospital'
		},
		{
			index: 13,
			label: 'Slug Hospital',
			name: 'slug',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'slug',
			placeholder: 'Input Slug'
		},
		{
			index: 14,
			label: 'SEO Robots',
			name: 'seo_robots',
			isMandatory: true,
			disabledCondition: isLoading,
			renderType: 'seo_robot',
		}
	];

	const checkSlugDuplicate = async () => {
		setForm({
			...form,
			slug: form.slug.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
		});
		await dispatch(getAllHospitalSlug('', 1, '', 1000, selectedLanguage.value, form.slug));
	};

	useEffect(() => {
		if ((slug_data !== null || slug_data !== undefined) && slug_data?.length > 0) {
			setIsDuplicateSlug(true);
		} else {
			setIsDuplicateSlug(false);
		}
	}, [slug_data]);

	return {
		thTitles,
		openModalDelete,
		setOpenModalDelete,
		selectedItem,
		setSelectedItem,
		form,
		setForm,
		formStatus,
		selectedStatus,
		setSelectedStatus,
		onChangeForm,
		onClickSubmit,
		previewImage,
		setPreviewImage,
		data,
		imagePayload,
		setImagePayload,
		details,
		paginationFunction,
		pagination,
		onDeleteHospital,
		onSearchHospital,
		effectFunctions,
		formArray,
		errorForm,
		setErrorForm,
		editor,
		onChangeEditor,
		selectedLanguage,
		setSelectedLanguage,
		selectedParent,
		setSelectedParent,
		isDuplicateSlug,
		checkSlugDuplicate,
		setIsDuplicateSlug,
		slug_data,
		setIsSeoIndex,
		isSeoIndex,
		setIsSeoFollow,
		isSeoFollow,
	};
};

export default useHospital;