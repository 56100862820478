import {
	useCallback,
	useState,
	useEffect,
	useRef
} from 'react';
import {
	TypedUseSelectorHook,
	useDispatch,
	useSelector
} from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { useLocation } from 'react-router-dom';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useForm = (initialValues: any) => {
	const [values, setValues] = useState(initialValues);

	return [
		values, (e, key) => setValues({
			...values,
			[key]: e,
		}),
	];
};

export const useStateCallback = (initialState: any) => {
	const [state, setState] = useState(initialState);
	const cbRef: any = useRef(null);

	const setStateCallback = useCallback((state, cb) => {
		cbRef.current = cb; // store passed callback to ref
		setState(state);
	}, []);

	useEffect(() => {
		// cb.current is `null` on initial render, so only execute cb on state *updates*
		if (cbRef.current) {
			cbRef.current(state);
			cbRef.current = null;
		}
	}, [state]);

	return [state, setStateCallback];
};

export const useCustomDispatch = (action: any, callback: any = null) => {
	const dispatch = useAppDispatch();

	return useCallback((param: any, optParam: any = null) => {
		dispatch(action(
			param,
			optParam,
			callback
		));
	}, [
		dispatch,
		action,
		callback
	]);
};

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
};

export type Dimensions = {
	width: number;
	height: number;
};

export const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState<Dimensions>(getWindowDimensions());

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};

export const useDebounce = (value: string, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};