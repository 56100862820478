import { useEffect } from 'react';

import { hooks } from 'helpers';
import { createFilterStatus } from 'helpers/misc';
import { AdminView } from 'components';
import { getAllAwards } from 'store/actions';

import useAwardsAndAccreditation from './useAwardsAndAccreditation';

const AwardsAndAccreditation = () => {
  const dispatch = hooks.useAppDispatch();
  const {
    thTitles,
    openModalDelete,
    setOpenModalDelete,
    selectedItem,
    setSelectedItem,
    data,
    paginationFunction,
    pagination,
    onDeleteAward,
    onSearchAward,
    selectedStatus,
    setSelectedStatus,
    formStatus
  } = useAwardsAndAccreditation();

  useEffect(() => {
    dispatch(getAllAwards('', 1, ''));
  }, []);

  return (
    <AdminView
      cellType='edit_delete'
      createText='Create Award and Accreditation'
      thTitles={ thTitles }
      editLink='awards/edit'
      viewLink='awards/view'
      items={ data }
      title='Awards and Accreditations'
      type='awards'
      createLink='awards/post'
      showModalDelete={ openModalDelete }
      setShowModalDelete={ setOpenModalDelete }
      selectedItem={ selectedItem }
      setSelectedItem={ setSelectedItem }
      onClickPagination={ paginationFunction }
      pagination={ pagination }
      onDeleteModalClick={ onDeleteAward }
      onChangeSearch={ onSearchAward }
      withFilter={ true }
      filter={ createFilterStatus(formStatus) }
      getData={ getAllAwards }
      selectedStatus={ selectedStatus }
      setSelectedStatus={ setSelectedStatus }
    />
  );
};

export default AwardsAndAccreditation;