import { Pagination } from 'interfaces/common';
import {
  ClinicActionTypes, GET_LIST_CLINICS, GET_CLINIC_DETAILS, GET_CLINIC_CLINICS_HOSPITAL_CODE, GET_LIST_CLINICS_SLUG
} from './actionType';

interface EventAndClassesState {
  data: any;
  details: any;
  pagination: Pagination;
	slug_data: any;
}

const initState: EventAndClassesState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
	slug_data: [],
};

const ClinicReducer = (state = initState, action: ClinicActionTypes) => {
  switch (action.type) {
    case GET_LIST_CLINICS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };
    case GET_CLINIC_DETAILS:
      return {
        ...state,
        details: action.details
      };
		case GET_CLINIC_CLINICS_HOSPITAL_CODE:
			return {
				...state,
				data: action.dataClinicsByHospitalCode
			};
		case GET_LIST_CLINICS_SLUG:
			return {
				...state,
				slug_data: action.data
			};
    default:
      return state;
  }
};

export default ClinicReducer;