import { useEffect } from 'react';
import { hooks } from 'helpers';
import { useParams } from 'react-router-dom';
import { getAllClinics, getClinicDetails, getAllNews } from 'store/actions';
import { getRelatedNewsClinic } from 'store/NewsAndArticles/action';
import AdminForm from 'components/AdminView/AdminForm';

import useClinics from '../useClinics';
import { getListDoctorsDropdown } from 'store/Doctors/action';
import { ParamsType } from 'interfaces/common';

const ClinicsForm = () => {
  const dispatch = hooks.useAppDispatch();
	const { formArray, isEdit, details, isView } = useClinics();
	const { id } = useParams<ParamsType>();

  useEffect(() => {
    dispatch(getListDoctorsDropdown());
    dispatch(getAllClinics('', 1, true, 1000, 'idn'));
		dispatch(getAllNews('', 1, true, '', 1000, 'idn'));
		
  }, []);

  return (
    <AdminForm
      backLink='/clinics'
      formArray={ formArray }
      getDetailAction={ getClinicDetails }
      isAbleToSubmit={ true }
      localHooks={ useClinics }
      title='Clinics'
      type='clinics'
    />
  );
};

export default ClinicsForm;