import { Pagination } from 'interfaces/common';

export const GET_ALL_CONTACT_US = 'GET_ALL_CONTACT_US';
export const GET_DETAIL_CONTACT_US = 'GET_DETAIL_CONTACT_US';

export interface ContactUsData {
	id: string;
	hospital_code: string;
	full_name: string;
	gender: string;
	email: string;
	phone: string;
  title: string;
	content: string;
	created_date: string;
}

export interface GetAllContactUsList {
	type: typeof GET_ALL_CONTACT_US;
	data: ContactUsData[];
  pagination: Pagination;
}

export interface GetDetailContactUs {
	type: typeof GET_DETAIL_CONTACT_US;
	details: ContactUsData[];
	pagination: Pagination;
}

export type ContactUsActionTypes = GetAllContactUsList | GetDetailContactUs;
