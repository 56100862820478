import useAwardsAndAccreditation from '../useAwardsAndAccreditation';
import { getAllAwards, getAwardDetails } from 'store/actions';
import AdminForm from 'components/AdminView/AdminForm';
import { useEffect } from 'react';
import { hooks } from 'helpers';

const AwardsAndAccreditationForm = () => {
  const dispatch = hooks.useAppDispatch();
  const { formArray } = useAwardsAndAccreditation();
  useEffect(() => {
    dispatch(getAllAwards('', 1, '', 1000, 'idn'));
  }, []);

  return (
    <AdminForm
      backLink='/awards'
      formArray={ formArray }
      getDetailAction={ getAwardDetails }
      isAbleToSubmit={ true }
      localHooks={ useAwardsAndAccreditation }
      title='Award and Accreditation'
      type='awards'
    />
  );
};

export default AwardsAndAccreditationForm;