import { Pagination } from 'interfaces/common';
import {
  NewsAndArticlesActionTypes, GET_LIST_NEWS, GET_NEWS_DETAILS, GET_ALL_TAGS, GET_ALL_RELATED_NEWS, GET_LIST_NEWS_SLUG
} from './actionType';

interface EventAndClassesState {
  data: any;
  details: any;
  pagination: Pagination;
  tags: any;
  relatedNews: any;
	slug_data: any;
}

const initState: EventAndClassesState = {
  data: [],
  details: {},
  pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: 'id',
    page: 1,
    total_page: 1,
    keyword: '',
    sort: 'desc',
    category: '',
    is_publish: 'true',
  },
  tags: [],
  relatedNews: [],
	slug_data: [],
};

const NewsAndArtcileReducer = (state = initState, action: NewsAndArticlesActionTypes) => {
  switch (action.type) {
    case GET_LIST_NEWS:
      return {
        ...state,
        data: action.data?.map(item => ({
          ...item,
          name: item?.title
        })),
        pagination: action.pagination
      };
    case GET_NEWS_DETAILS:
      return {
        ...state,
        details: action.details
      };
    case GET_ALL_TAGS:
      return {
        ...state,
        tags: action.tags
      };
    case GET_ALL_RELATED_NEWS:
      return {
        ...state,
        relatedNews: action?.relatedNews?.map(item => ({
          ...item,
          name: item?.title_news
        }))
      };
		case GET_LIST_NEWS_SLUG:
			return {
				...state,
				slug_data: action.data,
			};
    default:
      return state;
  }
};

export default NewsAndArtcileReducer;