/* eslint-disable no-undef */
export const setItem = (key: string, value: any) => {
	return localStorage.setItem(`${ key }`, JSON.stringify(value));
};

export const getItem = (key: string, parsing: boolean) => {
	const jsonValue = localStorage.getItem(`${ key }`);

	return jsonValue !== null
		? parsing
			? JSON.parse(jsonValue)
			: localStorage.getItem(`${ key }`)
		: null;
};

export const clearItem = (key: string) => {
	return localStorage.removeItem(`${ key }`);
};

export const clearStorage = () => {
	return localStorage.clear();
};

export const clearToken = () => {
	const keysToRemove = ['token'];

	return keysToRemove.forEach(k => clearItem(k));
};

export const getToken = (tokenType: 'token') => {
	return getItem(tokenType, true);
};

export const setToken = (accessToken: string) => {

	const objToSet = [
		{
			key: 'token',
			value: accessToken
		}
	];

	return objToSet.forEach(item => setItem(item.key, item.value));
};
