import { Pagination } from 'interfaces/common';

export const GET_ALL_FOOTER = 'GET_ALL_FOOTER';
export const GET_ALL_FOOTER_CATEGORY = 'GET_ALL_FOOTER_CATEGORY';
export const GET_DETAIL_FOOTER = 'GET_DETAIL_FOOTER';
export const GET_ALL_FOOTER_SLUG = 'GET_ALL_FOOTER_SLUG';

export interface FooterData {
  title: string;
  slug: string;
	sub_string: string;
	content: string;
	author: string;
  id: string;
  img_url: { url: string; };
	footer_category: string;
	is_publish: boolean;
	posted_date: string;
	created_date: string;
	updated_date: string;
	name: string;
}

export interface GetAllFooter {
	type: typeof GET_ALL_FOOTER;
  data: FooterData[];
  pagination: Pagination;
}

export interface GetAllFootersCategory {
	type: typeof GET_ALL_FOOTER_CATEGORY;
	data: FooterData[];
	pagination: Pagination;
}

export interface GetFooterBySlug {
	type: typeof GET_DETAIL_FOOTER;
	details: FooterData[];
	pagination: Pagination;
}

export interface GetAllFooterSlug {
	type: typeof GET_ALL_FOOTER_SLUG;
	data: FooterData[];
}

export type FooterActionTypes = GetAllFooter | GetAllFootersCategory | GetFooterBySlug | GetAllFooterSlug;
