import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface PropsState {
	editorHtml: string;
	handleChange: any;
	customModules?: any;
	className?: string;
	placeholder?: string;
	disabled?: boolean;
}

function Editor (props: PropsState) {
	const modules: Record<string, any> = {
		toolbar: [
			[
				{
					header: [
						1,
						2,
						3,
						4,
						5,
						6
					]
				}, { font: [] }
			],
			[{ size: [] }],
			[
				'bold',
				'italic',
				'underline',
				'strike',
				'blockquote'
			],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				{ indent: '-1' },
				{ indent: '+1' }
			],
			[
				'link',
				'image',
				'video'
			],
			['clean']
		],
		clipboard: { matchVisual: false }
	};

	const formats: string[] = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'video'
	];
	return (
		<ReactQuill
			theme='snow'
			modules={ props.customModules ? props.customModules : modules }
			formats={ formats }
			onChange={ props.handleChange }
			value={ props.editorHtml }
			placeholder={ props.placeholder }
			className={ props.className }
			readOnly={ props.disabled }
		/>
	);
}

export default Editor;
