import { RootState } from 'store';

const selectors = {
	// Misc
	loading: (state: RootState) => state.misc.loading,
	lazyLoad: (state: RootState) => state.misc.lazyLoad,
	pagination: (state: RootState) => state.pagination,
	showBurgerMenu: (state: RootState) => state.misc,

	// Auth
	auth: (state: RootState) => state.auth,

	// Dashboard
	eventAndClasses: (state: RootState) => state.eventAndClasses,

	// Hospitals
	hospitals: (state: RootState) => state.hospitals,

	// Facilities
	facilities: (state: RootState) => state.facilities,

	// News
	news: (state: RootState) => state.news,

	// Banners
	banners: (state: RootState) => state.banners,

	// Admins
	admins: (state: RootState) => state.admins,

	// Awards
	awards: (state: RootState) => state.awards,

	// Doctors
	doctors: (state: RootState) => state.doctors,

	// Clinics
	clinics: (state: RootState) => state.clinic,

	// Patients
	patients: (state: RootState) => state.patients,

	// RBAC
	RBAC: (state: RootState) => state.RBAC,
	// Footers
	footers: (state: RootState) => state.footers,
	// Specialities
	specialities: (state: RootState) => state.specialities,
	// Contact Us
	contactUs: (state: RootState) => state.contactUs,

	// Booking Config
	bookingConfig: (state: RootState) => state.bookingConfig,
	// Group Specialties
	groupSpecialties: (state: RootState) => state.groupSpecialties,
	// Medical specialities
	medicalSpecialities: (state: RootState) => state.medicalSpecialities
};

export { selectors };
